import { Utils } from '../Utilities/Utils';
import { dayjs } from '../Utilities/dayjs';

export const LocalStorageKeyToken = `${window.location.hostname}|AO.Token`;
export const EventNameLogout = 'current-user-logged-out';
export const EventNameToken = 'current-user-token';

enum TokenValidationResult {
  valid = 0,
  invalid = 1,
  expired = 2,
  expireSoon = 3,
}

//const getLocalStorageAuthKey = () => `${window.location.hostname}|${LocalStorageKeyToken}`;
//const authWorker = new Worker('/a.sw.js', { credentials: 'include', name: 'auth-worker' });
/**
 * Sends the specified message to the web worker
 * @param message The message to send
 * @param to The worker to send the message to
 */
export const getAuthTokenSW = (message: any) =>
  new Promise(function (resolve, reject) {
    const messageChannel = new MessageChannel();

    messageChannel.port1.onmessage = function (event) {
      // Only for fetch errors, as these get retried
      if (event.data?.error) {
        reject(new Error(event.data.error));
      } else {
        resolve(event.data);
      }
    };

    //authWorker.postMessage(message, [messageChannel.port2]);
  });
/**
 * Validate a JWT token for expiry
 * @param {string} token JWT token to be validated
 * @returns {TokenValidationResult}
 */
const validateToken = (token: string): TokenValidationResult => {
  if (token && token.length > 0) {
    const [payload] = token?.split('.');
    if (payload) {
      try {
        const cred = JSON.parse(atob(payload));
        if (cred && cred.exp) {
          const dt = dayjs.unix(cred.exp);
          //if token is already expired
          if (dt.isBefore(dayjs())) {
            return TokenValidationResult.expired;
          } else if (!dt.isSameOrAfter(dayjs().add(10, 'seconds'))) {
            return TokenValidationResult.expireSoon;
          }
        }
      } catch {
        return TokenValidationResult.invalid;
      }
      return TokenValidationResult.valid;
    }
  }
  return TokenValidationResult.invalid;
};

const InMemoryJWTManager = () => {
  let jwtToken: string | null = null;
  let isRefreshing: Promise<any> | null = null;
  let refreshTimeOutId: any;

  /**
   * This countdown feature is used to renew the JWT before it's no longer valid
   * in a way that is transparent to the user.
   * @param {number} delay Delay in seconds, default 300
   */
  const refreshToken = (delay: number = 5 * 60) => {
    abortRefreshToken();
    if (delay <= 30) delay = 5 * 60;
    //console.debug('Refresh timer set for ', delay);
    // Validity period of the token in seconds, minus 8 seconds
    const delayMs = (delay - 10) * 1000;
    refreshTimeOutId = setTimeout(getRefreshedToken, delayMs);
  };
  /**
   * Clear timeout for refresh token
   */
  const abortRefreshToken = () => {
    try {
      if (refreshTimeOutId) {
        clearTimeout(refreshTimeOutId);
        refreshTimeOutId = null;
      }
    } catch {}
  };

  const waitForTokenRefresh = async () => {
    if (!isRefreshing) {
      return Promise.resolve();
    }
    await isRefreshing;
    isRefreshing = null;
    return true;
  };

  /**
   * Check for access token and assign it if a valid token
   */
    const checkAndGetFromSession = () => {      
    let token = jwtToken || '';
    let result = validateToken(token);
    //console.debug('token state', result, token);
    let status =
      result === TokenValidationResult.valid || result === TokenValidationResult.expireSoon;
    if (!status) {
        token = Utils.getLocal(LocalStorageKeyToken);
      result = validateToken(token);
      //console.debug('token session', result, token);
      status =
        result === TokenValidationResult.valid || result === TokenValidationResult.expireSoon;
      if (status) {
        jwtToken = token;
      }
    }
    return { status, token, result };
  };
  /**
   * The method make a call to the refresh-token endpoint
   * If there is a valid cookie, the endpoint will set a fresh jwt in memory.
   * @param {boolean} useSessionToken True to use existing session token, if exists and valid
   * @returns
   */
  const getRefreshedToken = async (useSessionToken?: boolean) => {
    if (useSessionToken) {
      const res = checkAndGetFromSession();
      if (res.status) {
        jwtToken = res.token;
        //console.debug('using session token');
        return res.token;
      } else {
        //console.debug('session token status', res.result, res.token);
      }
    }
    await waitForTokenRefresh();
    return await getTokenAsync('getRefreshedToken');
  };

  /**
   * Get current auth token
   * @returns string - auth token
   */
  const getToken = () => jwtToken;

  /**
   * Set new auth token
   * @param token Auth token to be set
   * @param delay Delay in seconds to get refreshed
   * @returns true
   */
  const setToken = (token: string, delay?: number) => {
    jwtToken = token;
      Utils.setLocal(LocalStorageKeyToken, token);      
    if (delay && delay > 0) refreshToken(delay);
    return true;
  };

  /**
   * Clear auth token and refresh token hook,
   * Also send message to all tab about logout-event and clear sessionStorage
   * @returns true
   */
  const ereaseToken = (sendToAllTabs: boolean = false) => {
    jwtToken = null;
    abortRefreshToken();
    if (sendToAllTabs) Utils.sendMessageToAllTabs(EventNameLogout);
    sessionStorage.clear();
      localStorage.removeItem(LocalStorageKeyToken);
    return true;
  };

  const checkAuth = async () => {
    await waitForTokenRefresh();
    return getToken();
  };

  /**
   * Get new token from the server
   * @returns {Promise<string | null>}
   */
  const getTokenAsync = async (reqFrom?: string): Promise<string | null> => {
    if (isRefreshing) {
      console.debug('getToken skipped', new Date().toString(), reqFrom);
      return Promise.resolve(null);
    }

    //console.debug('getToken start', new Date().toString(), reqFrom);
    await waitForTokenRefresh();

    // isRefreshing = Axios.post<IApiResponse<any>>(
    //   '/auth/refreshtoken',
    //   {} as any,
    //   ApiUtility._axiosOptions({ withCredentials: true })
    // )
    //   .then((response) => {
    //     if (response.status === 401) {
    //       ereaseToken();
    //       //console.error('Token renewal failure', response);
    //     } else {
    //       const { result } = response.data;
    //       if (result && result.token) {
    //         const token = result.token;
    //         setToken(token);
    //         Utils.sendMessageToAllTabs(EventNameToken, { token });
    //         return token;
    //       }
    //     }
    //     return null;
    //   })
    //   .catch((err) => {
    //     //console.error('Error while refreshing token', err);
    //     if (err?.response?.status === 401) {
    //       ereaseToken();
    //       //stateUserContext.merge({ user: {}, loaded: true, status: UserStatus.Offline });
    //     }
    //     return null;
    //   })
    //   .finally(() => {
    //     isRefreshing = null;
    //   });

    return isRefreshing;
  };
  /**
   * Check existing token and get a new one from server if expired
   * @returns {Promise<boolean>}
   */
  const checkAndRefreshToken = async (reqFrom?: string): Promise<boolean> => {
    await waitForTokenRefresh();

    getAuthTokenSW({ request: 'token' }).then((t) => {
      console.debug('token from worker', t);
    });

   // const result = validateToken(getToken() || '');
    // if (result === TokenValidationResult.expired) {
    //   await getTokenAsync(reqFrom);
    //   //console.debug('token - refreshed');
    // } else if (result === TokenValidationResult.expireSoon) {
    //   getTokenAsync(reqFrom);
    //   //console.debug('token - refresh init');
    // }
    return true;
  };

  // This listener will allow to disconnect a session which started in another tab
  window.addEventListener('storage', (event) => {
    const message = Utils.getStorageMessage(event);
    if (message) {
      if (message.type === EventNameLogout) {
        ereaseToken();
        //console.debug('logged out from other tab');
      } else if (message.type === EventNameToken && message.token) {
        if (validateToken(message.token) === TokenValidationResult.valid) jwtToken = message.token;
        //console.debug('token from other tab, refresh postponed');
      }
    }
  });

  return {
    ereaseToken,
    getRefreshedToken,
    getToken,
    setToken,
    waitForTokenRefresh,
    abortRefreshToken,
    checkAuth,
    checkAndRefreshToken,
  };
};

export const InMemoryJWT = InMemoryJWTManager();
