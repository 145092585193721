import { Avatar, Col, Divider, Modal, Row, Tooltip, Button, Spin } from "antd";
import React, { useContext } from "react";
import { useClient } from "../../Context/ClientContext";
import { NavLink, useNavigate } from "react-router-dom";
import noteContext from "../../Context/StyleContext";
import { Utils } from "../../../Utilities/Utils";
import "../../Common/AddPageCommon.css";
import PurchaseService, {
  PurchaseOrderTypes,
} from "../../Services/PurchaseService";
import { useIndianState } from "../../Context/StateOptionsContext";
import { notificationContext } from "../../Common/PageRoute";
import { LoadingOutlined } from "@ant-design/icons";
import { IIndianState } from "../../../Types/CommonTypes";

const ViewPurchaseOrderModel: React.FC<{
  open: boolean;
  onCancel: any;
  invoiceId: string;
}> = (props) => {
  console.log(props);
  const navigate = useNavigate();
  const { windowWidth } = React.useContext(noteContext);
  const { currentClient } = useClient();
  const { stateOptions } = useIndianState();
  const { openNotification } = useContext(notificationContext);
  const [itemLength, setItemLength] = React.useState<number>(0);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [isGSTEnabled, setGstEnabled] = React.useState<boolean>(false);
  const [data, setData] = React.useState<any>({});
  const [isStateSame, setIsStateSame] = React.useState<boolean>(false);
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  React.useEffect(() => {
    if (props.invoiceId) {
      setLoading(true);
      PurchaseService.getPurchaseOrder(
        currentClient?.id!,
        PurchaseOrderTypes.PurchaseOrder,
        props.invoiceId
      )
        .then((res: any) => {
          if (res.result) {
            console.log(res.result);
            const order = res?.result?.invoice;
            setGstEnabled(res?.result?.isGstEnabled);
            setItemLength(order.items?.length);
            setIsStateSame(order?.isStateSame);
            const state = stateOptions?.find(
              (itm: IIndianState) => itm?.id === order?.supplyState
            ) as IIndianState | undefined;

            setData({
              tradingPartner: order.tradingPartner,
              phoneNo: order?.phoneNo,
              billingAddress: order?.billingAddress,
              shippingAddress: order?.shippingAddress,
              number: order?.number,
              supplyState: state?.name,
              date: Utils.getFormattedDate(order?.date),
              dueDate: Utils.getFormattedDate(order?.dueDate),
              items: order?.items,
              description: order?.description,
              totalQty: Utils.getFormattedNumber(order.amountSummary?.totalQty),
              totalUnitPrice: Utils.getFormattedNumber(
                order.amountSummary?.totalPrice
              ),
              totalDiscount: Utils.getFormattedNumber(
                order.amountSummary?.totalDis
              ),
              sellPrice: Utils.getFormattedNumber(
                order.amountSummary?.sellPrice
              ),
              dueBalance: Utils.getFormattedNumber(
                order.amountSummary?.dueBalance
              ),
              totalgst: order.amountSummary?.igst
                ? order.amountSummary?.igst
                : order.amountSummary?.sgst + order.amountSummary?.cgst,
              receivedAmount: Utils.getFormattedNumber(
                order.amountSummary?.receivedAmount
              ),
              totalInvoiceAmount: Utils.getFormattedNumber(
                order.amountSummary?.totalInvoiceAmount
              ),
            });
            setLoading(false);
          } else setLoading(false);
        })
        .catch((ex: any) => {
          openNotification("error", ex.message);
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient?.id, props.invoiceId]);

  return (
    <Modal
      open={props.open}
      width={1600}
      style={{ maxHeight: `calc(100% - 50px)`, overflowY: "auto" }}
      maskClosable={false}
      closable={false}
      onCancel={() => props.onCancel()}
      centered
      footer={
        <div style={{ marginTop: "0px" }}>
          <Button
            onClick={() => {
              props.onCancel();
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            disabled
            style={{ width: "90px" }}
            onClick={async () => {
              navigate(
                `/accounts/clients/${currentClient?.id}/purchase/editPurchaseOrder/${props.invoiceId}`
              );
            }}
          >
            Edit
          </Button>
        </div>
      }
    >
      <div style={{ padding: 15 }}>
        <Spin indicator={antIcon} spinning={loading}>
          <Row justify="space-between">
            <Col xl={17} lg={24} md={24} className="dashedBoxStyle">
              <Row gutter={24} className="ps-14" justify="space-between">
                <Col
                  lg={14}
                  md={11}
                  xs={23}
                  sm={23}
                  className="themeLightBgColor p-10"
                >
                  <Row gutter={[17, 2]}>
                    <Col lg={12}>
                      <span className="themeColor fw-500">Supplier</span>
                      <div>
                        <NavLink
                          to={`/accounts/clients/${currentClient?.id}/parties/${data.tradingPartner?.id}`}
                        >
                          {data.tradingPartner?.name}
                        </NavLink>
                      </div>
                    </Col>
                    <Col lg={12}>
                      <span className="themeColor fw-500">Phone No.</span>
                      <div>{data.phoneNo}</div>
                    </Col>
                    <Col lg={12}>
                      <span className="themeColor fw-500">Billing Address</span>
                      <div>{data.billingAddress}</div>
                    </Col>
                    <Col lg={12}>
                      <span className="themeColor fw-500">
                        Shipping Address
                      </span>
                      <div>{data.shippingAddress}</div>
                    </Col>
                  </Row>
                </Col>

                <Col lg={9} md={4} xs={23} sm={23} className="p-10">
                  <Row gutter={[17, 2]}>
                    <Col lg={12}>
                      <span className="themeColor fw-500">
                        Purchase Order No.
                      </span>
                      <div>{data.number}</div>
                    </Col>
                    <Col lg={8}>
                      <span className="themeColor fw-500">Place Of Supply</span>
                      <div>{data.supplyState}</div>
                    </Col>
                    <Col lg={12}>
                      <span className="themeColor fw-500">Date</span>
                      <div>{data.date}</div>
                    </Col>
                    <Col lg={8}>
                      <span className="themeColor fw-500">Expiry Date</span>
                      <div>{data.dueDate}</div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            {windowWidth <= 1024 ? (
              ""
            ) : (
              <Col
                xl={4}
                lg={7}
                md={7}
                className="fw-400 themeColor textEnd"
                style={{ fontSize: "25px" }}
              >
                Purchase Order
              </Col>
            )}
          </Row>

          <table
            className="ca-viewmodel-table"
            style={{ fontSize: windowWidth <= 1024 ? "10.3px" : "" }}
          >
            <thead>
              <tr>
                <th colSpan={3} rowSpan={2} style={{ width: "7%" }}>
                  S No.
                </th>
                <th
                  rowSpan={2}
                  style={{
                    width: "11%",
                    borderLeft: "1px solid rgb(240, 245, 255)",
                  }}
                >
                  Item
                </th>
                <th
                  rowSpan={2}
                  style={{ width: windowWidth <= 1024 ? "18%" : "18%" }}
                >
                  Description
                </th>
                <th rowSpan={2} style={{ width: "10%" }}>
                  Account
                </th>
                <th rowSpan={2} style={{ width: "5%" }}>
                  Qty
                </th>
                <th rowSpan={2} style={{ width: "6%" }}>
                  Unit
                </th>
                <th rowSpan={2} style={{ width: "9%" }}>
                  Unit Price
                </th>
                <th colSpan={2} style={{ width: "12%", paddingLeft: "29px" }}>
                  Discount
                </th>
                {isGSTEnabled && (
                  <th colSpan={2} style={{ width: "12%", paddingLeft: "39px" }}>
                    GST %
                  </th>
                )}
                <th rowSpan={2} style={{ width: "14%", textAlign: "end" }}>
                  Total Amount
                </th>
              </tr>
              <tr>
                <th style={{ width: "6%" }}>%</th>
                <th style={{ width: "6%" }}>₹</th>
                {isGSTEnabled && <th style={{ width: "6%" }}>%</th>}
                {isGSTEnabled && <th style={{ width: "6%" }}>₹</th>}
              </tr>
            </thead>

            <tbody>
              {data.items?.map((item: any, i: any) => {
                return (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>
                      {item.isService ? (
                        <Tooltip
                          title="Service"
                          placement="left"
                          color="#1d39c4"
                        >
                          <Avatar
                            style={{
                              cursor: "pointer",
                              verticalAlign: "middle",
                              color: "#1d39c4",
                              backgroundColor: "#f0f5ff",
                              borderColor: "#adc6ff",
                              fontWeight: "bold",
                            }}
                            size="small"
                          >
                            S
                          </Avatar>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Goods" placement="left" color="#389e0d">
                          <Avatar
                            style={{
                              cursor: "pointer",
                              verticalAlign: "middle",
                              color: "#389e0d",
                              backgroundColor: "#f6ffed",
                              borderColor: "#b7eb8f",
                              fontWeight: "bold",
                            }}
                            size="small"
                          >
                            G
                          </Avatar>
                        </Tooltip>
                      )}
                    </td>
                    <td>
                      {item.isTaxable ? (
                        <Tooltip
                          title="With Tax"
                          placement="right"
                          color="#08979C"
                        >
                          <Avatar
                            style={{
                              cursor: "pointer",
                              verticalAlign: "middle",
                              color: "#08979C",
                              backgroundColor: "#E6FFFB",
                              borderColor: "#08979C",
                              fontWeight: "bold",
                            }}
                            size="small"
                          >
                            T
                          </Avatar>
                        </Tooltip>
                      ) : (
                        <Tooltip
                          title="WithOut Tax"
                          placement="right"
                          color="#531DAB"
                        >
                          <Avatar
                            style={{
                              cursor: "pointer",
                              verticalAlign: "middle",
                              color: "#531DAB",
                              backgroundColor: "#F9F0FF",
                              borderColor: "#531DAB",
                              fontWeight: "bold",
                            }}
                            size="small"
                          >
                            WT
                          </Avatar>{" "}
                        </Tooltip>
                      )}
                    </td>

                    <td>
                      <NavLink
                        to={`/accounts/clients/${currentClient?.id}/purchase/inventory`}
                        state={{
                          id: item.id,
                          type: "sales",
                          itemType: item.isService
                            ? "purchaseService"
                            : "purchaseGoods",
                        }}
                      >
                        {item.name}
                      </NavLink>
                    </td>
                    <td>{item?.description}</td>
                    <td>{item.account?.name}</td>
                    <td>{Utils.getFormattedNumber(item.amount?.qty)}</td>
                    <td>{item.amount?.unit?.name}</td>
                    <td>{Utils.getFormattedNumber(item.amount?.price)}</td>
                    <td>{item.amount?.discount}%</td>
                    <td>
                      {Utils.getFormattedNumber(item.amount?.discountRupee)}
                    </td>
                    {isGSTEnabled && <td>{item.amount?.tax?.name}</td>}
                    {isGSTEnabled && (
                      <td>
                        {Utils.getFormattedNumber(item.amount?.taxAmount)}
                      </td>
                    )}
                    <td style={{ textAlign: "end" }}>
                      {Utils.getFormattedNumber(item.amount?.totalAmount)}
                    </td>
                  </tr>
                );
              })}
            </tbody>

            {itemLength > 0 && (
              <tfoot>
                <tr>
                  <th colSpan={6}>Total</th>
                  <th colSpan={2} style={{ textAlign: "start" }}>
                    {data.totalQty}
                  </th>
                  <th style={{ textAlign: "start" }}>
                    ₹ {data.totalUnitPrice}
                  </th>
                  <th colSpan={2}>₹ {data.totalDiscount}</th>
                  {isGSTEnabled && (
                    <th colSpan={2}>
                      ₹ {Utils.getFormattedNumber(data.totalgst)}
                    </th>
                  )}
                  <th colSpan={2} style={{ textAlign: "end" }}>
                    ₹ {data.totalInvoiceAmount}
                  </th>
                </tr>
              </tfoot>
            )}
          </table>

          <Row className="dashedBorderTop pt-10">
            <Col lg={12} style={{ paddingTop: "15px" }}>
              <p>Description : {data?.description}</p>
            </Col>
            <Col lg={6} offset={5}>
              <Row gutter={[0, 8]}>
                <Col lg={9}>Selling Price</Col>
                <Col lg={15} className="textEnd">
                  ₹ {data?.sellPrice}
                </Col>
                <Col lg={9}>Discount</Col>
                <Col lg={15} className="textEnd">
                  ₹ {data?.totalDiscount}
                </Col>
                {isGSTEnabled && (
                  <>
                    {isStateSame ? (
                      <>
                        <Col lg={9}>CGST</Col>
                        <Col lg={15} className="textEnd">
                          ₹ {Utils.getFormattedNumber(data?.totalgst / 2)}
                        </Col>
                        <Col lg={9}>SGST</Col>
                        <Col lg={15} className="textEnd">
                          ₹ {Utils.getFormattedNumber(data?.totalgst / 2)}
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col lg={9}>IGST</Col>
                        <Col lg={15} className="textEnd">
                          ₹ {Utils.getFormattedNumber(data?.totalgst)}
                        </Col>
                      </>
                    )}
                  </>
                )}
              </Row>
              <Divider className="my-5 grayBg" />
              <Row gutter={[12, 2]}>
                <Col lg={18}>Total Purchase Order Value</Col>

                <Col lg={6} className="textEnd">
                  ₹ {data?.totalInvoiceAmount}
                </Col>
              </Row>
              <Divider
                style={{
                  borderBottom: "2px solid rgb(203, 203, 203)",
                }}
                className="my-5"
              />
            </Col>
          </Row>
        </Spin>
      </div>
    </Modal>
  );
};
export default ViewPurchaseOrderModel;
