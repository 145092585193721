import { Button, Divider, Form, Input, InputNumber, Modal } from "antd";
import React from "react";
import ClientService from "../../Services/ClientService";

const AddGst: React.FC<{
  open: boolean;
  name: string;
  abbreviation: string;
  rate: number;
  id?: number;
  onCancel: any;
  setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
  notification: any;
}> = (props) => {
  const [form] = Form.useForm();

  React.useEffect(() => {
    if (props?.id) {
      form.setFieldsValue({
        name: props.name,
        rate: props.rate,
        abbreviation: props.abbreviation,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.id]);

  // --------- Add & Edit Post Api ---------
  const addGST = () => {
    form.validateFields().then(async (value: any) => {
      const PostGstData = {
        ...value,
        isActive: true,
        isEdit: true,
      };
      await ClientService.postGst(PostGstData, props?.id)
        .then((res: any) => {
          if (res?.result) {
            console.log(res?.result);
            props.notification(
              "success",
              `${props?.id ? " Edit" : "Add"} GST Add Successfully`
            );
            props.onCancel();
            props.setTrigger((x: boolean) => !x);
          }
        })
        .catch((error: any) => props.notification("error", error));
    });
  };

  return (
    <div>
      <Modal
        open={props.open}
        title={!props?.id ? "Add GST" : "Edit GST"}
        width={450}
        maskClosable={false}
        closable={false}
        centered
        footer={
          <div style={{ marginTop: "0px" }}>
            <Button
              onClick={() => {
                form.resetFields();
                props.onCancel();
              }}
            >
              Cancel
            </Button>
            <Button type="primary" onClick={() => addGST()}>
              {!props?.id ? "Save" : "Update"}
            </Button>
          </div>
        }
      >
        <Form
          form={form}
          name="purchases-item"
          autoCapitalize="false"
          requiredMark={false}
          disabled={false}
          colon={false}
          labelAlign="left"
          labelCol={{ flex: "110px" }}
        >
          <>
            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true, message: "Name is required" }]}
              className="input-item"
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="rate"
              label="GST Rate"
              className="input-item"
              rules={[{ required: true, message: "GST Rate is required" }]}
            >
              <InputNumber
                min={0}
                max={100}
                controls={false}
                addonAfter="%"
                style={{ width: "100%" }}
              />
            </Form.Item>

            <Form.Item
              name="abbreviation"
              label="Abbreviation"
              className="input-item"
            >
              <Input />
            </Form.Item>

            <Divider className="divider-purchase" />
          </>
        </Form>
      </Modal>
    </div>
  );
};

export default AddGst;
