import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Input,
  notification,
  Row,
  Select,
  Skeleton,
  Space,
  Table,
  //Tag,
  Tooltip,
} from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { useIndianState } from "../../Context/StateOptionsContext";
import ClientService, {
  BusinessNatures,
  getBusinessTypeName,
} from "../../Services/ClientService";
import AddOrEditClient from "./AddOrEditClient";
import { NavLink } from "react-router-dom";
import React from "react";
import { dayjs } from "../../../Utilities/dayjs";
import { BusinessTypes, CompanyGSTTypes } from "../../../Types/CommonConstants";
import "../../Common/Acc_Common.css";
import { useTheme } from "../../Context/ThemeContext";
import { useNavigate } from "react-router-dom";
//import { RiHistoryFill } from "react-icons/ri";
import search from "../../Images/search.svg";
import ClientDashboard from "./ClientDashboard";
import useNotification from "antd/es/notification/useNotification";

interface DataType {
  key: string;
  id: string;
  clientId: string;
  //autoId: string;
  bName: string;
  aliasName: string;
  //name: string;
  type: string;
  gstin: string;
  state: number;
  contactInformation: any;
  // email: string;
  // phone: number;
  pan: any;
}
type NotificationType = "success" | "error";

export const GSTTypeOptions = [
  {
    key: CompanyGSTTypes.Unregistered,
    value: CompanyGSTTypes.Unregistered,
    label: "Unregistered",
  },
  {
    key: CompanyGSTTypes.Regular,
    value: CompanyGSTTypes.Regular,
    label: "Regular",
  },
  {
    key: CompanyGSTTypes.Composition,
    value: CompanyGSTTypes.Composition,
    label: "Composition",
  },
  {
    key: CompanyGSTTypes.Consumer,
    value: CompanyGSTTypes.Consumer,
    label: "Consumer",
  },
];

export const BusinessTypeOptions = [
  { key: 0, value: 0, label: "Select Type" },
  {
    key: BusinessTypes.PrivateLimited,
    value: BusinessTypes.PrivateLimited,
    label: "Private Limited",
  },
  {
    key: BusinessTypes.PublicLimited,
    value: BusinessTypes.PublicLimited,
    label: "Public Limited",
  },
  {
    key: BusinessTypes.SoleProprietorship,
    value: BusinessTypes.SoleProprietorship,
    label: "Sole Proprietorship",
  },
  {
    key: BusinessTypes.Partnership,
    value: BusinessTypes.Partnership,
    label: "Partnership",
  },
  { key: BusinessTypes.LLP, value: BusinessTypes.LLP, label: "LLP" },
  { key: BusinessTypes.LLC, value: BusinessTypes.LLC, label: "LLC" },
  {
    key: BusinessTypes.JointVenture,
    value: BusinessTypes.JointVenture,
    label: "Joint Ventures",
  },
  {
    key: BusinessTypes.HUF,
    value: BusinessTypes.HUF,
    label: "Hindu Undivided Family",
  },
  { key: BusinessTypes.NGO, value: BusinessTypes.NGO, label: "NGO" },
  {
    key: BusinessTypes.GA,
    value: BusinessTypes.GA,
    label: "Govt. Authority (Local Authority)",
  },
];

export const BusinessNaturesTypeOptions = [
  { key: 0, value: 0, label: "Unspecified" },
  {
    key: BusinessNatures.Manufacturer,
    value: BusinessNatures.Manufacturer,
    label: "Manufacturer",
  },
  {
    key: BusinessNatures.ServiceProvider,
    value: BusinessNatures.ServiceProvider,
    label: "Service Provider",
  },
  // {
  //   key: BusinessNatures.Hybrid,
  //   value: BusinessNatures.Hybrid,
  //   label: "Hybrid",
  // },
  {
    key: BusinessNatures.Trader,
    value: BusinessNatures.Trader,
    label: "Trader",
  },
];

const Clients = () => {
  const { themeData } = useTheme();
  const navigate = useNavigate();
  const { stateOptions } = useIndianState();
  const [open, setOpen] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [apiResponse, setApiResponse] = useState([]);
  const [totalRecords, setTotalRecords] = React.useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [searchClient, setSearchClient] = useState("");
  const [editClient, setEditClient] = useState<any>();
  const [page, setPage] = React.useState(1);
  const [api, contextHolder] = notification.useNotification();
  const [pageSize, setPageSize] = React.useState<number>(15);
  const [businessType, setBusinessType] = React.useState<number>();
  const [width, setWidth] = React.useState<any>(window.innerWidth);
  const [filter, setfilter] = React.useState<any>({
    sortCol: "",
    sortDir: "",
  });

  React.useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  const openNotification = (type: NotificationType, message: string) => {
    api[type]({
      message: "",
      description: message,
      duration: 2,
      closeIcon: false,
      style: {
        width: 350,
      },
    });
  };

  useEffect(() => {
    setLoading(true);
    getClientLists(
      (page - 1) * pageSize,
      pageSize,
      filter.sortCol,
      filter.sortDir,
      searchClient,
      businessType!
    );
  }, [
    businessType,
    filter.sortCol,
    filter.sortDir,
    page,
    pageSize,
    searchClient,
    trigger,
  ]);

  const statusOptions = [
    { value: "all", label: "All" },
    { value: "active", label: "Active" },
    { value: "inactive", label: "Inactive" },
    { value: "adhoc", label: "Adhoc" },
    { value: "deleted", label: "Deleted" },
  ];

  const columns: ColumnsType<DataType> = [
    {
      title: "S.No",
      dataIndex: "sno",
      key: "sno",
      width: width <= 1440 ? "5%" : "4%",
      render: (sno: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {sno}
        </Skeleton>
      ),
    },

    // {
    //   title: "Client ID",
    //   dataIndex: "clientId",
    //   key: "clientId",
    //   width: "8%",
    //   sorter: true,
    //   render: (_: any, record: any) => (
    //     <Skeleton
    //       active
    //       loading={loading}
    //       paragraph={{ rows: 1 }}
    //       title={false}
    //     >
    //       <Tag
    //         color="success"
    //         style={{
    //           textAlign: "center",
    //           width: 70,
    //         }}
    //       >
    //         {record.clientId}
    //       </Tag>
    //     </Skeleton>
    //   ),
    // },

    {
      title: "Business Name (Short Name)",
      dataIndex: "bName",
      key: "bName",
      width: "16%",
      sorter: true,
      ellipsis: {
        showTitle: false,
      },
      showSorterTooltip: true,
      render: (_: any, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          <NavLink
            style={{ color: "#395cd2" }}
            to={`/accounts/clients/${record.id}/dashboard`}
            onClick={() => {
              localStorage.setItem("id", record.id);
              localStorage.setItem("name", record.bName);
            }}
          >
            {record.aliasName !== null
              ? record.bName + " (" + record.aliasName + ")"
              : record.bName}
          </NavLink>
        </Skeleton>
      ),
    },
    {
      title: "Client Type",
      dataIndex: "type",
      key: "type",
      width: "10%",
      sorter: true,
      ellipsis: true,
      render: (_: any, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          <div>{record?.businessType}</div>
        </Skeleton>
      ),
    },
    {
      title: "GSTIN",
      dataIndex: "gstin",
      key: "gstin",
      width: "10%",
      // sorter: true,
      ellipsis: {
        showTitle: false,
      },
      showSorterTooltip: true,
      render: (v: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {v}
        </Skeleton>
      ),
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      width: "10%",
      sorter: true,
      ellipsis: {
        showTitle: false,
      },
      showSorterTooltip: true,
      render: (v: number) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {stateOptions?.map((s: any) => (s.id === v ? s.name : ""))}
        </Skeleton>
      ),
    },
    {
      title: "PAN No.",
      dataIndex: "pan",
      key: "pan",
      width: "10%",
      sorter: true,
      render: (v: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {v}
        </Skeleton>
      ),
    },

    {
      title: "Email",
      dataIndex: "contactInformation",
      key: "email",
      width: width <= 1440 ? "11%" : "18%",
      // sorter: true,
      ellipsis: {
        showTitle: false,
      },
      render: (v: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {v?.email}
        </Skeleton>
      ),
      showSorterTooltip: true,
    },
    {
      title: "Contact No.",
      dataIndex: "contactInformation",
      key: "phone",
      width: "8%",
      // sorter: true,
      render: (v: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {v?.phone}
        </Skeleton>
      ),
    },
    // {
    //   title: "Business Start",
    //   dataIndex: "businessStart",
    //   key: "businessStart",
    //   width: "10%",
    //   sorter: true,
    //   render: (v: any) => (
    //     <Skeleton
    //       active
    //       loading={loading}
    //       paragraph={{ rows: 1 }}
    //       title={false}
    //     >
    //       {v}
    //     </Skeleton>
    //   ),
    // },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: width <= 1024 ? "5%" : "4%",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          <EditOutlined
            disabled
            style={{
              color: "#444653",
            }}
            className="ca-edit-btn"
            onClick={() => {
              console.log(record);

              setEditClient(record);
              setOpen(true);
            }}
          />
        </Skeleton>
      ),
    },
  ];

  // Table Ascending & Desending Order
  const tableSort = (pagination?: any, sort?: any, filter?: any) => {
    setfilter({
      sortCol: filter.column !== undefined ? filter.columnKey : "name",
      sortDir:
        filter.column !== undefined
          ? filter.order === "ascend"
            ? "asc"
            : "desc"
          : "asc",
    });
    //console.log("sort", filter, filter.column);
  };

  const getClientLists = async (
    start: any,
    length: any,
    sortCol?: string,
    sortDir?: string,
    search?: string,
    type?: number
  ) => {
    await ClientService.getClientList(
      start,
      length,
      sortCol,
      sortDir,
      search,
      type
    )
      .then((res: any) => {
        if (res != null || res?.items?.length > 0) {
          //console.log("res id", res);
          setApiResponse(
            res?.items?.map((r: any, i: number) => ({
              key: r?.id,
              id: r?.id,
              sno: i + 1,
              bName: r?.bName,
              aliasName: r?.alias,
              clientId: r?.clientId,
              contactInformation: r?.contactInformation[0],
              name: r?.defaultContact?.name,
              businessType: getBusinessTypeName(r?.businessType),
              gstin: r?.gstin,
              state: r?.state,
              //contacts: r?.defaultContact?.name,
              //email: r?.defaultContact?.email,
              //phone: r?.defaultContact?.phone,
              pan: r?.pan,
            }))
          );
          setTotalRecords(res?.totalRecords);
        } else if (res.items.length === 0) {
          setApiResponse([]);
          setTotalRecords(res?.totalRecords);
        }
      })
      .catch((ex: any) => {
        console.log("error", ex);
      });
    setLoading(false);
  };

  const RedirectToPreviousRoute = () => {
    let xyz: any = localStorage.getItem("id");
    navigate(`/accounts/clients/${xyz}/dashboard`);

    // Redirect to the previous route
  };

  return (
    <React.Fragment>
      <>
        <div
          style={{
            justifyContent: "center",
            margin: "20px 20px",
            // border: "1px solid #eef2fe",
            // boxShadow: "0px 0px 10px 0px #96A1C84D",
          }}
        >
          <ClientDashboard />
        </div>
      </>

      <div>
        <AddOrEditClient
          open={open}
          setOpen={setOpen}
          setTrigger={setTrigger}
          notification={openNotification}
          clt={editClient}
        />

        <Card
          size="small"
          className="TableCard"
          style={{
            justifyContent: "center",
            margin: "20px 20px",
            // border: "1px solid #eef2fe",
            boxShadow: "0px 0px 10px 0px #96A1C84D",
          }}
        >
          <Row
            justify={"space-between"}
            style={{ marginTop: 10, padding: "10px 2px 3px 2px" }}
          >
            <Col>
              <Space size={width <= 768 ? 280 : 5}>
                <Button
                  type="primary"
                  onClick={() => {
                    setOpen(true);
                    setEditClient({ id: null });
                  }}
                >
                  <PlusOutlined />
                  Add Client
                </Button>
                <div className="clientHover" style={{ padding: "5px" }}>
                  <span style={{ cursor: "pointer" }}>Continue with</span>

                  <span
                    onClick={RedirectToPreviousRoute}
                    style={{
                      fontWeight: 500,
                      color: "rgb(68, 70, 83)",
                      cursor: "pointer",
                      fontSize: "16px",
                    }}
                  >
                    {" "}
                    {localStorage.getItem("name")}{" "}
                  </span>
                  {/* <span>
                    <RiHistoryFill />
                  </span> */}
                </div>
              </Space>
            </Col>
            <Col
            // style={{
            //   marginTop: width <= 768 ? 15 : "",
            // }}
            >
              <Row>
                <Col>
                  <Select
                    placeholder="Select Client Type"
                    style={{ width: 161 }}
                    options={BusinessTypeOptions}
                    onSelect={(val) => {
                      setPage(1);
                      setBusinessType(val);
                    }}
                  ></Select>
                </Col>
                {/* <Col style={{ marginLeft: "10px" }}>
                  <Select
                    disabled
                    defaultValue="all"
                    style={{ width: 100 }}
                    options={statusOptions}
                  />
                </Col> */}
                <Col style={{ marginLeft: "10px" }}>
                  <Input
                    style={{
                      width: "200px",
                      // marginTop: "-5px",
                      border: "1px solid #f1f3ff",
                    }}
                    allowClear={true}
                    placeholder="Search here"
                    suffix={
                      loading || searchClient ? (
                        ""
                      ) : (
                        <img alt={"search client"} src={search} />
                      )
                    }
                    onChange={(e: any) => {
                      setLoading(true);
                      if (!e.cancelable) {
                        const data = setTimeout(() => {
                          setPage(1);
                          setSearchClient(e.target.value);
                        }, 1000);
                        return () => clearTimeout(data);
                      } else {
                        setSearchClient(e.target.value);
                      }
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Table
            className={`Tabel-style table-${
              themeData?.componentSize ?? "middle"
            }`}
            columns={columns}
            dataSource={
              loading
                ? (Array.from({ length: 20 }, (_, index) => ({
                    key: `loading-${index}`,
                  })) as DataType[])
                : apiResponse
            }
            size="small"
            locale={{
              triggerDesc: "",
              triggerAsc: "",
              cancelSort: "",
            }}
            onChange={tableSort}
            pagination={{
              total: totalRecords,
              current: page,
              pageSize: pageSize,
              showSizeChanger: true,
              onChange: (page: number, pageSize: number) => {
                setPage(page);
                setPageSize(pageSize);
              },
              pageSizeOptions: ["15", "25", "50", "100"],
              showTotal: (totalRecords, page) =>
                `${page[0]}-${page[1]} of ${totalRecords} items`,
            }}
            // scroll={apiResponse.length > 15 ? { x: 600, y: 600 } : { x: 600 }}
            scroll={
              pageSize > 10
                ? { x: width <= 1024 ? 1000 : 700, y: 290 }
                : { x: width <= 1024 ? 1000 : 600 }
            }
          />
        </Card>
        {contextHolder}
      </div>
    </React.Fragment>
  );
};
export default Clients;
