import {
  Card,
  Col,
  DatePicker,
  Row,
  Select,
  Spin,
  Table,
  TableColumnsType,
  TableProps,
} from "antd";
import React from "react";
import { useClient } from "../../../Context/ClientContext";
import { dayjs } from "../../../../Utilities/dayjs";
import ReportsServiceNew from "../../../Services/ReportsServiceNew";
import { LoadingOutlined } from "@ant-design/icons";
import { voucherType } from "../VoucherDetailsByLedger";
import { getIndianStates } from "../../../Services/ClientService";
import { useTheme } from "../../../Context/ThemeContext";
import { Utils } from "../../../../Utilities/Utils";

const GSTR1 = () => {
  const { themeData } = useTheme();
  const { currentClient, companySettings } = useClient();
  const { RangePicker } = DatePicker;
  const [reportType, setReportType] = React.useState<any>(0);
  const [data, setData] = React.useState<any>();
  const [loading, setLoading] = React.useState(false);
  const [gstReportsData, setGstReportsData] = React.useState<any>();
  const [filterDate, setFilterDate] = React.useState<any>({
    from: dayjs(companySettings?.period?.from).format("DD/MM/YYYY"),
    to: dayjs(companySettings?.period?.to).format("DD/MM/YYYY"),
  });

  React?.useEffect(() => {
    if (currentClient?.id) {
      getList(currentClient?.id, filterDate?.from, filterDate?.to, reportType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient?.id, filterDate?.from, filterDate?.to, reportType]);

  const getList = async (
    clientId: string,
    fromDate: string = "",
    toDate: string = "",
    reportType: any
  ) => {
    setLoading(true);
    await ReportsServiceNew?.getGSTReports(
      clientId,
      fromDate,
      toDate,
      reportType
    ).then((res: any) => {
      if (res) {
        setData(res?.result);
        setLoading(false);
        // console?.log("data", data);
        setGstReportsData(
          res?.result?.flatMap((r: any, index: number) => {
            return r?.taxDetail?.map((t: any) => {
              return {
                key: index,
                partyGSTNo: r?.partyGSTNo,
                partyName: r?.partyName,
                number: r?.number,
                voucherType: r?.voucherType,
                hsnsac: r?.hsnsac,
                date: dayjs(r?.date).format("DD-MM-YYYY"),
                invoiceValue: Utils?.getFormattedNumber(r?.invoiceValue),
                supplyState: r?.supplyState,
                taxableAmount: Utils?.getFormattedNumber(t?.taxableAmount),
                igstAmt: Utils?.getFormattedNumber(t?.igstAmt),
                cgstAmt: Utils?.getFormattedNumber(t?.cgstAmt),
                sgstAmt: Utils?.getFormattedNumber(t?.sgstAmt),
                rate:
                  t?.igstRate === 0
                    ? Number(t?.cgstRate) + Number(t?.sgstRate)
                    : t?.igstRate,
              };
            });
          })
        );
      }
    });
  };

  const columns: TableColumnsType<any> = [
    {
      title: "GSTIN of Suppliers",
      dataIndex: "partyGSTNo",
      key: "partyGSTNo",
    },
    {
      title: "Trade/Legal Name",
      dataIndex: "partyName",
      key: "partyName",
    },
    {
      title: "Invoice Details",
      children: [
        {
          title: "Invoice Number",
          dataIndex: "number",
          key: "number",
          align: "center",
        },
        {
          title: "Invoice Type",
          dataIndex: "voucherType",
          key: "voucherType",
          render: (_, record: any) => <>{voucherType[record?.voucherType]}</>,
        },
        {
          title: "Invoice Date",
          dataIndex: "date",
          key: "date",
          align: "center",
        },
        {
          title: "Invoice Value",
          dataIndex: "invoiceValue",
          key: "invoiceValue",
          align: "right",
        },
      ],
    },
    {
      title: "Place of Suppliers",
      dataIndex: "supplyState",
      key: "supplyState",
      render: (_: any, record: any) => (
        <>{getIndianStates(record?.supplyState)}</>
      ),
    },
    {
      title: "Supply Attract Reverse Charge",
      dataIndex: "supply",
      key: "supply",
      render: (_: any, record: any) => (
        <>
          <p>No</p>
        </>
      ),
    },
    {
      title: "Rate(%)",
      dataIndex: "rate",
      key: "rate",
      align: "center",
    },
    {
      title: "HSN/SAC",
      dataIndex: "hsnsac",
      key: "hsnsac",
      align: "center",
    },
    {
      title: "Taxable Value",
      dataIndex: "taxableAmount",
      key: "taxableAmount",
      align: "right",
    },
    {
      title: "Tax Amount",

      children: [
        {
          title: "Integrated Tax",
          dataIndex: "igstAmt",
          key: "igstAmt",
          align: "right",
        },
        {
          title: "Central Tax",
          dataIndex: "cgstAmt",
          key: "cgstAmt",
          align: "right",
        },
        {
          title: "State/UT Tax",
          dataIndex: "sgstAmt",
          key: "sgstAmt",
          align: "right",
        },
      ],
    },
  ];
  return (
    <div>
      <Spin
        spinning={loading}
        indicator={<LoadingOutlined />}
        style={{
          justifyContent: "center",
          marginTop: "180px",
          textAlign: "center",
        }}
      >
        <Card
          className="ca-card-body"
          style={{
            boxShadow: "0px 0px 10px 0px #96A1C84D",
            height: "90vh",
            padding: "10px",
          }}
        >
          <Row gutter={10}>
            <Col>
              <Select
                placeholder="Select..."
                // value={selectedOption}
                style={{ width: 160 }}
                onChange={(val: any) => setReportType(val)}
                options={[
                  { value: 0, label: "GSTR1" },
                  { value: 1, label: "GSTR2" },
                  { value: 2, label: "GSTR3" },
                ]}
              />
            </Col>
            <Col>
              <RangePicker
                format="DD/MM/YYYY"
                onChange={(e: any) => {
                  e === null
                    ? setFilterDate({})
                    : setFilterDate({
                        ...filterDate,
                        from: dayjs(e[0])?.format("DD/MM/YYYY"),
                        to: dayjs(e[1])?.format("DD/MM/YYYY"),
                      });
                }}
              />
            </Col>
          </Row>
          <div style={{ width: "100%", margin: "0 auto" }}>
            <Table
              bordered
              dataSource={gstReportsData}
              size="small"
              style={{ marginTop: "30px" }}
              columns={columns}
              className={`Tabel-style table-${
                themeData?.componentSize ?? "middle"
              }`}
            />
          </div>
        </Card>
      </Spin>
    </div>
  );
};

export default GSTR1;
