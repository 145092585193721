import { Button, Modal, Spin } from "antd";
import React, { useContext, useState } from "react";
import DownloadService, { ViewType } from "../../Services/DownloadService";
import { useClient } from "../../Context/ClientContext";
import { notificationContext } from "../../Common/PageRoute";
import { LoadingOutlined } from "@ant-design/icons";

const ViewPreviewModel: React.FC<{
  open: boolean;
  onCancel: any;
  voucherId?: any;
  invVoucharType?: any;
  invoiceId?: any;
  partyId?: string;
}> = (props) => {
  const { currentClient } = useClient();
  const { openNotification } = useContext(notificationContext);
  const [type, setType] = useState<ViewType>(ViewType.Preview);
  const [previewData, setPreviewData] = useState<string>("");
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);
  const [emailLoading, setEmailLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  React.useEffect(() => {
    const downloadAndView = async (
      cliendId: string,
      voucherId: string,
      type: ViewType
    ) => {
      if (cliendId && voucherId) {
        if (type === ViewType.Pdf)
          await DownloadService.downloadPdf(cliendId, voucherId, type)
            .then((res) => {
              setDownloadLoading(false);
            })
            .catch((err) => {
              setDownloadLoading(false);
              openNotification.error("error", err.message);
            });
        else {
          setLoading(true);
          await DownloadService.previewPdf(cliendId, voucherId, type)
            .then((res) => {
              if (res.result) {
                setPreviewData(res.result);
                setLoading(false);
              }
            })
            .catch((err) => {
              openNotification.error("error", err.message);
              setLoading(false);
            });
        }
      } else {
        openNotification("error", "Invalid Details");
      }
    };

    downloadAndView(currentClient?.id!, props.voucherId, type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open, type]);

  const sendEmail = async (
    cliendId: string,
    voucherId: string,
    type: ViewType = ViewType.Pdf
  ) => {
    if (props?.partyId) {
      if (cliendId && voucherId) {
        await DownloadService.sendPdfEmail(
          cliendId,
          voucherId,
          type,
          props.partyId
        )
          .then((res) => {
            if (res.result) {
              openNotification("success", "Email sent successfully");
            } else {
              console.log("res", res);
              openNotification("error", res.message);
            }
            setEmailLoading(false);
          })
          .catch((err) => {
            setEmailLoading(false);
            openNotification("error", err.message);
          });
      } else {
        openNotification("error", "Invalid Party");
      }
    }
    setEmailLoading(false);
  };

  const handlePrint = () => {
    var css = `@page { size: A4 portrait; margin: 2.5cm} 
      @page {
      @top-center {
        content: none;
      }
      @bottom-center {
        content: none;
      }
    }`;
    var head = document.head || document.getElementsByTagName("head")[0];
    var style = document.createElement("style");

    style.type = "text/css";
    style.media = "print";
    style.appendChild(document.createTextNode(css));

    head.appendChild(style);
    const printWindow = window.open("", "");
    if (printWindow) {
      // printWindow.document.write(
      //   "<title>Ledger Report</title>"
      // );
      printWindow.document.write(previewData);
      printWindow.document.close();
      printWindow.print();
    }
  };

  return (
    <Modal
      open={props.open}
      width={"214.89mm"}
      title="Preview"
      onCancel={() => props.onCancel()}
      // closable={props.onCancel}
      style={{ maxHeight: `calc(100% - 50px)`, overflowY: "auto" }}
      centered
      footer={
        <div style={{ marginTop: "0px" }}>
          <Spin
            indicator={<LoadingOutlined />}
            spinning={loading}
            // style={{ marginBottom: "40px" }}
          >
            {props.partyId && (
              <Button
                type="primary"
                loading={emailLoading}
                onClick={() => {
                  setEmailLoading(true);
                  sendEmail(currentClient?.id!, props.voucherId, ViewType.Pdf);
                }}
              >
                Email Pdf
              </Button>
            )}
            <Button
              type="primary"
              onClick={() => {
                handlePrint();
              }}
            >
              Print
            </Button>
            <Button
              type="primary"
              loading={downloadLoading}
              onClick={() => {
                setType(ViewType.Pdf);
                setDownloadLoading(true);
              }}
            >
              Download
            </Button>
            <Button
              onClick={() => {
                props.onCancel();
              }}
            >
              Cancel
            </Button>
          </Spin>
        </div>
      }
    >
      <div dangerouslySetInnerHTML={{ __html: previewData }} />
    </Modal>
  );
};

export default ViewPreviewModel;
