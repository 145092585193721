import {
  Button,
  Card,
  Col,
  Dropdown,
  Input,
  Popover,
  Row,
  Skeleton,
  Table,
  Tooltip,
} from "antd";
import React from "react";
import { useTheme } from "../../Context/ThemeContext";
import { useClient } from "../../Context/ClientContext";
import { ColumnsType } from "antd/es/table";
import {
  DownOutlined,
  MoreOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import AddBankAccountModel from "./Banks/AddBankAccountModel";
import AdjustBankBalanceModel, {
  AdjustBankType,
} from "./Banks/AdjustBankBalanceModel";
import VoucherDetailsByLedger from "../Reports/VoucherDetailsByLedger";
import LedgerService, {
  SearchLedgerByGroupsTypes,
} from "../../Services/LedgerService";
import { Party_Tax } from "../../Services/VoucherServices";
import { useKeyboardShortcuts } from "../../Common/KeyboardShortcuts ";
import { Utils } from "../../../Utilities/Utils";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface ICashTransactionsDataType {
  key: string;
  type: any;
  bankNameTo: any;
  cashInHandTransactionDetails: { id: string; number: string };
  date: string;
  totalPrice: number;
}

const CashInHandAllEffected = () => {
  const { themeData } = useTheme();
  const { currentClient } = useClient();
  const [bankTotalRecords, setBankTotalRecords] = React.useState<number>(0);
  // const [bankTransactionsTotalRecords, setBankTransactionsTotalRecords] =
  //   React.useState<number>(0);
  const [bankLoading, setBankLoading] = React.useState<boolean>(false);

  const [bankTrigger, setBankTrigger] = React.useState<boolean>(false);
  const [bankList, setBankList] = React.useState<any[]>([]);

  const [searchBank, setSearchBank] = React.useState<any>();
  const [pageInv, SetPageInv] = React.useState<number>(1);
  // const [pageSizeInv, setPageSizeInv] = React.useState<number>(15);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pageItem, setPageItem] = React.useState<number>(1);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pageSizeItem, setPageSizeItem] = React.useState<number>(15);
  const [bank, setBank] = React.useState<{
    id: string;
    name: string;
    partyOrTax: Party_Tax;
  }>({
    id: "",
    name: "",
    partyOrTax: Party_Tax.other,
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filterOption, setFilterOption] = React.useState<{
    gstRate: number[];
    partyType: number;
    iState: number;
  }>({
    gstRate: [],
    partyType: 0,
    iState: 0,
  });
  const [openModel, setOpenModel] = React.useState<{
    type: string;
    param: any;
  }>({
    type: "",
    param: {},
  });
  const [bankAccountDetails, setBankAccountDetails] = React.useState<any>({});
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filterOrder, setFilterOrder] = React.useState<any>({
    sortCol: "",
    sortDir: "",
  });

  // const [filterDate, setFilterDate] = React.useState<any>({
  //   from: "",
  //   to: "",
  // });
  const [triggerBankTransactions, setTriggerBankTransactions] =
    React.useState<boolean>(false);
  // const [triggerBankAccountDetails, setTriggerBankAccountDetails] =
  //   React.useState<boolean>(false);
  const [openbankAccountPopArray, setOpenBankAccountNoPopArray] =
    React.useState<boolean[]>([]);
  const [openbankTransactionPopArray, setOpenBankTransactionPopArray] =
    React.useState<boolean[]>([]);
  const [refresh, setRefresh] = React?.useState(false);

  //----- Bank Account Table List -----
  React.useEffect(() => {
    if (currentClient?.id) {
      setBankLoading(true);
      GetLedgersByGroupTypes(
        currentClient?.id,
        (pageItem - 1) * pageSizeItem,
        // pageSizeItem
        // filterOrder.sortCol,
        // filterOrder.sortDir,
        searchBank
        // []
        // props.goodsItm?.type,
      );
    } else {
      console.error("currentClient is null Items");
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // props.bankTrigger,
    bankTrigger,
    pageInv,
    pageItem,
    searchBank,
    filterOrder.sortCol,
    filterOrder.sortDir,
    filterOption.gstRate,
  ]);

  interface IAllBankListDataType {
    key: string;
    id: string;
    currentBalance: number;
    ledgerName: string;
    sno: string;
    partyOrTax: Party_Tax;
  }

  // --------- Get All BankAccount Table Data  API ---------
  const GetLedgersByGroupTypes = async (
    clientId: string,
    start: number,
    length: number
  ) => {
    await LedgerService?.getLedgersByGroupName(
      clientId,
      SearchLedgerByGroupsTypes?.CashOnly
    )
      .then((res: any) => {
        // console.log("hfghdhhjfdgfdhjgdfhjg", res);
        if (res.length > 0) {
          // console.log(res.items);
          setBankList(
            res?.map((v: any, i: any) => {
              return {
                parent: v?.parent,
                ledgerName: v?.ledgerName,
                id: v?.id,
                bankDetail: v?.bankDetail !== null ? v?.bankDetail : [{}],
                groupName: v?.groupName,
                balance: v?.balance,
                partyOrTax: v?.partyOrTax,
              };
            })
          );

          !bank.id &&
            setBank({
              id: res[0].id,
              name: res[0].ledgerName,
              partyOrTax: res[0].partyOrTax,
            });
          setBankTotalRecords(res?.length);
        } else if (res.length === 0) {
          setBankList([]);
          setBankTotalRecords(res?.bankTotalRecords);
        }
        setBankLoading(false);
      })
      .catch((ex: any) => {
        console.error(ex.message);
        setBankLoading(false);
        setBankList([]);
      });
  };

  const bankAccountPopoverOnchange = (
    columnIndex: number,
    newOpen: boolean
  ) => {
    const updatedOpenPopArray = [...openbankAccountPopArray];
    updatedOpenPopArray[columnIndex] = newOpen;
    setOpenBankAccountNoPopArray(updatedOpenPopArray);
  };

  // const getList =async() => {
  //   let res =await
  // }

  // --------- Bank Account Table Columns  ---------
  const bankColomns: ColumnsType<IAllBankListDataType> = [
    {
      title: "Account Name",
      dataIndex: "ledgerName",
      key: "currentBalance",
      render: (_, record, index: number) => (
        <Skeleton
          active
          loading={bankLoading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          <Row>
            <Col span={23} style={{ cursor: "pointer" }}>
              <div
                onClick={() => {
                  console.log("record.id", record.id);
                  setBank({
                    id: record.id,
                    name: record.ledgerName,
                    partyOrTax: record?.partyOrTax,
                  });
                }}
                style={{
                  cursor: "pointer",
                  color: "black",
                }}
              >
                {record.ledgerName}
              </div>
            </Col>
            <Col span={1}>
              <Popover
                trigger="click"
                placement="bottom"
                arrow={{ pointAtCenter: true }}
                open={openbankAccountPopArray[index]}
                // arrow={{ pointAtCenter: true }}
                onOpenChange={(newOpen) =>
                  bankAccountPopoverOnchange(index, newOpen)
                }
                // onOpenChange={(x: boolean) =>
                //   setPopoverOpen({ ...filter, inventoryTable: x })
                // }
                content={
                  <Row style={{ maxWidth: "100px" }}>
                    <Col lg={24}>
                      <Button
                        style={{ width: 100 }}
                        onClick={() => {
                          setOpenModel({
                            type: "addBankAccount",
                            param: {
                              id: record.id,
                            },
                          });
                          bankAccountPopoverOnchange(index, false);
                        }}
                      >
                        Edit
                      </Button>
                    </Col>
                    <Col lg={24} style={{ marginTop: "6px" }}>
                      <Button style={{ width: 100 }}>Delete</Button>
                    </Col>
                  </Row>
                }
              >
                <MoreOutlined style={{ fontSize: "16px" }} />
              </Popover>
            </Col>
          </Row>
        </Skeleton>
      ),
    },
  ];

  //------- Get Bank Account Details -------
  React.useEffect(() => {
    if (bank?.id) {
      // setModelLoading(true);
      const get = async (id: string) => {
        await setBankAccountDetails(bankList.find((x) => x.id === bank?.id));
      };
      get(bank.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bank?.id]);

  // Table Loading

  // const bankTransactionsPopoverOnchange = (
  //   columnIndex: number,
  //   newOpen: boolean
  // ) => {
  //   const updatedOpenPopArray = [...openbankTransactionPopArray];
  //   updatedOpenPopArray[columnIndex] = newOpen;
  //   setOpenBankTransactionPopArray(updatedOpenPopArray);
  // };

  // const items: MenuProps["items"] = [
  //   {
  //     key: "1",
  //     label: (
  //       <div
  //         onClick={() => {
  //           setOpenModel({
  //             type: "adjustBankBalance",
  //             param: { id: "", type: AdjustBankType.BankToBank },
  //           });
  //         }}
  //       >
  //         Bank to Bank Transfer
  //       </div>
  //     ),
  //   },
  //   {
  //     key: "2",
  //     label: (
  //       <div
  //         onClick={() => {
  //           setOpenModel({
  //             type: "adjustBankBalance",
  //             param: { id: "", type: AdjustBankType.BankToCash },
  //           });
  //         }}
  //       >
  //         Bank to Cash Transfer
  //       </div>
  //     ),
  //   },
  //   {
  //     key: "3",
  //     label: (
  //       <div
  //         onClick={() => {
  //           setOpenModel({
  //             type: "adjustBankBalance",
  //             param: { id: "", type: AdjustBankType.CashToBank },
  //           });
  //         }}
  //       >
  //         Cash to Bank Transfer
  //       </div>
  //     ),
  //   },
  //   {
  //     key: "4",
  //     label: (
  //       <div
  //         onClick={() => {
  //           setOpenModel({
  //             type: "adjustBankBalance",
  //             param: { id: "", type: AdjustBankType.CashToCash },
  //           });
  //         }}
  //       >
  //         Cash to Cash Transfer
  //       </div>
  //     ),
  //   },
  // ];

  // ----- Short-Cut for Open Model -----F
  useKeyboardShortcuts("N", ["shiftKey"], () => {
    setOpenModel({
      type: "addBankAccount",
      param: {
        id: "",
        type: "",
      },
    });
  });

  return (
    <div>
      {/* //tabbackground */}
      <div
        style={{
          borderRadius: "8px",
          marginTop: 7,
          backgroundColor: "white",
          padding: "48px 0px 0px 0px",
          boxShadow: "0px 0px 10px 0px #96A1C84D",
        }}
      ></div>
      <Row style={{ marginTop: "0px" }}>
        <Col lg={6} style={{ paddingRight: "5px", paddingTop: "7px" }}>
          <Card
            className="ca-card-body"
            style={{ boxShadow: "0px 0px 10px 0px #96A1C84D" }}
          >
            <div>
              <Row
                align="middle"
                justify="space-around"
                style={{ borderBottom: "1px solid #DFE9F7" }}
              >
                <Col lg={15}>
                  <Input
                    allowClear={true}
                    // width: "220px",
                    style={{ margin: "5px 0px 5px 0px" }}
                    placeholder="Search.."
                    onChange={(e: any) => {
                      setBankLoading(true);
                      if (!e.cancelable) {
                        const searchBank = setTimeout(() => {
                          SetPageInv(1);
                          setSearchBank(e.target.value);
                        }, 1000);
                        return () => clearTimeout(searchBank);
                      } else {
                        setSearchBank(e.target.value);
                      }
                    }}
                    suffix={bankLoading || searchBank ? "" : <SearchOutlined />}
                  />
                </Col>
                {/* style={{ marginLeft: "8px" }} */}
                <Col lg={7}>
                  <Button
                    className="customButtonCss"
                    type="primary"
                    id=""
                    icon={<PlusOutlined />}
                    onClick={() => {
                      setOpenModel({
                        type: "addBankAccount",
                        param: {
                          id: "",
                          type: "",
                        },
                      });
                    }}
                  >
                    Cash
                  </Button>
                </Col>
              </Row>
            </div>
            <div
              style={{
                maxHeight: "100%",
                overflowY: "auto",
                position: "relative",
              }}
            >
              <Table
                rowClassName={(record, index) => {
                  return record.id === bank.id ? "ca-active-table" : "";
                }}
                className={`Tabel-style-none table-${
                  themeData?.componentSize ?? "middle"
                }`}
                // showHeader={false}
                // rowSelection={rowSelection}
                columns={bankColomns}
                dataSource={
                  bankLoading
                    ? (Array.from({ length: 20 }, (_, index) => ({
                        key: `loading-${index}`,
                      })) as IAllBankListDataType[])
                    : bankList
                }
                pagination={false}
                footer={() => (
                  <>
                    <div
                      style={{
                        position: "sticky",
                        bottom: 0,
                        backgroundColor: "#F5F8FF",
                      }}
                    >
                      <table>
                        <tbody>
                          <tr>
                            <th className="textStart">
                              Total Cash Account
                              {/* {filterOption.partyType === 0
                              ? " Party"
                              : filterOption.partyType === 1
                              ? " Customer"
                              : " Supplier"} */}
                            </th>
                            <th style={{}}>{bankTotalRecords}</th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </>
                )}
              />
            </div>
          </Card>
        </Col>

        <Col
          lg={18}
          style={{ paddingLeft: "5px", paddingTop: "7px" }}
          className="ca-card-body-padding"
        >
          <Card
            style={{
              marginBottom: "10px",
              boxShadow: "0px 0px 10px 0px #96A1C84D",
            }}
          >
            <Row style={{ paddingTop: "5px", paddingBottom: "5px" }}>
              <Col span={6} style={{ marginTop: "auto", marginBottom: "auto" }}>
                <div>
                  {bankAccountDetails.ledgerName?.length > 30 ? (
                    <Tooltip
                      title={bankAccountDetails?.ledgerName}
                      placement="top"
                      color="#3D4998"
                    >
                      {bankAccountDetails?.ledgerName.slice(0, 30) + "..."}
                    </Tooltip>
                  ) : (
                    <>
                      <span
                        style={{
                          paddingRight: "10px",
                          color: "rgb(139, 143, 165)",
                        }}
                      >
                        Ledger:
                      </span>
                      {bankAccountDetails?.ledgerName}
                    </>
                  )}
                </div>
              </Col>
              <Col span={6} style={{ marginTop: "auto", marginBottom: "auto" }}>
                <div>
                  <span
                    style={{
                      paddingRight: "10px",
                      color: "rgb(139, 143, 165)",
                    }}
                  >
                    Group Name:
                  </span>
                  {bankAccountDetails?.groupName}
                </div>
              </Col>
              <Col span={6} style={{ marginTop: "auto", marginBottom: "auto" }}>
                <div>
                  <span
                    style={{
                      paddingRight: "10px",
                      color: "rgb(139, 143, 165)",
                    }}
                  >
                    Balance:
                  </span>
                  {bankAccountDetails?.balance === 0
                    ? 0
                    : bankAccountDetails?.balance > 0
                    ? Utils.getFormattedNumber(bankAccountDetails?.balance) +
                      " Dr"
                    : Utils.getFormattedNumber(
                        Math.abs(bankAccountDetails?.balance)
                      ) + " Cr"}
                </div>
              </Col>
              <Col
                span={6}
                style={{
                  marginTop: "auto",
                  marginBottom: "auto",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <div>
                  {/* <Dropdown
                    menu={{ items }}
                    placement="bottomLeft"
                    trigger={["click"]}
                  >
                    <Button type="primary">
                      Deposit / Withdraw
                      <DownOutlined />
                    </Button>
                  </Dropdown> */}

                  <Button
                    onClick={() => {
                      setOpenModel({
                        type: "adjustBankBalance",
                        param: { id: "", type: AdjustBankType.undefined },
                      });
                    }}
                    type="primary"
                  >
                    Deposit / Withdrawal
                  </Button>
                </div>
              </Col>
            </Row>
          </Card>

          <Card
            className="ca-card-body"
            style={{ boxShadow: "0px 0px 10px 0px #96A1C84D" }}
          >
            <div
              style={{
                height: `calc(100vh - 216px)`,
                // backgroundColor: "azure",
                overflowY: "auto",
              }}
            >
              {
                <VoucherDetailsByLedger
                  refresh={refresh}
                  ledgerId={bank?.id}
                  // filterFromDate={filterDate?.from}
                  // filterToDate={filterDate?.from}
                  ledgerName={bank?.name}
                />
              }
              {/* <Table
                className={`Tabel-style table-${
                  themeData?.componentSize ?? "middle"
                }`}
                columns={bankTransactionsColumns}
                pagination={false}
                dataSource={
                  bankLoading
                    ? (Array.from({ length: 25 }, (_, index) => ({
                        key: `loading-${index}`,
                      })) as IbankTransactionsDataType[])
                    : bankTransactionsList
                }
                footer={() => (
                  <>
                    <Row justify="space-between">
                      <Col lg={5} style={{ paddingLeft: 112 }}>
                        Closing Balance
                      </Col>
                      <Col lg={6} className="textEnd">
                        {bankBalance}
                      </Col>
                    </Row>
                  </>
                )}
              /> */}
            </div>
          </Card>
        </Col>
      </Row>

      {/* -------------ADD Bank Account ----------*/}
      {openModel.type === "addBankAccount" && (
        <AddBankAccountModel
          open={openModel.type === "addBankAccount"}
          onCancel={() => setOpenModel({ type: "", param: "" })}
          bankAccountTrigger={() => setBankTrigger(!bankTrigger)}
          id={openModel.param?.id}
          curentBankAccountId={bank.id}
          // triggerBankAccountDetails={() =>
          //   setTriggerBankAccountDetails(!triggerBankAccountDetails)
          // }
          allBankAccount={bankList}
        />
      )}
      {openModel.type === "adjustBankBalance" && (
        <AdjustBankBalanceModel
          open={true}
          onCancel={() => setOpenModel({ type: "", param: "" })}
          // bankAccountTrigger={() => setBankTrigger(!bankTrigger)}
          // triggerBankTransactions={() =>
          //   setTriggerBankTransactions(!triggerBankTransactions)
          // }
          type={openModel.param?.type}
          allBankAccount={bankList}
          bank={bank}
          setRefresh={setRefresh}
          id={openModel.param?.id}
          cashType={true}
          // item={{ id: bank.id, name: bankAccountDetails?.name }}
        />
      )}
    </div>
  );
};

export default CashInHandAllEffected;
