import {
  ArrowUpOutlined,
  BankOutlined,
  CalendarOutlined,
  DollarOutlined,
  EditOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  notification,
  Row,
  Statistic,
  Typography,
} from "antd";
import Meta from "antd/es/card/Meta";
import React from "react";
import ClientService, {
  getBusinessTypeName,
} from "../../Services/ClientService";
import { dayjs } from "../../../Utilities/dayjs";
import AddOrEditClient from "./AddOrEditClient";
import { NotificationType } from "../../Common/PageRoute";
import { Navigate, useNavigate } from "react-router-dom";

const ClientDashboard = () => {
  const [clienData, setClientData] = React.useState<any>([]);
  const [trigger, setTrigger] = React.useState(false);
  const [rowData, setRowData] = React?.useState<any>();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (type: NotificationType, message: string) => {
    api[type]({
      message: "",
      description: message,
      duration: 2,
      closeIcon: false,
      style: {
        width: 350,
      },
    });
  };
  React.useEffect(() => {
    const getClientSummary = async () => {
      let res = await ClientService?.getClientSummary("", "");
      if (res) {
        console.log("ClientRes", res);
        setClientData(res?.result);
      }
    };
    getClientSummary();
  }, [trigger]);

  return (
    <>
      <div>
        <Row justify={"space-between"} gutter={15}>
          {clienData.map((client: any, index: number) => (
            <Col span={6} key={index}>
              <Card
                className="dashboard-card"
                bordered={false}
                style={{
                  borderRadius: 10,
                  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Card.Meta
                  title={
                    <Row align="middle" justify="space-between">
                      <Typography.Title
                        level={5}
                        style={{ fontWeight: 500, marginBottom: 0 }}
                      >
                        <UserOutlined style={{ marginRight: 8 }} />
                        {client?.bName}
                      </Typography.Title>
                    </Row>
                  }
                />
                <Divider style={{ margin: "8px 0" }} />
                <Row
                  justify="space-between"
                  align="middle"
                  style={{ marginBottom: 4 }}
                >
                  <Col>
                    <Typography.Text type="secondary">
                      {getBusinessTypeName(client?.businessType)}
                    </Typography.Text>
                  </Col>
                  <Col>
                    <Typography.Text type="secondary">
                      {client?.gstin}
                    </Typography.Text>
                  </Col>
                </Row>
                <Divider style={{ margin: "8px 0" }} />
                <Row
                  justify="space-between"
                  align="middle"
                  style={{ marginBottom: 4 }}
                >
                  <Col>
                    <Typography.Text>Sales Voucher</Typography.Text>
                  </Col>
                  <Col>
                    <Typography.Text>Purchase Voucher</Typography.Text>
                  </Col>
                </Row>
                <Row
                  justify="space-between"
                  align="middle"
                  style={{ marginBottom: 4 }}
                >
                  <Col>
                    <Typography.Title level={5} style={{ margin: 0 }}>
                      <span>{`${client?.salesVouchers} No(s).`}</span>
                    </Typography.Title>
                  </Col>
                  <Col>
                    <Typography.Title level={5} style={{ margin: 0 }}>
                      <span>{`${client?.purchaseVouchers} No(s).`}</span>
                    </Typography.Title>
                  </Col>
                </Row>
                <Divider style={{ margin: "8px 0" }} />
                <Row
                  justify="space-between"
                  align="middle"
                  style={{ marginBottom: 4 }}
                >
                  <Col>
                    <Typography.Text>Payment Voucher</Typography.Text>
                  </Col>
                  <Col>
                    <Typography.Text>Receipt Voucher</Typography.Text>
                  </Col>
                </Row>
                <Row
                  justify="space-between"
                  align="middle"
                  style={{ marginBottom: 4 }}
                >
                  <Col>
                    <Typography.Title level={5} style={{ margin: 0 }}>
                      <span>{`${client?.paymentVouchers} No(s).`}</span>
                    </Typography.Title>
                  </Col>
                  <Col>
                    <Typography.Title level={5} style={{ margin: 0 }}>
                      <span>{`${client?.receiptVouchers} No(s).`}</span>
                    </Typography.Title>
                  </Col>
                </Row>
                {/* <Divider style={{ margin: "8px 0" }} />
                <Row
                  justify="space-between"
                  align="middle"
                  style={{ marginBottom: 4 }}
                >
                  <Col>
                    <BankOutlined style={{ marginRight: 8 }} />
                    <Typography.Text>Bank</Typography.Text>
                  </Col>
                  <Col>
                    <DollarOutlined style={{ marginRight: 8 }} />
                    <Typography.Text>Cash</Typography.Text>
                  </Col>
                </Row>
                <Row
                  justify="space-between"
                  align="middle"
                  style={{ marginBottom: 4 }}
                >
                  <Col>
                    <Typography.Title level={5} style={{ margin: 0 }}>
                      ₹ {client?.bankBalance}
                    </Typography.Title>
                  </Col>
                  <Col>
                    <Typography.Title level={5} style={{ margin: 0 }}>
                      ₹ {client?.cashBalance}
                    </Typography.Title>
                  </Col>
                </Row> */}
                <Divider style={{ margin: "8px 0" }} />
                <Row justify="space-between" align="middle">
                  <Col>
                    <Typography.Text>
                      <CalendarOutlined style={{ marginRight: 8 }} />
                      Last Entry Date
                    </Typography.Text>
                  </Col>
                  <Col></Col>
                </Row>
                <Row
                  justify="space-between"
                  align="middle"
                  style={{ marginBottom: 4 }}
                >
                  <Col>
                    <Typography.Title level={5} style={{ margin: 0 }}>
                      {dayjs(client?.lastEntryDate).format("DD/MM/YYYY")}
                    </Typography.Title>
                  </Col>
                  <Col>
                    <Typography.Text>
                      <Row gutter={10}>
                        <Col>
                          <Button
                            size="small"
                            type="primary"
                            onClick={() => {
                              navigate(
                                `/accounts/clients/${client.id}/dashboard`
                              );
                              localStorage.setItem("id", client.id);
                              localStorage.setItem("name", client.bName);
                            }}
                          >
                            Explore
                          </Button>
                        </Col>
                        <Col>
                          <Button
                            onClick={() => {
                              setRowData(client);
                              setOpen(true);
                            }}
                            type="primary"
                            size="small"
                          >
                            <EditOutlined />
                          </Button>
                        </Col>
                      </Row>
                    </Typography.Text>
                  </Col>
                </Row>
              </Card>
            </Col>
          ))}
        </Row>
        {contextHolder}
      </div>

      <AddOrEditClient
        open={open}
        setOpen={setOpen}
        setTrigger={setTrigger}
        notification={openNotification}
        clt={rowData}
      />
    </>
  );
};

export default ClientDashboard;
