import {
  FormOutlined,
  LoadingOutlined,
  PlusOutlined,
  SaveOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Space,
  Spin,
  Tag,
} from "antd";
import deleteImg from "../../../images/deletebutton.svg";
import { useContext, useEffect, useRef, useState } from "react";
import { Utils } from "../../../Utilities/Utils";
import { ConfirmationModal } from "../../Common/ConfirmationModal";
import { useClient } from "../../Context/ClientContext";
import { dayjs } from "../../../Utilities/dayjs";
import { ISelectGroup } from "../../../Types/CommonTypes";
import { notificationContext } from "../../Common/PageRoute";
import React from "react";
import LedgerService, {
  SearchLedgerByGroupsTypes,
} from "../../Services/LedgerService";
import VoucherServices, { Voucher_Type } from "../../Services/VoucherServices";
import { useParams } from "react-router-dom";
import { VoucherMode } from "../../Services/SalesService";
import ImportLedger from "./ImportLedger";
import AddLedger from "../Master/Ledger/AddLedger";

const SettingOpeningBalance = () => {
  const [form] = Form.useForm();
  // const { journalId } = useParams();
  const { currentClient, companySettings } = useClient();
  const { openNotification } = useContext(notificationContext);
  const [accounts, setAccounts] = useState<any[]>([]);
  const [listIndex, setListIndex] = useState<any>();
  const [initialRender, setInitialRender] = useState<boolean>(false);
  const [debitTotal, setDebitTotal] = useState<number>(0);
  const [creditTotal, setCreditTotal] = useState<number>(0);
  const [confirmation, setConfirmation] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [isDisabled, setDisabled] = useState<boolean>();
  const [transLength, setTransLength] = useState<number>(0);
  const [importedLedgers, setImportedLedgers] = useState<any>(null);
  const [journalId, setJournalId] = useState<string>("");

  const [trigger, setTrigger] = useState<boolean>(false);
  const [importModal, setImportModal] = React.useState<any>({
    type: "",
    param: {},
  });
  const [saveButtonLoading, setSaveButtonLoading] = useState<boolean>(false);
  const [hideImport, setHideImport] = useState<boolean>(false);
  const [requireParty, setRequireParty] = useState<{
    customer: boolean;
    supplier: boolean;
  }>({ customer: false, supplier: false });
  const [partyLedgerOption, setPartyLedgerOption] = useState<any[]>([]);
  const [selectedPartyOption, setSelectedPartyOption] = React.useState<any[]>(
    []
  );
  const [openModel, setOpenModel] = React.useState<{
    type: string;
    param: any;
  }>({
    type: "",
    param: "",
  });
  const [addLedgerResponse, setAddLedgerResponse] = useState<any>(null);

  useEffect(() => {
    if (addLedgerResponse) {
      const exists = partyLedgerOption.some(
        (option: any) => option?.id === addLedgerResponse.id
      );
      if (exists) {
        let partyLedger = [...(form.getFieldValue("partyLedger") || [])];
        partyLedger.pop();
        partyLedger.push({
          credit: "0.00",
          debit: "0.00",
          amount: 0,
          id: {
            label: addLedgerResponse?.ledgerName,
            value: addLedgerResponse.id,
            key: addLedgerResponse.id,
          },
        });
        partyLedger.push({
          credit: "0.00",
          debit: "0.00",
          amount: "0.00",
          id: {},
        });
        const index = partyLedger.findIndex(
          (ledgerArray) => ledgerArray?.id?.value === addLedgerResponse.id
        );
        form.setFieldValue("partyLedger", partyLedger);
        updateTotals();
        // partySelect(addLedgerResponse.id, index, addLedgerResponse.ledgerName);
        onClickSelectPartyLedger(addLedgerResponse.id, index);
        setAddLedgerResponse(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partyLedgerOption]);

  // useEffect(() => {
  //   setLoading(true);
  //   JournalService.getOpeningBalance(currentClient?.id!).then((res) => {
  //     if (res?.status) {
  //       const trans = res?.result?.transactions;
  //       setTransLength(trans?.length);
  //       if (res?.result?.accounts) {
  //         let acc: ISelectGroup[] = res?.result?.accounts?.map(
  //           (group: any) => ({
  //             label: group.typeName + " (" + group.groupName + ")",
  //             options: group.accounts?.map((a: AccountShort) => ({
  //               key: a.code,
  //               label: a.name,
  //               value: a.id,
  //               title: a.type,
  //               disabled: trans.find((t: any) => t.account?.id === a.id)
  //                 ? true
  //                 : false,
  //             })),
  //           })
  //         );
  //         setAccounts([...acc]);
  //       }
  //       let DebitTotal = debitTotal;
  //       let CreditTotal = creditTotal;
  //       const trnasactions = trans?.map((itm: any) => {
  //         DebitTotal += itm?.debit;
  //         CreditTotal += itm?.credit;
  //         return {
  //           account: {
  //             key: itm?.account?.code,
  //             label: itm?.account?.name,
  //             value: itm?.account?.id,
  //             title: itm?.account?.type,
  //           },
  //           debit: Utils.getFormattedNumber(itm?.debit),
  //           credit: Utils.getFormattedNumber(itm?.credit),
  //         };
  //       });
  //       setDebitTotal(DebitTotal);
  //       setCreditTotal(CreditTotal);
  //       form.setFieldsValue({
  //         date: dayjs(res.result?.date, "DD/MM/YYYY"),
  //         partyLedger: [...trnasactions, [{ credit: 0, debit: 0 }]],
  //       });
  //       console.log(form.getFieldsValue());
  //       console.log(dayjs(res.result?.date, "DD/MM/YYYY").format("DD/MM/YYYY"));

  //       setRequireParty({
  //         customer: res.result?.isCustomer,
  //         supplier: res.result?.isSupplier,
  //       });
  //       if (trans && trans.length > 0) {
  //         setDisabled(true);
  //       }
  //       setLoading(false);
  //     } else setLoading(false);
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [currentClient?.id, initialRender]);

  // opening balance get api
  useEffect(() => {
    const getOpeningBalance = async (cliendId: string) => {
      if (cliendId) {
        setLoading(true);
        await VoucherServices.openingEntryGet(cliendId).then((res) => {
          if (res) {
            setJournalId(res?.result?.id);
            const x = [
              ...res.result?.effectiveLedger,
              ...res.result?.partyLedger,
            ];

            const partyLedger = x.map((v: any, partyIndex: number) => {
              setSelectedPartyOption((prevState) => {
                const updatedSelectedOptions = [...prevState];
                updatedSelectedOptions[partyIndex] = v?.id;
                return updatedSelectedOptions;
              });

              return {
                ...v,
                debit: v?.isDebitNature
                  ? Utils.getFormattedNumber(Math.abs(v?.amount))
                  : "0.00",
                credit: !v?.isDebitNature
                  ? Utils.getFormattedNumber(Math.abs(v?.amount))
                  : "0.00",
                id: {
                  value: v?.id,
                  label: v?.ledgerName,
                  title: v?.partyOrTax,
                },
              };
            });

            if (partyLedger) {
              setDisabled(true);
              form.setFieldsValue({
                ...res.result,
                partyLedger: [
                  ...partyLedger,
                  { debit: "0.00", credit: "0.00" },
                ],
                date: dayjs(res.result?.date),
              });
            }
            setDebitTotal(res.result?.amountSummary?.totalInvoiceAmount);
            setCreditTotal(res.result?.amountSummary?.totalInvoiceAmount);
            setLoading(false);
          }
        });
      }
    };
    getOpeningBalance(currentClient?.id!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient?.id]);

  //   const AccountSelect = (val: any, index: number) => {
  //     const data = form.getFieldValue("partyLedger");
  //     const currentAccounts = data.filter(
  //       (itm: any) => itm?.account !== undefined
  //     );
  //     console.log(currentAccounts, data);
  //     if (val) {
  //       setAccounts(
  //         accounts.map((group: ISelectGroup) => {
  //           return {
  //             ...group,
  //             options: group.options.map((o: any) => {
  //               if (
  //                 currentAccounts.some(
  //                   (item: any) => item?.account?.key === o.key
  //                 )
  //               ) {
  //                 return {
  //                   ...o,
  //                   disabled: true,
  //                 };
  //               } else {
  //                 return {
  //                   ...o,
  //                   disabled: false,
  //                 };
  //               }
  //             }),
  //           };
  //         })
  //       );
  //     }
  //     const currentList = form.getFieldValue("partyLedger")[index];
  //     form.setFieldValue(["partyLedger", index], {
  //       ...currentList,
  //       debit: Utils.getFormattedNumber(0),
  //       credit: Utils.getFormattedNumber(0),
  //     });
  //   };

  const handleTotalChange = (List: any) => {
    // debugger;
    let filterList = List.filter(
      (v: any) => v?.credit !== undefined || v?.debit !== undefined
    );

    const TotalCredit = filterList.reduce((acc: number, curr: any) => {
      return (acc += Utils.getNumber(curr?.credit));
    }, 0);
    setCreditTotal(TotalCredit);

    const TotalDebit = filterList.reduce((acc: number, curr: any) => {
      return (acc += Utils.getNumber(curr?.debit));
    }, 0);
    setDebitTotal(TotalDebit);
  };

  const onCreditBlur = (val: string, index: number) => {
    const itemList = form.getFieldValue(["partyLedger"]);
    const updatedItems = [...itemList];
    if (val) {
      if (Utils.getNumber(val) !== 0) {
        updatedItems[index].debit = Utils.getFormattedNumber(0);
        updatedItems[index].credit = Utils.getFormattedNumber(val);
      } else updatedItems[index].credit = Utils.getFormattedNumber(val);
    } else updatedItems[index].credit = Utils.getFormattedNumber(0);
    form.setFieldValue("partyLedger", updatedItems);
    handleTotalChange(updatedItems);
  };

  const onDebitBlur = (val: string, index: number) => {
    const itemList = form.getFieldValue(["partyLedger"]);
    const updatedItems = [...itemList];
    if (val) {
      if (Utils.getNumber(val) !== 0) {
        updatedItems[index].credit = Utils.getFormattedNumber(0);
        updatedItems[index].debit = Utils.getFormattedNumber(val);
      } else updatedItems[index].debit = Utils.getFormattedNumber(val);
    } else updatedItems[index].debit = Utils.getFormattedNumber(0);
    form.setFieldValue("partyLedger", updatedItems);
    handleTotalChange(updatedItems);
  };

  const debitRefs: any = useRef([]);
  const creditRefs: any = useRef([]);
  const updateTotals = () => {
    // const updatedFields = form.getFieldValue(["partyLedger"]);
    const currentFields = form.getFieldValue(["partyLedger"]) || [];
    const nonNullFields = currentFields.filter(
      (item: any) => item !== null && item !== undefined
    );

    const totalDebit = nonNullFields.reduce(
      (sum: number, item: any) => sum + Utils.getNumber(item.debit),
      0
    );

    const totalCredit = nonNullFields.reduce(
      (sum: number, item: any) => sum + Utils.getNumber(item.credit),
      0
    );
    const remainingDebit = totalCredit - totalDebit;
    const remainingCredit = totalDebit - totalCredit;
    const lastIndex = currentFields.length - 2;
    if (currentFields.length > 2) {
      if (remainingDebit > 0) {
        debitRefs.current[lastIndex].select();
        currentFields[lastIndex].debit = remainingDebit;
        currentFields[lastIndex].credit = "0.00";
      } else if (remainingCredit > 0) {
        creditRefs.current[lastIndex].select();
        currentFields[lastIndex].credit = remainingCredit;
        currentFields[lastIndex].debit = "0.00";
      } else {
        debitRefs.current[lastIndex].select();
        currentFields[lastIndex].debit = "0.00";
        currentFields[lastIndex].credit = "0.00";
      }
      console.log("updatedFields", currentFields);
      form.setFieldsValue({ partyLedger: currentFields });
      handleTotalChange(currentFields);
    } else {
      debitRefs.current[lastIndex].select();
    }
  };
  //   const AddOpeningBalance = () => {
  //     setSaveButtonLoading(true);
  //     form.validateFields().then(async (val) => {
  //       console.log(val);
  //       const date = dayjs(val?.date, "DD/MM/YYYY").format("DD/MM/YYYY");
  //       const trans = val?.partyLedger
  //         .filter((x: any) => x.account !== undefined)
  //         .map((itm: any) => {
  //           return {
  //             account: {
  //               id: itm?.account?.value,
  //               name: itm?.account?.label,
  //               code: itm?.account?.key,
  //               type: itm?.account?.title,
  //             },
  //             credit: itm?.credit,
  //             debit: itm?.debit,
  //             // date: dayjs(val?.date, 'DD/MM/YYYY').format('DD/MM/YYYY')
  //           };
  //         });
  //       console.log("trans-----------", trans);
  //       JournalService.saveOpeningBalance(currentClient?.id!, date, trans).then(
  //         (res) => {
  //           if (res.status) {
  //             setDisabled(true);
  //             openNotification(
  //               "success",
  //               `Opening partyLedger Added successfully`
  //             );
  //             setSaveButtonLoading(false);
  //           } else {
  //             openNotification("error", res?.message);
  //             setSaveButtonLoading(false);
  //           }
  //         }
  //       );
  //     });
  //   };

  // post (save) add OpeningBalance api
  const AddOpeningBalance = () => {
    form.validateFields().then(async (val: any) => {
      setLoading(true);
      console.log("post", val);
      setSaveButtonLoading(true);

      const effectiveLedger: any[] = [];
      const partyLedger: any[] = [];
      val?.partyLedger?.map((x: any) => {
        if (parseFloat(x?.debit) > 0) {
          effectiveLedger.push({
            ...x,
            amount: x?.debit,
            id: x?.id?.value,
            partyOrTax: x?.id?.title,
            isDebitNature: true,
          });
        } else {
          partyLedger.push({
            ...x,
            amount: x?.credit,
            id: x?.id?.value,
            partyOrTax: x?.id?.title,
            isDebitNature: false,
          });
        }
      });

      const givenDate = new Date(val.date);

      const currentTime = new Date();
      console.log("givenDate", givenDate, "currentTime", currentTime);

      // Set the time part of givenDate to match currentTime
      givenDate?.setHours(
        currentTime.getHours(),
        currentTime.getMinutes(),
        currentTime.getSeconds(),
        currentTime.getMilliseconds()
      );

      const data = {
        ...val,
        amount: 0,
        mode: VoucherMode.Opening,
        date: dayjs(givenDate).toISOString(),
        // dueDate: dayjs(val?.date).toISOString(),
        isLocked: false,
        isReconciled: false,
        isAudited: false,
        isActive: true,
        partyLedger: partyLedger,
        effectiveLedger: effectiveLedger,
      };

      console.log("post data", data);

      await VoucherServices.postVoucher(
        currentClient?.id!,
        Voucher_Type.Journal,
        data,
        journalId
      )
        .then((res) => {
          if (res?.status) {
            setDisabled(true);
            openNotification(
              "success",
              `Opening Balance ${
                journalId ? " Edit successfully" : "Add successfully"
              }`
            );
            // navigate(`/accounts/clients/${currentClient?.id}/journals`);
          } else if (res?.message) {
            openNotification("error", res?.message);
          }
        })
        .catch((ex) => openNotification("error", ex));
      setSaveButtonLoading(false);
      setLoading(false);
    });
  };

  useEffect(() => {
    setLoading(true);
    LedgerService.getLedgersByGroupName(
      currentClient?.id!,
      SearchLedgerByGroupsTypes?.AssetnLiabilities
    ).then((res) => {
      if (res) {
        setPartyLedgerOption(res);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  useEffect(() => {
    if (importedLedgers) {
      setLoading(true);

      const partyLedger = importedLedgers?.reduce((acc: any[], l: any) => {
        // Check if the ledgerId exists in partyLedgerOption
        const isPartyLedger = partyLedgerOption?.some(
          (p: any) => p.id === l.ledgerId
        );
        if (isPartyLedger) {
          acc.push({
            debit: Utils.getFormattedNumber(l.debit),
            credit: Utils.getFormattedNumber(l.credit),
            id: {
              label: l.ledgerName,
              value: l.ledgerId,
              key: l.ledgerId,
            },
          });
        }
        return acc;
      }, []);
      partyLedger.push({});
      // console.log("partyLedger", partyLedger);
      form.setFieldValue("partyLedger", partyLedger);
      handleTotalChange(partyLedger);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partyLedgerOption]);

  const focusOnvalue = (i: number) => {
    const currentFields = [...form.getFieldValue("partyLedger")];
    if (currentFields[i]?.debit > currentFields[i]?.credit) {
      debitRefs.current[i].select();
    } else if (currentFields[i]?.credit > currentFields[i]?.debit) {
      creditRefs.current[i].select();
    } else {
      debitRefs.current[i].select();
    }
  };
  //   const partySelect: any = async (
  //     partyId: string,
  //     index: number,
  //     title?: Party_Tax
  //   ) => {
  //     // if (!journalId) {
  //     setSelectPartyRef((prevDataArray) => {
  //       const newArray = [...prevDataArray];
  //       if (index < 0 || index >= newArray.length) {
  //         newArray.push([]);
  //       } else {
  //         newArray.splice(index, 1, []);
  //       }
  //       return newArray;
  //     });
  //     // }
  //     // user select party
  //     if (title === Party_Tax.party) {
  //       // update case if user delete reference & again add same reference
  //       if (journalId) {
  //         let onePartyUpdateData = allUpdatePartyData.find(
  //           (x) => x.id.value === partyId
  //         );
  //         console.log("onePartyUpdateData", onePartyUpdateData);

  //         if (onePartyUpdateData) {
  //           setSelectPartyRef((prevState: any[]) => {
  //             const updatedSelectedOptions: any[] = [...prevState];
  //             updatedSelectedOptions[index] = [...(prevState[index] || [])];
  //             updatedSelectedOptions[index] = onePartyUpdateData?.ref?.map(
  //               (x: any) => x?.parentRef
  //             );
  //             return updatedSelectedOptions;
  //           });

  //           setPartyRef((prevDataArray) => {
  //             const newArray = [...prevDataArray];
  //             newArray[index] = onePartyUpdateData?.ref?.map((x: any) => {
  //               return {
  //                 ...x,
  //                 maxAmount: x?.dueAmount + x?.value,
  //               };
  //             });
  //             return newArray;
  //           });
  //         }
  //         form.setFieldValue(["partyLedger", index], {
  //           ...onePartyUpdateData,
  //         });

  //         console.log("allUpdatePartyData", onePartyUpdateData);
  //       }

  //       await VoucherServices.getReferencesByParty(
  //         currentClient?.id!,
  //         partyId,
  //         Voucher_Type.Journal
  //       ).then((res: any) => {
  //         console.log("result", res.result?.length, index);
  //         if (res.result?.length > 0) {
  //           const data = res.result?.map((v: any, i: number) => {
  //             return {
  //               name: res.result[i]?.refNo,
  //               maxAmount: res.result[i]?.dueAmount,
  //               dueAmount: res.result[i]?.dueAmount,
  //               // id: res.result[i]?.id,
  //               parentRef: res.result[i]?.id,
  //               id: null,
  //               refType: 1,
  //             };
  //           });

  //           if (
  //             journalId &&
  //             allUpdatePartyData.find((x) => x.id?.value === partyId)
  //           ) {
  //             setPartyRef((prevDataArray) => {
  //               const prevArray = prevDataArray[index];
  //               // Filter out the common elements based on 'parentRefId'
  //               const filteredData = data.filter((newItem: any) => {
  //                 return !prevArray.some(
  //                   (prevItem: any) => prevItem?.parentRef === newItem?.parentRef
  //                 );
  //               });
  //               const updatedArray = [...prevArray, ...filteredData];
  //               console.log("newArray", updatedArray);
  //               const newArray = [...prevDataArray];
  //               newArray[index] = updatedArray;
  //               return newArray;
  //             });
  //           } else {
  //             // debugger;
  //             form.setFieldValue(["partyLedger", index, "ref"], [{}]);

  //             form.setFieldValue(["partyLedger", index, "ref", 0], {
  //               name: form.getFieldValue(["number"]),
  //               amount: 0,
  //               refType: 0,
  //             });

  //             form.setFieldValue(["partyLedger", index, "ref", 1], {});

  //             setPartyRef((prevDataArray) => {
  //               const newArray = [...prevDataArray];
  //               newArray[index] = data;
  //               return newArray;
  //             });
  //           }
  //         } else {
  //           form.setFieldValue(["partyLedger", index, "ref"], [{}]);
  //           form.setFieldValue(["partyLedger", index, "ref", 0], {
  //             name: form.getFieldValue(["number"]),
  //             amount: 0,
  //             refType: 0,
  //           });
  //           setPartyRef((prevDataArray) => {
  //             const newArray = [...prevDataArray];
  //             newArray[index] = [];
  //             return newArray;
  //           });
  //         }
  //       });
  //     } else {
  //       setPartyRef((prevDataArray) => {
  //         const newArray = [...prevDataArray];
  //         newArray[index] = [];
  //         return newArray;
  //       });
  //     }

  //     setDisabledAmountInputs((prev) => {
  //       const newDisabledInputs = [...prev];
  //       newDisabledInputs[index] = title ? true : false;
  //       return newDisabledInputs;
  //     });
  //     // }
  //     // handleLedgerNetAmount(index);
  //   };

  const onClickSelectPartyLedger = (value: string, index: number) => {
    // console.log("x", value, index);
    setSelectedPartyOption((prevState) => {
      const updatedSelectedOptions = [...prevState];
      updatedSelectedOptions[index] = value;
      // console.log(updatedSelectedOptions);
      return updatedSelectedOptions;
    });
  };

  return (
    <div style={{ padding: "0px 0px 3px 0px", width: "60%" }}>
      <Spin
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        spinning={loading}
      >
        <Form
          form={form}
          autoComplete="off"
          name="openingBalance"
          disabled={isDisabled}
          initialValues={{
            date: dayjs(companySettings?.period?.from),
            partyLedger: [{}],
          }}
        >
          <Row justify={"space-between"}>
            <Col className="dashedBoxStyle">
              <Space>
                <span>Opening Balance as on</span>

                <Form.Item
                  className="m-0"
                  name="date"
                  rules={[{ required: true, message: "Select a Date" }]}
                >
                  <DatePicker
                    // disabled
                    // defaultValue={dayjs(companySettings?.period?.from)}
                    format="DD/MM/YYYY"
                  />
                </Form.Item>
              </Space>
            </Col>

            <Col>
              {isDisabled ? (
                <FormOutlined
                  className="ca-edit-btn"
                  style={{ color: "rgb(106 119 227)" }}
                  onClick={() => {
                    setDisabled(false);
                  }}
                />
              ) : (
                !journalId && (
                  <Button
                    onClick={() =>
                      setImportModal({ type: "importModal", param: {} })
                    }
                    size="small"
                    type="default"
                    icon={<UploadOutlined />}
                    style={{
                      backgroundColor: "rgb(231, 235, 255, 0.6)",
                      color: "#435cff",
                    }}
                  >
                    Import
                  </Button>
                )
              )}
            </Col>
          </Row>

          <div>
            <table className="AddPageTabelDesign">
              <thead>
                <tr>
                  <th style={{ width: "60%" }}>Account</th>
                  <th style={{ width: "20%" }}>Debit</th>
                  <th colSpan={2} style={{ width: "20%" }}>
                    Credit
                  </th>
                </tr>
              </thead>
              <Form.List name="partyLedger">
                {(fields, { add, remove }) => {
                  return (
                    <>
                      <tbody>
                        {fields.map(({ key, name, ...restField }, index) => {
                          return (
                            <tr key={key}>
                              <td>
                                <Form.Item
                                  name={[name, "id"]}
                                  className="m-0"
                                  {...restField}
                                >
                                  <Select
                                    labelInValue
                                    showSearch
                                    placeholder="Search to items"
                                    optionFilterProp="children"
                                    popupMatchSelectWidth={false}
                                    style={{ width: "100%" }}
                                    dropdownStyle={{ width: "250px" }}
                                    placement="bottomRight"
                                    // onSearch={(value: string) =>
                                    //   setSearchItem(value)
                                    // }
                                    options={partyLedgerOption
                                      .filter(
                                        (option) =>
                                          !selectedPartyOption.includes(
                                            option.id
                                          )
                                      )
                                      .map((option: any) => ({
                                        value: option?.id,
                                        label: option?.ledgerName,
                                        title: option?.partyOrTax,
                                      }))}
                                    filterOption={(input, option) =>
                                      (option?.label?.toString() ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                    }
                                    onChange={(e: any) => {
                                      if (fields.length === index + 1) {
                                        add();
                                        updateTotals();
                                      } else {
                                        focusOnvalue(index);
                                      }
                                      //   partySelect(e.value, index, e.title);
                                      onClickSelectPartyLedger(e.value, index);
                                    }}
                                    dropdownRender={(customerOptions) => (
                                      <>
                                        {customerOptions}
                                        <Divider
                                          style={{ margin: "5px 0px" }}
                                        />
                                        <Button
                                          style={{ width: "100%" }}
                                          icon={<PlusOutlined />}
                                          type="link"
                                          onClick={() => {
                                            setOpenModel({
                                              type: "addLedger",
                                              param: {
                                                open: true,
                                              },
                                            });
                                            // setEditParty({ id: "" });
                                          }}
                                        >
                                          New Ledger
                                        </Button>
                                      </>
                                    )}
                                  />
                                </Form.Item>
                              </td>
                              <td>
                                <Form.Item
                                  name={[name, "debit"]}
                                  {...restField}
                                  className="m-0"
                                  initialValue={Utils.getFormattedNumber(0)}
                                >
                                  <Input
                                    ref={(el) =>
                                      (debitRefs.current[index] = el)
                                    }
                                    className="textEnd"
                                    onBlur={(e) =>
                                      Number(e.target.value) > 0 &&
                                      onDebitBlur(e.target.value, index)
                                    }
                                    onChange={() =>
                                      handleTotalChange(
                                        form.getFieldValue(["partyLedger"])
                                      )
                                    }
                                  />
                                </Form.Item>
                              </td>
                              <td style={{ width: "100%" }}>
                                <Form.Item
                                  name={[name, "credit"]}
                                  {...restField}
                                  className="m-0"
                                  initialValue={Utils.getFormattedNumber(0)}
                                >
                                  <Input
                                    ref={(el) =>
                                      (creditRefs.current[index] = el)
                                    }
                                    className="textEnd"
                                    style={{}}
                                    onBlur={(e) =>
                                      Number(e.target.value) > 0 &&
                                      onCreditBlur(e.target.value, index)
                                    }
                                    onChange={() =>
                                      handleTotalChange(
                                        form.getFieldValue(["partyLedger"])
                                      )
                                    }
                                  />
                                </Form.Item>
                              </td>
                              <td style={{ width: 30 }}>
                                {fields.length !== index + 1 && (
                                  <img
                                    alt="Delete"
                                    src={deleteImg}
                                    className="cursor mx-1"
                                    style={{
                                      backgroundColor: isDisabled
                                        ? "#f2f2f2"
                                        : "",
                                    }}
                                    onClick={() => {
                                      !isDisabled && setListIndex(name);
                                      !isDisabled && setConfirmation(true);
                                    }}
                                  />
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                      {confirmation && (
                        <ConfirmationModal
                          open={confirmation}
                          text="Do you really want to delete this row ?"
                          onNo={() => setConfirmation(false)}
                          onYes={() => {
                            const data = [...form.getFieldValue("partyLedger")];
                            const rowsAccount =
                              form.getFieldValue("partyLedger")[listIndex]
                                .account;
                            data.splice(listIndex, 1);
                            if (rowsAccount) {
                              setAccounts(
                                accounts.map((group: ISelectGroup) => {
                                  return {
                                    ...group,
                                    options: group.options.map((o: any) => {
                                      if (o.key === rowsAccount?.key) {
                                        return {
                                          ...o,
                                          disabled: false,
                                        };
                                      }
                                      return o;
                                    }),
                                  };
                                })
                              );
                            }
                            remove(listIndex);
                            handleTotalChange(data);
                            setConfirmation(false);
                            setSelectedPartyOption((prev) => {
                              const newDisabledInputs = [...prev];
                              newDisabledInputs.splice(listIndex, 1);
                              return newDisabledInputs;
                            });
                          }}
                        />
                      )}
                    </>
                  );
                }}
              </Form.List>
            </table>
          </div>
        </Form>
        <Row className="dashedBorderTop pt-10 mt-14">
          <Col lg={12}></Col>
          <Col lg={12}>
            <Row className="px-34">
              <Col lg={4}>Total</Col>
              <Col lg={10} className="textEnd">
                ₹ {Utils.getFormattedNumber(debitTotal)}
              </Col>
              <Col lg={10} className="textEnd">
                ₹ {Utils.getFormattedNumber(creditTotal)}
              </Col>
            </Row>
            <Divider className="my-5 grayBg" />
          </Col>
        </Row>
        {(creditTotal !== 0 || debitTotal !== 0) && (
          <div className="textEnd py-10 ms-0">
            Status :{" "}
            <Tag
              bordered={true}
              color={creditTotal === debitTotal ? "green" : "red"}
              className="m-0"
            >
              {creditTotal === debitTotal ? "Balanced" : "Unbalanced"}
            </Tag>
          </div>
        )}
        <div className="textEnd" style={{ paddingTop: "10px" }}>
          <Space>
            {!isDisabled && transLength > 0 && (
              <Button
                onClick={() => {
                  setDisabled(true);
                  setDebitTotal(0);
                  setCreditTotal(0);
                  setInitialRender(!initialRender);
                }}
              >
                Cancle
              </Button>
            )}
            {!isDisabled && (
              <Button
                type="primary"
                icon={<SaveOutlined />}
                disabled={
                  creditTotal === debitTotal &&
                  creditTotal !== 0 &&
                  debitTotal !== 0 &&
                  !isDisabled
                    ? false
                    : true
                }
                //   loading={saveButtonLoading}
                onClick={() => AddOpeningBalance()}
              >
                {journalId ? "Update" : "Save"}
              </Button>
            )}
          </Space>
        </div>
      </Spin>

      {importModal?.type === "importModal" && (
        <ImportLedger
          onDismiss={(confirmed: boolean) => {
            setImportModal({ type: "", param: {} });
          }}
          setImportedLedgers={setImportedLedgers}
          setTrigger={setTrigger}
        />
      )}
      {openModel.type === "addLedger" && (
        <AddLedger
          openDrawer={true}
          id={""}
          setOpenDrawer={() => setOpenModel({ type: "", param: "" })}
          setTrigger={()=>setTrigger(!trigger)}
          setAddLedgerResponse={setAddLedgerResponse}
        />
      )}
    </div>
  );
};

export default SettingOpeningBalance;
