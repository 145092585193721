import { DeleteTwoTone, EditOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Input,
  Row,
  Select,
  Skeleton,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import React from "react";
import type { ColumnsType } from "antd/es/table";
import "./Purchase.css";
import ItemsServices, { ItemType } from "../../Services/ItemsService";
import AddItems from "./AddItems";
import { useClient } from "../../Context/ClientContext";
import { NavLink, useLocation } from "react-router-dom";
import { useTheme } from "../../Context/ThemeContext";
import "../../Common/Acc_Common.css";
import search from "../../Images/search.svg";
import { useKeyboardShortcuts } from "../../Common/KeyboardShortcuts ";
import { Utils } from "../../../Utilities/Utils";
// import { ItemType } from "antd/es/breadcrumb/Breadcrumb";

interface IItemListData {
  key: string;
  sno: number;
  id: string;
  name: string;
  number: string;
  // description: string;
  HSNCode: string;
  SACCode: string;
  itemType: boolean;
  isTaxable: boolean;
  gstRate: number;
  taxType: boolean;
  // unitPrice: number;
  salesPrice: number;
  purchasePrice: number;
  accountHead: string;
  vat: string;
}

const Items: React.FC<{
  itemPage: ItemType;
  trigger?: boolean;
  setTrigger?: any;
  partyId?: string;
}> = (props) => {
  const { currentClient } = useClient();
  const { themeData } = useTheme();
  const location = useLocation();
  const [openModel, setOpenModel] = React.useState<any>({
    type: "",
    param: {},
  });
  const [itemsList, setItemsList] = React.useState<IItemListData[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [trigger, setTrigger] = React.useState<boolean>(false);
  const [pageSize, setPageSize] = React.useState<number>(15);
  const [totalRecords, setTotalRecords] = React.useState<number>(0);
  const [page, setPage] = React.useState(1);
  const [searchItem, setSearchItem] = React.useState<string>("");
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
  // const [isService, setIsServices] = React.useState<string>();
  const [gstRate, setGstRate] = React.useState<any[]>([]);
  const [width, setWidth] = React.useState<any>(window.innerWidth);
  const [filter, setfilter] = React.useState<any>({
    sortCol: "",
    sortDir: "",
  });
  const [type, setType] = React.useState<ItemType>(props.itemPage);
  React.useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  React.useEffect(() => {
    setLoading(true);
    if (currentClient?.id) {
      getList(
        currentClient?.id,
        (page - 1) * pageSize,
        pageSize,
        filter.sortCol,
        filter.sortDir,
        searchItem,
        undefined,
        type,
        gstRate
      );
    } else if (currentClient?.id === null) {
      console.error("currentClient is null Items");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.trigger,
    trigger,
    page,
    pageSize,
    searchItem,
    location.pathname,
    filter.sortCol,
    filter.sortDir,
    gstRate,
    type,
  ]);

  React.useEffect(() => {
    const handleKeyDown = (e: any) => {
      console.log("useEffect", e.shiftKey);

      if (e.shiftKey && e.key === "I") {
        setOpenModel({
          type: "addItem",
          param: { open: true },
        });
      }
      if (e.altKey) {
        if (e.shiftKey && e.key.toLowerCase() === "c") {
          setOpenModel({ type: "", param: "" });
        }
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  // --------- Table Columns  ---------
  const columns: ColumnsType<IItemListData> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: width <= 1440 ? "10%" : "18%",
      onFilter: (value: any, record) => true,
      render: (_, record) =>
        type === ItemType.SalesItems ? (
          <Skeleton
            active
            loading={loading}
            paragraph={{ rows: 1 }}
            title={false}
          >
            <NavLink
              to={`/accounts/clients/${currentClient?.id}/sales/inventory`}
              state={{
                id: record.id,
                type: "sales",
                itemType: record.itemType ? "salesService" : "salesGoods",
              }}
            >
              {record?.name}
            </NavLink>
          </Skeleton>
        ) : (
          <Skeleton
            active
            loading={loading}
            paragraph={{ rows: 1 }}
            title={false}
          >
            <NavLink
              to={`/accounts/clients/${currentClient?.id}/purchase/inventory`}
              state={{
                id: record.id,
                type: "purchase",
                itemType: record.itemType ? "purchaseService" : "purchaseGoods",
              }}
            >
              {record?.name}
            </NavLink>
          </Skeleton>
        ),
      /*  filterSearch: true,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        close,
      }) => (
        <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
          <Input />
        </div>
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
      ),
*/
      // onFilter: (value: string, record) => record.name.includes(value),
    },

    {
      title: "Code",
      dataIndex: "number",
      key: "Number",
      width: "7%",
      // sorter: true,
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.number}
        </Skeleton>
      ),
    },

    {
      title: "Pur. Price",
      dataIndex: "unitPrice",
      key: "PurchaseItem.Price",
      // sorter: true,
      width: width <= 1440 ? "12%" : "8%",
      align: "right",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
            style: { display: "flex", justifyContent: "end" },
          }}
          title={false}
        >
          {Utils.getFormattedNumber(record?.purchasePrice)}
        </Skeleton>
      ),
    },
    {
      title: "Sales Price",
      dataIndex: "unitPrice",
      key: "SalesItem.Price",
      // sorter: true,
      width: width <= 1440 ? "12%" : "8%",
      align: "right",
      render: (_: any, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
            style: { display: "flex", justifyContent: "end" },
          }}
          title={false}
        >
          {Utils.getFormattedNumber(record?.salesPrice)}
        </Skeleton>
      ),
    },

    {
      title: "Item Type",
      dataIndex: "itemType",
      key: "IsService",
      width: "10%",
      // sorter: true,
      render: (_, record) => {
        if (record?.itemType)
          return (
            <Skeleton
              active
              loading={loading}
              paragraph={{ rows: 1 }}
              title={false}
            >
              <Tag
                color="geekblue"
                style={{
                  textAlign: "center",
                  width: 52,
                }}
              >
                Service
              </Tag>
            </Skeleton>
          );
        else
          return (
            <Skeleton
              active
              loading={loading}
              paragraph={{ rows: 1 }}
              title={false}
            >
              <Tag
                color="green"
                style={{
                  textAlign: "center",
                  width: 52,
                }}
              >
                Goods
              </Tag>
            </Skeleton>
          );
      },
    },
    {
      title: "HSN/SAC Code",
      dataIndex: "HSNCode",
      key: "description",
      width: "18%",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.HSNCode ?? record?.SACCode}
          {/* SACCode */}
        </Skeleton>
      ),
    },
    {
      title: "Tax Preference",
      dataIndex: "isTaxable",
      key: "IsTaxable",
      width: width <= 1440 ? "12%" : "10%",
      // sorter: true,
      render: (_, record) => {
        if (record?.taxType === true)
          return (
            <Skeleton
              active
              loading={loading}
              paragraph={{ rows: 1 }}
              title={false}
            >
              <Tag
                color="cyan"
                style={{
                  textAlign: "center",
                  width: 85,
                }}
              >
                Inclusive Tax
              </Tag>
            </Skeleton>
          );
        else
          return (
            <Skeleton
              active
              loading={loading}
              paragraph={{ rows: 1 }}
              title={false}
            >
              <Tag
                // bordered={false}
                color="purple"
                style={{
                  textAlign: "center",
                  width: 85,
                }}
              >
                Exclusive Tax
              </Tag>
            </Skeleton>
          );
      },
    },
    {
      title: "GST",
      dataIndex: "gstRate",
      key: "Rate",
      width: "8%",
      // sorter: true,
      // filters: [
      //   {
      //     text: "5%",
      //     value: 5,
      //   },
      //   {
      //     text: "3%",
      //     value: 3,
      //   },
      // ],
      onFilter: (value: any, record) => true,
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.gstRate}
        </Skeleton>
      ),

      // filtered: true,
    },
    {
      title: "Action",
      dataIndex: "",
      key: "action",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          <div style={{ display: "flex", gap: "15px" }}>
            <div>
              <Tooltip title="Edit" placement="top" color="#3D4998">
                <EditOutlined
                  // className="ca-edit-btn"
                  style={{ color: "#444653" }}
                  onClick={async () => {
                    setOpenModel({
                      type: "addItem",
                      param: {
                        open: true,
                        id: record.id,
                      },
                    });
                  }}
                />
              </Tooltip>
            </div>
            <div>
              <Tooltip title="Under Progress" placement="top" color="red">
                <DeleteTwoTone
                  className="ca-delete-btn cursor"
                  twoToneColor="#ff879d"

                  // onClick={() => { setEditItem(record)} }
                />
              </Tooltip>
            </div>
          </div>
        </Skeleton>
      ),
    },
  ];

  //----- Table Ascending & Desending Order -----
  const tableSort = (pagination?: any, sort?: any, filter?: any) => {
    setfilter({
      sortCol: filter.column !== undefined ? filter.columnKey : "number",
      sortDir:
        filter.column !== undefined
          ? filter.order === "descend"
            ? "desc"
            : "asc"
          : "desc",
    });
    console.log("sort1111111111111", sort?.TaxRate?.Rate, sort);
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  // --------- Get TableData API ---------
  const getList = async (
    clientId: string,
    start: number,
    length: number,
    sortCol?: string,
    sortDir?: string,
    search?: string,
    status?: any,
    type?: ItemType,
    gstRate?: number[]
  ) => {
    // debugger;
    await ItemsServices.getListItems(
      clientId,
      start,
      length,
      sortCol,
      sortDir,
      search,
      status,
      type,
      gstRate
    )
      .then((res: any) => {
        if (res.items.length > 0) {
          setItemsList(
            res?.items?.map((v: any, i: any) => {
              return {
                key: i,
                id: v?._id,
                name: v?.Name,
                number: v?.Number,

                // description:
                //   type === "purchase"
                //     ? v?.PurchaseItem?.Description
                //     : v?.SalesItem?.Description,
                HSNCode: v?.HSNCode,
                SACCode: v?.SACCode,
                itemType: v?.IsService,
                taxType: v?.IsTaxable,
                salesPrice: v?.sPrice,
                purchasePrice: v?.pPrice,
                // unitPrice:
                //   type === ItemType.PurchaseItems
                //     ? v?.PurchaseItem?.Price
                //     : v?.SalesItem?.Price,
                gstRate: v?.TaxRate,
              };
            })
          );
          setTotalRecords(res?.totalRecords);
          // console.log("table,,,,,,,,,,,", props.trigger);
        } else if (res.items.length === 0) {
          setItemsList([]);
          setTotalRecords(res?.totalRecords);
        }
        setLoading(false);
      })
      .catch((ex: any) => {
        console.error(ex.message);
        setLoading(false);
        setItemsList([]);
      });
  };

  return (
    <Card
      size="small"
      className="TableCard"
      style={{
        // marginTop: "6px",
        // padding: "48px 0px 0px 0px",
        marginTop: 35,
        boxShadow: "0px 0px 10px 0px #96A1C84D",
      }}
    >
      {props.trigger === true || (
        <>
          {/* <Affix offsetTop={130}> */}
          <Row
            justify="space-between"
            style={{
              // paddingTop: props?.partyId ? "10px" : "0px"
              // marginTop: 10,
              padding: "0px 0px 3px 0px",
            }}
            // style={{ position: "sticky",zIndex:1, top: 0, padding: "5px" }}
          >
            <Col>
              <Space size={width <= 768 ? 465 : 5}>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    setOpenModel({
                      type: "addItem",
                      param: { open: true },
                    });
                  }}
                >
                  Item
                </Button>

                {/* <Button
                  style={{
                    marginLeft: "10px",
                  }}
                  icon={<ArrowDownOutlined />}
                >
                  Export
                </Button> */}
              </Space>
            </Col>

            <Col
              style={{
                marginTop: width <= 768 ? 15 : "",
              }}
            >
              <Row gutter={width <= 768 ? 98 : 8}>
                <Col>
                  <Input
                    allowClear
                    style={{ width: "200px", border: "1px solid #f1f3ff" }}
                    placeholder="Search.."
                    onChange={(e: any) => {
                      setLoading(true);
                      if (!e.cancelable) {
                        //console.log("typeing user............");
                        const searchItem = setTimeout(() => {
                          setPage(1);
                          setSearchItem(e.target.value);
                        }, 1000);
                        return () => clearTimeout(searchItem);
                      } else {
                        // console.log("Click cancle............");
                        setSearchItem(e.target.value);
                      }
                    }}
                    suffix={
                      loading || searchItem ? (
                        ""
                      ) : (
                        <img alt="Search.." src={search} />
                      )
                    }
                  />
                </Col>
                <Col>
                  <Select
                    // defaultValue={[null]}
                    mode="multiple"
                    maxTagCount={"responsive"}
                    // value={"GST = " + (gstRate === 0 ? "All" : gstRate + "%")}
                    style={{ minWidth: "158px" }}
                    allowClear
                    onChange={(e: any) => {
                      console.log(e);
                      setGstRate(e);
                      setPage(1);
                      // e === null ? setGstRate([]) :  (e);
                      // setGstRate(e === null ? null : e);
                      // console.log(gstRate);
                    }}
                    placeholder="GST"
                    options={[
                      // { value: 0, label: "All" },
                      { value: 0, label: "0%" },
                      { value: 0.25, label: "0.25%" },
                      { value: 3, label: "3%" },
                      { value: 5, label: "5%" },
                      { value: 12, label: "12%" },
                      { value: 18, label: "18%" },
                      { value: 28, label: "28%" },
                    ]}
                  />
                </Col>
                {/* <Col>
                  <Select
                    defaultValue="All"
                    style={{ width: "100px" }}
                    onSelect={() => setPage(1)}
                    options={[
                      { value: "Active", label: "Active" },
                      { value: "Deleted", label: "Deleted" },
                    ]}
                  />
                </Col> */}
                <Col>
                  {location.pathname.split("/")[4] === "master" && (
                    <>
                      <Select
                        defaultValue={ItemType.All}
                        style={{ width: "140px" }}
                        onChange={(val: ItemType) => {
                          setType(1);
                          setType(val);
                        }}
                        options={[
                          { value: ItemType.All, label: "All Items" },
                          { value: ItemType.SalesItems, label: "Sales Items" },
                          {
                            value: ItemType.PurchaseItems,
                            label: "Purchase Items",
                          },
                        ]}
                      />
                    </>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
          {/* </Affix> */}

          {/* --------- Table --------- */}
          <Table
            columns={columns}
            dataSource={
              loading
                ? (Array.from({ length: pageSize }, (_, index) => ({
                    key: `loading-${index}`,
                  })) as IItemListData[])
                : itemsList
            }
            locale={{
              triggerDesc: "",
              triggerAsc: "",
              cancelSort: "",
            }}
            onChange={tableSort}
            className={`Tabel-style table-${
              themeData?.componentSize ?? "middle"
            }`}
            rowSelection={rowSelection}
            pagination={{
              total: totalRecords,
              current: page,
              pageSize: pageSize,
              showSizeChanger: true,
              onChange: (page: number, pageSize: number) => {
                setPage(page);
                setPageSize(pageSize);
              },
              pageSizeOptions: ["10", "15", "25", "50", "100"],
              showTotal: (totalRecords, page) =>
                `${page[0]}-${page[1]} of ${totalRecords} items`,
            }}
            // y: 640
            // scroll={{ x: "max-content", y: `calc(100vh - 500px)` }}
            // scroll={itemsList.length > 15 ? { x: 600 } : { x: 600 }}
            // scroll={
            //   pageSize > 10
            //     ? {
            //         x: width <= 768 ? 1500 : width <= 1024 ? 1150 : 700,
            //         y: 370,
            //       }
            //     : { x: width <= 768 ? 1500 : width <= 1024 ? 1150 : 600 }
            // }
            scroll={{ x: 1150, y: window.innerHeight - 315 }}
          />

          {/* --------- AddItem --------- */}
          {openModel.type === "addItem" && (
            <AddItems
              open={openModel?.param.open}
              onCancel={() => setOpenModel({ type: "", param: "" })}
              id={openModel?.param.id}
              // setTrigger={setTrigger((x) => !x)}
              setTrigger={setTrigger}
            />
          )}
        </>
      )}
    </Card>
  );
};

export default Items;
