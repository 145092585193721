import {
  Avatar,
  Col,
  Divider,
  Modal,
  Row,
  Switch,
  Tooltip,
  Button,
  Spin,
  Space,
} from "antd";
import React from "react";
import { useClient } from "../../Context/ClientContext";
import { NavLink, useNavigate } from "react-router-dom";
import noteContext from "../../Context/StyleContext";
import { Utils } from "../../../Utilities/Utils";
import "../../Common/AddPageCommon.css";
import PurchaseService from "../../Services/PurchaseService";
import { PurchaseInvoiceTypes } from "../../../Types/CommonConstants";
import { EyeOutlined, LoadingOutlined } from "@ant-design/icons";
import ViewSalesInvoiceNoDetails from "../Sales/ViewSalesInvoiceNoDetails";
import ViewPurchaseInvoiceNoDetails from "./ViewPurchaseInvoiceNoDetails";
import { useIndianState } from "../../Context/StateOptionsContext";
import { IIndianState } from "../../../Types";

const ViewPurchaseInvoice: React.FC<{
  open: boolean;
  onCancel: any;
  invoiceId: string | undefined;
}> = (props) => {
  console.log(props);
  const navigate = useNavigate();
  const { windowWidth } = React.useContext(noteContext);
  const { currentClient } = useClient();
  const { stateOptions } = useIndianState();
  const [itemLength, setItemLength] = React.useState<number>(0);
  const [tdsOrTcs, setTdsOrTcs] = React.useState<number>(0);
  const [isInCash, setIsInCash] = React.useState<boolean>(false);
  const [isStateSame, setIsStateSame] = React.useState<boolean>(false);
  const [isGSTEnabled, setGstEnabled] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [data, setData] = React.useState<any>({});
  const [openModel, setOpenModel] = React.useState<boolean>(false);
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  React.useEffect(() => {
    if (props.invoiceId) {
      setLoading(true);
      PurchaseService.getPurchaseInvoices(
        currentClient?.id!,
        PurchaseInvoiceTypes.PurchaseInventory,
        true,
        props.invoiceId
      )
        .then((res: any) => {
          setLoading(false);
          const resp = res?.result?.invoice;
          if (resp) {
            console.log(res?.result);
            setItemLength(resp.items.length);
            setGstEnabled(res?.result?.isGstEnabled);
            setIsStateSame(resp?.isStateSame);
            const state = stateOptions?.find(
              (itm: IIndianState) => itm?.id === resp?.supplyState
            ) as IIndianState | undefined;
            setData({
              number: resp?.number,
              invoiceId: resp?.id,
              tradingPartner: resp.tradingPartner,
              phoneNo: resp?.phoneNo,
              billingAddress: resp?.billingAddress,
              shippingAddress: resp?.shippingAddress,
              paymentMethod: resp?.paymentMethod === 1 ? true : false,
              date: Utils.getFormattedDate(resp?.date),
              supplyState: state?.name,
              dueDate: Utils.getFormattedDate(resp?.dueDate),
              items: resp.items,
              totalQty: Utils.getFormattedNumber(resp.amountSummary?.totalQty),
              totalPrice: Utils.getFormattedNumber(
                resp.amountSummary?.totalPrice
              ),
              totalDiscount: Utils.getFormattedNumber(
                resp.amountSummary?.totalDis
              ),
              sellPrice: Utils.getFormattedNumber(
                resp?.amountSummary?.sellPrice
              ),
              dueBalance: Utils.getFormattedNumber(
                resp.amountSummary?.dueBalance
              ),
              received: resp.amount?.paid,
              refund: resp.amount?.refund,
              dueAmount: resp.amount?.due,
              totalgst: resp.amountSummary?.igst
                ? resp.amountSummary?.igst
                : resp.amountSummary?.sgst + resp.amountSummary?.cgst,
              igst: resp.amountSummary?.igst,
              cgst: resp.amountSummary?.cgst,
              sgst: resp.amountSummary?.sgst,
              receivedAmount: Utils.getFormattedNumber(
                resp.amountSummary?.receivedAmount
              ),
              totalInvoiceAmount: Utils.getFormattedNumber(
                resp.amountSummary?.totalInvoiceAmount
              ),
            });
            console.log("datastate", resp);
          }
        })
        .catch((ex) => {
          console.error(ex);
          setLoading(true);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient?.id, props.invoiceId]);

  return (
    <Modal
      open={props.open}
      width={1600}
      style={{ maxHeight: `calc(100% - 50px)`, overflowY: "auto" }}
      maskClosable={false}
      onCancel={() => props.onCancel()}
      centered
      footer={
        <div style={{ marginTop: "0px" }}>
          <Button
            onClick={() => {
              props.onCancel();
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            disabled
            style={{ width: "90px" }}
            onClick={async () => {
              navigate(
                `/accounts/clients/${currentClient?.id}/sales/editInvoice/${props.invoiceId}`
              );
            }}
          >
            Edit
          </Button>
        </div>
      }
    >
      <div>
        <Spin indicator={antIcon} spinning={loading}>
          {windowWidth <= 1024 ? (
            <h3 className="fs-35 fw-500 pt-20 themeColor textCenter ">
              Purchase Invoice
            </h3>
          ) : (
            ""
          )}
          <Row justify="space-between">
            <Col xl={17} lg={24} md={24} className="dashedBoxStyle">
              <Row gutter={24} className="ps-14" justify="space-between">
                <Col
                  lg={14}
                  md={11}
                  xs={23}
                  sm={23}
                  className="themeLightBgColor p-10"
                >
                  <Row gutter={[17, 2]}>
                    <Col lg={12}>
                      <span className="themeColor fw-500">Supplier</span>
                      <div>
                        <NavLink
                          to={`/accounts/clients/${currentClient?.id}/parties/${data.tradingPartner?.id}`}
                        >
                          {data.tradingPartner?.name}
                        </NavLink>
                      </div>
                    </Col>
                    <Col lg={12}>
                      <span className="themeColor fw-500">Phone No.</span>
                      <div>{data.phoneNo}</div>
                    </Col>
                    <Col lg={12}>
                      <span className="themeColor fw-500">Billing Address</span>
                      <div>{data.billingAddress}</div>
                    </Col>
                    <Col lg={12}>
                      <span className="themeColor fw-500">
                        Shipping Address
                      </span>
                      <div>{data.shippingAddress}</div>
                    </Col>
                  </Row>
                </Col>

                <Col lg={9} md={4} xs={23} sm={23}>
                  <Row gutter={[17, 8]}>
                    <Col lg={24}>
                      <span style={{ display: "flex", justifyContent: "end" }}>
                        <span
                          className="pe-10 pt-5 fw-500"
                          style={{
                            color: isInCash ? "gray" : "rgb(11, 48, 142)",
                          }}
                        >
                          Credit
                        </span>

                        <Switch
                          checked={data?.paymentMethod}
                          disabled
                          size="small"
                          style={{ marginTop: "7px" }}
                        />

                        <span
                          className="ps-11 pt-5 fw-500"
                          style={{
                            color: isInCash ? "rgb(11, 48, 142)" : "gray",
                          }}
                        >
                          Cash
                        </span>
                      </span>
                    </Col>
                    <Col lg={12}>
                      <div className="themeColor fw-500">Invoice Ref.</div>
                      <Space wrap>
                        <p>{data.number}</p>
                        <Tooltip
                          title={"All Invoice Details"}
                          placement="top"
                          color={"gray"}
                        >
                          <EyeOutlined onClick={() => setOpenModel(true)} />
                        </Tooltip>
                      </Space>
                    </Col>
                    <Col lg={12}>
                      <span className="themeColor fw-500">Place Of Supply</span>
                      <div>{data.supplyState}</div>
                    </Col>
                    <Col lg={12}>
                      <span className="themeColor fw-500">Date</span>
                      <div>{data.date}</div>
                    </Col>
                    <Col lg={12}>
                      <span className="themeColor fw-500">Due Date</span>
                      <div>{data.dueDate}</div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            {windowWidth <= 1024 ? (
              ""
            ) : (
              <Col
                xl={4}
                lg={7}
                md={7}
                className="fs-35 fw-400 themeColor textEnd"
              >
                Purchase Invoice
              </Col>
            )}
          </Row>

          <table
            className="ca-viewmodel-table"
            style={{ fontSize: windowWidth <= 1024 ? "10.3px" : "" }}
          >
            <thead>
              <tr>
                <th
                  colSpan={3}
                  rowSpan={2}
                  style={{ width: "6%" }}
                  className="textStart ps-10"
                >
                  S no.
                </th>
                <th
                  rowSpan={2}
                  style={{
                    width: "11%",
                    borderLeft: "1px solid rgb(240, 245, 255)",
                  }}
                  className="textStart"
                >
                  Item
                </th>
                <th
                  rowSpan={2}
                  // style={{ width: windowWidth <= 1024 ? "18%" : "18%" }}
                  className="textStart"
                >
                  Description
                </th>
                <th rowSpan={2} style={{ width: "10%" }} className="textStart">
                  Account
                </th>
                <th rowSpan={2} style={{ width: "6%" }} className="textStart">
                  Qty
                </th>
                <th rowSpan={2} style={{ width: "6%" }} className="textStart">
                  Unit
                </th>
                <th rowSpan={2} style={{ width: "9%" }} className="textStart">
                  Unit Price
                </th>
                <th colSpan={2} style={{ width: "12%" }}>
                  Discount
                </th>
                {isGSTEnabled && (
                  <th colSpan={2} style={{ width: "12%" }}>
                    GST %
                  </th>
                )}
                <th
                  rowSpan={2}
                  style={{ width: "14%" }}
                  className="pe-10 textEnd"
                >
                  Total Amount
                </th>
              </tr>
              <tr>
                <th style={{ width: "6%" }}>%</th>
                <th style={{ width: "7%" }}>₹</th>
                {isGSTEnabled && <th style={{ width: "6%" }}>%</th>}
                {isGSTEnabled && <th style={{ width: "7%" }}>₹</th>}
              </tr>
            </thead>

            <tbody>
              {data.items?.map((item: any, i: any) => {
                return (
                  <tr key={i}>
                    <td className="ps-10">{i + 1}</td>
                    <td>
                      {item.isService ? (
                        <Tooltip
                          title="Service"
                          placement="left"
                          color="#1d39c4"
                        >
                          <Avatar
                            style={{
                              cursor: "pointer",
                              verticalAlign: "middle",
                              color: "#1d39c4",
                              backgroundColor: "#f0f5ff",
                              borderColor: "#adc6ff",
                              fontWeight: "bold",
                            }}
                            size="small"
                          >
                            S
                          </Avatar>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Goods" placement="left" color="#389e0d">
                          <Avatar
                            style={{
                              cursor: "pointer",
                              verticalAlign: "middle",
                              color: "#389e0d",
                              backgroundColor: "#f6ffed",
                              borderColor: "#b7eb8f",
                              fontWeight: "bold",
                            }}
                            size="small"
                          >
                            G
                          </Avatar>
                        </Tooltip>
                      )}
                    </td>
                    <td>
                      {item.isTaxable ? (
                        <Tooltip
                          title="With Tax"
                          placement="right"
                          color="#08979C"
                        >
                          <Avatar
                            style={{
                              cursor: "pointer",
                              verticalAlign: "middle",
                              color: "#08979C",
                              backgroundColor: "#E6FFFB",
                              borderColor: "rgb(127 231 235)",
                              fontWeight: "bold",
                            }}
                            size="small"
                          >
                            T
                          </Avatar>
                        </Tooltip>
                      ) : (
                        <Tooltip
                          title="WithOut Tax"
                          placement="right"
                          color="#531DAB"
                        >
                          <Avatar
                            style={{
                              cursor: "pointer",
                              verticalAlign: "middle",
                              color: "#531DAB",
                              backgroundColor: "#F9F0FF",
                              borderColor: "rgb(193 166 239)",
                              fontWeight: "bold",
                            }}
                            size="small"
                          >
                            WT
                          </Avatar>
                        </Tooltip>
                      )}
                    </td>
                    <td>
                      <NavLink
                        to={`/accounts/clients/${currentClient?.id}/purchase/inventory`}
                        state={{
                          id: item.id,
                          type: "sales",
                          itemType: item.isService
                            ? "purchaseService"
                            : "purchaseGoods",
                        }}
                      >
                        {item.name}
                      </NavLink>
                    </td>
                    <td>{item?.description}</td>
                    <td>{item.account?.name}</td>
                    <td>{Utils.getFormattedNumber(item.amount?.qty)}</td>
                    <td>{item.amount?.unit?.name}</td>
                    <td>{Utils.getFormattedNumber(item.amount?.price)}</td>
                    <td className="textCenter">{item.amount?.discount} %</td>
                    <td className="textCenter">
                      {Utils.getFormattedNumber(item.amount?.discountRupee)}
                    </td>
                    {isGSTEnabled && (
                      <td className="textCenter">
                        {item.amount?.tax?.name ? item.amount?.tax?.name : 0}
                      </td>
                    )}
                    {isGSTEnabled && (
                      <td className="textCenter">
                        {Utils.getFormattedNumber(item.amount?.taxAmount)}
                      </td>
                    )}
                    <td className="pe-10 textEnd">
                      {Utils.getFormattedNumber(item.amount?.totalAmount)}
                    </td>
                  </tr>
                );
              })}
            </tbody>

            {itemLength > 0 && (
              <tfoot>
                <tr>
                  <th colSpan={6} className="textStart ps-10">
                    Total
                  </th>
                  <th colSpan={2} className="textStart">
                    {data.totalQty}
                  </th>
                  <th className="textStart">₹ {data.totalPrice}</th>
                  <th colSpan={2} className="textEnd pe-31 ">
                    ₹ {data.totalDiscount}
                  </th>
                  {isGSTEnabled && (
                    <th colSpan={2} className="textEnd pe-31 ">
                      ₹ {Utils.getFormattedNumber(data.totalgst)}
                    </th>
                  )}
                  <th colSpan={2} className="textEnd pe-10">
                    ₹ {data.totalInvoiceAmount}
                  </th>
                </tr>
              </tfoot>
            )}
          </table>

          <Row className="dashedBorderTop pt-10 pe-10">
            <Col lg={5}>
              <Row gutter={[0, 8]}>
                <Col lg={9}>Invoice Amount</Col>
                <Col lg={15} className="textEnd">
                  ₹ {data?.totalInvoiceAmount}
                </Col>
                <Col lg={9}>Refund</Col>
                <Col lg={15} className="textEnd">
                  ₹ {Utils.getFormattedNumber(data?.refund)}
                </Col>

                <Col lg={9}>Received</Col>
                <Col lg={15} className="textEnd">
                  ₹ {Utils.getFormattedNumber(data?.received)}
                </Col>
                <Col lg={9}>Due Amount</Col>
                <Col lg={15} className="textEnd">
                  ₹ {Utils.getFormattedNumber(data?.dueAmount)}
                </Col>
              </Row>
            </Col>
            <Col lg={12}></Col>
            <Col lg={7}>
              <Row gutter={[0, 8]}>
                <Col lg={9}>Selling Price</Col>
                <Col lg={15} className="textEnd">
                  ₹ {data?.sellPrice}
                </Col>
                <Col lg={9}>Discount</Col>
                <Col lg={15} className="textEnd">
                  ₹ {data?.totalDiscount}
                </Col>
                {isGSTEnabled && (
                  <>
                    {isStateSame ? (
                      <>
                        <Col lg={9}>CGST</Col>
                        <Col lg={15} className="textEnd">
                          ₹ {Utils.getFormattedNumber(data?.cgst)}
                        </Col>
                        <Col lg={9}>SGST</Col>
                        <Col lg={15} className="textEnd">
                          ₹ {Utils.getFormattedNumber(data?.sgst)}
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col lg={9}>IGST</Col>
                        <Col lg={15} className="textEnd">
                          ₹ {Utils.getFormattedNumber(data?.igst)}
                        </Col>
                      </>
                    )}
                    {/* <Col lg={15}>
                  <Radio.Group value={tdsOrTcs}>
                    <Radio value={0}>TDS</Radio>
                    <Radio value={1}>TCS</Radio>
                  </Radio.Group>
                </Col>
                {tdsOrTcs === 1 ? (
                  <Col lg={9} className="textEnd">
                    ₹ {data.totalTcs}
                  </Col>
                ) : tdsOrTcs === 0 ? (
                  <Col lg={9} className="textEnd">
                    ₹ {data.totalTds}
                  </Col>
                ) : (
                  <></>
                )} */}
                  </>
                )}
              </Row>
              <Divider className="my-5 grayBg" />
              <Row gutter={[12, 2]}>
                <Col lg={18}>Total Invoice Value</Col>

                <Col lg={6} className="textEnd">
                  ₹ {data?.totalInvoiceAmount}
                </Col>
                {/* {!isInCash && itemLength > 0 && (
                  <>
                    <Col lg={12} className="pt-5">
                      Received
                    </Col>
                    <Col lg={12} className="textEnd">
                      <div> ₹ {data?.receivedAmount}</div>
                    </Col>
                    <Divider className="my-5 grayBg" />

                    <Col lg={9}>Balance</Col>
                    <Col lg={15} className="textEnd">
                      ₹ {data?.dueBalance}
                    </Col>
                  </>
                )} */}
              </Row>
              <Divider
                style={{
                  borderBottom: "2px solid rgb(203, 203, 203)",
                }}
                className="my-5"
              />
            </Col>
          </Row>
        </Spin>
      </div>
      {openModel && (
        <ViewPurchaseInvoiceNoDetails
          open={openModel}
          onCancel={() => setOpenModel(false)}
          invoiceId={data?.invoiceId}
        />
      )}
    </Modal>
  );
};
export default ViewPurchaseInvoice;
