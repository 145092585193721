import {
  ArrowLeftOutlined,
  DownOutlined,
  FilterFilled,
  FormOutlined,
  PlusOutlined,
  UpOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Input,
  Popover,
  Row,
  Select,
  Skeleton,
  Table,
  Tabs,
  Tooltip,
} from "antd";
import React from "react";
import { useTheme } from "../../../Context/ThemeContext";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import PartyService from "../../../Services/PartyService";
import { useClient } from "../../../Context/ClientContext";
import { ColumnsType } from "antd/es/table";
import SalesInvoiceTable from "../../Sales/SalesInvoiceTable";
import CreditNotes from "../../Sales/CreditNotes";
import PaymentIn from "../../Sales/PaymentIn";
import QuotationTable from "../../Sales/QuotationTable";
import SalesOrder from "../../Sales/OrderTable";
import DeliveryChallan from "../../Sales/DeliveryChallan";
import PurchaseCreditNotes from "../../Purchase/PurchaseCreditNotes";
import PurchaseOrder from "../../Purchase/PurchaseOrder";
import { useIndianState } from "../../../Context/StateOptionsContext";
import AddParty from "../AddParty";
import "../../../Common/Acc_Common.css";
import "../Party.css";
import "../../../Common/Dashboardlayout.css";
import PurchaseInvoiceTable from "../../Purchase/PurchaseInvoiceTable";
import PartyBalanceList from "./PartyBalanceListTable";
import PurchasePaymentOut from "../../Purchase/PurchasePaymentOut";
import search from "../../../Images/search.svg";
import { IIndianState } from "../../../../Types";
import PartyWiseItemsDetails from "./PartyWiseItemsDetails";
import { InvoiceTradeType } from "../../../Services/SalesService";
interface DataType {
  id: any;
  key: React.Key;
  bName: string;
  name: string;
  email: string;
  phone: number;
  balance: number;
}

const PartyDeatils = () => {
  const { TabPane } = Tabs;
  const navigate = useNavigate();
  const { themeData } = useTheme();
  const { partyId } = useParams();
  const { stateOptions } = useIndianState();
  const { currentClient } = useClient();
  const [totalRecords, setTotalRecords] = React.useState<number>(0);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [trigger, setTrigger] = React.useState<boolean>(false);
  const [tableData, setTableData] = React.useState<any[]>([]);
  const [searchItem, setSearchItem] = React.useState<string>("");
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState<number>(50);
  const [editParty, setEditParty] = React.useState<{ id: any }>({
    id: null,
  });
  const [party, setParty] = React.useState<{
    partyId: string | undefined;
    name: string;
  }>({
    partyId: partyId,
    name: "",
  });
  // const [type, setType] = React.useState<number>(0);
  const [popoverOpen, setPopoverOpen] = React.useState<boolean>(false);
  const [openDrawer, setOpenDrawer] = React.useState<boolean>(false);
  const [filterOption, setFilterOption] = React.useState<{
    partyType: number;
    gstType: number;
    iState: number;
  }>({
    partyType: 0,
    gstType: 3,
    iState: 0,
  });
  const [salesInvoiceList, setSalesInvoiceList] = React.useState<any>([]);
  const [estimateList, setEstimateList] = React.useState<any>([]);
  const [purchaseInvoiceList, setPurchaseInvoiceList] = React.useState<any>([]);
  const [partyDetails, setPartyDetails] = React.useState<any>({});
  const [loadSkeleton, setLoadSkeleton] = React.useState<boolean>(false);
  const [tabs, setTabs] = React.useState<{
    overView: any;
    sales: string;
    purchase: string;
  }>({
    overView: "",
    sales: "4",
    purchase: "13",
  });
  const [firstParty, setFirstParty] = React.useState<boolean>(false);
  const [toggle, setToggle] = React.useState<boolean>(false);

  // console.log("overview,,,,,,,", tabs.overView);

  React.useEffect(() => {
    setLoading(true);
    getPartyList(
      filterOption.partyType,
      (page - 1) * pageSize,
      pageSize * page,
      undefined,
      undefined,
      searchItem,
      undefined,
      filterOption.gstType,
      filterOption.iState
    );
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger, page, pageSize, searchItem]);

  // --------- Get All Party List API ---------
  const getPartyList = async (
    type: number,
    start: number,
    length: number,
    sortCol: any,
    sortDir: any,
    search: string,
    status: any,
    gstType: number,
    iState: number
  ) => {
    await PartyService.getPartDetailsList(
      currentClient?.id!,
      type,
      start,
      length,
      sortCol,
      sortDir,
      search,
      status,
      gstType,
      iState
    )
      .then((res: any) => {
        // debugger;
        if (res != null || res?.items?.length > 0) {
          console.log(res, "resdata");
          setTableData(
            res?.items?.map((r: any, i: any) => {
              return {
                key: i,
                id: r?.id,
                bName: r?.bName,
              };
            })
          );

          firstParty &&
            setParty({ partyId: res?.items[0].id, name: res.items[0].bName });
          setTotalRecords(res?.totalRecords);
        } else if (res.items.length === 0) {
          setTableData([]);
          setTotalRecords(res?.totalRecords);
        }
      })
      .catch((ex: any) => {
        console.log("error", ex);
        setTableData([]);
      });
    setLoading(false);
  };

  // --------- Table Columns  ---------
  const columns: ColumnsType<DataType> = [
    {
      title: " ",
      dataIndex: "bName",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          <NavLink
            to={`/accounts/clients/${currentClient?.id}/parties/${record?.id}`}
          >
            <div
              onClick={() => {
                setParty({ partyId: record?.id, name: record?.bName });
              }}
              style={{
                color: "black",
              }}
            >
              {record?.bName}
            </div>
          </NavLink>
        </Skeleton>
      ),
    },
  ];

  // Party Details Get Api
  React.useEffect(() => {
    async function details(partyId: string | undefined) {
      partyId &&
        (await PartyService.getPartytDetailsById(currentClient?.id!, partyId!)
          .then((result: any) => {
            if (result) {
              const state = stateOptions?.find(
                (itm: IIndianState) => itm?.id === result?.state
              ) as IIndianState | undefined;
              setParty({ partyId: result.id, name: result.bName });
              setPartyDetails({
                bName: result.bName?.trim(),
                phone: result.defaultContact?.phone,
                bAddress: result.bAddress?.trim(),
                balance: result.balance,
                name:
                  result.defaultContact?.name &&
                  (result.defaultContact?.name?.first
                    ? result.defaultContact?.name?.first?.trim() + " "
                    : "") + (result.defaultContact?.name?.last?.trim() ?? ""),
                email: result.defaultContact?.email,
                gst: result.gst,
                state: state?.name,
                type: result.type,
              });
              setTabs({
                ...tabs,
                overView: result.type === 0 || result.type === 1 ? "2" : "3",
              });
            }
            console.log(
              "result........",
              result.bAddress,
              result.bAddress.trim().length
            );
          })
          .catch((err: any) => {
            console.log(err);
          }));
    }
    details(party.partyId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [party.partyId, trigger]);

  return (
    <div>
      {/* style={{ border: "1px solid #DFE9F7", borderRadius: "5px" }} */}

      <Row>
        {/* style={{ borderRight: "2px solid #DFE9F7" }} */}

        {toggle ? (
          <>
            <Col lg={5} style={{ paddingRight: "5px", display: "flex" }}>
              <Card
                style={{ border: "1.5px solid #eef2fe" }}
                className="ca-card-body "
              >
                <Row
                  align={"middle"}
                  style={{ width: "292px", paddingTop: "5px" }}
                >
                  <Col lg={1} style={{ margin: "0px 8px" }}>
                    <NavLink
                      to={`/accounts/clients/${currentClient?.id}/parties`}
                    >
                      <ArrowLeftOutlined />
                    </NavLink>
                  </Col>

                  <Col lg={18}>
                    <Select
                      value={[{ label: party.name, value: party.partyId }]}
                      showSearch
                      labelInValue
                      optionFilterProp="children"
                      filterOption={false}
                      style={{ margin: "5px 0px 5px 0px", width: 205 }}
                      placeholder="Search Party Name"
                      // showArrow={false}
                      notFoundContent={"Select"}
                      onSearch={(value: string) => {
                        setLoading(true);
                        setSearchItem(value);
                      }}
                      onClick={() => {
                        // setSearchCategory("");
                        // setNewItem({
                        //   id: "",
                        //   name: "",
                        //   isSaved: false,
                        // });
                      }}
                      onChange={(x: any) => {
                        console.log("value.........", x);
                        setParty({ partyId: x.value, name: x.label });
                        navigate(
                          `/accounts/clients/${currentClient?.id}/parties/${x.value}`
                        );

                        // if (fields.length === index + 1) add();
                        // ItemSelect(value, index);
                      }}
                      options={tableData?.map((value: any) => ({
                        value: value?.id,
                        label: value?.bName,
                      }))}
                      dropdownRender={(categoryOptions) => (
                        <>
                          {categoryOptions}
                          <Divider style={{ margin: "5px 0px" }} />
                          <Button
                            style={{ width: "100%" }}
                            icon={<PlusOutlined />}
                            type="link"
                            onClick={() => {
                              setOpenDrawer(true);
                              setEditParty({ id: null });
                            }}
                          >
                            Add Party
                          </Button>
                        </>
                      )}
                    />
                  </Col>
                  <Col lg={2}>
                    <Popover
                      trigger="click"
                      placement="bottomRight"
                      arrow={{ pointAtCenter: true }}
                      open={popoverOpen}
                      onOpenChange={(x: boolean) => setPopoverOpen(x)}
                      content={
                        <Row style={{ maxWidth: "230px" }}>
                          <Col lg={24}>
                            <Select
                              value={filterOption.partyType}
                              style={{ width: "100%" }}
                              onSelect={(e: any) => {
                                setFilterOption({
                                  ...filterOption,
                                  partyType: e,
                                });
                                console.log("Clicked", e);
                              }}
                              options={[
                                {
                                  label: "All",
                                  value: 0,
                                },
                                { label: "Customer", value: 1 },
                                { label: "Suppliers", value: 2 },
                              ]}
                            />
                          </Col>
                          <Col lg={24} style={{ marginTop: "6px" }}>
                            <Select
                              value={
                                filterOption.gstType === 3
                                  ? ""
                                  : filterOption.gstType
                              }
                              allowClear
                              onClear={() =>
                                setFilterOption({
                                  ...filterOption,
                                  gstType: 3,
                                })
                              }
                              style={{ width: "100%" }}
                              placeholder="GST Type"
                              // value={party}
                              onSelect={(e: any) => {
                                console.log("Clicked", e);
                                setFilterOption({
                                  ...filterOption,
                                  gstType: e,
                                });
                                // setParty(e);
                              }}
                              options={[
                                {
                                  label: "Unregistered",
                                  value: 0,
                                },
                                { label: "Regular", value: 1 },
                                { label: "Composition", value: 2 },
                              ]}
                            />
                          </Col>
                          <Col lg={24} style={{ marginTop: "6px" }}>
                            <Select
                              value={
                                filterOption.iState === 0
                                  ? ""
                                  : filterOption.iState
                              }
                              allowClear
                              onClear={() =>
                                setFilterOption({
                                  ...filterOption,
                                  iState: 0,
                                })
                              }
                              showSearch
                              style={{ width: "100%" }}
                              placeholder="State"
                              optionFilterProp="children"
                              onSelect={(e: any) => {
                                setFilterOption({
                                  ...filterOption,
                                  iState: e,
                                });
                              }}
                              options={stateOptions?.map((x: any) => ({
                                value: x.id,
                                label: x.name,
                              }))}
                              filterOption={(input, option) =>
                                (option?.label ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                            />
                          </Col>
                          <Col lg={24} style={{ marginTop: "6px" }}>
                            <Row justify="space-around">
                              <Button
                                type="primary"
                                onClick={() => {
                                  setTrigger((x) => !x);
                                  setPopoverOpen(false);
                                  setFirstParty(true);
                                }}
                              >
                                Apply
                              </Button>
                              <Button
                                danger
                                onClick={() => {
                                  setFilterOption({
                                    partyType: 0,
                                    gstType: 3,
                                    iState: 0,
                                  });
                                  setTrigger((x) => !x);
                                  setPopoverOpen(false);
                                }}
                              >
                                Reset
                              </Button>
                              <Button
                                onClick={() => {
                                  setPopoverOpen(false);
                                }}
                              >
                                Close
                              </Button>
                            </Row>
                          </Col>
                        </Row>
                      }
                    >
                      <FilterFilled
                        style={{ color: "#9AAEC1", fontSize: "16px" }}
                      />
                    </Popover>
                  </Col>
                  <Col lg={1}>
                    <UpOutlined
                      onClick={() => {
                        setToggle(!toggle);
                      }}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col
              lg={19}
              // style={{ paddingLeft: "22px" }}
              className="ca-card-body-padding"
            >
              <Card
                style={{
                  border: "1.5px solid #eef2fe",
                }}
              >
                <Row>
                  <Col span={6}>
                    <div>
                      {partyDetails.bName?.length > 30 ? (
                        <Tooltip
                          title={partyDetails?.bName}
                          placement="top"
                          color="#3D4998"
                        >
                          {partyDetails?.bName.slice(0, 30) + "..."}
                        </Tooltip>
                      ) : (
                        partyDetails?.bName
                      )}
                    </div>
                    <div>
                      <span
                        style={{
                          paddingRight: "10px",
                          color: "rgb(139, 143, 165)",
                        }}
                      >
                        Phone:
                      </span>
                      {partyDetails?.phone}
                    </div>
                  </Col>
                  <Col span={6}>
                    <div>
                      <span
                        style={{
                          paddingRight: "14px",
                          color: "rgb(139, 143, 165)",
                        }}
                      >
                        Email:
                      </span>
                      {partyDetails.email?.length > 23 ? (
                        <Tooltip
                          title={partyDetails?.email}
                          placement="top"
                          color="#3D4998"
                        >
                          {partyDetails?.email.slice(0, 23) + "..."}
                        </Tooltip>
                      ) : (
                        partyDetails?.email
                      )}
                    </div>
                    <div>
                      <span
                        style={{
                          paddingRight: "10px",
                          color: "rgb(139, 143, 165)",
                        }}
                      >
                        Name:
                      </span>
                      {partyDetails.name?.length > 15 ? (
                        <Tooltip
                          title={partyDetails?.name}
                          placement="bottom"
                          color="#3D4998"
                        >
                          {partyDetails?.name.slice(0, 35) + "..."}
                        </Tooltip>
                      ) : (
                        partyDetails?.name
                      )}
                    </div>
                  </Col>
                  <Col span={5}>
                    <div>
                      <span
                        style={{
                          paddingRight: "16px",
                          color: "rgb(139, 143, 165)",
                        }}
                      >
                        GST:
                      </span>
                      {partyDetails?.gst && partyDetails?.gst?.gstin}
                    </div>

                    <div>
                      <span
                        style={{
                          paddingRight: "10px",
                          color: "rgb(139, 143, 165)",
                        }}
                      >
                        State:
                      </span>
                      {partyDetails?.state}
                    </div>
                  </Col>
                  {/* style={{ marginRight: "30px" }} */}
                  <Col span={6}>
                    <span
                      style={{
                        paddingRight: "10px",
                        color: "rgb(139, 143, 165)",
                      }}
                    >
                      Address:
                    </span>
                    {partyDetails.bAddress?.length > 55 ? (
                      <Tooltip
                        title={partyDetails?.bAddress}
                        placement="bottom"
                        color="#3D4998"
                      >
                        {partyDetails?.bAddress.slice(0, 55) + "..."}
                      </Tooltip>
                    ) : (
                      partyDetails?.bAddress
                    )}
                  </Col>
                  <Col span={1} style={{ textAlign: "center" }}>
                    <Tooltip placement="top" color="#3D4998">
                      <FormOutlined
                        className="ca-edit-btn"
                        style={{ color: "#a5abdd" }}
                        onClick={() => {
                          setOpenDrawer(true);
                          setEditParty({ id: party.partyId });
                        }}
                      />
                    </Tooltip>
                  </Col>
                </Row>
              </Card>
            </Col>
          </>
        ) : (
          <>
            <Col lg={5} style={{ paddingRight: "5px" }}>
              <Card
                className="ca-card-body"
                style={{ border: "1.5px solid #eef2fe" }}
              >
                <div>
                  <Row
                    align="middle"
                    style={{
                      borderBottom: "1px solid #DFE9F7",
                      height: "50px",
                    }}
                  >
                    <Col lg={1} style={{ margin: "0px 8px" }}>
                      <NavLink
                        to={`/accounts/clients/${currentClient?.id}/parties`}
                      >
                        <ArrowLeftOutlined />
                      </NavLink>
                    </Col>
                    <Col lg={15}>
                      <Input
                        allowClear
                        // width: "220px",
                        style={{ margin: "5px 0px 5px 0px" }}
                        placeholder="Search Party.."
                        onChange={(e: any) => {
                          setLoading(true);
                          if (!e.cancelable) {
                            const searchItem = setTimeout(() => {
                              setPage(1);
                              setSearchItem(e.target.value);
                            }, 1000);
                            return () => clearTimeout(searchItem);
                          } else {
                            // console.log("Click cancle............");
                            setSearchItem(e.target.value);
                          }
                        }}
                        suffix={
                          loading || searchItem ? (
                            ""
                          ) : (
                            <img alt="Search.." src={search} />
                          )
                        }
                      />
                    </Col>
                    <Col lg={2} style={{ marginLeft: "8px" }}>
                      <PlusOutlined
                        onClick={() => {
                          setOpenDrawer(true);
                          setEditParty({ id: null });
                        }}
                      />
                    </Col>
                    <Col lg={2}>
                      <Popover
                        trigger="click"
                        placement="bottomRight"
                        arrow={{ pointAtCenter: true }}
                        open={popoverOpen}
                        onOpenChange={(x: boolean) => setPopoverOpen(x)}
                        content={
                          <Row style={{ maxWidth: "230px" }}>
                            <Col lg={24}>
                              <Select
                                value={filterOption.partyType}
                                style={{ width: "100%" }}
                                onSelect={(e: any) => {
                                  setFilterOption({
                                    ...filterOption,
                                    partyType: e,
                                  });
                                  console.log("Clicked", e);
                                }}
                                options={[
                                  {
                                    label: "All",
                                    value: 0,
                                  },
                                  { label: "Customer", value: 1 },
                                  { label: "Suppliers", value: 2 },
                                ]}
                              />
                            </Col>
                            <Col lg={24} style={{ marginTop: "6px" }}>
                              <Select
                                value={
                                  filterOption.gstType === 3
                                    ? ""
                                    : filterOption.gstType
                                }
                                allowClear
                                onClear={() =>
                                  setFilterOption({
                                    ...filterOption,
                                    gstType: 3,
                                  })
                                }
                                style={{ width: "100%" }}
                                placeholder="GST Type"
                                // value={party}
                                onSelect={(e: any) => {
                                  console.log("Clicked", e);
                                  setFilterOption({
                                    ...filterOption,
                                    gstType: e,
                                  });
                                  // setParty(e);
                                }}
                                options={[
                                  {
                                    label: "Unregistered",
                                    value: 0,
                                  },
                                  { label: "Regular", value: 1 },
                                  { label: "Composition", value: 2 },
                                ]}
                              />
                            </Col>
                            <Col lg={24} style={{ marginTop: "6px" }}>
                              <Select
                                value={
                                  filterOption.iState === 0
                                    ? ""
                                    : filterOption.iState
                                }
                                allowClear
                                onClear={() =>
                                  setFilterOption({
                                    ...filterOption,
                                    iState: 0,
                                  })
                                }
                                showSearch
                                style={{ width: "100%" }}
                                placeholder="State"
                                optionFilterProp="children"
                                onSelect={(e: any) => {
                                  setFilterOption({
                                    ...filterOption,
                                    iState: e,
                                  });
                                }}
                                options={stateOptions?.map((x: any) => ({
                                  value: x.id,
                                  label: x.name,
                                }))}
                                filterOption={(input, option) =>
                                  (option?.label ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                              />
                            </Col>
                            <Col lg={24} style={{ marginTop: "6px" }}>
                              <Row justify="space-around">
                                <Button
                                  type="primary"
                                  onClick={() => {
                                    setTrigger((x) => !x);
                                    setPopoverOpen(false);
                                    setFirstParty(true);
                                  }}
                                >
                                  Apply
                                </Button>
                                <Button
                                  danger
                                  onClick={() => {
                                    setFilterOption({
                                      partyType: 0,
                                      gstType: 3,
                                      iState: 0,
                                    });
                                    setTrigger((x) => !x);
                                    setPopoverOpen(false);
                                  }}
                                >
                                  Reset
                                </Button>
                                <Button
                                  onClick={() => {
                                    setPopoverOpen(false);
                                  }}
                                >
                                  Close
                                </Button>
                              </Row>
                            </Col>
                          </Row>
                        }
                      >
                        <FilterFilled
                          style={{ color: "#9AAEC1", fontSize: "16px" }}
                        />
                      </Popover>
                    </Col>
                    <Col lg={1}>
                      <DownOutlined
                        onClick={() => {
                          setToggle(!toggle);
                          console.log(toggle, "chekc");
                        }}
                      />
                    </Col>
                  </Row>

                  <div
                    style={{
                      // height: toggle ? "8px " : `calc(100vh - 145px)`,
                      // backgroundColor: "GrayText",
                      overflowY: "auto",
                    }}
                    className={`${
                      toggle ? "partydetailsanimation" : "withoutanimation"
                    }`}
                  >
                    <Table
                      rowClassName={(record) => {
                        return record.id === party.partyId
                          ? "ca-active-table"
                          : "";
                      }}
                      className={`Tabel-style-none table-${
                        themeData?.componentSize ?? "middle"
                      }`}
                      showHeader={false}
                      // rowSelection={rowSelection}
                      columns={columns}
                      dataSource={
                        loading
                          ? (Array.from({ length: pageSize }, (_, index) => ({
                              key: `loading-${index}`,
                            })) as DataType[])
                          : tableData
                      }
                      pagination={false}
                      footer={() => (
                        <>
                          <table>
                            <tbody>
                              <tr>
                                <th className="textStart">
                                  Total
                                  {filterOption.partyType === 0
                                    ? " Party"
                                    : filterOption.partyType === 1
                                    ? " Customer"
                                    : " Supplier"}
                                </th>
                                <th style={{ textAlign: "end" }}>
                                  {totalRecords}
                                </th>
                              </tr>
                            </tbody>
                          </table>
                        </>
                      )}
                    />
                  </div>
                </div>
              </Card>
            </Col>
          </>
        )}
        <Col
          lg={toggle ? 24 : 19}
          style={{
            padding: toggle ? "0px" : "5px",
            paddingTop: toggle ? "8px" : "",
          }}
          className="ca-card-body-padding"
        >
          {!toggle && (
            <Card
              style={{ marginBottom: "10px", border: "1.5px solid #eef2fe" }}
            >
              <Row>
                <Col span={6}>
                  <div>
                    {partyDetails.bName?.length > 30 ? (
                      <Tooltip
                        title={partyDetails?.bName}
                        placement="top"
                        color="#3D4998"
                      >
                        {partyDetails?.bName.slice(0, 30) + "..."}
                      </Tooltip>
                    ) : (
                      partyDetails?.bName
                    )}
                  </div>
                  <div>
                    <span
                      style={{
                        paddingRight: "10px",
                        color: "rgb(139, 143, 165)",
                      }}
                    >
                      Phone:
                    </span>
                    {partyDetails?.phone}
                  </div>
                </Col>
                <Col span={6}>
                  <div>
                    <span
                      style={{
                        paddingRight: "14px",
                        color: "rgb(139, 143, 165)",
                      }}
                    >
                      Email:
                    </span>
                    {partyDetails.email?.length > 23 ? (
                      <Tooltip
                        title={partyDetails?.email}
                        placement="top"
                        color="#3D4998"
                      >
                        {partyDetails?.email.slice(0, 23) + "..."}
                      </Tooltip>
                    ) : (
                      partyDetails?.email
                    )}
                  </div>
                  <div>
                    <span
                      style={{
                        paddingRight: "10px",
                        color: "rgb(139, 143, 165)",
                      }}
                    >
                      Name:
                    </span>
                    {partyDetails.name?.length > 15 ? (
                      <Tooltip
                        title={partyDetails?.name}
                        placement="bottom"
                        color="#3D4998"
                      >
                        {partyDetails?.name.slice(0, 35) + "..."}
                      </Tooltip>
                    ) : (
                      partyDetails?.name
                    )}
                  </div>
                </Col>
                <Col span={5}>
                  <div>
                    <span
                      style={{
                        paddingRight: "16px",
                        color: "rgb(139, 143, 165)",
                      }}
                    >
                      GST:
                    </span>
                    {partyDetails?.gst && partyDetails?.gst?.gstin}
                  </div>

                  <div>
                    <span
                      style={{
                        paddingRight: "10px",
                        color: "rgb(139, 143, 165)",
                      }}
                    >
                      State:
                    </span>
                    {partyDetails?.state}
                  </div>
                </Col>
                {/* style={{ marginRight: "30px" }} */}
                <Col span={6}>
                  <span
                    style={{
                      paddingRight: "10px",
                      color: "rgb(139, 143, 165)",
                    }}
                  >
                    Address:
                  </span>
                  {partyDetails.bAddress?.length > 55 ? (
                    <Tooltip
                      title={partyDetails?.bAddress}
                      placement="bottom"
                      color="#3D4998"
                    >
                      {partyDetails?.bAddress.slice(0, 55) + "..."}
                    </Tooltip>
                  ) : (
                    partyDetails?.bAddress
                  )}
                </Col>
                <Col span={1} style={{ textAlign: "center" }}>
                  <Tooltip placement="top" color="#3D4998">
                    <FormOutlined
                      className="ca-edit-btn"
                      style={{ color: "#a5abdd" }}
                      onClick={() => {
                        setOpenDrawer(true);
                        setEditParty({ id: party.partyId });
                      }}
                    />
                  </Tooltip>
                </Col>
              </Row>
            </Card>
          )}
          <div className={`${toggle ? "partyanimationoverview" : ""}`}>
            <Card className="ca-card-body">
              <div
                style={{
                  height: `calc(100vh - 170px)`,
                  // backgroundColor: "azure",
                  overflowY: "auto",
                }}
              >
                <Tabs
                  className="ca-tab-party"
                  size="small"
                  defaultActiveKey="1"
                  onChange={(e) => {
                    console.log("tabs1.........", e);
                    setTabs({ ...tabs, overView: e });
                  }}
                >
                  <TabPane tab="Overview">
                    <Tabs
                    // activeKey={tabs.overView}
                    // onChange={(e) => setTabs({ ...tabs, overView: e })}
                    >
                      {(partyDetails.type === 0 || partyDetails.type === 1) && (
                        <TabPane tab="Customer" key="2">
                          {/* {tabs.overView === "2" && ( */}
                          <PartyBalanceList partyId={party.partyId} type={1} />
                          {/* )} */}
                        </TabPane>
                      )}
                      {(partyDetails.type === 0 || partyDetails.type === 2) && (
                        <TabPane tab="Suppliers" key="3">
                          {/* {tabs.overView === "3" && ( */}
                          <PartyBalanceList partyId={party.partyId} type={2} />
                          {/* )} */}
                        </TabPane>
                      )}
                    </Tabs>
                  </TabPane>
                  {(partyDetails.type === 0 || partyDetails.type === 1) && (
                    <TabPane tab="Sales" key="4">
                      <Tabs
                        activeKey={tabs.sales}
                        onChange={(e) => setTabs({ ...tabs, sales: e })}
                        // style={{ margin: "10px", padding: "10px" }}
                      >
                        <TabPane tab="Invoices" key="4">
                          {tabs.sales === "4" && (
                            <SalesInvoiceTable
                              LoadSkeleton={loadSkeleton}
                              setLoadSkeleton={setLoadSkeleton}
                              salesInvoiceList={salesInvoiceList}
                              setSalesInvoiceList={setSalesInvoiceList}
                              partyId={party.partyId}
                            />
                          )}
                        </TabPane>
                        <TabPane tab="Credit Note" key="5">
                          {tabs.sales === "5" && (
                            <CreditNotes partyId={party.partyId} />
                          )}
                        </TabPane>

                        <TabPane tab="Payment In" key="6">
                          {tabs.sales === "6" && (
                            <PaymentIn partyId={party.partyId} />
                          )}
                        </TabPane>

                        <TabPane tab="Estimate" key="7">
                          {tabs.sales === "7" && (
                            <QuotationTable
                              estimateList={estimateList}
                              setEstimateList={setEstimateList}
                              partyId={party.partyId}
                            />
                          )}
                        </TabPane>

                        <TabPane tab="Order" key="8">
                          {tabs.sales === "8" && (
                            <SalesOrder
                              partyId={party.partyId}
                              salesOrderList={[]}
                              setSalesOrderList={function (value: any): void {}}
                              setLoading={function (value: boolean): void {}}
                            />
                          )}
                        </TabPane>

                        <TabPane tab="Delivery Challan" key="9">
                          {tabs.sales === "9" && (
                            <DeliveryChallan partyId={party.partyId} />
                          )}
                        </TabPane>

                        <TabPane tab="Items" key="10">
                          {tabs.sales === "10" && (
                            <PartyWiseItemsDetails
                              partyId={party.partyId}
                              InvoiceTradeType={InvoiceTradeType.Sales}
                            />
                          )}
                        </TabPane>
                      </Tabs>
                    </TabPane>
                  )}
                  {(partyDetails.type === 0 || partyDetails.type === 2) && (
                    <TabPane tab="Purchase" key="13">
                      <Tabs
                        activeKey={tabs.purchase}
                        defaultActiveKey="12"
                        onChange={(e) => setTabs({ ...tabs, purchase: e })}
                      >
                        <TabPane tab="Invoices" key="13">
                          {tabs.purchase === "13" && (
                            <PurchaseInvoiceTable
                              purchaseInvoiceList={purchaseInvoiceList}
                              setPurchaseInvoiceList={setPurchaseInvoiceList}
                              partyId={party.partyId}
                            />
                          )}
                        </TabPane>
                        <TabPane tab="Debit Note" key="14">
                          {tabs.purchase === "14" && (
                            <PurchaseCreditNotes partyId={party.partyId} />
                          )}
                        </TabPane>

                        <TabPane tab="Payment Out" key="15">
                          {tabs.purchase === "15" && (
                            <PurchasePaymentOut partyId={party.partyId} />
                          )}
                        </TabPane>

                        <TabPane tab="Order" key="16">
                          {tabs.purchase === "16" && (
                            <PurchaseOrder partyId={party.partyId} />
                          )}
                        </TabPane>

                        <TabPane tab="Items" key="17">
                          {tabs.purchase === "17" && (
                            <PartyWiseItemsDetails
                              partyId={party.partyId}
                              InvoiceTradeType={InvoiceTradeType.Purchase}
                            />
                          )}
                        </TabPane>
                      </Tabs>
                    </TabPane>
                  )}
                  <TabPane tab="Mails" key="18">
                    {<>Work in progress</>}
                  </TabPane>
                </Tabs>
              </div>
            </Card>
          </div>
        </Col>
      </Row>

      {openDrawer && (
        <AddParty
          openDrawer={openDrawer}
          setTrigger={setTrigger}
          setOpenDrawer={setOpenDrawer}
          editParty={editParty}
          setNewPartie={() => ""}
        />
      )}
    </div>
  );
};

export default PartyDeatils;

// bordered={false}
// title={
//   <Row style={{ position: "sticky" }}>
//     <Col lg={16}>
//       <Select
//         style={{ width: "110px" }}
//         value={party}
//         onChange={(e: any) => {
//           console.log(e);
//           setParty(e);
//         }}
//         options={[
//           {
//             label: "Both",
//             value: 0,
//           },
//           { label: "Customer", value: 1 },
//           { label: "Suppliers", value: 2 },
//         ]}
//       />
//     </Col>
//     <Col lg={8}>
//       <PlusOutlined />
//     </Col>
//   </Row>
// }
// style={{ height: `calc(100vh - 150px)` }}
