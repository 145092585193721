import React, { useContext } from "react";
import {
  Avatar,
  Col,
  Divider,
  Modal,
  Row,
  Tooltip,
  Button,
  Spin,
  Checkbox,
} from "antd";
import "../../Common/AddPageCommon.css";
import noteContext from "../../Context/StyleContext";
import { Utils } from "../../../Utilities/Utils";
import { useClient } from "../../Context/ClientContext";
import { NavLink, useNavigate } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { notificationContext } from "../../Common/PageRoute";
import { useIndianState } from "../../Context/StateOptionsContext";
import { IIndianState } from "../../../Types";
import ViewSalesInvoiceNoDetails from "./ViewSalesInvoiceNoDetails";
import VoucherServices, { Voucher_Type } from "../../Services/VoucherServices";
import { getIndianStates } from "../../Services/ClientService";
import QuotationServices from "../../Services/QuotationServices";

const ViewEstimateModel: React.FC<{
  open: boolean;
  onCancel: any;
  estimateId: string;
  voucharType?: Voucher_Type;
}> = (props) => {
  console.log(props.estimateId, "resid");
  const navigate = useNavigate();
  const { currentClient, companySettings } = useClient();
  const { windowWidth } = React.useContext(noteContext);
  const { openNotification } = useContext(notificationContext);
  const [data, setData] = React.useState<any>({});
  const [loading, setLoading] = React.useState<boolean>(false);
  const [isGSTEnabled, setGstEnabled] = React.useState<boolean>(false);
  const [itemLength, setItemLength] = React.useState<number>(0);
  const [freightLength, setFreightLength] = React.useState<number>(0);
  // const [isInCash, setIsInCash] = React.useState<boolean>(false);
  const [isStateSame, setIsStateSame] = React.useState<boolean>(false);
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const [openModel, setOpenModel] = React.useState<boolean>(false);
  const [freightCheckbox, setfreightCheckbox] = React.useState<boolean>(true);

  React.useEffect(() => {
    const get = async (estimateId: string) => {
      setLoading(true);
      await QuotationServices.getQuotationId(
        currentClient?.id!,
        estimateId,
        props.voucharType
      )
        .then((res: any) => {
          const resp = res?.result;
          if (resp) {
            setGstEnabled(resp?.isGstEnabled ?? true);
            setItemLength(resp?.items?.length);
            setFreightLength(resp?.freightInsLedgers?.length);
            setIsStateSame(resp?.isStateSame);
            setData({
              number: resp?.number,
              invoiceId: resp?.id,
              ledgerId: resp?.partyLedger[0]?.id,
              ledgerName: resp?.partyLedger[0]?.ledgerName,
              phoneNo: resp?.phoneNo,
              narration: resp?.narration,
              billingAddress: resp?.billingAddress?.address,
              shippingAddress: resp?.shippingAddress?.address,
              effectiveLedger: resp?.effectiveLedger[0],
              supplyState: getIndianStates(resp?.supplyState),
              date: Utils.getFormattedDate(resp?.date),
              purchaseOrderRef: {
                id: resp.purchaseOrderRef?.id,
                name: resp.purchaseOrderRef?.name,
              },
              dueDate: Utils.getFormattedDate(resp?.dueDate),
              items: resp?.items,
              totalQty: Utils.getFormattedNumber(resp.amountSummary?.totalQty),
              totalPrice: Utils.getFormattedNumber(
                resp.amountSummary?.totalPrice
              ),
              totalDiscount: Utils.getFormattedNumber(
                resp.amountSummary?.totalDis
              ),
              sellPrice: Utils.getFormattedNumber(
                resp.amountSummary?.taxableAmount
              ),
              dueBalance: Utils.getFormattedNumber(
                resp.amountSummary?.dueBalance
              ),
              cifExpenses: Utils.getFormattedNumber(
                resp?.amountSummary?.cifExpenses
              ),
              totalItemGSTAmt: Utils.getFormattedNumber(
                resp?.amountSummary?.totalItemGSTAmt
              ),
              igstAmt: Utils.getFormattedNumber(resp?.amountSummary?.igstAmt),
              cgstAmt: Utils.getFormattedNumber(resp?.amountSummary?.cgstAmt),
              sgstAmt: Utils.getFormattedNumber(resp?.amountSummary?.sgstAmt),
              received: resp.amount?.paid,
              refund: resp.amount?.refund,
              // dueAmount: resp.amount?.due,
              totalgst: resp.amountSummary?.igst
                ? resp.amountSummary?.igst
                : resp.amountSummary?.sgst + resp.amountSummary?.cgst,
              igst: resp.amountSummary?.igst,
              cgst: resp.amountSummary?.cgst,
              sgst: resp.amountSummary?.sgst,
              receivedAmount: Utils.getFormattedNumber(
                resp.amountSummary?.receivedAmount
              ),
              dueAmount: Utils.getFormattedNumber(
                resp?.amountSummary?.dueAmount
              ),
              totalInvoiceAmount: Utils.getFormattedNumber(
                resp.amountSummary?.totalInvoiceAmount
              ),
              freightInsLedgers: resp?.freightInsLedgers,
              totalInvoiceAmountInWords:
                resp?.amountSummary?.totalInvoiceAmountInWords,
            });
          } else {
            console.error("error..");
          }
          // console.log("resSalesInvoice", resp);
          setLoading(false);
        })
        .catch((ex: any) => {
          console.error("error..", ex.message);

          openNotification("error", ex.message);
          setLoading(true);
        });
    };
    get(props.estimateId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient?.id, props.estimateId]);

  // console.log(freightLength, "checklength");
  //console.log(data.totalItemGSTAmt,"gstamounttoatal");
  // console.log(data?.dueAmount, "testDueAmount");

  return (
    <Modal
      open={props.open}
      width={1600}
      style={{ maxHeight: `calc(100% - 50px)`, overflowY: "auto" }}
      maskClosable={false}
      closable={false}
      onCancel={() => props.onCancel()}
      centered
      footer={
        <div style={{ marginTop: "0px" }} className="pe-15">
          <Button
            onClick={() => {
              props.onCancel();
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            style={{ width: "90px" }}
            onClick={async () => {
              props.voucharType === Voucher_Type.Quotation
                ? navigate(
                    `/accounts/clients/${currentClient?.id}/sales/editQuotation/${props.estimateId}`
                  )
                : props.voucharType === Voucher_Type.SalesOrder
                ? navigate(
                    `/accounts/clients/${currentClient?.id}/sales/editSalesOrder/${props.estimateId}`
                  )
                : navigate(
                    `/accounts/clients/${currentClient?.id}/purchase/editPurchaseOrder/${props.estimateId}`
                  );
            }}
          >
            Edit
          </Button>
        </div>
      }
    >
      <div style={{ padding: 15 }}>
        <Spin indicator={antIcon} spinning={loading}>
          {windowWidth <= 1024 ? (
            <h3 className="fs-35 fw-500 pt-20 themeColor textCenter ">
              Estimate
            </h3>
          ) : (
            ""
          )}
          <Row justify="space-between">
            <Col xl={17} lg={24} md={24} className="dashedBoxStyle">
              <Row gutter={24} className="ps-14" justify="space-between">
                <Col
                  lg={14}
                  md={11}
                  xs={23}
                  sm={23}
                  className="themeLightBgColor p-10"
                >
                  <Row gutter={[17, 2]}>
                    <Col lg={12}>
                      <span className="themeColor fw-500">Customer</span>
                      <div>
                        <NavLink
                          to={`/accounts/clients/${currentClient?.id}/master/ledger/${data?.ledgerId}`}
                        >
                          {data?.ledgerName}
                        </NavLink>
                      </div>
                    </Col>
                    <Col lg={12}>
                      <span className="themeColor fw-500">Phone No.</span>
                      <div>{data?.phoneNo}</div>
                    </Col>
                    <Col lg={12}>
                      <span className="themeColor fw-500">Billing Address</span>
                      <div>{data.billingAddress}</div>
                    </Col>
                    <Col lg={12}>
                      <span className="themeColor fw-500">
                        Shipping Address
                      </span>
                      <div>{data.shippingAddress}</div>
                    </Col>
                  </Row>
                </Col>

                <Col lg={9} md={4} xs={23} sm={23}>
                  <Row gutter={[17, 8]}>
                    <Col lg={12}>
                      <span className="themeColor fw-500">
                        {props.voucharType === Voucher_Type.PurchaseOrder
                          ? "Purchase Ledger"
                          : "Sales Ledger"}
                      </span>
                      <div>
                        <NavLink
                          to={`/accounts/clients/${currentClient?.id}/master/ledger/${data?.effectiveLedger?.id}`}
                        >
                          {data?.effectiveLedger?.ledgerName}
                        </NavLink>
                      </div>
                    </Col>
                    <Col lg={12}>
                      <span className="themeColor fw-500">
                        {props.voucharType === Voucher_Type.Quotation
                          ? "Estimate No."
                          : props.voucharType === Voucher_Type.SalesOrder
                          ? "Sales Order No."
                          : "Purchase Order No."}
                      </span>
                      <p>{data.number}</p>
                    </Col>
                    <Col lg={12}>
                      <span className="themeColor fw-500">Date</span>
                      <div>{data?.date}</div>
                    </Col>
                    {/* <Col lg={12}>
                      <span className="themeColor fw-500">
                        Freight & Insurance
                      </span>{" "}
                      <br></br>
                      <Checkbox
                        checked={freightLength > 0 ? freightCheckbox : false}
                        disabled
                      ></Checkbox>
                    </Col> */}
                    <Col lg={12}>
                      <span className="themeColor fw-500">Place Of Supply</span>
                      <div>{data?.supplyState}</div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            {windowWidth <= 1024 ? (
              ""
            ) : (
              <Col
                xl={6}
                lg={7}
                md={7}
                className="fs-35 fw-400 themeColor textEnd"
              >
                {props?.voucharType === 8
                  ? companySettings?.setting?.pOrder?.title
                  : props?.voucharType === 9
                  ? companySettings?.setting?.sOrder?.title
                  : companySettings?.setting?.estimate?.title}
              </Col>
            )}
          </Row>
          <table
            className="ca-viewmodel-table"
            style={{ fontSize: windowWidth <= 1024 ? "10.3px" : "" }}
          >
            <thead>
              <tr>
                <th colSpan={3} rowSpan={2} style={{ width: "7%" }}>
                  S No.
                </th>
                <th
                  rowSpan={2}
                  style={{
                    width: "11%",
                    borderLeft: "1px solid rgb(240, 245, 255)",
                  }}
                >
                  Item
                </th>
                <th
                  rowSpan={2}
                  style={{ width: windowWidth <= 1024 ? "18%" : "18%" }}
                >
                  Description
                </th>

                <th rowSpan={2} style={{ width: "5%" }}>
                  Qty
                </th>
                <th rowSpan={2} style={{ width: "6%" }}>
                  Unit
                </th>
                <th rowSpan={2} style={{ width: "9%" }}>
                  Unit Price
                </th>
                <th colSpan={2} style={{ width: "12%", paddingLeft: "29px" }}>
                  Discount
                </th>
                {isGSTEnabled && (
                  <th colSpan={2} style={{ width: "12%", paddingLeft: "39px" }}>
                    GST %
                  </th>
                )}
                <th rowSpan={2} style={{ width: "14%", textAlign: "end" }}>
                  Total Amount
                </th>
              </tr>
              <tr>
                <th style={{ width: "6%" }}>%</th>
                <th style={{ width: "6%" }}>₹</th>
                {isGSTEnabled && <th style={{ width: "6%" }}>%</th>}
                {isGSTEnabled && <th style={{ width: "6%" }}>₹</th>}
              </tr>
            </thead>
            <tbody>
              {data.items?.map((item: any, i: any) => {
                return (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>
                      {item.isService ? (
                        <Tooltip
                          title="Service"
                          placement="left"
                          color="#1d39c4"
                        >
                          <Avatar
                            style={{
                              cursor: "pointer",
                              verticalAlign: "middle",
                              color: "#1d39c4",
                              backgroundColor: "#f0f5ff",
                              borderColor: "#adc6ff",
                              fontWeight: "bold",
                            }}
                            size="small"
                          >
                            S
                          </Avatar>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Goods" placement="left" color="#389e0d">
                          <Avatar
                            style={{
                              cursor: "pointer",
                              verticalAlign: "middle",
                              color: "#389e0d",
                              backgroundColor: "#f6ffed",
                              borderColor: "#b7eb8f",
                              fontWeight: "bold",
                            }}
                            size="small"
                          >
                            G
                          </Avatar>
                        </Tooltip>
                      )}
                    </td>
                    <td>
                      {item.isTaxable ? (
                        <Tooltip
                          title="With Tax"
                          placement="right"
                          color="#08979C"
                        >
                          <Avatar
                            style={{
                              cursor: "pointer",
                              verticalAlign: "middle",
                              color: "#08979C",
                              backgroundColor: "#E6FFFB",
                              borderColor: "#08979C",
                              fontWeight: "bold",
                            }}
                            size="small"
                          >
                            T
                          </Avatar>
                        </Tooltip>
                      ) : (
                        <Tooltip
                          title="WithOut Tax"
                          placement="right"
                          color="#531DAB"
                        >
                          <Avatar
                            style={{
                              cursor: "pointer",
                              verticalAlign: "middle",
                              color: "#531DAB",
                              backgroundColor: "#F9F0FF",
                              borderColor: "#531DAB",
                              fontWeight: "bold",
                            }}
                            size="small"
                          >
                            WT
                          </Avatar>{" "}
                        </Tooltip>
                      )}
                    </td>

                    <td style={{ textAlign: "center" }}>
                      <NavLink
                        to={`/accounts/clients/${currentClient?.id}/purchase/inventory`}
                        state={{
                          id: item.id,
                          type: "sales",
                          itemType: item.isService
                            ? "purchaseService"
                            : "purchaseGoods",
                        }}
                      >
                        {item.name}
                      </NavLink>
                    </td>
                    <td>{item?.description}</td>
                    <td style={{ textAlign: "center" }}>
                      {Utils.getFormattedNumber(item.amount?.qty)}
                    </td>
                    <td>{item.amount?.unit?.name}</td>
                    <td style={{ textAlign: "center" }}>
                      {Utils.getFormattedNumber(item.amount?.price)}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {item.amount?.discount}%
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {Utils.getFormattedNumber(item.amount?.discountRupee)}
                    </td>
                    {isGSTEnabled && (
                      <td style={{ textAlign: "center" }}>
                        {item.amount?.tax?.name}
                      </td>
                    )}
                    {isGSTEnabled && (
                      <td style={{ textAlign: "center" }}>
                        {Utils.getFormattedNumber(item.amount?.itemTaxAmt)}
                      </td>
                    )}
                    <td style={{ textAlign: "end" }}>
                      {Utils.getFormattedNumber(item.amount?.totalAmount)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
            {itemLength > 0 && (
              <tfoot className="dashedBorderTop">
                <tr>
                  <th colSpan={5} className="textStart">
                    Total
                  </th>
                  <th colSpan={2} className="textStart ps-31">
                    {data.totalQty}
                  </th>
                  <th className="textStart ps-42">₹ {data.sellPrice}</th>
                  <th colSpan={2} className="textEnd pe-31 ">
                    ₹ {data.totalDiscount}
                  </th>
                  {isGSTEnabled && (
                    <th colSpan={2} className="textEnd pe-31 ">
                      ₹ {data?.totalItemGSTAmt}
                    </th>
                  )}
                  <th colSpan={2} className="textEnd">
                    ₹ {data.totalInvoiceAmount}
                  </th>
                </tr>
              </tfoot>
            )}
          </table>

          <table
            className="ca-viewmodel-table"
            style={{ fontSize: windowWidth <= 1024 ? "10.3px" : "" }}
          >
            <tbody>
              {data?.freightInsLedgers?.map((itm: any, indx: any) => {
                return (
                  <tr key={indx}>
                    <td style={{ width: "50%" }}>{itm?.ledgerName}</td>
                    <td style={{ width: "22%", paddingLeft: "38px" }}>
                      {Utils.getFormattedNumber(Math.abs(itm?.amount))}
                    </td>
                    <td style={{ width: "6%", textAlign: "center" }}>
                      {itm?.gstRate?.name}
                    </td>
                    <td style={{ width: "6%", textAlign: "center" }}>
                      {Utils.getFormattedNumber(itm?.gstAmount)}
                    </td>
                    <td style={{ width: "15%", textAlign: "end" }}>
                      {Utils.getFormattedNumber(itm?.totalAmount)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
            {freightLength > 0 && (
              <tfoot className="dashedBorderTop">
                <tr>
                  <th colSpan={1} className="textStart">
                    Total
                  </th>
                  <th colSpan={2} className="textStart ps-31">
                    ₹ {data?.cifExpenses}
                  </th>
                  <th className="textStart ps-23">₹ {data.totalPrice}</th>
                  {isGSTEnabled && (
                    <th colSpan={2} className="textEnd  ">
                      ₹ {Utils.getFormattedNumber(data.totalgst)}
                    </th>
                  )}
                </tr>
              </tfoot>
            )}
          </table>
          <Row className="pt-10">
            <Col lg={17}>
              <Row>
                <Col lg={3}>Discription:</Col>
                <Col lg={15}>{data?.narration}</Col>
              </Row>
            </Col>

            <Col lg={7}>
              <Row gutter={[0, 8]}>
                <Col lg={9}>Selling Price</Col>
                <Col lg={15} className="textEnd">
                  ₹ {data?.sellPrice}
                </Col>
                <Col lg={9}>Direct Expenses</Col>
                <Col lg={15} className="textEnd">
                  ₹ {Utils.getFormattedNumber(data?.cifExpenses)}
                </Col>
                <Col lg={9}>Discount</Col>
                <Col lg={15} className="textEnd">
                  ₹ {data?.totalDiscount}
                </Col>
                {isGSTEnabled && (
                  <>
                    {isStateSame ? (
                      <>
                        <Col lg={9}>CGST</Col>
                        <Col lg={15} className="textEnd">
                          ₹ {data?.cgstAmt}
                        </Col>
                        <Col lg={9}>SGST</Col>
                        <Col lg={15} className="textEnd">
                          ₹ {data?.sgstAmt}
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col lg={9}>IGST</Col>
                        <Col lg={15} className="textEnd">
                          ₹ {data?.igstAmt}
                        </Col>
                      </>
                    )}
                    {/* <Col lg={10}>
                  <Radio.Group value={tdsOrTcs}>
                    <Radio value={0}>TDS</Radio>
                    <Radio value={1}>TCS</Radio>
                  </Radio.Group>
                </Col>
                {tdsOrTcs === 1 ? (
                  <Col lg={14} className="textEnd">
                    ₹ {data.totalTcs ? data.totalTcs : 0}
                  </Col>
                ) : tdsOrTcs === 0 ? (
                  <Col lg={14} className="textEnd">
                    ₹ {data.totalTds ? data.totalTds : 0}
                  </Col>
                ) : (
                  <></>
                )} */}
                  </>
                )}
              </Row>
              <Divider className="my-5 grayBg" />
              <Row gutter={[12, 2]}>
                <Col lg={18}>Total Invoice Value</Col>
                <Col lg={6} className="textEnd">
                  ₹ {data?.totalInvoiceAmount}
                </Col>
                {/* {!isInCash && itemLength > 0 && (
                  <>
                    <Col lg={12} className="pt-5">
                      Received
                    </Col>
                    <Col lg={12} className="textEnd">
                      <div> ₹ {data?.receivedAmount}</div>
                    </Col>
                    <Divider className="my-5 grayBg" />

                    <Col lg={9}>Balance</Col>
                    <Col lg={15} className="textEnd">
                      ₹ {data?.dueBalance}
                    </Col>
                  </>
                )} */}
              </Row>
              <Divider
                style={{
                  borderBottom: "2px solid rgb(203, 203, 203)",
                }}
                className="my-5"
              />
            </Col>
          </Row>
        </Spin>
      </div>
      {openModel && (
        <ViewSalesInvoiceNoDetails
          open={openModel}
          onCancel={() => setOpenModel(false)}
          invoiceId={data?.invoiceId}
        />
      )}
    </Modal>
  );
};
export default ViewEstimateModel;
