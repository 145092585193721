import { ApiUtility } from "../../Utilities/ApiUtility.axios";
export const enum LogTypes {
  Parties,
  Sales,
  Purchase,
  Expenses,
  Assets,
  Journals,
}

class LogService {
  route = `/v1/api/UserLog`;
  getUserLog = (
    types: LogTypes,
    userId: string,
    clientId: string,
    start: number,
    length: number,
    search:string | undefined,
  ) =>
    ApiUtility.getResult(`${this.route}`, {
      types,
      userId,
      clientId,
      start,
      length,
      search
    });
}
export default new LogService();
