import { FC, Fragment, useContext, useRef, useState } from "react";
import {
  Button,
  Modal,
  Form,
  Input,
  Divider,
  Spin,
  InputRef,
  Checkbox,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useClient } from "../../Context/ClientContext";
import { notificationContext } from "../../Common/PageRoute";

const AddSalesInvoiceDespatchDetails: FC<{
  open: boolean;
  onCancel: any;
  data: any;
}> = (props) => {
  console.log("props", props);
  const inputRef = useRef<InputRef>(null);
  const [form] = Form.useForm();
  const { currentClient } = useClient();
  const { openNotification } = useContext(notificationContext);
  const [saveButtonLoading, setSaveButtonLoading] = useState<boolean>(false);
  // const [groupData, setGroupData] = useState<any[]>();

  const [loading, setLoading] = useState<boolean>(false);

  const saveGroup = () => {
    form.validateFields().then(async (value) => {
      //   setSaveButtonLoading(true);
      props.data(value);
      props.onCancel();
      console.log(value);
    });
  };

  return (
    <Fragment>
      <Modal
        open={props.open}
        title="Add Despatch Details"
        maskClosable={false}
        width={400}
        style={{ top: 44 }}
        onCancel={() => props.onCancel({})}
        footer={
          <div style={{ marginTop: "0px" }}>
            <Button
              onClick={() => {
                form.resetFields();
                props.onCancel({});
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={() => saveGroup()}
              style={{ width: "80px" }}
              loading={saveButtonLoading}
            >
              Save
            </Button>
          </div>
        }
      >
        <Spin
          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
          spinning={loading}
        >
          <Form
            name="despatchDetail"
            form={form}
            autoComplete="off"
            colon={false}
            labelAlign="left"
            // initialValues={intialValue}
            labelCol={{ xs: 7, sm: 10 }}
            wrapperCol={{ xs: 16, sm: 14 }}
            labelWrap={true}
          >
            <Divider className="ca-model-css" />
            <Form.Item
              name="delNoteNo"
              label="Despatch Note No"
              className="mb-14"
              //   rules={[{ required: true, message: "Account Code is required" }]}
            >
              <Input autoFocus={true} ref={inputRef} />
            </Form.Item>
            <Form.Item
              name="desDocNo"
              label="Despatch Doc. No"
              className="mb-12"
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="desThrough"
              label="Despatch Through"
              className="mb-14"
              //   rules={[{ required: true, message: "Account Type is required" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="destination"
              label="Destination"
              className="mb-14"
              //   rules={[{ required: true, message: "Nature is required" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="freightToPay"
              label="Freight To Pay"
              className="mb-14"
              valuePropName="checked"
              //   rules={[{ required: true, message: "Statement  is required" }]}
            >
              <Checkbox />
            </Form.Item>
          </Form>
          <Divider className="divider-account" />
        </Spin>
      </Modal>
    </Fragment>
  );
};

export default AddSalesInvoiceDespatchDetails;
