import React, { useEffect } from "react";
import {
  Layout,
  Row,
  Popover,
  Button,
  Col,
  Menu,
  theme,
  MenuProps,
  Divider,
  ConfigProvider,
  Input,
  Drawer,
  Tooltip,
  Typography,
  Space,
  Result,
  FloatButton,
  message,
  Tag,
} from "antd";
import companylogo1 from "../Images/caplogo.png";
import "./MainLayout.css";
import {
  MenuOutlined,
  PlusOutlined,
  ReadOutlined,
  MenuFoldOutlined,
  BankOutlined,
  WifiOutlined,
  BellOutlined,
  CommentOutlined,
  CustomerServiceOutlined,
} from "@ant-design/icons";
import { Link, matchPath, NavLink, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../Context/UserContext";
import companylogo from "../Images/CapsitechAccounts.svg";
import "./Dashboardlayout.css";
import PageRoute from "./PageRoute";
import ClientDataProvider from "../Context/ClientContext";
import { CgShoppingCart } from "react-icons/cg";
import { TfiBag } from "react-icons/tfi";
import { useTheme } from "../Context/ThemeContext";
import AddItems from "../Pages/Purchase/AddItems";
import AddCustomers from "../Pages/Sales/AddCustomers";
import AddPurchaseSupplier from "../Pages/Purchase/AddPurchaseSupplier";
import { ContentUser } from "../../Common/Admin/AdminComponent";
import "../Common/Dashboardlayout.css";
import Search from "../Images/search.svg";
import allclients from "../../images/allclient.png";
import saleslogo from "../../images/saleslogo.png";
import purchaselogo from "../../images/purchaselogo.png";
import journals from "../../images/journals.png";
import masters from "../../images/masters.png";
import settingslogo from "../../images/settingslogo.png";
import chartofaccounts from "../../images/chartofaccounts.png";
import agegingreport from "../../images/agegingreport.png";
import trialbal from "../../images/trialbal.png";
import profitloss from "../../images/profitloss.png";
import balancesheet from "../../images/balancesheet.png";
import dashbrd from "../../images/dashbrd.svg";
import avtaruser from "../../images/avtaruser.png";
import bankImg from "../../images/bankImg.svg";
import AssetImg from "../../images/AssetImg.svg";

import AddLedger from "../Pages/Master/Ledger/AddLedger";
import AddSalesInvoice from "../Pages/Sales/AddSalesInvoice";
import AdjustBankBalanceModel from "../Pages/Banking/Banks/AdjustBankBalanceModel";
import FloatButtons from "./FloatButtons";
import AddGroup from "../Pages/Master/Group/AddGroup";

const { Header, Sider, Content } = Layout;
type MenuItem = Required<MenuProps>["items"][number];

const AccountsMainLayout: React.FC = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useUser();
  const themeData: any = useTheme();
  const [openLogDrawer, setOpenLogDrawer] = React.useState(false);
  const [collapsed, setCollapsed] = React.useState<boolean>(false);
  const [width, setWidth] = React.useState<any>(window.innerWidth);
  const [openSiderDrawer, setOpenSiderDrawer] = React.useState(false);
  const [openQuickAdd, setOpenQuickAdd] = React.useState(false);
  const [quickAddProps, setQuickAddProps] = React.useState<any>({
    type: "",
    open: false,
  });
  // const [trigger, setTrigger] = React.useState<boolean>(false);
  const [logData, setLogData] = React.useState<any>([]);
  const location = useLocation();
  const clientId = matchPath(`/accounts/clients/:clientId/*`, location.pathname)
    ?.params?.clientId;
  const [locationUrl, setLocationUrl] = React.useState<string>("");
  const { defaultAlgorithm, darkAlgorithm } = theme;
  // useHotkeys(
  //   "ctrl+c+l",
  //   (event) => {
  //     event.preventDefault();
  //     setQuickAddProps({
  //       type: "AddLedger",
  //       open: true,
  //     });
  //   },
  //   { preventDefault: true }
  // );
  const notify = (action: any) => {
    message.info(`Action triggered: ${action}`);
  };
  const [isConnected, setIsConnected] = React.useState(true);
  // console.log("themeData----------", themeData?.themeColor, themeData );

  React.useEffect(() => {
    const handleNetworkChange = () => {
      setIsConnected(navigator.onLine);
    };

    window.addEventListener("online", handleNetworkChange);
    window.addEventListener("offline", handleNetworkChange);

    return () => {
      window.removeEventListener("online", handleNetworkChange);
      window.removeEventListener("offline", handleNetworkChange);
    };
  }, []);

  React.useEffect(() => {
    if (!isAuthenticated()) navigate("/login");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  //----- Menu Color in -----
  React.useEffect(() => {
    let splittedpath = location.pathname.split("/");
    setLocationUrl(
      `/${splittedpath[1]}/${splittedpath[2]}/${splittedpath[3]}/${
        splittedpath[4] === "income"
          ? "expenses"
          : splittedpath[4] === "category"
          ? "expenses"
          : splittedpath[4]
      }`
    );
  }, [location]);

  React.useEffect(() => {
    // console.log(width);
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  // ------------- Short Cut Key (Ex.= alt+p+o ) -------------
  // React.useEffect(() => {
  //   const handleKeyDown = (event: any) => {
  //     if (event.altKey && event.key.toLowerCase() === "s" && !event.shiftKey) {
  //       document.addEventListener("keydown", handleNextKeyDownSales);
  //     } else if (
  //       event.altKey &&
  //       event.key.toLowerCase() === "p" &&
  //       !event.shiftKey
  //     ) {
  //       document.addEventListener("keydown", handleNextKeyDownPurchase);
  //     } else if (
  //       event.altKey &&
  //       event.key.toLowerCase() === "j" &&
  //       !event.shiftKey
  //     ) {
  //       navigate(`/accounts/clients/${clientId}/journals/add`);
  //     } else if (
  //       event.altKey &&
  //       event.key.toLowerCase() === "l" &&
  //       !event.shiftKey
  //     ) {
  //       navigate(`/accounts/clients/${clientId}/ledger`);
  //     } else if (
  //       event.altKey &&
  //       event.key.toLowerCase() === "t" &&
  //       !event.shiftKey
  //     ) {
  //       navigate(`/accounts/clients/${clientId}/trialbalance`);
  //     } else if (
  //       event.altKey &&
  //       event.key.toLowerCase() === "b" &&
  //       !event.shiftKey
  //     ) {
  //       navigate(`/accounts/clients/${clientId}/BalanceSheet`);
  //     } else if (
  //       event.altKey &&
  //       event.key.toLowerCase() === "c" &&
  //       !event.shiftKey
  //     ) {
  //       navigate(`/accounts/clients/${clientId}/chartOfAccounts`);
  //     }
  //     // } else if (
  //     //   event.shiftKey &&
  //     //   event.key.toLowerCase() === "s" &&
  //     //   !event.altKey
  //     // ) {
  //     //   navigate(`/accounts/clients/${clientId}/settings`);
  //     // }
  //   };

  //   const handleNextKeyDownSales = (event: any) => {
  //     if (event.key.toLowerCase() === "i") {
  //       navigate(`/accounts/clients/${clientId}/sales/addInvoice`);
  //     } else if (event.key.toLowerCase() === "c") {
  //       navigate(`/accounts/clients/${clientId}/sales/addCreditNote`);
  //     } else if (event.key.toLowerCase() === "e") {
  //       navigate(`/accounts/clients/${clientId}/sales/addEstimates`);
  //     } else if (event.key.toLowerCase() === "r") {
  //       navigate(`/accounts/clients/${clientId}/sales/addReceipts`);
  //     }
  //     document.removeEventListener("keydown", handleNextKeyDownSales);
  //   };

  //   const handleNextKeyDownPurchase = (event: any) => {
  //     if (event.key.toLowerCase() === "i") {
  //       navigate(`/accounts/clients/${clientId}/purchase/invoice`);
  //     } else if (event.key.toLowerCase() === "c") {
  //       navigate(`/accounts/clients/${clientId}/purchase/creditnote`);
  //     } else if (event.key.toLowerCase() === "o") {
  //       navigate(`/accounts/clients/${clientId}/purchase/order`);
  //     } else if (event.key.toLowerCase() === "p") {
  //       navigate(`/accounts/clients/${clientId}/purchase/payment`);
  //     } else if (event.key.toLowerCase() === "l") {
  //       navigate(`/accounts/clients/${clientId}/profitAndLoss`);
  //     }
  //     document.removeEventListener("keydown", handleNextKeyDownPurchase);
  //   };

  //   document.addEventListener("keydown", handleKeyDown);

  //   return () => {
  //     document.removeEventListener("keydown", handleKeyDown);
  //     document.removeEventListener("keydown", handleNextKeyDownPurchase);
  //   };
  // }, []);

  useEffect(() => {
    const handleKeyDown = (event: any) => {
      if (event.ctrlKey && event.shiftKey) {
        event.preventDefault(); // Prevent default action for all key events
        switch (event.key.toLowerCase()) {
          case "f2":
            console.log("Ctrl+Shift+F2 pressed");
            // notify("Locking Period");
            break;
          case "b":
            navigate(`/accounts/clients/${clientId}/dashboard`);
            break;
          case "l":
            navigate(`/accounts/clients/${clientId}/master/ledger`);
            setQuickAddProps({
              type: "AddLedger",
              open: true,
            });
            break;
          case "g":
            navigate(`/accounts/clients/${clientId}/master/group`);
            setQuickAddProps({
              type: "AddGroup",
              open: true,
            });
            break;
          case "i":
            navigate(`/accounts/clients/${clientId}/master/items`);
            setQuickAddProps({
              type: "AddItems",
              open: true,
            });
            // notify("Item");
            break;
          case "u":
            // notify("Unit");
            break;
          case "r":
            navigate(`/accounts/clients/${clientId}/sales/addReceipt`);
            setQuickAddProps({
              type: "",
              open: false,
            });
            // notify("Receipt");
            break;
          case "q":
            navigate(`/accounts/clients/${clientId}/sales/addQuotation`);
            setQuickAddProps({
              type: "",
              open: false,
            });
            // notify("Sales Quotation");
            break;
          case "o":
            navigate(`/accounts/clients/${clientId}/sales/addOrder`);
            setQuickAddProps({
              type: "",
              open: false,
            });
            // notify("Sales Quotation");
            break;
          case "d":
            navigate(`/accounts/clients/${clientId}/sales/addOrder`);
            setQuickAddProps({
              type: "",
              open: false,
            });
            // notify("Delivery Challan");
            break;
          case "p":
            navigate(`/accounts/clients/${clientId}/purchase/addPaymentOut`);
            setQuickAddProps({
              type: "",
              open: false,
            });
            // notify("Payment");
            break;
          case "s":
            navigate(`/accounts/clients/${clientId}/sales/addInvoice`);
            setQuickAddProps({
              type: "",
              open: false,
            });
            // notify("Sales Invoice");
            break;
          case "f12":
            navigate(`/accounts/clients/${clientId}/settings/companyInfo`);
            setQuickAddProps({
              type: "",
              open: false,
            });
            // notify("Settings");
            break;
          // Add more cases as needed
        }
      } else if (event.shiftKey) {
        event.preventDefault(); // Prevent default action for all key events
        switch (event.key.toLowerCase()) {
          case "f3":
            setQuickAddProps({
              type: "",
              open: false,
            });
            navigate(`/accounts/clients/${clientId}/journals/add`);
            // notify("Sales Return");
            break;
          case "f4":
            navigate(`/accounts/clients/${clientId}/banking/bankAccounts`);
            setQuickAddProps({
              type: "AdjustBankBalanceModel",
              open: true,
            });
            // notify("Sales Return");
            break;
          case "f5":
            setQuickAddProps({
              type: "",
              open: false,
            });
            navigate(`/accounts/clients/${clientId}/sales/addInvoice`);
            // notify("Sales Return");
            break;
          case "f6":
            navigate(`/accounts/clients/${clientId}/sales/addCreditNote`);
            setQuickAddProps({
              type: "",
              open: false,
            });
            // notify("Sales Return");
            break;
          case "f7":
            navigate(`/accounts/clients/${clientId}/sales/addReceipt`);
            setQuickAddProps({
              type: "",
              open: false,
            });
            // notify("Receipt");
            break;
          case "f8":
            navigate(`/accounts/clients/${clientId}/sales/addQuotation`);
            setQuickAddProps({
              type: "",
              open: false,
            });
            // notify("Sales Quotation");
            break;
          case "f9":
            navigate(`/accounts/clients/${clientId}/purchase/addPurchaseBill`);
            setQuickAddProps({
              type: "",
              open: false,
            });
            // notify("Purchase Bill");
            break;
          case "f10":
            navigate(`/accounts/clients/${clientId}/purchase/addDebitNote`);
            setQuickAddProps({
              type: "",
              open: false,
            });
            // notify("Purchase Return");
            break;
          case "f11":
            navigate(`/accounts/clients/${clientId}/purchase/addPaymentOut`);
            setQuickAddProps({
              type: "",
              open: false,
            });
            // notify("Payment");
            break;
          case "f12":
            navigate(`/accounts/clients/${clientId}/purchase/addOrder`);
            setQuickAddProps({
              type: "",
              open: false,
            });
            // notify("Purchase Order");
            break;
        }
      }
    };

    // Add event listener when component mounts
    document.addEventListener("keydown", handleKeyDown);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const buttonDark = (currentUrl: any) => {
    // console.log("hello----------------button Dark");
    return location.pathname === currentUrl ? "link" : "text";
  };
  const showLogDrawer = () => {
    setOpenLogDrawer(true);
  };
  const CloseLogDrawer = () => {
    setOpenLogDrawer(false);
  };

  //#region --------------- QuickAdd Button ---------------
  const QuickAdd = (
    <Row>
      <Col sm={8} xs={11}>
        <p style={{ marginBottom: "6px" }}>
          <CgShoppingCart
            fontSize={15}
            style={{ padding: "0px 12px 0px 7px" }}
          />
          Sales
        </p>
        <p>
          <Link
            to={`/accounts/clients/${clientId}/sales/addInvoice`}
            onClick={() => setOpenQuickAdd(false)}
          >
            <Tooltip title="Add Invoice (Alt+S+I)" placement="left">
              <Button
                className={"ca-quickadd-btn"}
                type={buttonDark(
                  `/accounts/clients/${clientId}/sales/addInvoice`
                )}
              >
                <PlusOutlined />
              </Button>
            </Tooltip>
          </Link>
          <Link
            to={`/accounts/clients/${clientId}/sales/invoices`}
            onClick={() => setOpenQuickAdd(false)}
          >
            <Button
              className={"ca-quickadd-btn"}
              type={buttonDark(`/accounts/clients/${clientId}/sales/invoices`)}
            >
              Invoice
            </Button>
          </Link>
        </p>
        <p>
          <Link
            to={`/accounts/clients/${clientId}/sales/addCreditNote`}
            onClick={() => setOpenQuickAdd(false)}
          >
            <Tooltip title="Add Credit Note (Alt+S+C)" placement="left">
              <Button
                className={"ca-quickadd-btn"}
                type={buttonDark(
                  `/accounts/clients/${clientId}/sales/addCreditNote`
                )}
              >
                <PlusOutlined />
              </Button>
            </Tooltip>
          </Link>
          <Link
            to={`/accounts/clients/${clientId}/sales/creditNotes`}
            onClick={() => setOpenQuickAdd(false)}
          >
            <Button
              className={"ca-quickadd-btn"}
              type={buttonDark(
                `/accounts/clients/${clientId}/sales/creditNotes`
              )}
            >
              Credit Notes
            </Button>
          </Link>
        </p>
        <p>
          <Link
            to={`/accounts/clients/${clientId}/sales/addEstimates`}
            onClick={() => setOpenQuickAdd(false)}
          >
            <Tooltip title="Add Estimates(Alt+S+E)" placement="left">
              <Button
                className={"ca-quickadd-btn"}
                type={buttonDark(
                  `/accounts/clients/${clientId}/sales/addEstimates`
                )}
              >
                <PlusOutlined />
              </Button>
            </Tooltip>
          </Link>
          <Link
            to={`/accounts/clients/${clientId}/sales/estimates`}
            onClick={() => setOpenQuickAdd(false)}
          >
            <Button
              className={"ca-quickadd-btn"}
              type={buttonDark(`/accounts/clients/${clientId}/sales/estimates`)}
            >
              Estimates
            </Button>
          </Link>
        </p>
        <p>
          <Link
            to={`/accounts/clients/${clientId}/sales/addReceipts`}
            onClick={() => setOpenQuickAdd(false)}
          >
            <Tooltip title="Add Receipt(Alt+S+R)" placement="left">
              <Button
                className={"ca-quickadd-btn"}
                type={buttonDark(
                  `/accounts/clients/${clientId}/sales/addReceipts`
                )}
              >
                <PlusOutlined />
              </Button>
            </Tooltip>
          </Link>
          <Link
            to={`/accounts/clients/${clientId}/sales/receipts`}
            onClick={() => setOpenQuickAdd(false)}
          >
            <Button
              className={"ca-quickadd-btn"}
              type={buttonDark(`/accounts/clients/${clientId}/sales/receipts`)}
            >
              Receipts
            </Button>
          </Link>
        </p>
        <p>
          <Tooltip title="Add Customer" placement="left">
            <Button
              type="text"
              className={"ca-quickadd-btn"}
              onClick={() => {
                setQuickAddProps({ type: "addCustomer", open: true });
                setOpenQuickAdd(false);
              }}
            >
              <PlusOutlined />
            </Button>
          </Tooltip>
          <Link
            to={`/accounts/clients/${clientId}/sales/customers`}
            onClick={() => setOpenQuickAdd(false)}
          >
            <Button
              className={"ca-quickadd-btn"}
              type={buttonDark(`/accounts/clients/${clientId}/sales/customers`)}
            >
              Customers
            </Button>
          </Link>
        </p>
        <p>
          <Tooltip title="Add Item" placement="left">
            <Button
              type="text"
              className={"ca-quickadd-btn"}
              onClick={() => {
                setQuickAddProps({ type: "addItem", open: true });
                setOpenQuickAdd(false);
              }}
            >
              <PlusOutlined />
            </Button>
          </Tooltip>

          <Link
            to={`/accounts/clients/${clientId}/sales/items`}
            onClick={() => setOpenQuickAdd(false)}
          >
            <Button
              className={"ca-quickadd-btn"}
              type={buttonDark(`/accounts/clients/${clientId}/sales/items`)}
            >
              Items
            </Button>
          </Link>
        </p>
      </Col>

      <Col sm={8} xs={11}>
        <p style={{ marginBottom: "6px" }}>
          <TfiBag style={{ padding: "0px 8px 0px 7px" }} /> Purchase
        </p>

        <p>
          <Link
            to={`/accounts/clients/${clientId}/purchase/invoice`}
            onClick={() => setOpenQuickAdd(false)}
          >
            <Tooltip title="Add Invoice (Alt+P+I)" placement="topLeft">
              <Button
                className={"ca-quickadd-btn"}
                type={buttonDark(
                  `/accounts/clients/${clientId}/purchase/invoice`
                )}
              >
                <PlusOutlined />
              </Button>
            </Tooltip>
          </Link>

          <Link
            to={`/accounts/clients/${clientId}/purchase/invoices`}
            onClick={() => setOpenQuickAdd(false)}
          >
            <Button
              className={"ca-quickadd-btn"}
              type={buttonDark(
                `/accounts/clients/${clientId}/purchase/invoices`
              )}
            >
              Invoice
            </Button>
          </Link>
        </p>

        <p>
          <Link
            to={`/accounts/clients/${clientId}/purchase/creditnote`}
            onClick={() => setOpenQuickAdd(false)}
          >
            <Tooltip title="Add Credit Note (Alt+P+C)" placement="topLeft">
              <Button
                className={"ca-quickadd-btn"}
                type={buttonDark(
                  `/accounts/clients/${clientId}/purchase/creditnote`
                )}
              >
                <PlusOutlined />
              </Button>
            </Tooltip>
          </Link>
          <Link
            to={`/accounts/clients/${clientId}/purchase/creditNotes`}
            onClick={() => setOpenQuickAdd(false)}
          >
            <Button
              className={"ca-quickadd-btn"}
              type={buttonDark(
                `/accounts/clients/${clientId}/purchase/creditNotes`
              )}
            >
              Credit Notes
            </Button>
          </Link>
        </p>

        <p>
          <Link
            to={`/accounts/clients/${clientId}/purchase/order`}
            onClick={() => setOpenQuickAdd(false)}
          >
            <Tooltip title="Add Order (Alt+P+O)" placement="topLeft">
              <Button
                className={"ca-quickadd-btn"}
                type={buttonDark(
                  `/accounts/clients/${clientId}/purchase/order`
                )}
              >
                <PlusOutlined />
              </Button>
            </Tooltip>
          </Link>
          <Link
            to={`/accounts/clients/${clientId}/purchase/purchaseOrders`}
            onClick={() => setOpenQuickAdd(false)}
          >
            <Button
              className={"ca-quickadd-btn"}
              type={buttonDark(
                `/accounts/clients/${clientId}/purchase/purchaseOrders`
              )}
            >
              Order
            </Button>
          </Link>
        </p>

        <p>
          <Link
            to={`/accounts/clients/${clientId}/purchase/payment`}
            onClick={() => setOpenQuickAdd(false)}
          >
            <Tooltip title="Add Payment (Alt+P+P)" placement="topLeft">
              <Button
                className={"ca-quickadd-btn"}
                type={buttonDark(
                  `/accounts/clients/${clientId}/purchase/payment`
                )}
              >
                <PlusOutlined />
              </Button>
            </Tooltip>
          </Link>
          <Link
            to={`/accounts/clients/${clientId}/purchase/payments`}
            onClick={() => setOpenQuickAdd(false)}
          >
            <Button
              className={"ca-quickadd-btn"}
              type={buttonDark(
                `/accounts/clients/${clientId}/purchase/payments`
              )}
            >
              Payments
            </Button>
          </Link>
        </p>

        <p>
          <Tooltip title="Add Supplier" placement="topLeft">
            <Button
              type="text"
              className={"ca-quickadd-btn"}
              onClick={() => {
                setQuickAddProps({ type: "addSupplier", open: true });
                setOpenQuickAdd(false);
              }}
            >
              <PlusOutlined />
            </Button>
          </Tooltip>
          <Link
            to={`/accounts/clients/${clientId}/purchase/suppliers`}
            onClick={() => setOpenQuickAdd(false)}
          >
            <Button
              className={"ca-quickadd-btn"}
              type={buttonDark(
                `/accounts/clients/${clientId}/purchase/suppliers`
              )}
            >
              Suppliers
            </Button>
          </Link>
        </p>

        <p>
          <Tooltip title="Add Items" placement="topLeft">
            <Button
              type="text"
              className={"ca-quickadd-btn"}
              onClick={() => {
                setQuickAddProps({ type: "addItem", open: true });
                setOpenQuickAdd(false);
              }}
            >
              <PlusOutlined />
            </Button>
          </Tooltip>
          <Link
            to={`/accounts/clients/${clientId}/purchase/items`}
            onClick={() => setOpenQuickAdd(false)}
          >
            <Button
              className={"ca-quickadd-btn"}
              type={buttonDark(`/accounts/clients/${clientId}/purchase/items`)}
            >
              Items
            </Button>
          </Link>
        </p>
      </Col>

      <Col sm={8} xs={11}>
        <p style={{ marginBottom: "6px" }}>
          <ReadOutlined style={{ padding: "0px 14px 0px 8px" }} />
          Other
        </p>
        <Link
          to={`/accounts/clients/${clientId}/journals/add`}
          onClick={() => setOpenQuickAdd(false)}
        >
          <Tooltip title="Add Journal (Alt+J)" placement="topLeft">
            <Button
              className={"ca-quickadd-btn"}
              type={buttonDark(`/accounts/clients/${clientId}/journals/add`)}
            >
              <PlusOutlined />
            </Button>
          </Tooltip>
        </Link>
        <Link
          to={`/accounts/clients/${clientId}/journals`}
          onClick={() => setOpenQuickAdd(false)}
        >
          <Button
            className={"ca-quickadd-btn"}
            type={buttonDark(`/accounts/clients/${clientId}/journals`)}
          >
            Journals
          </Button>
        </Link>
        {/* <p style={{ paddingLeft: "20px" }}>
          <Link
            to={`/accounts/clients/${clientId}/journals/add`}
            onClick={() => setOpenQuickAdd(false)}
          >
            <Tooltip title="Alt+L" placement="right">
              <Button
                type={buttonDark(
                  `/accounts/clients/${clientId}/ledger`
                )}
              >
                Ledger
              </Button>
            </Tooltip>
          </Link>
        </p> */}
        <p style={{ paddingLeft: "20px" }}>
          <Link
            to={`/accounts/clients/${clientId}/registers`}
            onClick={() => setOpenQuickAdd(false)}
          >
            <Tooltip title="Alt+L" placement="right">
              <Button
                type={buttonDark(`/accounts/clients/${clientId}/registers`)}
              >
                Registers
              </Button>
            </Tooltip>
          </Link>
        </p>

        <p style={{ paddingLeft: "20px" }}>
          <Link
            to={`/accounts/clients/${clientId}/trialbalance`}
            onClick={() => setOpenQuickAdd(false)}
          >
            <Tooltip title="Alt+T" placement="right">
              <Button
                type={buttonDark(`/accounts/clients/${clientId}/trialbalance`)}
              >
                Trial Balance
              </Button>
            </Tooltip>
          </Link>
        </p>
        <p style={{ paddingLeft: "20px" }}>
          <Link
            to={`/accounts/clients/${clientId}/profitAndLoss`}
            onClick={() => setOpenQuickAdd(false)}
          >
            <Tooltip title="Alt+P+L" placement="right">
              <Button
                type={buttonDark(`/accounts/clients/${clientId}/profitAndLoss`)}
              >
                Profit & Loss
              </Button>
            </Tooltip>
          </Link>
        </p>
        <p style={{ paddingLeft: "20px" }}>
          <Link
            to={`/accounts/clients/${clientId}/BalanceSheet`}
            onClick={() => setOpenQuickAdd(false)}
          >
            <Tooltip title="Alt+B" placement="right">
              <Button
                type={buttonDark(`/accounts/clients/${clientId}/BalanceSheet`)}
              >
                Balance Sheet
              </Button>
            </Tooltip>
          </Link>
        </p>
        {/* <p style={{ paddingLeft: "20px" }}>
          <Link
            to={`/accounts/clients/${clientId}/chartOfAccounts`}
          >
            <Button
              type={buttonDark(
                `/accounts/clients/${clientId}/chartOfAccounts`
              )}
            >
              Chart Of Accounts
            </Button>
          </Link>
        </p> */}
        <p style={{ paddingLeft: "20px" }}>
          <Link
            to={`/accounts/clients/${clientId}/settings/companyInfo`}
            onClick={() => setOpenQuickAdd(false)}
          >
            {/* title="Shift+S" */}
            <Tooltip placement="right">
              <Button
                type={buttonDark(
                  `/accounts/clients/${clientId}/settings/companyInfo`
                )}
              >
                Setting
              </Button>
            </Tooltip>
          </Link>
        </p>
      </Col>
    </Row>
  );

  const content = (
    <div style={{ width: 295 }}>
      <Row
        className="px-3 py-2 linkcolor"
        onClick={() => {
          localStorage.setItem("projecttype", "accounts");
          navigate("/accounts");
        }}
      >
        <p style={{ cursor: "pointer" }}> Accounting</p>
      </Row>
    </div>
  );
  //#endregion

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  function getItem(
    label: React.ReactNode,
    key: any,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: "group"
  ): MenuItem {
    return {
      key,
      icon,
      label,
      type,
    } as MenuItem;
  }
  const MenuAddButton = (
    name: string,
    to: string,
    fn: () => void,
    type: string = ""
  ) => {
    return !collapsed ? (
      <Row gutter={[4, 0]}>
        <Col span={22}>
          <span>{name}</span>
        </Col>
        <Col span={2}>
          {type === "link" ? (
            <Link to={to}>
              <Button
                className="add-icon-button"
                // type="link"
                size="small"
                icon={<PlusOutlined />}
                onClick={(e: any) => {
                  // fn();
                }}
              />
            </Link>
          ) : (
            <Button
              className="add-icon-button"
              // type="link"
              size="small"
              icon={<PlusOutlined />}
              onClick={(e: any) => {
                fn();
              }}
            />
          )}
        </Col>
      </Row>
    ) : (
      name
    );
  };
  const items: MenuItem[] = [
    getItem(
      <Link to={"/accounts"} style={{ color: "#44546F" }}>
        All Clients
      </Link>,
      "/accounts",
      // <HomeOutlined style={{ color: "#3a5dd3" }} />
      <img src={allclients} alt="all-clients" height={"20px"} width={"20px"} />
    ),
    getItem(
      <Link
        to={`/accounts/clients/${clientId}/dashboard`}
        className="Customize"
      >
        Dashboard
      </Link>,

      `/accounts/clients/${clientId}/dashboard`,
      // <AiOutlineDashboard style={{ color: "#3a5dd3" }} />
      <img
        alt="dashbrd"
        src={dashbrd}
        style={{ height: "20px", width: "15px", marginRight: "6px" }}
      />
    ),
    // getItem(
    //   <Link to={`/accounts/clients/${clientId}/parties`}>
    //     Parties
    //   </Link>,
    //   `/accounts/clients/${clientId}/parties`,
    //   <UsergroupAddOutlined style={{ color: "#3a5dd3" }} />
    // ),
    getItem(
      <Link to={`/accounts/clients/${clientId}/master/ledger`}>
        {
          <Tooltip
            title={
              <>
                Add Ledger
                <br />
                <Tag style={{ color: "white" }}>
                  <kbd>Ctrl + Shift + L</kbd>
                </Tag>
              </>
            }
          >
            {MenuAddButton(
              "Master",
              "/accounts/clients/${clientId}/master",
              () => {
                setQuickAddProps({
                  type: "AddLedger",
                  open: true,
                });
              }
            )}
          </Tooltip>
        }
      </Link>,
      `/accounts/clients/${clientId}/master`,
      // <UsergroupAddOutlined style={{ color: "#3a5dd3" }} />
      <img
        alt="masters"
        src={masters}
        style={{ height: "20px", width: "15px", marginRight: "6px" }}
      />
    ),
    getItem(
      <Link to={`/accounts/clients/${clientId}/sales/invoices`}>
        <Tooltip
          title={
            <>
              Add Invoice
              <br />
              <Tag style={{ color: "white" }}>
                <kbd>Shift + F5</kbd>
              </Tag>
            </>
          }
        >
          {MenuAddButton(
            "Sales",
            `/accounts/clients/${clientId}/sales/addInvoice`,
            () => {},
            "link"
          )}
        </Tooltip>
      </Link>,
      // `/accounts/clients/${clientId}/sales/invoices`,
      `/accounts/clients/${clientId}/sales`,
      // <BsCurrencyRupee style={{ color: "#3a5dd3" }} />
      <img
        alt="saleslogo"
        src={saleslogo}
        style={{ height: "17px", width: "21px", marginRight: "0px" }}
      />
    ),
    getItem(
      <Link to={`/accounts/clients/${clientId}/purchase/purchaseBill`}>
        <Tooltip
          title={
            <>
              Add Purchase Bill
              <br />
              <Tag style={{ color: "white" }}>
                <kbd>Shift + F9</kbd>
              </Tag>
            </>
          }
        >
          {MenuAddButton(
            "Purchase",
            `/accounts/clients/${clientId}/purchase/addPurchaseBill`,
            () => {},
            "link"
          )}
        </Tooltip>
      </Link>,
      // `/accounts/clients/${clientId}/purchase/purchaseBill`,
      `/accounts/clients/${clientId}/purchase`,
      // <BiCube style={{ color: "#3a5dd3" }} />
      <img
        alt="purchaselogo"
        src={purchaselogo}
        style={{ height: "20px", width: "21px" }}
      />
    ),

    // getItem(
    //   <Link to={`/accounts/clients/${clientId}/expenses`}>
    //     Expenses
    //   </Link>,
    //   `/accounts/clients/${clientId}/expenses`,
    //   <WalletOutlined style={{ color: "#3a5dd3" }} />
    // ),

    getItem(
      <Link to={`/accounts/clients/${clientId}/asset/fixedAsset`}>
        {MenuAddButton(
          "Assets",
          `/accounts/clients/${clientId}/asset/addFixedAsset`,
          () => {},
          "link"
        )}
      </Link>,
      `/accounts/clients/${clientId}/asset`,
      // <TbBuildingCommunity style={{ color: "#3a5dd3" }} />
      <img
        alt="AssetImg"
        src={AssetImg}
        style={{ height: "20px", width: "21px" }}
      />
    ),

    getItem(
      <Link to={`/accounts/clients/${clientId}/journals`}>
        <Tooltip
          title={
            <>
              Add Journal
              <br />
              <Tag style={{ color: "white" }}>
                <kbd>Shift + F3</kbd>
              </Tag>
            </>
          }
        >
          {MenuAddButton(
            "Journals",
            `/accounts/clients/${clientId}/journals/add`,
            () => {},
            "link"
          )}
        </Tooltip>
      </Link>,
      `/accounts/clients/${clientId}/journals`,
      // <BookOutlined style={{ color: "#3a5dd3" }} />
      <img
        alt="journals"
        src={journals}
        style={{ height: "19px", width: "15px", marginRight: "6px" }}
      />
    ),
    getItem(
      <Link to={`/accounts/clients/${clientId}/banking/bankAccounts`}>
        <Tooltip
          title={
            <>
              Add Transfer Entry
              <br />
              <Tag style={{ color: "white" }}>
                <kbd>Shift + F4</kbd>
              </Tag>
            </>
          }
        >
          {MenuAddButton(
            "Cash & Bank",
            `/accounts/clients/${clientId}/banking/bankAccounts`,
            () => {
              setQuickAddProps({
                type: "AdjustBankBalanceModel",
                open: true,
              });
            }
          )}
        </Tooltip>
      </Link>,
      `/accounts/clients/${clientId}/banking`,
      <img
        alt="Bank"
        src={bankImg}
        style={{ height: "19px", width: "15px", marginRight: "6px" }}
      />
    ),

    // getItem(
    //   <Link
    //     to={`/accounts/clients/${clientId}/sharecapital/sharecapital`}
    //   >
    //     Share Capital
    //   </Link>,
    //   `/accounts/clients/${clientId}/sharecapital/sharecapital`,
    //   <FileSearchOutlined />
    // ),

    // getItem(
    //   collapsed ? (
    //     <Divider className="divider-m0" />
    //   ) : (
    //     <b style={{ color: "black" }}>Automation</b>
    //   ),
    //   "automation",
    //   null,
    //   [
    //     getItem(
    //       <Link to={`/accounts/clients/${clientId}/bankRules`}>
    //         Bank Rules
    //       </Link>,
    //       `/accounts/clients/${clientId}/bankRules`,
    //       <HiOutlineNewspaper />
    //     ),
    //     getItem(
    //       <Link to={`/accounts/clients/${clientId}/gstReturn`}>
    //         GST Return
    //       </Link>,
    //       `/accounts/clients/${clientId}/gstReturn`,
    //       <PercentageOutlined />
    //     ),
    //   ],
    //   "group"
    // ),
    // getItem(
    //   collapsed ? (
    //     <Divider />
    //   ) : (
    //     // <Typography.Title
    //     //   level={5}
    //     //   style={{
    //     //     // color: "#395cd2",
    //     //     color: "#44546F",
    //     //     fontWeight: 600,
    //     //     fontSize: "15px",
    //     //   }}
    //     // >
    //     //   Reports
    //     // </Typography.Title>
    //     <></>
    //   ),
    //   "report",
    //   null,
    //   [
    // getItem(
    //   <Link to={`/accounts/clients/${clientId}/ledger`}>
    //     Ledger
    //   </Link>,
    //   `/accounts/clients/${clientId}/ledger`,
    //   <BookOutlined style={{ color: "#3a5dd3" }} />
    // ),
    // getItem(
    //   <Link to={`/accounts/clients/${clientId}/ledgernew`}>
    //     Ledger
    //   </Link>,
    //   `/accounts/clients/${clientId}/ledgernew`,
    //     <img src={ledger} style={{height:'18px', width:'16px'}} />
    // ),
    getItem(
      <Link to={`/accounts/clients/${clientId}/registers`}>Registers</Link>,
      `/accounts/clients/${clientId}/registers`,
      // <MdOutlineBalance style={{ color: "#3a5dd3" }} />
      <img
        alt="journals"
        src={journals}
        style={{ height: "19px", width: "15px", marginRight: "6px" }}
      />
    ),

    getItem(
      <Link to={`/accounts/clients/${clientId}/reports`}>Reports</Link>,
      `/accounts/clients/${clientId}/reports`,
      // <MdOutlineBalance style={{ color: "#3a5dd3" }} />
      <img
        alt="journals"
        src={journals}
        style={{ height: "19px", width: "15px", marginRight: "6px" }}
      />
    ),
    // getItem(
    //   <Link
    //     to={`/accounts/clients/${clientId}/reports/ageing/debtors`}
    //   >
    //     Ageing Report
    //   </Link>,
    //   `/accounts/clients/${clientId}/reports/ageing`,
    //   // <BsGraphUpArrow />
    //   // <HiOutlineChartPie style={{ color: "#3a5dd3" }} />
    //   <img src={agegingreport} style={{ height: "20px", width: "20px" }} />
    // ),

    // getItem(
    //   <Link
    //     to={`/accounts/clients/${clientId}/generalLedger`}
    //   >
    //     General Ledger
    //   </Link>,
    //   `/accounts/clients/${clientId}/generalLedger`,
    //   // <MdOutlineBalance style={{ color: "#3a5dd3" }} />
    //   <img
    //     alt="journals"
    //     src={journals}
    //     style={{ height: "19px", width: "15px", marginRight: "6px" }}
    //   />
    // ),
    // getItem(
    //   <Link
    //     to={`/accounts/clients/${clientId}/trialbalance`}
    //   >
    //     Trial Balance
    //   </Link>,
    //   `/accounts/clients/${clientId}/trialbalance`,
    //   <MdOutlineBalance style={{ color: "#3a5dd3" }} />
    // ),
    // getItem(
    //   <Link
    //     to={`/accounts/clients/${clientId}/trialbalancenew`}
    //   >
    //     Trial Balance
    //   </Link>,
    //   `/accounts/clients/${clientId}/trialbalancenew`,
    //   // <MdOutlineBalance style={{ color: "#3a5dd3" }} />
    //   <img
    //     alt="trialbal"
    //     src={trialbal}
    //     style={{ height: "20px", width: "18px", marginRight: "8px" }}
    //   />
    // ),
    // getItem(
    //   <Link
    //     to={`/accounts/clients/${clientId}/profitAndLoss`}
    //   >
    //     Profit and Loss
    //   </Link>,
    //   `/accounts/clients/${clientId}/profitAndLoss`,
    //   <HiOutlineChartPie style={{ color: "#3a5dd3" }} />
    // ),
    // getItem(
    //   <Link
    //     to={`/accounts/clients/${clientId}/profitAndLossnew`}
    //   >
    //     Profit and Loss
    //   </Link>,
    //   `/accounts/clients/${clientId}/profitAndLossnew`,
    //   <img
    //     alt="profitloss"
    //     src={profitloss}
    //     style={{ height: "20px", width: "20px", marginRight: "8px" }}
    //   />
    // ),
    // getItem(
    //   <Link
    //     to={`/accounts/clients/${clientId}/BalanceSheet`}
    //   >
    //     Balance Sheet
    //   </Link>,
    //   `/accounts/clients/${clientId}/BalanceSheet`,
    //   // <IoMdPaper style={{ color: "#3a5dd3" }} />
    //   <img
    //     alt="balancesheet"
    //     src={balancesheet}
    //     style={{ height: "20px", width: "20px", marginRight: "8px" }}
    //   />
    // ),
    // getItem(
    //   <Link
    //     to={`/accounts/clients/${clientId}/ageing/debtors`}

    //   >
    //     Ageing Report
    //   </Link>,
    //   `/accounts/clients/${clientId}/ageing`,
    //   // <BsGraphUpArrow />
    //   // <HiOutlineChartPie style={{ color: "#3a5dd3" }} />
    //   <img src={agegingreport} style={{ height: "20px", width: "20px" }} />
    // ),
    // getItem(
    //   <Link to={`/accounts/clients/${clientId}/GSTR1`}>
    //     GST
    //   </Link>,
    //   `/accounts/clients/${clientId}/GSTR1`,
    //   // <IoMdPaper style={{ color: "#3a5dd3" }} />
    //   <img
    //     alt="balancesheet"
    //     src={balancesheet}
    //     style={{ height: "20px", width: "20px", marginRight: "8px" }}
    //   />
    // ),

    getItem(
      collapsed ? (
        <Divider className="divider-m0" />
      ) : (
        <Typography.Title
          level={5}
          style={{
            // color: "#395cd2",
            color: "#44546F",
            fontWeight: 600,
            fontSize: "15px",
          }}
        >
          Other
        </Typography.Title>
      ),
      "other"
      // null,
      // [
      //   // getItem(
      //   //   <Link
      //   //     to={`/accounts/clients/${clientId}/baking/banks`}
      //   //   >
      //   //     Banking
      //   //   </Link>,
      //   //   `/accounts/clients/${clientId}/baking/banks`,
      //   //   <BankOutlined style={{ color: "#3a5dd3" }} />
      //   // ),
      //   // getItem(
      //   //   <Link
      //   //     to={`/accounts/clients/${clientId}/openingbalance`}
      //   //   >
      //   //     Opening Balance
      //   //   </Link>,
      //   //   `/accounts/clients/${clientId}/openingbalance`,
      //   //   <InsertRowBelowOutlined style={{ color: "#3a5dd3" }} />
      //   // ),
      //   // getItem(
      //   //   <Link
      //   //     to={`/accounts/clients/${clientId}/chartOfAccounts`}
      //   //   >
      //   //     Chart of Accounts
      //   //   </Link>,
      //   //   `/accounts/clients/${clientId}/chartOfAccounts`,
      //   //   // <MdOutlineBalance style={{ color: "#3a5dd3" }} />
      //   //   <img
      //   //     src={chartofaccounts}
      //   //     style={{ height: "20px", width: "20px" }}
      //   //   />
      //   // ),
      //   getItem(
      //     <Link to={`/accounts/clients/${clientId}/settings/companyInfo`}>
      //       Settings
      //     </Link>,
      //     `/accounts/clients/${clientId}/settings`,
      //     // <SettingOutlined style={{ color: "#3a5dd3" }} />
      //     <img
      //       alt="settingslogo"
      //       src={settingslogo}
      //       style={{ height: "20px", width: "20px", marginRight: "8px" }}
      //     />
      //   ),
      // ],
      // "group"
    ),
    getItem(
      <Link to={`/accounts/clients/${clientId}/settings/companyInfo`}>
        Settings
      </Link>,
      `/accounts/clients/${clientId}/settings`,
      // <SettingOutlined style={{ color: "#3a5dd3" }} />
      <img
        alt="settingslogo"
        src={settingslogo}
        style={{ height: "20px", width: "20px", marginRight: "8px" }}
      />
    ),
  ];

  const selectedMenuItem = (key: any) => {
    if (key === `/accounts/clients/${clientId}/sales`) {
      <Link to={key + "/invoices"}></Link>;
      setOpenSiderDrawer(false);
    } else if (key === `/accounts/clients/${clientId}/purchase`) {
      setOpenSiderDrawer(false);
    } else if (key === `/accounts/clients/${clientId}/asset`) {
      <Link to={key + "/fixedAsset"}></Link>;
      setOpenSiderDrawer(false);
    } else if (key === `/accounts/clients/${clientId}/banking`) {
      <Link to={key + "/bankAccounts"}></Link>;
      setOpenSiderDrawer(false);
    } else if (key === `/accounts/clients/${clientId}/ageing`) {
      <Link to={key + "/debtors"}></Link>;
      setOpenSiderDrawer(false);
    } else if (key === `/accounts/clients/${clientId}/master`) {
      <Link to={key + "/ledger"}></Link>;
      setOpenSiderDrawer(false);
    } else if (key === `/accounts/clients/${clientId}/settings`) {
      <Link to={key + "/companyInfo"}></Link>;
      setOpenSiderDrawer(false);
    } else {
      // navigate(key);
      <Link to={key}></Link>;
      setOpenSiderDrawer(false);
    }
  };
  //#region Commented Code
  // React.useEffect(() => {
  //   LogService.getUserLog(LogTypes.Sales, 0, 15).then((res: any) => {
  //     console.log(res, "log response......>>>>>");
  //     if (res !== null || res?.items?.length > 0) {
  //       setLogData(
  //         res?.items?.map((d: any, i: number) => ({
  //           // serialNo: i + 1 + listParams.start,
  //           key: i,
  //           company: d?.company?.name,
  //           date: dayjs(d?.createdBy?.date).format("DD MMM YYYY , hh:mm A"),
  //           description: d?.description,
  //           user: d?.createdBy?.userName,
  //           action: d?.action,
  //         }))
  //       );
  //       // setLoading(false);
  //       // setTotalRecords(res?.totalRecords);
  //     } else {
  //       // messageApi.error(res?.message);
  //     }
  //   });
  // }, []);
  //#endregion

  return (
    <>
      <ConfigProvider
        theme={{
          // token: { colorPrimary: "#3a5dd3" },
          token: {
            colorPrimary: themeData?.themeData?.themeColor ?? "#395cd2",
            fontSize: themeData?.themeData?.fontSize ?? 14,
            borderRadius: themeData?.themeData?.borderRadius ?? 6,
          },
          components: {
            Input: {
              colorBgContainerDisabled: "aliceblue",
              colorTextDisabled: "dimgrey",
            },
            DatePicker: {
              colorBgContainerDisabled: "aliceblue",
              colorTextDisabled: "dimgrey",
            },
            Select: {
              colorBgContainerDisabled: "aliceblue",
              colorTextDisabled: "dimgrey",
            },
          },
          algorithm:
            themeData?.themeData?.darkMode === true
              ? darkAlgorithm
              : defaultAlgorithm,
        }}
        componentSize={themeData?.themeData?.componentSize ?? "middle"}
      >
        <ClientDataProvider clientId={clientId!}>
          {/* {isAuthenticated() ? ( */}
          <Layout>
            {/* ---------- Sider & Drawer ----------- */}
            {width > 1024 && themeData?.themeData?.silderSize ? (
              <Sider
                trigger={null}
                collapsible
                collapsed={collapsed}
                breakpoint="lg"
                // onCollapse={(x) => {
                //   setCollapsed(x);
                // }}
                style={{
                  background: colorBgContainer,
                  position: "fixed",
                  transition: "0.2s",
                  // boxShadow: "2px 1px 5px rgb(81 100 120 / 8%)",
                  boxShadow: "0px 15px 4px 0px #E4E8F0",
                  // boxShadow: "0px 4px 15px 0px #96A1C81C",

                  height: "100vh",
                  // borderRight: "2px solid #eef2fe",
                  borderRight: "1px solid #E4E8F0",
                }}
                className="sidertoggle"
              >
                <div>
                  <div style={{ position: "fixed", top: 0, zIndex: 1 }}>
                    <img
                      src={collapsed ? companylogo1 : companylogo}
                      width={collapsed ? 50 : 140}
                      height={50}
                      onClick={() => setCollapsed(!collapsed)}
                      style={{
                        margin: "4px 0px 0px 16px",
                        transitionDuration: "0.18s",
                        cursor: "pointer",
                      }}
                      alt="companylogo1"
                    />
                  </div>
                  <Menu
                    mode="inline"
                    // defaultSelectedKeys={[locationUrl]}
                    selectedKeys={[locationUrl]}
                    defaultSelectedKeys={[locationUrl]}
                    // selectedKeys={[location.pathname]}
                    items={items}
                    triggerSubMenuAction={"hover"}
                    onClick={(e: any) => {
                      selectedMenuItem(e.key);
                    }}
                    style={{
                      top: "60px",
                      position: "relative",
                      overflowY: "auto",
                      height: `calc(100vh - 50px)`,
                      fontFamily: "'Open Sans','sans-serif'",
                      fontSize: "14px",
                      borderRight: "none",
                      border: "none",
                      // boxShadow: "2px 1px 5px rgb(81 100 120 / 8%)",
                    }}
                  />
                </div>
              </Sider>
            ) : (
              <Drawer
                className="drawerToggle"
                open={openSiderDrawer}
                onClose={() => setOpenSiderDrawer(false)}
                closeIcon={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={companylogo}
                      width={120}
                      height={50}
                      style={{
                        margin: "0px 0px 0px 10px",
                      }}
                      alt="companylogo1"
                    />
                    <div>
                      <MenuOutlined
                        style={{
                          fontSize: "20px",
                          paddingLeft: "20px",
                        }}
                      />
                    </div>
                  </div>
                }
                placement="left"
                style={{ width: "200px" }}
              >
                <Menu
                  className="Overide"
                  mode="inline"
                  defaultSelectedKeys={[locationUrl]}
                  items={items}
                  onClick={(e: any) => {
                    selectedMenuItem(e.key);
                  }}
                  style={{
                    // top: "60px",
                    width: "200px",
                    position: "relative",
                    overflowY: "auto",
                    // height: `calc(100vh - 60px)`,
                  }}
                />
              </Drawer>
            )}
            {/* themeData?.themeData?.silderSize !== true */}
            <Layout
              style={{
                marginLeft:
                  width <= 1024 || themeData?.themeData?.silderSize !== true
                    ? "0px"
                    : collapsed !== true
                    ? "200px"
                    : "80px",
                transition: "0.2s",
              }}
            >
              {/* ----------- Header ------------ */}
              <Header
                style={{
                  padding: 0,
                  // backgroundColor:
                  //   themeData?.themeData?.themeColor ?? "#3d4998",
                  height: "40px",
                  // borderBottom: "2px solid #eef2fe",
                  // borderBottom: "1px solid #E4E8F0",

                  // backgroundColor: "white",
                  backgroundColor: "#f7f8fa",
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ marginTop: "-8px" }}>
                    <Space>
                      {!collapsed === true ? (
                        <MenuOutlined
                          style={{
                            fontSize: "20px",
                            color: "#8B99A7",
                            paddingLeft: "20px",
                          }}
                          onClick={() => {
                            setCollapsed(!collapsed);
                            setOpenSiderDrawer(true);
                          }}
                        />
                      ) : (
                        <MenuFoldOutlined
                          onClick={() => {
                            // setCollapsed(false);
                            setCollapsed(!collapsed);
                            setOpenSiderDrawer(true);
                          }}
                          style={{
                            fontSize: "20px",
                            // color: "#395cd2",
                            color: "#8B99A7",
                            paddingLeft: "20px",
                          }}
                        />
                      )}
                      {/* <Typography.Title level={5} style={{ color: "white" }}>
                        Capsitech Accounts
                      </Typography.Title> */}
                    </Space>
                    {/* ------- Quick Add ------- */}
                    {/* <Tooltip title="Quick Add" placement="right">
                        <Popover
                          content={QuickAdd}
                          placement={width > 600 ? "topLeft" : "topRight"}
                          // trigger="click"
                          arrow={false}
                          open={openQuickAdd}
                          onOpenChange={(x) => setOpenQuickAdd(x)}
                        >
                          <PlusCircleOutlined
                            style={{
                              // rotate:"30deg",
                              cursor: "pointer",
                              fontSize: "20px",
                              color: "white",
                              paddingLeft: "15px",
                            }}
                          />
                        </Popover>
                      </Tooltip> */}
                    {/* <CloseCircleOutlined
                          style={{
                            fontSize: "20px",
                            color: "white",
                            paddingLeft: "10px",
                          }}
                        /> */}
                  </div>

                  <div>
                    <div
                      style={{
                        marginRight: "2px",
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "6px",
                      }}
                    >
                      <div>
                        <Input
                          style={{
                            display: "flex",
                            marginTop: "5px",
                            cursor: "pointer",
                            width: "165px",
                            height: "28px",
                            // backgroundColor: "#f5f8ff",
                            border: "none",
                          }}
                          suffix={
                            <>
                              <img
                                src={Search}
                                alt="search"
                                style={{ opacity: "50%" }}
                              />
                            </>
                          }
                        ></Input>
                      </div>
                      {/* <img src={bellimg} style={{marginBottom:"22px"}}/> */}
                      <BellOutlined
                        style={{ marginBottom: "22px", color: "#8B99A7" }}
                      />
                      {/* <div
                        onClick={showLogDrawer}
                        style={{ cursor: "pointer" }}
                      >
                        <ImHistory size={20} />
                      </div> */}
                      <div>
                        <Popover
                          content={<ContentUser />}
                          placement="bottomLeft"
                          trigger="click"
                        >
                          {/* <Avatar
                            className="avatar-user"
                            icon={<UserOutlined />}
                            style={{
                              cursor: "pointer",
                              marginBottom: "27px",
                              marginRight: "10px",
                            }}
                          /> */}
                          <img
                            src={avtaruser}
                            alt="avtaruser"
                            style={{
                              cursor: "pointer",
                              marginBottom: "6px",
                              marginRight: "10px",
                            }}
                          />
                        </Popover>
                      </div>
                    </div>
                  </div>
                </div>
              </Header>
              {/* ------- Client Details Content ------- */}
              <Content
                style={{
                  background: colorBgContainer,
                }}
              >
                {/* <Drawer
                  title="Basic Drawer"  
                  placement={"right"}
                  closable={false}
                  onClose={CloseLogDrawer}
                  open={openLogDrawer}
                >
                  {logData?.map((item: any) => {
                    return (
                      <Timeline
                        items={[
                          {
                            children: (
                              <>
                                <p>{item?.description}</p>
                                <span>{item?.date}</span> By {item?.company}
                              </>
                            ),
                          },
                          {
                            children: (
                              <>
                                <p> {item?.user}</p>
                              </>
                            ),
                          },
                        ]}
                      />
                    );
                  })}
                </Drawer> */}
                {isConnected !== true ? (
                  <Result
                    icon={<WifiOutlined />}
                    title="Network Disconnected"
                    subTitle="Please check your internet connection and try again."
                    extra={
                      <Button
                        type="primary"
                        onClick={() => window.location.reload()}
                      >
                        Retry
                      </Button>
                    }
                  />
                ) : (
                  <>
                    {" "}
                    <PageRoute />
                    <FloatButtons
                      setQuickAddProps={setQuickAddProps}
                      clientId={clientId}
                      images={{
                        masters,
                        saleslogo,
                        purchaselogo,
                        AssetImg,
                        journals,
                        bankImg,
                      }}
                    />
                  </>
                )}
              </Content>
            </Layout>
            {/* <ShortcutKeys
              setQuickAddProps={setQuickAddProps}
              quickAddProps={quickAddProps}
              clientId={clientId}
            /> */}
            {/*--------------- QuickAdd Props --------------- */}
            {quickAddProps.type === "addItem" && (
              <AddItems
                open={quickAddProps.open}
                onCancel={() => setQuickAddProps({ type: "", open: false })}
                // id={quickAddProps.id}
                // setTrigger={setTrigger}
              />
            )}
            {quickAddProps.type === "addCustomer" && (
              <AddCustomers
                openDrawer={quickAddProps.open}
                setOpenDrawer={() =>
                  setQuickAddProps({ type: "", open: false })
                }
              />
            )}
            {quickAddProps.type === "addSupplier" && (
              <AddPurchaseSupplier
                openDrawer={quickAddProps.open}
                setOpenDrawer={() =>
                  setQuickAddProps({ type: "", open: false })
                }
              />
            )}
            {quickAddProps.type === "AddLedger" && (
              <AddLedger
                id={""}
                openDrawer={true}
                setOpenDrawer={() =>
                  setQuickAddProps({ type: "", open: false })
                }
              />
            )}
            {quickAddProps.type === "AddGroup" && (
              <AddGroup
                open={true}
                editData={""}
                groupData={[]}
                setTrigger={{}}
                onCancel={() => setQuickAddProps({ type: "", open: false })}
              />
            )}
            {quickAddProps.type === "AddItems" && (
              <AddItems
                open={true}
                onCancel={() => setQuickAddProps({ type: "", open: false })}
              />
            )}
            {quickAddProps.type === "AddSalesInvoice" && <AddSalesInvoice />}
            {quickAddProps.type === "AdjustBankBalanceModel" && (
              <AdjustBankBalanceModel
                open={true}
                onCancel={() => setQuickAddProps({ type: "", open: false })}
                type={""}
              />
              // <></>
            )}
          </Layout>
          {/* ) : (
             
            // <Layout className="site-layout" />
          )} */}
        </ClientDataProvider>
      </ConfigProvider>
    </>
  );
};

export default AccountsMainLayout;
