import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Popover,
  Row,
  Skeleton,
  Space,
  Table,
  Tooltip,
} from "antd";
import React from "react";
import { Utils } from "../../../Utilities/Utils";
import {
  PlusOutlined,
  QuestionCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import { dayjs } from "../../../Utilities/dayjs";
import { useClient } from "../../Context/ClientContext";
import { useTheme } from "../../Context/ThemeContext";
import FixedAssetsService, {
  AssetStatus,
} from "../../Services/FixedAssetsServiceNew";
import Search from "antd/es/transfer/search";
const { RangePicker } = DatePicker;

interface IDisposedListDataType {
  key: string;
  id: string;
  number: string;
  name: string;
  sno: number;
  date: string;
  disposalValue: number;
  disposedate: string;
  depreciation: number;
  price: string;
  gainloss: number;
}
const FAR = () => {
  const { currentClient, companySettings } = useClient();
  const { themeData } = useTheme();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [disposeList, setDisposeList] = React.useState<any[]>([]);
  const [totalRecords, setTotalRecords] = React.useState<number>(0);
  const [searchDispose, setSearchDispose] = React.useState<any>();
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState<number>(10);
  const [reRenderData, setReRenderData] = React.useState<boolean>(false);
  const [filterDate, setFilterDate] = React.useState<{
    from: string;
    to: string;
  }>({
    from: dayjs(companySettings?.period?.from).format("DD/MM/YYYY"),
    // to: dayjs(companySettings?.period?.to).format("DD/MM/YYYY"),
    to: dayjs().format("DD/MM/YYYY"),
  });

  const [selectedColumns, setSelectedColumns] = React?.useState([
    "sno",
    "number",
    "name",
    "date",
    "disposedate",
    "price",
    "disposalValue",
    "depreciation",
    "gainloss",
  ]);

  const allColumns = [
    { title: "S.No", dataIndex: "sno", key: "sno" },
    { title: "Asset Reference", dataIndex: "number", key: "number" },
    { title: "Asset Name", dataIndex: "name", key: "name" },
    { title: "Purchase Date", dataIndex: "date", key: "date" },
    { title: "Dispose Date", dataIndex: "disposedate", key: "disposedate" },
    { title: "Purchase Price", dataIndex: "price", key: "price" },
    {
      title: "Disposal Value",
      dataIndex: "disposalValue",
      key: "disposalValue",
    },
    { title: "Depreciation", dataIndex: "depreciation", key: "depreciation" },
    { title: "Gain/Loss", dataIndex: "gainloss", key: "gainloss" },
    { title: "Payment Date", dataIndex: "payDate", key: "payDate" },
    {
      title: "Depreciation Start Date",
      dataIndex: "depreciationStartDate",
      key: "depreciationStartDate",
    },
    { title: "Location", dataIndex: "location", key: "location" },
    {
      title: "Disposal Payment Ref",
      dataIndex: "disposalPaymentRef",
      key: "disposalPaymentRef",
    },
    {
      title: "Purchase Pay Reference",
      dataIndex: "purPayRef",
      key: "purPayRef",
    },
    {
      title: "Warranty Date",
      dataIndex: "warrantyExpDate",
      key: "warrantyExpDate",
    },
  ];
  const columns: ColumnsType<IDisposedListDataType> = [
    {
      title: "S.No",
      dataIndex: "sno",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.sno}
        </Skeleton>
      ),
    },
    {
      title: "Asset Reference",
      dataIndex: "number",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          <span onClick={() => {}}> {record?.number}</span>
        </Skeleton>
      ),
    },
    {
      title: "Asset Name",
      dataIndex: "name",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.name}
        </Skeleton>
      ),
    },
    {
      title: "Purchase Date",
      dataIndex: "date",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.date}
        </Skeleton>
      ),
    },
    {
      title: "Dispose Date",
      dataIndex: "disposedate",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.disposedate}
        </Skeleton>
      ),
    },
    {
      title: "Purchase Price",
      dataIndex: "price",
      align: "right",
      width: 150,
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
            style: { display: "flex", justifyContent: "end" },
          }}
          title={false}
        >
          {record?.price}
        </Skeleton>
      ),
    },
    {
      title: "Disposal Value",
      dataIndex: "disposalValue",
      align: "right",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
            style: { display: "flex", justifyContent: "end" },
          }}
          title={false}
        >
          {record?.disposalValue}
        </Skeleton>
      ),
    },
    {
      title: "Depreciation",
      dataIndex: "depreciation",
      align: "right",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
            style: { display: "flex", justifyContent: "end" },
          }}
          title={false}
        >
          {record?.depreciation}
        </Skeleton>
      ),
    },
    {
      title: (
        <>
          {"Gain/Loss "}
          <Tooltip
            title="Disposal Value - (Asset Value - Accumulated Depreciation)"
            placement="top"
            color="#4b5b95"
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </>
      ),
      dataIndex: "gainloss",
      align: "right",
      render: (x) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
            style: { display: "flex", justifyContent: "end" },
          }}
          title={false}
        >
          <div style={{ color: x > 0 ? "green" : "red" }}>
            {Utils.getFormattedCurrency(x)}
          </div>
        </Skeleton>
      ),
    },
    {
      title: "Payment Date",
      dataIndex: "payDate",
      align: "right",
    },
    {
      title: "Depreciation Start Date",
      dataIndex: "depreciationStartDate",
      align: "right",
    },
    {
      title: "Location",
      dataIndex: "location",
      align: "right",
    },
    {
      title: "Disposal Payment Ref",
      dataIndex: "disposalPaymentRef",
      align: "right",
    },
    {
      title: "Purchase Pay Reference",
      dataIndex: "purPayRef",
      align: "right",
    },

    {
      title: "Warranty Date",
      dataIndex: "warrantyExpDate",
      align: "right",
    },
  ];

  console.log("search", searchDispose);

  const getList = async (
    clientId: string,
    start: number,
    length: number,
    sortCol: any,
    sortDir: any,
    searchDispose: string,
    status: any
  ) => {
    await FixedAssetsService.getFixedAssetsList(
      clientId,
      start,
      length,
      sortCol,
      sortDir,
      searchDispose,
      AssetStatus?.Disposed
    )
      .then((res) => {
        if (res != null || res?.items?.length > 0) {
          setDisposeList(
            res?.items?.map((e: any, index: number) => ({
              key: index,
              sno: index + 1,
              id: e?.id,
              number: e?.number,
              name: e?.name,
              date: dayjs(e?.invoiceDetail.date).format("DD/MM/YYYY"),
              price: Utils.getFormattedNumber(e?.invoiceDetail.price),
              disposalValue: Utils.getFormattedNumber(e?.disposalValue),
              disposedate: dayjs(e?.disposalDate).format("DD/MM/YYYY"),
              depreciation: Utils.getFormattedNumber(
                e?.accumulatedDepreciation
              ),
              gainloss: e?.pnLAmount,
              payDate: dayjs(e?.payDate).format("DD/MM/YYYY"),
              depreciationStartDate: dayjs(e?.depreciationStartDate).format(
                "DD/MM/YYYY"
              ),
              location: e?.location,
              disposalPaymentRef: e?.payRef,
              purPayRef: e?.invoiceDetail?.purPayRef,
              warrantyExpDate: dayjs(e?.warrantyExpDate).format("DD/MM/YYYY"),

              //description: e?.description,
              // isDepreciable: e?.isDepreciable === true ? "Yes" : "No",
              // journals: e?.journals,
              // jrNumber: e?.jrNumber,
            }))
          );
          setTotalRecords(res?.totalRecords);
        } else if (res.items.length === 0) {
          setDisposeList([]);
          setTotalRecords(res?.totalRecords);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
    setLoading(false);
  };

  React.useEffect(() => {
    setLoading(true);

    getList(
      currentClient?.id!,
      (page - 1) * pageSize,
      pageSize,

      undefined,
      undefined,
      searchDispose,
      AssetStatus.Disposed
      // "", // tyep
      // "", // lastAccDate
      // true
    );
  }, [currentClient?.id, searchDispose, page, pageSize, reRenderData]);

  const handleCheckboxChange = (value: any) => {
    setSelectedColumns((prev) =>
      prev.includes(value)
        ? prev.filter((col) => col !== value)
        : [...prev, value]
    );
  };

  const columnOptions = allColumns.map((col: any) => ({
    label: col.title,
    value: col.key,
  }));

  const content = (
    <Space direction="vertical">
      {columnOptions.map((option) => (
        <Checkbox
          key={option.value}
          checked={selectedColumns.includes(option.value)}
          onChange={() => handleCheckboxChange(option.value)}
        >
          {option.label}
        </Checkbox>
      ))}
    </Space>
  );

  return (
    <>
      <div>
        <Card
          className="ca-cashInHand-card-body"
          style={{
            marginTop: "6px",
            padding: "48px 0px 0px 0px",
            boxShadow: "0px 0px 10px 0px #96A1C84D",
          }}
        >
          <Row
            gutter={10}
            justify={"space-between"}
            style={{ margin: "20px 0px" }}
          >
            <Col>
              <Row>
                <Col>
                  <h1 style={{ color: "rgb(57, 92, 210) " }}>
                    Fixed Assets Register
                  </h1>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row>
                <Col>
                  <span>Colums </span>
                  <Popover content={content} trigger="click">
                    <Button
                      size="small"
                      type="primary"
                      icon={<PlusOutlined />}
                      style={{
                        padding: "1px",
                        margin: "3px 10px 0px 0px",
                      }}
                    />
                  </Popover>
                </Col>
                <Col>
                  <Search
                    placeholder="Search..."
                    onChange={(e: any) => setSearchDispose(e.target.value)}
                  />
                </Col>
                <Col>
                  <span style={{ padding: "0px 10px" }}>Date</span>
                  <RangePicker
                    defaultValue={[
                      dayjs(filterDate.from, "DD/MM/YYYY"),
                      dayjs(),
                      // dayjs(filterDate.to, "DD/MM/YYYY"),
                    ]}
                    allowClear
                    format="DD/MM/YYYY"
                    style={{ width: "220px", border: "1px solid #f1f3ff" }}
                    onChange={(e: any) => {
                      e === null
                        ? setFilterDate({ from: "", to: "" })
                        : setFilterDate({
                            ...filterDate,
                            from: dayjs(e[0]).format("DD/MM/YYYY"),
                            to: dayjs(e[1]).format("DD/MM/YYYY"),
                          });
                      //console.log(e, "dateCheck");
                    }}
                  />{" "}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col lg={24} md={24}>
              <Table
                className={`Tabel-style table-${
                  themeData?.componentSize ?? "middle"
                }`}
                columns={columns.filter((cl: any) =>
                  selectedColumns.includes(cl?.dataIndex)
                )} // dataSource={disposeList}
                dataSource={
                  loading
                    ? (Array.from({ length: pageSize }, (_, index) => ({
                        key: `loading-${index}`,
                      })) as IDisposedListDataType[])
                    : disposeList
                }
                size="small"
                scroll={
                  disposeList.length > 15 ? { x: 700, y: 620 } : { x: 600 }
                }
              />
            </Col>
          </Row>
        </Card>
      </div>
    </>
  );
};

export default FAR;
