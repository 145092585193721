import { Button, Divider, Form, Input, Modal } from "antd";
import React from "react";
import ItemsService from "../../Services/ItemsService";
import { useClient } from "../../Context/ClientContext";
import { notificationContext } from "../../Common/PageRoute";

const UnitModel: React.FC<{
  open: boolean;
  data: any;
  setTrigger: any;
  onCancel: any;
}> = (props) => {
  const [form] = Form.useForm();
  const { currentClient } = useClient();
  const { openNotification } = React.useContext(notificationContext);
  const [saveButtonLoading, setSaveButtonLoading] =
    React.useState<boolean>(false);

  // Post Api
  const postItem = () => {
    form.validateFields().then(async (value: any) => {
      setSaveButtonLoading(true);
      let data = {
        ...value,
      };

      await ItemsService.postUnit(data, currentClient?.id, props.data?.id)
        .then((res: any) => {
          openNotification(
            "success",
            `Item ${props.data?.id ? "Edit successfully" : "Add successfully"}`
          );
          props.onCancel();
          setSaveButtonLoading(false);
          props.setTrigger((x: boolean) => !x);

          console.log(res);
        })
        .catch((ex: any) => {
          setSaveButtonLoading(false);

          openNotification("error", ex);
          console.error(ex);
        });
    });
  };

  // Get Api
  React.useEffect(() => {
    if (props?.data?.id) {
      form.setFieldsValue({ ...props.data });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.data.id]);
  // getItemInventory;

  // --------- InitialValues in Add Item Model ---------
  const initialValues = {};

  return (
    <div>
      <Modal
        open={props.open}
        title={props?.data.id ? "Update Unit" : "New Unit"}
        width={400}
        onCancel={() => props.onCancel()}
        style={{ maxHeight: `calc(100% - 100px)`, overflowY: "auto", top: 180 }}
        footer={
          <div style={{ marginTop: "0px" }}>
            <Button
              onClick={() => {
                form.resetFields();
                props.onCancel();
              }}
            >
              Cancel
            </Button>
            <Button type="primary" onClick={() => postItem()} loading={saveButtonLoading}>
              {!props?.data.id ? "Save" : "Update"}
            </Button>
          </div>
        }
      >
        <Divider className="ca-model-css" />
        {/* <Spin indicator={antIcon} spinning={modelLoading}> */}
        <Form
          form={form}
          name="item"
          autoCapitalize="false"
          requiredMark={false}
          initialValues={initialValues}
          colon={false}
          labelAlign="left"
          labelCol={{ xs: 8, sm: 7 }}
          wrapperCol={{ xs: 16, sm: 16 }}
          labelWrap={true}
        >
          <Form.Item
            label="Unit Name"
            name="name"
            rules={[{ required: true, message: "Unit Name is required" }]}
            className="input-item"
          >
            <Input placeholder="Unit Name" />
          </Form.Item>

          <Form.Item
            label="Type"
            name="type"
            rules={[{ required: true, message: "Type is required" }]}
            className="input-item"
          >
            <Input placeholder="Type" />
          </Form.Item>

          <Form.Item label="Short Name" name="shortName" className="input-item">
            <Input placeholder="Short name" />
          </Form.Item>
        </Form>
        {/* </Spin> */}
      </Modal>
    </div>
  );
};

export default UnitModel;
