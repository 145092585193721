import {
  DeleteOutlined,
  LockFilled,
  PlusOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";

import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Tooltip,
} from "antd";
import React, { useEffect } from "react";
import { useClient } from "../../Context/ClientContext";
import LedgerService, {
  SearchLedgerByGroupsTypes,
} from "../../Services/LedgerService";
import { Utils } from "../../../Utilities/Utils";
import { dayjs } from "../../../Utilities/dayjs";
import { CalcMethod, PmtMethod } from "./AssetModel";

const AssetInvoiceDetail = ({
  open,
  onCancel,
  singleAssetType,
  isDepreciable,
  depMethod,
  refNumber,
  selKey,
  setInvoiceDetail,
  invoiceDetaildata,
}: any) => {
  console.log("invoiceDetaildata: ", invoiceDetaildata);

  const { currentClient, companySettings } = useClient();
  const [selIndex, setSelIndex] = React.useState<any>(selKey ?? 0);
  const [payLedgers, setPayLedgers] = React.useState<any>([]);
  const [partyLedgers, setPartyLedgers] = React.useState<any>([]);
  const [childform] = Form.useForm();
  const [assetLocation, setAssetLocation] = React.useState<number>(1);
  const [childAssetCount, setChildAssetCount] = React.useState<number>(
    invoiceDetaildata?.childAssetDetail?.length ?? 1
  );
  // const [assetQty, setAssetQty] = React.useState<number>(1);
  const [paymentMode, setPaymentMode] = React.useState<any>();
  const [voucherDate, setVoucherDate] = React.useState<string>();
  const [openModel, setOpenModel] = React.useState<{
    type: string;
    param: any;
  }>({
    type: "",
    param: "",
  });
  const handleLocation = (selValue: number) => {
    //alert(typeof selValue);
    setAssetLocation(selValue);
  };
  const gstOption = companySettings?.setting?.gstDetails?.gst;
  const onPaymentModeChange = (selValue: PmtMethod) => {
    childform.setFieldValue("purPaymentLedger", "");

    if (selValue === PmtMethod.bank) {
      LedgerService.getLedgersByGroupName(
        currentClient?.id!,
        SearchLedgerByGroupsTypes.BankOnly
      ).then((res: any) => {
        setPayLedgers(res);
      });
    }
    if (selValue === PmtMethod.fullCredit) {
      LedgerService.getLedgersByGroupName(
        currentClient?.id!,
        SearchLedgerByGroupsTypes.PartiesOnly
      ).then((res: any) => {
        setPartyLedgers(res);
      });
    }
    if (selValue === PmtMethod.cash) {
      LedgerService.getLedgersByGroupName(
        currentClient?.id!,
        SearchLedgerByGroupsTypes.CashOnly
      ).then((res: any) => {
        setPayLedgers(res);
      });
    }
    if (selValue === PmtMethod.partialCredit) {
      LedgerService.getLedgersByGroupName(
        currentClient?.id!,
        SearchLedgerByGroupsTypes.BankAndCash
      ).then((res: any) => {
        setPayLedgers(res);
      });
      LedgerService.getLedgersByGroupName(
        currentClient?.id!,
        SearchLedgerByGroupsTypes.PartiesOnly
      ).then((res: any) => {
        setPartyLedgers(res);
      });
    }
    setPaymentMode(selValue);
  };

  const handleQtyChange = (val: number) => {
    setChildAssetCount(val);
    // childform.setFieldsValue({ qty: newVal });
  };

  const handleDateChange = (val: any) => {
    let childAssetData = childform?.getFieldValue("childAssetDetail");
    console.log("childAssetData", childAssetData);
    childAssetData.map((childData: any, index: number) => {
      childData?.depreciationStartDate === undefined
        ? childform?.setFieldValue(
            ["childAssetDetail", index, "depreciationStartDate"],
            val
          )
        : childData?.depreciationStartDate < val &&
          childform?.setFieldValue(
            ["childAssetDetail", index, "depreciationStartDate"],
            val
          );
    });
  };

  React.useEffect(() => {
    const currentValues = childform?.getFieldValue("childAssetDetail") || [];

    if (childAssetCount > 0) {
      const newValues = Array.from(
        { length: childAssetCount || 0 },
        (_, index: number) => ({
          ...(currentValues[index] || {}),
          key: index,
          lifeCycleDuration:
            currentValues[index]?.lifeCycleDuration !== undefined
              ? currentValues[index]?.lifeCycleDuration
              : invoiceDetaildata?.lifeCycleDuration !== undefined
              ? invoiceDetaildata?.lifeCycleDuration
              : "",
          depreciationStartDate:
            currentValues[index]?.depreciationStartDate! === undefined &&
            childform.getFieldValue("date") === undefined
              ? undefined
              : currentValues[index]?.depreciationStartDate! === undefined &&
                childform.getFieldValue("date") !== undefined
              ? childform.getFieldValue("date")
              : currentValues[index]?.depreciationStartDate!,
          childAssetNumber:
            refNumber +
            "-" +
            (invoiceDetaildata.key + 1).toString().padStart(2, `0`) +
            "-" +
            (index + 1).toString().padStart(2, `0`),
        })
      );
      console.log("New Values: ", newValues);

      childform?.setFieldValue("childAssetDetail", newValues);
      childform?.setFieldValue("qty", childAssetCount);
    }
  }, [childAssetCount]);

  React.useEffect(() => {
    if (invoiceDetaildata) {
      childform.setFieldsValue({
        ...invoiceDetaildata,
        date: invoiceDetaildata?.date
          ? dayjs(invoiceDetaildata?.date!)
          : undefined,
        voucherDate:
          dayjs(invoiceDetaildata?.voucherDate).unix() > 1
            ? dayjs(invoiceDetaildata?.voucherDate!)
            : undefined,
        purPayDate: invoiceDetaildata?.purPayDate
          ? dayjs(invoiceDetaildata?.purPayDate!)
          : undefined,
      });
      setPaymentMode(invoiceDetaildata?.purPayMethod!);
      onPaymentModeChange(invoiceDetaildata?.purPayMethod!);

      const childAssetDetails =
        invoiceDetaildata?.childAssetDetail !== undefined
          ? invoiceDetaildata?.childAssetDetail?.map((item: any) => ({
              ...item,
              location: item.location === "1" ? 1 : 2,
              warrantyExpDate: item.warrantyExpDate
                ? dayjs(item.warrantyExpDate)
                : null,
              depreciationStartDate: item.depreciationStartDate
                ? dayjs(item.depreciationStartDate)
                : null,
            }))
          : [
              {
                childAssetNumber:
                  refNumber +
                  "-" +
                  (invoiceDetaildata.key + 1).toString().padStart(2, `0`) +
                  "-" +
                  (1).toString().padStart(2, `0`),
                lifeCycleDuration:
                  invoiceDetaildata?.lifeCycleDuration !== undefined
                    ? invoiceDetaildata?.lifeCycleDuration
                    : "",
              },
            ];
      console.log("childAssetDetails: ", childAssetDetails);

      childform.setFieldsValue({
        childAssetDetail: childAssetDetails,
      });
    }
  }, [invoiceDetaildata]);

  return (
    <>
      <Drawer
        width={"60vw"}
        title="Add Asset Purchase Detail"
        onClose={() => onCancel(false)}
        maskClosable={false}
        open={open}
        extra={
          <Space>
            <Button
              onClick={() => {
                // setPurchaseDetailCount(purchaseDetailCount - 1);
                // setOpenDrawer(false);
                // setSelIndex(undefined);
                childform.resetFields();
                onCancel(false);
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={() => {
                setInvoiceDetail((prevValues: any) => {
                  let newValues = [...prevValues];
                  selIndex < newValues.length
                    ? (newValues = newValues.map((ent: any, index: number) =>
                        index === selIndex
                          ? {
                              ...ent,
                              ...childform.getFieldsValue(),
                              totalValue: childform.getFieldValue("price"),
                              key: index,
                            }
                          : {
                              ...ent,
                              // voucherDate: dayjs(voucherDate),
                            }
                      ))
                    : newValues.push({
                        ...childform?.getFieldsValue(),
                        key: newValues.length,
                      });
                  return newValues;
                });
                onCancel();
              }}
            >
              Save Detail
            </Button>
          </Space>
        }
      >
        <Form
          name="invoiceData"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          style={{ overflow: "scrollY" }}
          autoComplete="off"
          form={childform}
          requiredMark={true}
          layout="vertical"
          size="middle"
          initialValues={{
            qty: childAssetCount,
            childAssetDetail: [{}],
          }} //onFinishFailed={}
        >
          <Row gutter={6}>
            <Col lg={6} md={6}>
              <Form.Item
                name="date"
                label="Purchase Date"
                rules={[
                  {
                    required: true,
                    message: "Purchase Date required",
                  },
                ]}
                className="inputboxmb"
              >
                <DatePicker
                  style={{ width: "100%" }}
                  allowClear={true}
                  format={"DD/MM/YYYY"}
                  onChange={handleDateChange}
                />
              </Form.Item>
            </Col>
            <Col lg={6} md={6}>
              <Form.Item
                name="voucherDate"
                className="inputboxmb"
                label={
                  <Space>
                    Voucher Date
                    <Tooltip
                      title="Please input the Voucher Date in case you want to provide specific date for Voucher Entry else Purchase date will be Voucher Date."
                      placement="top"
                      color="#4b5b95"
                    >
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </Space>
                }
              >
                <DatePicker
                  style={{ width: "100%" }}
                  allowClear={true}
                  format={"DD/MM/YYYY"}
                  onChange={(value: any) =>
                    value === undefined
                      ? setVoucherDate("")
                      : setVoucherDate(value.toISOString())
                  }
                />
              </Form.Item>
            </Col>
            <Col lg={6} md={6}>
              <Form.Item
                name="invNumber"
                label="Invoice No."
                className="inputboxmb"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col lg={6} md={6}>
              <Form.Item
                name="taxCredit"
                label="Tax Credit"
                className="inputboxmb"
              >
                <Select
                  //className="inputboxsize"
                  disabled={gstOption == null ? true : false}
                  placeholder="Please select"
                  options={[
                    {
                      value: 0,
                      label: "Not Available",
                    },
                    { value: 1, label: "Available" },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col lg={6} md={6}>
              <Form.Item
                name="price"
                className="inputboxmb"
                label={
                  <Space>
                    Purchase Price
                    <Tooltip
                      title="Please input the base purchase price for the Input Tax Credit, else include GST with the base price"
                      placement="top"
                      color="#4b5b95"
                    >
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </Space>
                }
                rules={[
                  {
                    required: true,
                    message: "Please provide price of Asset.",
                  },
                ]}
              >
                <Input type="number" className="textEnd" />
              </Form.Item>
            </Col>
            <Col lg={6} md={6}>
              <Form.Item name="gst" label="GST" className="inputboxmb">
                <Select
                  disabled={gstOption === null ? true : false}
                  style={{ marginBottom: "3px" }}
                  labelInValue
                  options={gstOption?.map((gst: any) => ({
                    key: gst.rate,
                    value: gst.id,
                    label: gst.name,
                  }))}
                />
              </Form.Item>
            </Col>
            <Col lg={6} md={6}>
              <Form.Item
                name="purPayMethod"
                label="Payment Mode"
                className="inputboxmb"
                rules={[
                  {
                    required: true,
                    message: "Payment mode is required",
                  },
                ]}
              >
                <Select
                  //className="inputboxsize"
                  style={{ marginBottom: "3px" }}
                  placeholder="Please select payment mode"
                  onChange={onPaymentModeChange}
                  options={[
                    {
                      value: PmtMethod.bank,
                      label: "Bank",
                    },
                    {
                      value: PmtMethod.cash,
                      label: "Cash",
                    },
                    {
                      value: PmtMethod.fullCredit,
                      label: "Credit",
                    },
                    {
                      value: PmtMethod.partialCredit,
                      label: "Partial Payment",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            {paymentMode === PmtMethod.partialCredit && (
              <>
                <Col lg={6} md={6}>
                  <Form.Item
                    name="partiallyPaidAmount"
                    label="Partially Paid Amount"
                    className="inputboxmb"
                  >
                    <Input
                      //className="inputboxsize"
                      style={{ marginBottom: "3px" }}
                      placeholder="Enter amount"
                    />
                  </Form.Item>
                </Col>
              </>
            )}
            {paymentMode !== PmtMethod.fullCredit && (
              <>
                <Col lg={6} md={6}>
                  <Form.Item
                    name="purPayDate"
                    label="Payment Date"
                    rules={[
                      {
                        required: true,
                        message: "Payment date required",
                      },
                    ]}
                    className="inputboxmb"
                  >
                    <DatePicker
                      allowClear={false}
                      format={"DD/MM/YYYY"}
                      style={{ width: "100%" }}
                      disabled={
                        paymentMode === PmtMethod.fullCredit ? true : false
                      }
                      // disabledDate={(current) =>
                      //   current && current > dayjs().endOf("day")
                      // }
                    />
                  </Form.Item>
                </Col>
              </>
            )}

            {(paymentMode === PmtMethod.partialCredit ||
              paymentMode === PmtMethod.cash ||
              paymentMode === PmtMethod.bank) && (
              <>
                <Col lg={6} md={6}>
                  <Form.Item
                    name="purPaymentLedger"
                    label="Payment Ledger"
                    className="inputboxmb"
                    rules={[
                      {
                        required: true,
                        message: "Payment Ledger is required",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label?.toString() ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      style={{ marginBottom: "3px" }}
                      options={payLedgers?.map((value: any) => ({
                        value: value?.id,
                        label: value?.ledgerName.concat(
                          value?.aliasName
                            ? `${" (" + value?.aliasName + ")"}`
                            : ""
                        ),
                      }))}
                      dropdownRender={(supplierOptions) => (
                        <>
                          {supplierOptions}
                          <Divider style={{ margin: "5px 0px" }} />
                          <Button
                            style={{ width: "100%" }}
                            icon={<PlusOutlined />}
                            type="link"
                            onClick={() => {
                              setOpenModel({
                                type: "addLedger",
                                param: "",
                              });
                              // setEditParty({ id: "" });
                            }}
                          >
                            New Ledger
                          </Button>
                        </>
                      )}
                    />
                  </Form.Item>
                </Col>
              </>
            )}

            {(paymentMode === PmtMethod.partialCredit ||
              paymentMode === PmtMethod.fullCredit) && (
              <>
                <Col lg={6} md={6}>
                  <Form.Item
                    name="purPartyLedger"
                    label="Party Ledger"
                    className="inputboxmb"
                    rules={[
                      {
                        required: true,
                        message: "Party Ledger is required",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label?.toString() ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      style={{ marginBottom: "3px" }}
                      options={partyLedgers?.map((value: any) => ({
                        value: value?.id,
                        label: value?.ledgerName.concat(
                          value?.aliasName
                            ? `${" (" + value?.aliasName + ")"}`
                            : ""
                        ),
                      }))}
                      dropdownRender={(supplierOptions) => (
                        <>
                          {supplierOptions}
                          <Divider style={{ margin: "5px 0px" }} />
                          <Button
                            style={{ width: "100%" }}
                            icon={<PlusOutlined />}
                            type="link"
                            onClick={() => {
                              setOpenModel({
                                type: "addLedger",
                                param: "",
                              });
                              // setEditParty({ id: "" });
                            }}
                          >
                            New Ledger
                          </Button>
                        </>
                      )}
                    />
                  </Form.Item>
                </Col>
              </>
            )}
            {(paymentMode === PmtMethod.partialCredit ||
              paymentMode === PmtMethod.bank) && (
              <>
                <Col lg={6} md={6}>
                  <Form.Item
                    name="purPayRef"
                    label="Payment Reference"
                    className="inputboxmb"
                  >
                    <Input
                      disabled={
                        paymentMode === PmtMethod.fullCredit
                          ? true
                          : paymentMode === PmtMethod.cash
                          ? true
                          : false
                      }
                    />
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>
          <Row>
            <Col lg={4} md={4}>
              <Form.Item
                name="qty"
                label="Quantity"
                className="inputboxmb"
                rules={[
                  {
                    required: true,
                    message: "Quantity is required",
                  },
                ]}
              >
                <InputNumber
                  min={1}
                  disabled={singleAssetType}
                  value={childAssetCount}
                  onChange={(val: any) => {
                    if (val < 1) {
                      val = 1;
                    }
                    setChildAssetCount(val);
                  }}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
          {childAssetCount && (
            <>
              <Card
                title={`Children (${childAssetCount!}) Asset Detail`}
                size="small"
              >
                <Form.List name="childAssetDetail">
                  {(fields, { add, remove }) => (
                    <div>
                      {fields.map(({ key, name, ...restField }, index) => (
                        <div key={index}>
                          <Divider
                            orientation="left"
                            orientationMargin={0}
                            style={{ margin: "5px 0px" }}
                          >
                            <span
                              style={{ fontSize: "12px", fontWeight: "600" }}
                            >
                              Child {name + 1}
                            </span>
                          </Divider>
                          <Row gutter={6}>
                            <Col lg={6} md={6}>
                              <Form.Item
                                name={[name, "childAssetName"]}
                                label="Child Asset Name"
                                className="inputboxmb"
                                rules={[
                                  {
                                    required: true,
                                    message: "Child Asset name is required",
                                  },
                                ]}
                              >
                                <Input></Input>
                              </Form.Item>
                            </Col>
                            <Col lg={6} md={6}>
                              <Form.Item
                                name={[name, "childAssetNumber"]}
                                label="Asset Number"
                                className="inputboxmb"
                              >
                                <Input disabled />
                              </Form.Item>
                            </Col>
                            <Col lg={6} md={6}>
                              <Form.Item
                                name={[name, "price"]}
                                label="Price"
                                className="inputboxmb"
                                rules={[
                                  {
                                    required: true,
                                    message: "Price is required.",
                                  },
                                ]}
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                            <Col lg={6} md={6}>
                              <Form.Item
                                name={[name, "location"]}
                                label="Location"
                                className="inputboxmb"
                              >
                                <Select
                                  //className="inputboxsize"
                                  value={assetLocation}
                                  style={{ marginBottom: "3px" }}
                                  onChange={handleLocation}
                                  options={[
                                    {
                                      value: 1,
                                      label: "Office",
                                    },
                                    {
                                      value: 2,
                                      label: "Plant",
                                    },
                                  ]}
                                />
                              </Form.Item>
                            </Col>
                            <Col lg={6} md={6}>
                              <Form.Item
                                name={[name, "model"]}
                                label="Model"
                                className="inputboxmb"
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                            <Col lg={6} md={6}>
                              <Form.Item
                                name={[name, "identityCode"]}
                                label="Identity Code"
                                className="inputboxmb"
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                            <Col lg={6} md={6}>
                              <Form.Item
                                name={[name, "warrantyExpDate"]}
                                label="Warranty / Guarantee Expiry Date"
                                className="inputboxmb"
                              >
                                <DatePicker
                                  style={{ width: "100%" }}
                                  allowClear={false}
                                  format={"DD/MM/YYYY"}
                                />
                              </Form.Item>
                            </Col>
                            {isDepreciable && (
                              <>
                                <Col lg={6} md={6}>
                                  <Form.Item
                                    name={[name, "lifeCycleDuration"]}
                                    label="Life Cycle Duration"
                                    className="inputboxmb"
                                  >
                                    <Input />
                                  </Form.Item>
                                </Col>
                                {depMethod === CalcMethod.StraightLine && (
                                  <>
                                    <Col lg={6} md={6}>
                                      <Form.Item
                                        name={[name, "residualValue"]}
                                        label="Residual Value"
                                        className="inputboxmb"
                                        rules={[
                                          {
                                            required: true,
                                            message:
                                              "Residual value is required",
                                          },
                                        ]}
                                      >
                                        <Input />
                                      </Form.Item>
                                    </Col>
                                  </>
                                )}
                                <Col lg={6} md={6}>
                                  <Form.Item
                                    name={[name, "depreciationStartDate"]}
                                    label="Depreciation Start Date"
                                    className="inputboxmb"
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "Depreciation date is required",
                                      },
                                    ]}
                                  >
                                    <DatePicker
                                      // defaultValue={dayjs()}
                                      style={{ width: "100%" }}
                                      allowClear={false}
                                      format={"DD/MM/YYYY"}
                                    />
                                  </Form.Item>
                                </Col>
                              </>
                            )}
                            <Col lg={23} md={23}>
                              <Form.Item
                                name={[name, "description"]}
                                label="Description"
                                className="inputboxmb"
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                            <Col lg={1} md={1} style={{ marginTop: "29px" }}>
                              <Button
                                disabled={fields.length < 2}
                                icon={<DeleteOutlined />}
                                style={{ color: "red" }}
                                onClick={() => {
                                  remove(name);
                                  handleQtyChange(childAssetCount - 1);
                                }}
                              />
                            </Col>
                          </Row>
                        </div>
                      ))}
                      {!singleAssetType && (
                        <>
                          <Row justify={"end"} style={{ marginRight: "7px" }}>
                            <Col>
                              <Button
                                type="primary"
                                onClick={() => {
                                  handleQtyChange(childAssetCount + 1);
                                }}
                                icon={<PlusOutlined />}
                              >
                                Add
                              </Button>
                            </Col>
                          </Row>
                        </>
                      )}
                    </div>
                  )}
                </Form.List>
              </Card>
            </>
          )}
        </Form>
      </Drawer>
    </>
  );
};

export default AssetInvoiceDetail;
