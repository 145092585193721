import React from "react";
import { useClient } from "../../Context/ClientContext";
import { dayjs } from "../../../Utilities/dayjs";
import type { TableColumnsType } from "antd";
import {
  Col,
  DatePicker,
  Row,
  Select,
  Skeleton,
  Table,
  Tag,
  Tooltip,
} from "antd";
import { useTheme } from "../../Context/ThemeContext";
import { Utils } from "../../../Utilities/Utils";
import VoucherServices, {
  ReconcileTypes,
  VoucherFilterStatus,
  Voucher_Type,
} from "../../Services/VoucherServices";
import ViewPaymentIn from "../Sales/ViewPaymentIn";
import {
  DeleteOutlined,
  DeleteTwoTone,
  EditOutlined,
  EditTwoTone,
  SyncOutlined,
} from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { SearchLedgerByGroupsTypes } from "../../Services/LedgerService";
import ViewSalesInvoice from "../Sales/ViewSalesInvoice";
import ViewCreditNote from "../Sales/ViewCreditNote";
import ViewJournal from "../Journals/ViewJournal";
import ViewContra from "../Banking/ViewContra";
import { voucherType } from "./VoucherDetailsByLedger";
import { viewVoucher } from "../../Services/ViewVoucher";
import ViewPreviewModel from "../Sales/ViewPreviewModel";
interface IVoucherType {
  voucherType: Voucher_Type;
}
interface RegisterDetailsData {
  voucherType: any;
  key: React.Key;
  id: string;
  number: number;
  ledgerName: string;
  date: any;
  amount: number;
  partyGSTIN: string;
  taxableAmount: number;
  igstAmount: number;
  cgstAmount: number;
  sgstAmount: number;
  voucherStatus: number;
}

export const RegisterDetail = (props: IVoucherType) => {
  const { currentClient } = useClient();
  const location = useLocation();

  const navigate = useNavigate();
  const [loading, setLoading] = React.useState<boolean>(false);
  const { themeData } = useTheme();
  const { RangePicker } = DatePicker;
  const [voucherDetailData, setVoucherDetailData] = React.useState<any>([]);
  const [width, setWidth] = React.useState<any>(window.innerWidth);
  const [id, setId] = React?.useState<any>();
  const [creditModalOpen, setCreditModalOpen] = React.useState<boolean>(false);
  const [journalModal, setJournalModal] = React.useState<boolean>(false);
  const [contraModal, setContraModal] = React.useState<boolean>(false);
  const [invoiceModel, setInvoiceModel] = React.useState<string>("");
  const [invoiceStatus, setInvoiceStatus] = React.useState<VoucherFilterStatus>(
    VoucherFilterStatus.Active
  );
  const [filterDate, setFilterDate] = React.useState<{
    from: any;
    to: any;
  }>({
    from:
      props.voucherType === 0
        ? dayjs().format("DD/MM/YYYY")
        : dayjs().startOf("month").format("DD/MM/YYYY"),
    to:
      props.voucherType === 0
        ? dayjs().format("DD/MM/YYYY")
        : dayjs().endOf("month").format("DD/MM/YYYY"),
  });
  const [searchCustomer, setSearchCustomer] = React.useState<string>("");
  const [filter, setfilter] = React.useState<any>({
    sortCol: "",
    sortDir: "",
  });
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState<number>(10);
  const [totAmount, setTotAmount] = React.useState<number>(0);
  const [totalRecords, setTotalRecords] = React.useState<number>();
  const [type, setType] = React?.useState<any>();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [paymentId, setPaymentId] = React.useState<string>("");
  const [open, setOpen] = React.useState<string>("");
  const [contraModalOpen, setContraModalOpen] = React.useState<boolean>(false);

  const [openModel, setOpenModel] = React.useState<{
    type: string;
    param: any;
  }>({
    type: "",
    param: {},
  });

  // let voucherType = [
  //   "Journal",
  //   "Sales Voucher",
  //   "Credit Note",
  //   "Purchase Voucher",
  //   "DebitNote",
  //   "Payment",
  //   "Receipt",
  //   "Contra",
  // ];

  React.useEffect(() => {
    // console.log(width);
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  React.useEffect(() => {
    if (currentClient?.id) {
      getList(
        currentClient?.id,
        props.voucherType,
        (page - 1) * pageSize,
        pageSize,
        filterDate.from,
        filterDate.to,
        filter.sortCol,
        filter.sortDir,
        // VoucherFilterStatus.Active,
        "",
        ReconcileTypes.Both,
        searchCustomer,
        invoiceStatus
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentClient?.id,
    props.voucherType,
    page,
    pageSize,
    filter.sortCol,
    filter.sortDir,
    filterDate.from,
    filterDate.to,
    invoiceStatus,
    searchCustomer,
  ]);

  const getList = async (
    clientId: string,
    Voucher_Type: Voucher_Type = 1,
    start: number,
    length: number,
    fromDate: string = "",
    toDate: string = "",
    sortCol?: string,
    sortDir?: string,
    partyId?: string,
    reconciled?: ReconcileTypes | any,
    search?: string,
    status?: VoucherFilterStatus
  ) => {
    setLoading(true);
    await VoucherServices.getVoucharList(
      clientId,
      Voucher_Type,
      start,
      length,
      fromDate,
      toDate,
      sortCol,
      sortDir,
      partyId,
      reconciled,
      search,
      status
    )
      .then((res: any) => {
        setTotAmount(res?.result?.totalAmount);
        setVoucherDetailData(
          res?.result?.voucherItems
            ?.map((el: any, index: number) => {
              // console.log("This is my data", el);
              return {
                key: index,
                id: el?.vId,
                date: dayjs(el?.date).format("DD/MM/YYYY"),
                number: el?.number,
                partyGSTIN: el?.partyGSTIN,
                voucherStatus: el?.voucherStatus,
                ledgerName: el?.pLedger[0]?.ledgerName,
                taxableAmount: Utils.getFormattedNumber(
                  el?.amountSummary?.taxableAmount
                ),
                igstAmount: Utils.getFormattedNumber(
                  el?.amountSummary?.igstAmt
                ),
                cgstAmount: Utils.getFormattedNumber(
                  el?.amountSummary?.cgstAmt
                ),
                sgstAmount: Utils.getFormattedNumber(
                  el?.amountSummary?.sgstAmt
                ),
                amount: Utils.getFormattedNumber(el?.invAmount),
                voucherType: el?.voucherType,
              };
            })
            .flat()
        );
        setTotalRecords(res?.result?.totalRecords);
        setLoading(false);
        // setVoucherDetailsData(res);
      })
      .catch((ex: any) => {
        console.error(ex.message);
        setLoading(false);
        //setVoucherDetailsData([]);
      });
  };

  const columns: TableColumnsType<RegisterDetailsData> = [
    {
      title: "Date",
      dataIndex: "date",
      //ellipsis: true,
      width: width <= 1440 ? "10%" : "9%",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
          }}
          title={false}
        >
          {record?.date}
        </Skeleton>
      ),
    },
    {
      title: "Ref No.",
      dataIndex: "number",
      align: "left",
      //ellipsis: true,
      width: width <= 1440 ? "10%" : "9%",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
          }}
          title={false}
        >
          {record?.voucherStatus !== 1 ? (
            <>
              <span
                style={{
                  color: "#395cd2",
                  cursor: "pointer",
                }}
                onClick={() =>
                  viewVoucher(
                    navigate,
                    currentClient,
                    record.id,
                    record.voucherType,
                    setId,
                    setIsOpen,
                    setPaymentId,
                    setType,
                    setInvoiceModel,
                    setCreditModalOpen,
                    setJournalModal,
                    setContraModalOpen
                  )
                }
              >
                {record?.number}
              </span>
            </>
          ) : (
            <>
              <span style={{ color: "red" }}>{record?.number}</span>
            </>
          )}
        </Skeleton>
      ),
    },
    {
      title: "Account Name",
      dataIndex: "ledgerName",
      //ellipsis: true,
      width: "25%",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
          }}
          title={false}
        >
          {record?.ledgerName}
        </Skeleton>
      ),
    },
    {
      title: "Voucher",
      dataIndex: "voucherType",
      ellipsis: true,
      width: "13%",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
          }}
          title={false}
        >
          {voucherType[record?.voucherType]}
        </Skeleton>
      ),
    },
    {
      title: "Amount (in ₹)",
      dataIndex: "amount",
      align: "right",
      //ellipsis: true,
      width: "10%",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
          }}
          title={false}
        >
          {record.voucherStatus !== 1 ? (
            <>{record?.amount}</>
          ) : (
            <>
              <span style={{ textDecoration: "line-through" }}>
                {record?.amount}
              </span>
            </>
          )}
        </Skeleton>
      ),
    },
    {
      title: "Action",
      dataIndex: "id",
      // ellipsis: true,
      width: "10%",
      align: "right",
      render: (_, record) => (
        <>
          <Skeleton
            active
            loading={loading}
            paragraph={{
              rows: 1,
              style: { display: "flex", justifyContent: "end" },
            }}
            title={false}
          >
            {record?.voucherStatus !== 1 ? (
              <>
                <Tooltip title="Edit" color="#444653" placement="top">
                  <EditOutlined
                    twoToneColor="#444653"
                    style={{
                      marginRight: "10px",
                    }}
                    onClick={() => {
                      record?.voucherType === Voucher_Type.Receipt
                        ? navigate(
                            `/accounts/clients/${currentClient?.id}/sales/editReceipt/${record?.id}`
                          )
                        : record?.voucherType === Voucher_Type.Payment
                        ? navigate(
                            `/accounts/clients/${currentClient?.id}/purchase/editPaymentOut/${record.id}`
                          )
                        : record?.voucherType === Voucher_Type.SalesVoucher
                        ? navigate(
                            `/accounts/clients/${currentClient?.id}/sales/editInvoice/${record?.id}`,
                            {
                              state: { copy: false },
                            }
                          )
                        : record?.voucherType === Voucher_Type.PurchaseVoucher
                        ? navigate(
                            `/accounts/clients/${currentClient?.id}/sales/editInvoice/${record?.id}`,
                            {
                              state: { copy: false },
                            }
                          )
                        : record?.voucherType === Voucher_Type.CreditNote
                        ? navigate(
                            `/accounts/clients/${currentClient?.id}/sales/editCreditNote/${record?.id}`
                          )
                        : record?.voucherType === Voucher_Type.DebitNote
                        ? navigate(
                            `/accounts/clients/${currentClient?.id}/sales/editCreditNote/${record?.id}`
                          )
                        : record?.voucherType === Voucher_Type.Journal
                        ? navigate(
                            `/accounts/clients/${currentClient?.id}/journals/${record?.id}`
                          )
                        : setId(record?.id);
                      setType(SearchLedgerByGroupsTypes?.All);
                      setOpenModel({
                        type: "adjustBankBalance",
                        param: {
                          id: "",
                          type: "",
                        },
                      });
                    }}
                  />
                </Tooltip>
                <Tooltip title="Delete" color="red" placement="top">
                  <DeleteTwoTone
                    className="ca-delete-btn cursor"
                    twoToneColor="#ff879d"
                  />
                </Tooltip>
              </>
            ) : (
              <>
                <Tooltip title="Revive Voucher">
                  <SyncOutlined
                    style={{ color: "green" }}
                    // className="ca-delete-btn cursor"
                    onClick={() => {
                      record?.voucherType === Voucher_Type.Receipt
                        ? navigate(
                            `/accounts/clients/${currentClient?.id}/sales/editReceipt/${record?.id}`
                          )
                        : record?.voucherType === Voucher_Type.Payment
                        ? navigate(
                            `/accounts/clients/${currentClient?.id}/purchase/editPaymentOut/${record.id}`
                          )
                        : record?.voucherType === Voucher_Type.SalesVoucher
                        ? navigate(
                            `/accounts/clients/${currentClient?.id}/sales/editInvoice/${record?.id}`,
                            {
                              state: { copy: false },
                            }
                          )
                        : record?.voucherType === Voucher_Type.PurchaseVoucher
                        ? navigate(
                            `/accounts/clients/${currentClient?.id}/sales/editInvoice/${record?.id}`,
                            {
                              state: { copy: false },
                            }
                          )
                        : record?.voucherType === Voucher_Type.CreditNote
                        ? navigate(
                            `/accounts/clients/${currentClient?.id}/sales/editCreditNote/${record?.id}`
                          )
                        : record?.voucherType === Voucher_Type.DebitNote
                        ? navigate(
                            `/accounts/clients/${currentClient?.id}/sales/editCreditNote/${record?.id}`
                          )
                        : record?.voucherType === Voucher_Type.Journal
                        ? navigate(
                            `/accounts/clients/${currentClient?.id}/journals/${record?.id}`
                          )
                        : setId(record?.id);
                      setType(SearchLedgerByGroupsTypes?.All);
                      setOpenModel({
                        type: "adjustBankBalance",
                        param: {
                          id: "",
                          type: "",
                        },
                      });
                    }}
                  />
                </Tooltip>
              </>
            )}
          </Skeleton>
        </>
      ),
    },
  ];

  if (
    props.voucherType === 1 ||
    props.voucherType === 2 ||
    props.voucherType === 3 ||
    props.voucherType === 4
  ) {
    columns.splice(columns.length - 3, 0, {
      title:
        props.voucherType === 1 || props.voucherType === 2
          ? "Buyer's GSTIN"
          : "Supplier's GSTIN",
      dataIndex: "partyGSTIN",
      align: "center",
      width: "15%",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
          }}
          title={false}
        >
          {record?.partyGSTIN}
        </Skeleton>
      ),
    });
    columns.splice(columns.length - 1, 0, {
      title: "Taxable Amount",
      dataIndex: "taxableAmount",
      align: "right",
      //ellipsis: true,
      width: "10%",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
          }}
          title={false}
        >
          {record.voucherStatus !== 1 ? (
            <>{record?.taxableAmount}</>
          ) : (
            <>
              <span style={{ textDecoration: "line-through" }}>
                {record?.taxableAmount}
              </span>
            </>
          )}
        </Skeleton>
      ),
    });
    columns.splice(columns.length - 1, 0, {
      title: "IGST Amount",
      dataIndex: "igstAmount",
      align: "right",
      //ellipsis: true,
      width: "10%",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
          }}
          title={false}
        >
          {record.voucherStatus !== 1 ? (
            <>{record?.igstAmount !== 0 ? record.igstAmount : 0}</>
          ) : (
            <>
              <span style={{ textDecoration: "line-through" }}>
                {record?.igstAmount !== 0 ? record.igstAmount : 0}
              </span>
            </>
          )}
        </Skeleton>
      ),
    });
    columns.splice(columns.length - 1, 0, {
      title: "CGST Amount",
      dataIndex: "cgstAmount",
      align: "right",
      //ellipsis: true,
      width: "10%",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
          }}
          title={false}
        >
          {record.voucherStatus !== 1 ? (
            <>{record?.cgstAmount !== 0 ? record.cgstAmount : 0}</>
          ) : (
            <>
              <span style={{ textDecoration: "line-through" }}>
                {record?.cgstAmount !== 0 ? record.cgstAmount : 0}
              </span>
            </>
          )}
        </Skeleton>
      ),
    });
    columns.splice(columns.length - 1, 0, {
      title: "SGST Amount",
      dataIndex: "sgstAmount",
      align: "right",
      //ellipsis: true,
      width: "10%",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
          }}
          title={false}
        >
          {record.voucherStatus !== 1 ? (
            <>{record?.sgstAmount !== 0 ? record.sgstAmount : 0}</>
          ) : (
            <>
              <span style={{ textDecoration: "line-through" }}>
                {record?.sgstAmount !== 0 ? record.sgstAmount : 0}
              </span>
            </>
          )}
        </Skeleton>
      ),
    });
  }

  return (
    <>
      <Row justify={"end"}>
        <Col style={{ marginRight: "10px" }}>
          <Select
            clearIcon={true}
            value={invoiceStatus}
            style={{ width: 120 }}
            allowClear
            options={[
              { value: VoucherFilterStatus.All, label: "All" },
              { value: VoucherFilterStatus.Active, label: "Active" },
              { value: VoucherFilterStatus.Deleted, label: "Deleted" },
            ]}
            onChange={(val: VoucherFilterStatus) => {
              setPage(1);
              setInvoiceStatus(val);
            }}
          />
        </Col>
        <Col>
          <RangePicker
            format="DD/MM/YYYY"
            style={{ width: "220px", border: "1px solid #f1f3ff" }}
            defaultValue={
              props.voucherType === 0
                ? [dayjs(), dayjs()]
                : [dayjs().startOf("month"), dayjs().endOf("month")]
            }
            onChange={(e: any) => {
              // console.log(e, "date");
              e === null
                ? setFilterDate({ from: "", to: "" })
                : setFilterDate({
                    ...filterDate,

                    from: dayjs(e[0]).format("DD/MM/YYYY"),
                    to: dayjs(e[1]).format("DD/MM/YYYY"),
                  });
              console.log("testDate", filterDate.from, filterDate.to);
            }}
            presets={[
              {
                label: "Today",
                value: [dayjs().add(0, "d"), dayjs()],
              },
              {
                label: "Last 7 Days",
                value: [dayjs().add(-7, "d"), dayjs()],
              },
              {
                label: "Last 15 Days",
                value: [dayjs().add(-15, "d"), dayjs()],
              },
              {
                label: "This Month",
                value: [dayjs().startOf("month"), dayjs()],
              },
              {
                label: "Last Month",
                value: [dayjs().date(0).startOf("month"), dayjs().date(0)],
              },
              {
                label: "Quarter 1",
                value: [
                  dayjs().month(3).startOf("month"),
                  dayjs().month(5).endOf("month"),
                ],
              },
              {
                label: "Quarter 2",
                value: [
                  dayjs().month(6).startOf("month"),
                  dayjs().month(8).endOf("month"),
                ],
              },
              {
                label: "Quarter 3",
                value: [
                  dayjs().month(9).startOf("month"),
                  dayjs().month(11).endOf("month"),
                ],
              },
              {
                label: "Quarter 4",
                value: [
                  dayjs().month(0).startOf("month"),
                  dayjs().month(2).endOf("month"),
                ],
              },
              {
                label: "This Year",
                //           dayjs().month(0).startOf("month"),
                // dayjs(),
                value: [dayjs().month(0).startOf("month"), dayjs()],
              },
              {
                label: "Last Year",
                value: [
                  dayjs().month(-12).startOf("month"),
                  dayjs().month(-1).endOf("month"),
                ],
              },
            ]}
          />
        </Col>
      </Row>
      <Table
        className={`Tabel-style table-${themeData?.componentSize ?? "middle"}`}
        columns={columns}
        //dataSource={voucherDetailData}
        dataSource={
          loading
            ? (Array.from({ length: 15 }, (_, index) => ({
                key: `loading-${index}`,
              })) as RegisterDetailsData[])
            : voucherDetailData
        }
        pagination={{
          total: totalRecords,
          current: page,
          pageSize: pageSize,
          showSizeChanger: true,
          onChange: (page: number, pageSize: number) => {
            setPage(page);
            setPageSize(pageSize);
          },
          pageSizeOptions: ["10", "15", "25", "50", "100"],
          showTotal: (totalRecords, page) =>
            `${page[0]}-${page[1]} of ${totalRecords} items`,
        }}
        summary={() => {
          return (
            <Table.Summary fixed>
              <Table.Summary.Row
                style={{ backgroundColor: "#f5f8ff", fontWeight: "bold" }}
              >
                <Table.Summary.Cell index={1}></Table.Summary.Cell>
                <Table.Summary.Cell index={2}></Table.Summary.Cell>
                <Table.Summary.Cell index={3}>
                  Total Vouchers' Amount
                </Table.Summary.Cell>
                {props.voucherType === 1 ||
                props.voucherType === 2 ||
                props.voucherType === 3 ||
                props.voucherType === 4 ? (
                  <>
                    <Table.Summary.Cell index={4}>{"   "}</Table.Summary.Cell>
                    <Table.Summary.Cell index={5}>{"   "}</Table.Summary.Cell>
                    <Table.Summary.Cell
                      index={6}
                      className="fw600"
                      align="right"
                    >
                      ₹ {Utils.getFormattedNumber(Math.abs(totAmount))}{" "}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={7}>{"   "}</Table.Summary.Cell>
                    <Table.Summary.Cell index={8}>{"   "}</Table.Summary.Cell>
                    <Table.Summary.Cell index={9}>{"   "}</Table.Summary.Cell>
                    <Table.Summary.Cell index={10}>{"   "}</Table.Summary.Cell>
                    <Table.Summary.Cell index={11}>{"   "}</Table.Summary.Cell>
                  </>
                ) : (
                  <>
                    <Table.Summary.Cell index={5} align="right">
                      {`₹ ${Utils.getFormattedNumber(Math.abs(totAmount))}`}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={6}>{"   "}</Table.Summary.Cell>
                  </>
                )}
              </Table.Summary.Row>
            </Table.Summary>
          );
        }}
        scroll={{ x: 1140, y: window.innerHeight - 340 }}
      />

      {isOpen && (
        <ViewPaymentIn
          open={isOpen}
          onCancel={() => setIsOpen(false)}
          paymentId={paymentId}
          type={type}
        />
      )}

      {open === "viewSalesInvoice" && (
        <ViewSalesInvoice
          open={open === "viewSalesInvoice"}
          invVoucharType={type}
          onCancel={() => setOpen("")}
          invoiceId={paymentId}
        />
      )}

      {creditModalOpen && (
        <ViewCreditNote
          open={creditModalOpen}
          onCancel={() => setCreditModalOpen(false)}
          creditNoteId={paymentId}
          voucharType={type}
        />
      )}
      {journalModal && (
        <ViewJournal
          open={journalModal}
          onCancel={() => setJournalModal(false)}
          journalId={paymentId}
          type={type}
        />
      )}
      {contraModal && (
        <ViewContra
          open={contraModal}
          onCancel={() => setContraModal(false)}
          voucherId={paymentId}
          type={type}
        />
      )}
      {invoiceModel === "viewSalesInvoice" && (
        <ViewPreviewModel
          open={invoiceModel === "viewSalesInvoice"}
          invVoucharType={type}
          onCancel={() => setInvoiceModel("")}
          voucherId={paymentId}
        />
      )}
    </>
  );
};
