import React, { useEffect, useState } from "react";
import ReportsService from "../../Services/ReportsService";
import { useClient } from "../../Context/ClientContext";
import { AccountShort, ISelectGroup } from "../../../Types/CommonTypes";
import { dayjs } from "../../../Utilities/dayjs";
import ViewSalesInvoice from "../Sales/ViewSalesInvoice";
import ViewPurchaseInvoice from "../Purchase/ViewPurchaseInvoice";
import ViewCreditNote from "../Sales/ViewCreditNote";
import ViewDebitNote from "../Purchase/ViewDebitNote";
import ViewPaymentIn from "../Sales/ViewPaymentIn";
import ViewPaymentIOut from "../Purchase/ViewPaymentOut";
import ViewJournal from "../Journals/ViewJournal";
import { Button, Col, DatePicker, Row, Select, Skeleton, Table } from "antd";
import { useTheme } from "../../Context/ThemeContext";
import { ColumnsType } from "antd/es/table";
import { Utils } from "../../../Utilities/Utils";
import {
  AccountNatures,
  TransactionTypes,
} from "../../../Types/CommonConstants";
import { DownloadOutlined, ReloadOutlined } from "@ant-design/icons";
import ViewAssetModel from "../Assets/ViewAssetModel";

const Ledger = () => {
  const { currentClient, companySettings } = useClient();
  const [accountsList, setAccountList] = useState<any[]>([]);

  useEffect(() => {
    ReportsService.getAccounts(currentClient?.id!).then((res) => {
      if (res?.result) {
        let acc: ISelectGroup[] = res?.result?.map((group: any) => ({
          label: `${group.typeName} (${group.groupName})`,
          options: group.accounts?.map((a: AccountShort) => ({
            label: a.name,
            value: a.id,
          })),
        }));
        setAccountList([...acc]);
      }
    });
  }, [currentClient?.id]);

  return (
    <div>
      <AccountDetailsList
        clientId={currentClient?.id!}
        from={dayjs(companySettings?.period?.from).format("DD/MM/YYYY")}
        to={dayjs(companySettings?.period?.to).format("DD/MM/YYYY")}
        account={{ id: 1, name: "Sales-1/1" }}
        accountList={accountsList}
      />
    </div>
  );
};
export default Ledger;

interface IAccountDetailsList {
  key?: string;
  clientId: string;
  from: any;
  to: any;
  account: { id: number | undefined; name: string };
  accountList: any[];
  items?: any;
  setItems?: any;
  setTabs?: any;
  setActive?: any;
}

interface DataType {
  date: string;
  description: string;
  transactionType: string;
  refNo: {
    id: number;
    name: string;
    type: number;
  };
  amount: number;
  balance: number;
}

export const AccountDetailsList = (props: IAccountDetailsList) => {
  const { themeData } = useTheme();
  const { RangePicker } = DatePicker;
  const { currentClient } = useClient();
  const [balance, setBalance] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [shouldRefresh, setShouldRefresh] = useState<boolean>(false);
  const [accountDetailsList, setAccountDetailsList] = useState<any[]>([]);
  const [accountId, setAccountId] = useState<number | undefined>(
    props.account?.id
  );
  const [viewModal, setViewModal] = React.useState<{
    type: TransactionTypes | undefined;
    value: string | undefined;
  }>({ type: undefined, value: undefined });
  const [filterDate, setFilterDate] = useState<{ from: string; to: string }>({
    from: props.from,
    to: props.to,
  });

  console.log("props", props);

  const transactionType = [
    "-",
    "Journal",
    "Sales",
    "Purchase",
    "Cr. Note",
    "Dr. Note",
    "Receipt",
    "Payment",
    "BadDebts",
    "Fixed Assets",
    "Depreciate",
    "DividendAccrual",
    "DividendPayment",
    "Opening",
  ];

  useEffect(() => {
    if (accountId) {
      getAccountDetList(
        props.clientId!,
        accountId,
        filterDate.from,
        filterDate.to,
        false
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId, filterDate, shouldRefresh]);

  const getAccountDetList = async (
    clientId: string,
    id: number,
    from: string,
    to: string,
    isPdf: boolean
  ) => {
    setLoading(true);
    await ReportsService.getAccountDetails(clientId, id, from, to)
      .then((res: any) => {
        if (res) {
          if (res?.items && res?.items.length > 0) {
            let bal = 0;
            let itms = res.items;
            let itemsMap =
              itms?.map((itm: any, index: number) => {
                let amount = Utils.getNumber(itm.amount);
                if (
                  itm.account?.nature === AccountNatures.Expenses ||
                  itm.account?.nature === AccountNatures.Assets
                )
                  amount = -amount;
                bal = bal + amount;
                return {
                  key: index,
                  date: itm?.date,
                  description: itm?.name,
                  transactionType: transactionType[itm?.type],
                  refNo: {
                    id: itm?.target?.id,
                    name: itm?.target?.name,
                    type: itm?.type,
                  },
                  amount: Utils.getFormattedCurrency(amount),
                  balance: Utils.getFormattedCurrency(bal),
                  account: itm?.account,
                };
              }) ?? [];

            let lastItem = itemsMap[itemsMap.length - 1];
            if (lastItem) {
              let suffix =
                lastItem.account?.nature === AccountNatures.Expenses ||
                lastItem.account?.nature === AccountNatures.Assets
                  ? bal < 0
                    ? "Cr"
                    : bal > 0
                    ? "Dr"
                    : ""
                  : bal < 0
                  ? "Dr"
                  : bal > 0
                  ? "Cr"
                  : "";
              let balance = Utils.getFormattedCurrency(Math.abs(bal));
              setBalance(`${balance} ${suffix}`);
            }
            setLoading(false);
            setAccountDetailsList(itemsMap);
          } else {
            setAccountDetailsList([]);
            setLoading(false);
          }
        } else setLoading(false);
      })
      .catch((ex: any) => {
        setLoading(false);
        console.log(ex);
      });
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Date",
      dataIndex: "date",
      width: "10%",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.date}
        </Skeleton>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.description}
        </Skeleton>
      ),
    },
    {
      title: "Transaction Type",
      dataIndex: "transactionType",
      width: "18%",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.transactionType}
        </Skeleton>
      ),
    },
    {
      title: "Ref. No.",
      dataIndex: "refNo",
      width: "14%",
      render: (x) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          <span
            style={{ color: "#1677FF" }}
            className="cursorPointer"
            onClick={() => {
              setViewModal({ type: x?.type, value: x?.id });
            }}
          >
            {x?.name}
          </span>
        </Skeleton>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      width: "18%",
      align: "right",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
            style: { display: "flex", justifyContent: "end" },
          }}
          title={false}
        >
          {record?.amount}
        </Skeleton>
      ),
    },
    {
      title: "Balance",
      dataIndex: "balance",
      width: "18%",
      align: "right",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
            style: { display: "flex", justifyContent: "end" },
          }}
          title={false}
        >
          {record?.balance}
        </Skeleton>
      ),
    },
  ];

  return (
    <div style={{ width: "80%" }} className="m-auto">
      <div className="themeColor fs-20 fw-600">{currentClient?.businessName}</div>
      <p style={{ color: "rgb(120, 120, 120)" }}>
        Ledger ({dayjs(filterDate.from, "DD/MM/YYYY").format("DD MMM YYYY")} to{" "}
        {dayjs(filterDate.to, "DD/MM/YYYY").format("DD MMM YYYY")})
      </p>
      <Row justify={"end"} gutter={[8, 0]}>
        <Col>
          <Select
            labelInValue
            showSearch
            defaultValue={{
              value: accountId,
              label: `${props.account?.name}`,
            }}
            placeholder="select account"
            popupMatchSelectWidth={false}
            dropdownStyle={{ width: "250px" }}
            style={{ width: "250px" }}
            placement="bottomRight"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={props.accountList}
            onSelect={(val) => {
              setAccountId(val.value);
              if (props.setTabs?.length > 0) {
                props.setTabs({ id: val.value, name: val.label });
                let index = props.items?.findIndex(
                  (itm: any) => itm.account.id === val.value
                );
                let newData = {
                  label: `${val.label}`,
                  key: val.label,
                  account: {
                    id: val.value,
                    name: `${val.label}`,
                  },
                };
                if (index !== -1) {
                  props.items[index] = newData;
                  props.setItems(props.items);
                } else {
                  props.items.push(newData);
                  props.setItems(props.items);
                }
                props.setActive(val.label);
              }
            }}
          />
        </Col>
        <Col>
          <RangePicker
            defaultValue={[
              dayjs(props.from, "DD/MM/YYYY"),
              dayjs(props.to, "DD/MM/YYYY"),
            ]}
            allowClear={false}
            format="DD/MM/YYYY"
            style={{ width: "220px", border: "1px solid #f1f3ff" }}
            onChange={(e: any) => {
              e === null
                ? setFilterDate({ from: "", to: "" })
                : setFilterDate({
                    ...filterDate,
                    from: dayjs(e[0]).format("DD/MM/YYYY"),
                    to: dayjs(e[1]).format("DD/MM/YYYY"),
                  });
            }}
            presets={[
              {
                label: "Today",
                value: [dayjs().add(0, "d"), dayjs()],
              },
              {
                label: "Last 7 Days",
                value: [dayjs().add(-7, "d"), dayjs()],
              },
              {
                label: "Last 15 Days",
                value: [dayjs().add(-15, "d"), dayjs()],
              },
              {
                label: "This Month",
                value: [dayjs().startOf("month"), dayjs()],
              },
              {
                label: "Last Month",
                value: [dayjs().date(0).startOf("month"), dayjs().date(0)],
              },
              {
                label: "Quarter 1",
                value: [
                  dayjs().month(3).startOf("month"),
                  dayjs().month(5).endOf("month"),
                ],
              },
              {
                label: "Quarter 2",
                value: [
                  dayjs().month(6).startOf("month"),
                  dayjs().month(8).endOf("month"),
                ],
              },
              {
                label: "Quarter 3",
                value: [
                  dayjs().month(9).startOf("month"),
                  dayjs().month(11).endOf("month"),
                ],
              },
              {
                label: "Quarter 4",
                value: [
                  dayjs().month(0).startOf("month"),
                  dayjs().month(2).endOf("month"),
                ],
              },
              {
                label: "This Year",
                value: [dayjs().month(0).startOf("month"), dayjs()],
              },
              {
                label: "Last Year",
                value: [
                  dayjs().month(-12).startOf("month"),
                  dayjs().month(-1).endOf("month"),
                ],
              },
            ]}
          />
        </Col>
        <Col>
          <Button icon={<DownloadOutlined />}></Button>
        </Col>
        <Col>
          <Button
            icon={<ReloadOutlined />}
            onClick={() => setShouldRefresh(!shouldRefresh)}
          ></Button>
        </Col>
      </Row>
      <Table
        className={`Tabel-style table-${themeData?.componentSize ?? "middle"}`}
        columns={columns}
        dataSource={
          loading
            ? (Array.from({ length: 15 }, (_, index) => ({
                key: `loading-${index}`,
              })) as IAccountDetailsList[])
            : accountDetailsList
        }
        footer={() => (
          <>
            <Row justify="space-between">
              <Col lg={5} style={{ paddingLeft: 112 }}>
                Closing Balance
              </Col>
              <Col lg={6} className="textEnd">
                {balance}
              </Col>
            </Row>
          </>
        )}
      />

      {/*---- All view models for Transactions ----*/}
      {viewModal.type === TransactionTypes.Sales && (
        <ViewSalesInvoice
          open={viewModal.type === TransactionTypes.Sales}
          onCancel={() => setViewModal({ type: undefined, value: undefined })}
          invoiceId={viewModal.value}
        />
      )}
      {viewModal.type === TransactionTypes.Purchase && (
        <ViewPurchaseInvoice
          open={viewModal.type === TransactionTypes.Purchase}
          onCancel={() => setViewModal({ type: undefined, value: undefined })}
          invoiceId={viewModal.value}
        />
      )}
      {viewModal.type === TransactionTypes.Receipt && (
        <ViewPaymentIn
          open={viewModal.type === TransactionTypes.Receipt}
          onCancel={() => setViewModal({ type: undefined, value: undefined })}
          paymentId={viewModal.value}
        />
      )}
      {viewModal.type === TransactionTypes.CrNote && (
        <ViewCreditNote
          open={viewModal.type === TransactionTypes.CrNote}
          onCancel={() => setViewModal({ type: undefined, value: undefined })}
          creditNoteId={viewModal.value}
        />
      )}
      {viewModal.type === TransactionTypes.DrNote && (
        <ViewDebitNote
          open={viewModal.type === TransactionTypes.DrNote}
          onCancel={() => setViewModal({ type: undefined, value: undefined })}
          debitNoteId={viewModal.value}
        />
      )}
      {viewModal.type === TransactionTypes.Payment && (
        <ViewPaymentIOut
          open={viewModal.type === TransactionTypes.Payment}
          onCancel={() => setViewModal({ type: undefined, value: undefined })}
          paymentId={viewModal.value}
        />
      )}
      {/* {viewModal.type === TransactionTypes.Journal && (
        <ViewJournal
          open={viewModal.type === TransactionTypes.Journal}
          onCancel={() => setViewModal({ type: undefined, value: undefined })}
          journalId={viewModal.value}
        />
      )} */}
      {viewModal.type === TransactionTypes.FixedAsset && (
        <ViewAssetModel
          isViewModelOpen={viewModal.type === TransactionTypes.FixedAsset}
          onCancel={() => setViewModal({ type: undefined, value: undefined })}
          assetId={viewModal.value}
        />
      )}
    </div>
  );
};
