import { Card, Table } from "antd";
import React from "react";
import ItemsService, { ItemType } from "../../Services/ItemsService";
import { useTheme } from "../../Context/ThemeContext";
import { useClient } from "../../Context/ClientContext";
import { ColumnsType } from "antd/es/table";

interface IItemDataType {
  id: any;
  key: React.Key;
  name: string;
  qty: number;
  tAmount: number;
}

const CategoryItems = () => {
  const { currentClient } = useClient();
  const { themeData } = useTheme();
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState<number>(50);
  const [itemsList, setItemsList] = React.useState<any[]>([]);
  const [totalRecords, setTotalRecords] = React.useState<number>(0);
  const [searchItem, setSearchItem] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);

  const [filter, setfilter] = React.useState<any>({
    sortCol: "",
    sortDir: "",
  });
  const [filterOption, setFilterOption] = React.useState<{
    gstRate: number[];
    partyType: number;
    iState: number;
  }>({
    gstRate: [3, 5, 12],
    partyType: 0,
    iState: 0,
  }); 

  React.useEffect(() => {
    // setLoading(true);
    if (currentClient?.id) {
      getGoodsItemList(
        currentClient?.id,
        (page - 1) * pageSize,
        pageSize,
        filter.sortCol,
        filter.sortDir,
        searchItem,
        undefined,
        ItemType.SalesGoods,
        // "salesProduct",
        filterOption.gstRate,
        true
      );
    } else if (currentClient?.id === null) {
      console.error("currentClient is null Items");
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // props.trigger,
    page,
    pageSize,
    searchItem,
    // location.pathname,
    filter.sortCol,
    filter.sortDir,
    filterOption.gstRate,
  ]);

  // --------- Get Items TableData API ---------
  const getGoodsItemList = async (
    clientId: string,
    start: number,
    length: number,
    sortCol?: string,
    sortDir?: string,
    search?: string,
    status?: any,
    type?: ItemType,
    gstRate?: number[],
    itemInventory?: boolean
  ) => {
    await ItemsService.getListItems(
      clientId,
      start,
      length,
      sortCol,
      sortDir,
      search,
      status,
      type,
      gstRate,
      itemInventory
    )
      .then((res: any) => {
        if (res.items.length > 0) {
          setItemsList(
            res?.items?.map((v: any, i: any) => {
              return {
                key: i,
                // id: v?._id,
                name: v?.Name,
                qty: v?.Qty,
                tAmount: v?.TAmount,
              };
            })
          );
          setTotalRecords(res?.totalRecords);
        } else if (res.items.length === 0) {
          setItemsList([]);
          setTotalRecords(res?.totalRecords);
        }
        setLoading(false);
      })
      .catch((ex: any) => {
        console.error(ex.message);
        setLoading(false);
        setItemsList([]);
      });
  };
  // --------- Goods Item Table Columns  ---------
  const itemColumns: ColumnsType<IItemDataType> = [
    {
      title: "Name",
      dataIndex: "name",
      render: (_, record) => (
        // <NavLink
        //   to={`/accounts/clients/${currentClient?.id}/sales/inventory/${record.id}`}
        // >
        <div
          //   onClick={() => {
          //     setItemId(record.id);
          //   }}
          style={{
            color: "black",
          }}
        >
          {record.name}
        </div>
        // </NavLink>
      ),
    },
    {
      title: "Qty",
      dataIndex: "qty",
      key: "qty",
      render: (_, record) => (
        <div style={{ cursor: "pointer" }}>{record.qty}</div>
      ),
    },
    {
      title: "Stock Value",
      dataIndex: "tAmount",
      key: "tAmount",
    },
  ];
  return (
    <div>
      {/* <Card className="ca-card-body"> */}
      <Table
        className={`Tabel-style-none table-${
          themeData?.componentSize ?? "middle"
        }`}
        style={{ paddingTop: "15px" }}
        // showHeader={false}
        // loading={tableLoading}
        // rowSelection={rowSelection}
        columns={itemColumns}
        dataSource={itemsList}
        pagination={false}
      />
      {/* </Card> */}
    </div>
  );
};

export default CategoryItems;
