import { Col, Divider, Modal, Row, Button, Spin } from "antd";
import React, { useContext, useEffect } from "react";
import { useClient } from "../../Context/ClientContext";
import noteContext from "../../Context/StyleContext";
import { Utils } from "../../../Utilities/Utils";
import "../../Common/AddPageCommon.css";
import "./Journals.css";
import { LoadingOutlined } from "@ant-design/icons";
import { notificationContext } from "../../Common/PageRoute";
import { dayjs } from "../../../Utilities/dayjs";
import VoucherServices, { Voucher_Type } from "../../Services/VoucherServices";
import Title from "antd/es/skeleton/Title";
import { useIndianState } from "../../Context/StateOptionsContext";
import { useLocation, useNavigate } from "react-router-dom";

const ViewJournal: React.FC<{
  open: boolean;
  onCancel: any;
  journalId: string;
  type?: any;
}> = (props) => {
  const { windowWidth } = React.useContext(noteContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { currentClient, companySettings } = useClient();
  const { openNotification } = useContext(notificationContext);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [data, setData] = React.useState<any>({});
  const [debitSubTotal, setDebitSubTotal] = React.useState<number>(0);
  const [creditSubTotal, setCreditSubTotal] = React.useState<number>(0);
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const { stateOptions } = useIndianState();

  // -------- Edit Journal Get API ---------
  // useEffect(() => {
  //   if (props.journalId) {
  //     setLoading(true);
  //     VoucherServices.getVoucharById(
  //       currentClient?.id!,
  //       props.journalId,
  //       Voucher_Type.Journal
  //     )
  //       .then((res: any) => {
  //         const jnl = res?.result?.journal;
  //         if (res?.status) {
  //           setData({
  //             date: dayjs(jnl?.date).format("DD/MM/YYYY"),
  //             number: jnl?.number,
  //             name: jnl?.name,
  //             items: jnl?.items,
  //           });
  //           setDebitSubTotal(jnl?.amount?.debit);
  //           setCreditSubTotal(jnl?.amount?.credit);
  //           setLoading(false);
  //         } else setLoading(false);
  //       })
  //       .catch((ex: any) => {
  //         openNotification("error", ex.message);
  //         setLoading(false);
  //       });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [props.journalId]);

  useEffect(() => {
    if (props.journalId) {
      setLoading(true);
      VoucherServices.getVoucharById(
        currentClient?.id!,
        props.journalId,
        props?.type
      )
        .then((res: any) => {
          console.log("viewJournal", res);
          setData({
            date: dayjs(res.result?.date).format("DD/MM/YYYY"),
            number: res.result?.number,
            narration: res.result?.narration,
            effectiveLedger: res.result?.effectiveLedger,
            partyLedger: res.result?.partyLedger,
            voucherNetAmount: res.result?.amountSummary?.totalInvoiceAmount,
            amountInWords: res.result?.amountSummary.totalInvoiceAmountInWords,
          });

          setLoading(false);
        })
        .catch((err: any) => {
          console.log("error", err);
          setLoading(false);
        });
    }
  }, [props.journalId]);

  //console.log("view", data);

  let splitPathInVchType: Voucher_Type;
  if (location.pathname.split("/")[6] === "journal") {
    splitPathInVchType = Voucher_Type.Journal;
  } else {
    // splitPathInVchType = Voucher_Type.Payment;
    splitPathInVchType = props?.type;
  }

  console.log("split", splitPathInVchType);

  return (
    <Modal
      open={props.open}
      className="modelposition"
      width={"50%"}
      style={{ maxHeight: `calc(100% - 20%)`, overflowY: "auto" }}
      maskClosable={false}
      closable={false}
      onCancel={() => props.onCancel()}
      centered
      footer={
        <div style={{ marginTop: "0px" }} className="pe-15">
          <Button
            onClick={() => {
              props.onCancel();
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={async () => {
              // props.type === Voucher_Type.Journal
              navigate(
                `/accounts/clients/${currentClient?.id}/journals/${props.journalId}`
              );
              // : navigate(
              //     `/accounts/clients/${currentClient?.id}/purchase/editInvoice/${props.journalId}`
              //   );
            }}
          >
            Edit
          </Button>
          {/* <Button type="primary">Print</Button> */}
        </div>
      }
    >
      <div style={{ padding: 15 }}>
        <Spin indicator={antIcon} spinning={loading}>
          {/* {windowWidth <= 1024 ? (
            <h3 className="fs-35 fw-500 pt-20 themeColor textCenter ">
              Purchase Invoice
            </h3>
          ) : (
            ""
          )} */}
          <Row>
            <Col span={12}>
              <>
                <div>
                  <span style={{ fontSize: "1.5em" }}>
                    {companySettings?.setting.companyInfo.name}
                  </span>
                </div>
                <div>
                  {companySettings?.setting?.companyInfo?.regAddress
                    ?.building !== null ? (
                    <>
                      <span>
                        {
                          companySettings?.setting?.companyInfo?.regAddress
                            ?.building!
                        }
                      </span>
                      {", "}
                    </>
                  ) : (
                    ""
                  )}
                  {companySettings?.setting?.companyInfo?.regAddress?.street !==
                  null ? (
                    <>
                      <span>
                        {
                          companySettings?.setting?.companyInfo?.regAddress
                            ?.street
                        }
                      </span>
                      <br />
                    </>
                  ) : (
                    ""
                  )}
                  {companySettings?.setting?.companyInfo?.regAddress
                    ?.landmark !== null ? (
                    <>
                      <span>
                        {
                          companySettings?.setting?.companyInfo?.regAddress
                            ?.landmark
                        }
                      </span>
                      <br />
                    </>
                  ) : (
                    ""
                  )}
                  {companySettings?.setting?.companyInfo?.regAddress?.city !==
                  null ? (
                    <>
                      <span>
                        {
                          companySettings?.setting?.companyInfo?.regAddress
                            ?.city
                        }
                      </span>
                      {", "}
                    </>
                  ) : (
                    ""
                  )}
                  {companySettings?.setting?.companyInfo?.regAddress
                    ?.district !== null ? (
                    <>
                      <span>
                        {
                          companySettings?.setting?.companyInfo?.regAddress
                            ?.district
                        }
                      </span>
                      <br />
                    </>
                  ) : (
                    ""
                  )}
                  {companySettings?.setting?.companyInfo?.regAddress?.state !==
                  null ? (
                    <>
                      <span>
                        <strong>State: </strong>
                        {stateOptions?.map((s: any) =>
                          s.id ===
                          companySettings?.setting?.companyInfo?.regAddress
                            ?.state
                            ? s.name
                            : ""
                        )}
                        , <strong>Code: </strong>
                        {companySettings?.setting?.companyInfo?.regAddress?.state
                          .toString()
                          .padStart(2, "0")}
                      </span>
                      <br />
                    </>
                  ) : (
                    ""
                  )}
                  {companySettings?.setting?.companyInfo?.gstNumber !== null ? (
                    <>
                      <span>
                        <strong>GSTIN: </strong>
                        {companySettings?.setting?.companyInfo?.gstNumber}
                      </span>
                      <br />
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </>
            </Col>
            <Col span={12} style={{ height: "auto" }}>
              <Row
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  height: "100%",
                }}
              >
                <div
                  className="themeColor"
                  style={{
                    fontSize: "1.5em",
                    textAlign: "right",
                  }}
                >
                  {companySettings?.setting?.journal?.title}
                </div>
                <div
                  style={{
                    marginTop: "auto",
                  }}
                >
                  <div style={{ textAlign: "end" }}>
                    <strong>Date:</strong> {data?.date}
                  </div>
                  <div style={{ textAlign: "end" }}>
                    <strong>Voucher No.:</strong> {data?.number}
                  </div>
                </div>
              </Row>
            </Col>
          </Row>
          <table
            className="ca-viewmodel-table"
            style={{ fontSize: windowWidth <= 1024 ? "10.3px" : "" }}
          >
            <thead>
              <tr>
                <th style={{ width: "30%" }} className="textStart ps-10">
                  Account
                </th>

                <th style={{ width: "10%" }} className="textEnd pe-10">
                  {`Debit (in ₹)`}
                </th>
                <th style={{ width: "10%" }} className="textEnd pe-10">
                  {`Credit (in ₹)`}
                </th>
              </tr>
            </thead>
            <tbody>
              {data.effectiveLedger?.map((itm: any, i: any) => {
                return (
                  <tr key={i}>
                    <td className="ps-10">{itm?.ledgerName}</td>

                    <td className="textEnd pe-10">
                      {itm?.amount > 0
                        ? Utils.getFormattedNumber(Math.abs(itm?.amount))
                        : "-"}
                    </td>
                    <td className="textEnd pe-10">
                      {itm?.amount < 0
                        ? Utils.getFormattedNumber(Math.abs(itm?.amount))
                        : "-"}
                    </td>
                  </tr>
                );
              })}
              {data.partyLedger?.map((itm: any, i: any) => {
                return (
                  <tr key={i}>
                    <td className="ps-10">{itm?.ledgerName}</td>

                    <td className="textEnd pe-10">
                      {itm?.amount > 0
                        ? Utils.getFormattedNumber(Math.abs(itm?.amount))
                        : "-"}
                    </td>
                    <td className="textEnd pe-10">
                      {itm?.amount < 0
                        ? Utils.getFormattedNumber(Math.abs(itm?.amount))
                        : "-"}
                    </td>
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
              <tr>
                <th className="textEnd pe-10">
                  {" "}
                  <span
                    style={{
                      color: "rgb(11, 48, 142)",
                      fontWeight: "400",
                      fontSize: "16px",
                    }}
                  >
                    Total Amount
                  </span>
                </th>
                <th className="textEnd" style={{ paddingRight: "5px" }}>
                  <span
                    style={{
                      color: "rgb(11, 48, 142)",
                      fontWeight: "400",
                      fontSize: "16px",
                    }}
                  >
                    {Utils.getFormattedNumber(data?.voucherNetAmount)}
                  </span>
                </th>
                <th className="textEnd" style={{ paddingRight: "5px" }}>
                  <span
                    style={{
                      color: "rgb(11, 48, 142)",
                      fontWeight: "400",
                      fontSize: "16px",
                    }}
                  >
                    {Utils.getFormattedNumber(data?.voucherNetAmount)}
                  </span>
                </th>
              </tr>
            </tfoot>
          </table>

          <Row className="pe-10">
            <Col lg={16} style={{ paddingRight: "10px" }}>
              <Row>
                <div>
                  {`Amount (in words): `}
                  <em>INR {data.amountInWords}</em>
                </div>
              </Row>
              <Row>
                <strong>Description-:</strong>
                <div>{data?.narration}</div>
              </Row>
            </Col>
          </Row>
          <Row style={{ marginTop: "60px" }}>
            <Col span={8}>
              <strong>Prepared by</strong>
            </Col>
            <Col span={8} style={{ textAlign: "center" }}>
              <strong>Checked by</strong>
            </Col>
            <Col span={8} style={{ textAlign: "end" }}>
              <strong>Authorized Signatory</strong>
            </Col>
          </Row>
        </Spin>
      </div>
    </Modal>
  );
};
export default ViewJournal;
