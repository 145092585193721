import { Button, Col, Modal, Row, Upload } from "antd";
import { SliderMarks } from "antd/es/slider";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ThemeContextProvider from "../Context/ThemeContext";
import ThemeSetting from "./ThemeSetting";
import { Image } from "antd";
import "./Profile.css";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { useUser } from "../../Context/UserContext";
import Profiletabs from "./Profiletabs";
import { SiMicrosoftoutlook } from "react-icons/si";
import { BiLink } from "react-icons/bi";
import { ExclamationCircleOutlined, UploadOutlined } from "@ant-design/icons";
import ProfileService from "../../Services/ProfileService";
import modal from "antd/es/modal";
interface IChangeProps {
  darkMode?: boolean;
  themeColor: string;
  fontSize: number;
  borderRadius: number;
  componentSize: "small" | "middle" | "large" | undefined;
}
const UserProfile = () => {
  const { getUser } = useUser();
  const loginUser: any = { ...getUser() };
  const navigate = useNavigate();
  const [onChange, setOnChange] = React.useState<any>({});
  const [data, setData] = React.useState<any>(onChange);
  const [loading, setLoading] = React.useState<any>(false);
  const [segementVal, setSegementVal] = React.useState<any>("1");
  React.useEffect(() => {
    if (localStorage.getItem("ci-config")) {
      let x: any = localStorage.getItem("ci-config");
      console.log("useEffect", x);
      setOnChange(JSON.parse(x));
      setData(JSON.parse(x));
      setLoading(true);
    } else {
      setOnChange({
        darkMode: false,
        themeColor: "#3d4998",
        fontSize: 14,
        borderRadius: 6,
        componentSize: "middle",
        tablePageSize: 15,
        silderSize: true,
      });
      setData({
        darkMode: false,
        themeColor: "#3d4998",
        fontSize: 14,
        borderRadius: 6,
        componentSize: "middle",
        tablePageSize: 15,
        silderSize: true,
      });
      setLoading(true);
    }
  }, []);
  const [true1, setTrue1] = React.useState<boolean>();
  const getOutlook = () => {
    window.location.href = "mailto:email@outlook.com";
  };
  const getMail = () => {
    window.location.href = "mailto:email@gmail.com";
  };

  const onImageUpload = (userId: string, file: any) => {
    const config = "multipart/form-data";
    const formData = new FormData();
    formData.append("file", file);
    Modal.confirm({
      title: "Confirmation",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure to upload profile",
      okText: "Yes",
      cancelText: "Cancel",
      onOk: async () => {
        await ProfileService.uploadProfilePicture(
          userId,
          formData,
          config
        ).then((res: any) => {
          if (res?.status) {
            console.log("Success");
          } else {
            console.log("Failed");
          }
        });
      },
    });
  };

  return (
    <>
      {loading && (
        <>
          <Row
            style={{
              padding: "20px 20px 0px 20px",
              height: `calc(100vh - 70px)`,
              overflowY: "auto",
            }}
          >
            <Col
              xl={10}
              style={{
                backgroundColor: "rgb(247, 248, 250)",
                marginTop: "-20px",
                marginLeft: "-20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "35px",
                }}
              >
                <Image
                  className="custom"
                  src="	https://www.capsitech.com/wp-content/themes/capsitech/assets/images/capitech.webp"
                />
              </div>
              <div
                style={{
                  paddingTop: "12px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Upload
                  maxCount={1}
                  onChange={(e: any) => onImageUpload(loginUser?.id, e.file)}
                  showUploadList={{ showRemoveIcon: false }}
                  beforeUpload={() => {
                    return false;
                  }}
                >
                  <Button icon={<UploadOutlined />}>Click to Upload</Button>
                </Upload>
              </div>
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: 500,
                  paddingTop: "4px",
                  color: "lightslategray",
                }}
              >
                {loginUser.email}
              </span>
              <Row
                justify={"center"}
                gutter={75}
                style={{ paddingTop: "18px" }}
              >
                <Col>
                  <Button
                    onClick={getMail}
                    icon={
                      <>
                        <BiLink />
                      </>
                    }
                  >
                    Link Gmail
                  </Button>
                </Col>
                <Col>
                  <Button
                    onClick={getOutlook}
                    icon={
                      <>
                        <SiMicrosoftoutlook />
                      </>
                    }
                  >
                    {" "}
                    Link Outlook
                  </Button>
                </Col>
              </Row>
            </Col>
            {/* mailto:example@outlook.com?subject=Subject&body=Body */}
            <Col xl={14} sm={14} xs={15}>
              <div style={{ marginBottom: "16px" }}>
                <Profiletabs true1={true1} setTrue1={setTrue1} />
              </div>
            </Col>
          </Row>
          {true1 === true &&
            (localStorage.setItem("ci-config", JSON.stringify(data)),
            navigate(-1))}

          {true1 === false && navigate(-1)}

          {data && <ThemeContextProvider data={data} />}
        </>
      )}
    </>
  );
};

export default UserProfile;
