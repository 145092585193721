import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Spin,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useClient } from "../../../Context/ClientContext";
// import { notificationContext } from "../../../Common/PageRoute";
import CashAndBankAccountServices from "../../../Services/CashAndBankAccountServices";
import DemoCheque from "./DemoCheque"; // import the DemoCheque component
import LedgerService, {
  SearchLedgerByGroupsTypes,
} from "../../../Services/LedgerService";
import { notificationContext } from "../../../Common/PageRoute";
import ImageUpload from "../../../Common/ImageUpload";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

interface AddChequeBookModalProps {
  open: boolean;
  onCancel: () => void;
  id?: string;
  ledgerId: string;
  refresh: boolean;
  setRefresh: any;
}

export interface Coordinate {
  label: string;
  key: string;
  value: string;
  x: number | null;
  y: number | null;
}

export interface Measurement {
  chequeHeight: number | null;
  chequeWidth: number | null;
  coordinates: Coordinate[];
  fields: Coordinate[];
}

interface FormValues {
  chequeBook_Name: string | null;
  fromPage: number | null;
  toPage: number | null;
  issuedNo: number | null;
  measurement: Measurement | null;
  printPortrait: boolean;
}

const AddChequeBookModal: React.FC<AddChequeBookModalProps> = (props) => {
  const { openNotification } = React.useContext(notificationContext);
  const [modelLoading, setModelLoading] = useState<boolean>(false);
  const [imageBase64, setImageBase64] = useState<string | null>(null);
  const [form] = Form.useForm<FormValues>();

  const { currentClient } = useClient();
  const chequeBookId = props?.id || null;
  const [saveButtonLoading, setSaveButtonLoading] = useState<boolean>(false);
  const [ledgerId, setLedgerId] = useState<string>(props?.ledgerId);

  // const { openNotification } = useContext(notificationContext);
  const [ledgerList, setLedgerList] = React?.useState<any>();

  const defaultMeasurementObject = {
    chequeHeight: null,
    chequeWidth: null,
    coordinates: [
      { label: "d1", key: "d1", value: "0", x: null, y: null },
      { label: "d2", key: "d2", value: "0", x: null, y: null },
      { label: "m1", key: "m1", value: "0", x: null, y: null },
      { label: "m2", key: "m2", value: "0", x: null, y: null },
      { label: "y1", key: "y1", value: "0", x: null, y: null },
      { label: "y2", key: "y2", value: "0", x: null, y: null },
      { label: "y3", key: "y3", value: "0", x: null, y: null },
      { label: "y4", key: "y4", value: "0", x: null, y: null },
    ],
    fields: [
      {
        label: "Amount",
        key: "amount",
        value: "1,00,000",
        x: null,
        y: null,
      },
      {
        label: "payeeName",
        key: "payeeName",
        value: "Payee Name",
        x: null,
        y: null,
      },
      {
        label: "amountInWords1",
        key: "amountInWords1",
        value: "One Lakh Only",
        x: null,
        y: null,
      },
      {
        label: "amountInWords2",
        key: "amountInWords2",
        value: "Second Line, if any",
        x: null,
        y: null,
      },
      {
        label: "accountPayable",
        key: "accountPayable",
        value: "A/c Payable",
        x: null,
        y: null,
      },
    ],
  };

  const initialValues: FormValues = {
    chequeBook_Name: null,
    fromPage: null,
    toPage: null,
    issuedNo: null,
    measurement: defaultMeasurementObject,
    printPortrait: true,
  };

  const handleSave = async () => {
    try {
      console.log("form", form.getFieldsValue());

      setModelLoading(true);
      setSaveButtonLoading(true);
      const values = await form.validateFields();
      let measurement;
      if (
        values?.measurement?.chequeHeight === null ||
        values?.measurement?.chequeHeight === null
      ) {
        measurement = null;
      } else {
        measurement = {
          chequeHeight: values?.measurement?.chequeHeight,
          chequeWidth: values?.measurement?.chequeWidth,
          ...values?.measurement?.coordinates.reduce((acc: any, c: any) => {
            if (c.x !== null && c.y !== null) {
              acc[c.key] = { x: c.x, y: c.y };
            } else {
              acc[c.key] = null;
            }
            return acc;
          }, {}),
          ...values?.measurement?.fields.reduce((acc: any, f: any) => {
            if (f.x !== null && f.y !== null) {
              acc[f.key] = { x: f.x, y: f.y };
            } else {
              acc[f.key] = null;
            }
            return acc;
          }, {}),
        };
      }
      const reqData = {
        chequeBook_Name: values?.chequeBook_Name,
        fromPage: values?.fromPage,
        toPage: values?.toPage,
        issuedNo: values?.issuedNo,
        measurement: measurement,
        chequeImage:imageBase64,
      };

      console.log("reqData", reqData);

      const res = await CashAndBankAccountServices.addChequeBook(
        currentClient?.id,
        ledgerId,
        reqData,
        chequeBookId
      );
      if (res.status) {
        form.resetFields();
        props.onCancel();
        props.setRefresh(!props.refresh);
      } else {
        openNotification("error", res.message);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setModelLoading(false);
      setSaveButtonLoading(false);
    }
  };

  const [measurement, setMeasurement] = useState<any>(false);
  const [refresh, setRefresh] = useState<number>(0);

  useEffect(() => {
    setMeasurement(form.getFieldValue("measurement"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  const setCoordinatesYAxis = (yAxis: number | null) => {
    const coordinatesYAxis = [
      ...form.getFieldValue(["measurement", "coordinates"]),
    ];
    const newCoordinatesYAxis = coordinatesYAxis.map((coord) => ({
      ...coord, // Spread the existing coordinate object
      y: yAxis, // Update only the y value
    }));

    form.setFieldValue(["measurement", "coordinates"], newCoordinatesYAxis);
  };

  React.useEffect(() => {
    const getLedgerList = async () => {
      try {
        setModelLoading(true);
        let res = await LedgerService?.getLedgersByGroupName(
          currentClient?.id,
          SearchLedgerByGroupsTypes?.BankOnly
        );
        if (res) {
          setLedgerList(res);
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setModelLoading(false);
      }
    };
    getLedgerList();
  }, [currentClient?.id]);

  const getchequeBookData = async (chequeBookId: string) => {
    try {
      setModelLoading(true);

      if (chequeBookId) {
        const chequeBookResponse =
          await CashAndBankAccountServices.getChequeBook(
            currentClient?.id,
            chequeBookId
          );

        if (chequeBookResponse?.status && chequeBookResponse?.result) {
          const { chequeBook_Name, fromPage, toPage, issuedNo, measurement } =
            chequeBookResponse.result;

          let formattedMeasurement = {
            chequeHeight: measurement?.chequeHeight ?? null,
            chequeWidth: measurement?.chequeWidth ?? null,
            coordinates: [
              {
                label: "d1",
                key: "d1",
                value: measurement?.d1?.value ?? "0",
                x: measurement?.d1?.x ?? null,
                y: measurement?.d1?.y ?? null,
              },
              {
                label: "d2",
                key: "d2",
                value: measurement?.d2?.value ?? "0",
                x: measurement?.d2?.x ?? null,
                y: measurement?.d2?.y ?? null,
              },
              {
                label: "m1",
                key: "m1",
                value: measurement?.m1?.value ?? "0",
                x: measurement?.m1?.x ?? null,
                y: measurement?.m1?.y ?? null,
              },
              {
                label: "m2",
                key: "m2",
                value: measurement?.m2?.value ?? "0",
                x: measurement?.m2?.x ?? null,
                y: measurement?.m2?.y ?? null,
              },
              {
                label: "y1",
                key: "y1",
                value: measurement?.y1?.value ?? "0",
                x: measurement?.y1?.x ?? null,
                y: measurement?.y1?.y ?? null,
              },
              {
                label: "y2",
                key: "y2",
                value: measurement?.y2?.value ?? "0",
                x: measurement?.y2?.x ?? null,
                y: measurement?.y2?.y ?? null,
              },
              {
                label: "y3",
                key: "y3",
                value: measurement?.y3?.value ?? "0",
                x: measurement?.y3?.x ?? null,
                y: measurement?.y3?.y ?? null,
              },
              {
                label: "y4",
                key: "y4",
                value: measurement?.y4?.value ?? "0",
                x: measurement?.y4?.x ?? null,
                y: measurement?.y4?.y ?? null,
              },
            ],
            fields: [
              {
                label: "Amount",
                key: "amount",
                value: measurement?.amount?.value ?? "1,00,000",
                x: measurement?.amount?.x ?? null,
                y: measurement?.amount?.y ?? null,
              },
              {
                label: "payeeName",
                key: "payeeName",
                value: measurement?.payeeName?.value ?? "Payee Name",
                x: measurement?.payeeName?.x ?? null,
                y: measurement?.payeeName?.y ?? null,
              },
              {
                label: "amountInWords1",
                key: "amountInWords1",
                value: measurement?.amountInWords1?.value ?? "One Lakh Only",
                x: measurement?.amountInWords1?.x ?? null,
                y: measurement?.amountInWords1?.y ?? null,
              },
              {
                label: "amountInWords2",
                key: "amountInWords2",
                value:
                  measurement?.amountInWords2?.value ?? "Second Line, if any",
                x: measurement?.amountInWords2?.x ?? null,
                y: measurement?.amountInWords2?.y ?? null,
              },
              {
                label: "accountPayable",
                key: "accountPayable",
                value: measurement?.accountPayable?.value ?? "A/c Payable",
                x: measurement?.accountPayable?.x ?? null,
                y: measurement?.accountPayable?.y ?? null,
              },
            ],
          };

          const formData = {
            chequeBook_Name,
            fromPage,
            toPage,
            issuedNo,
            measurement: formattedMeasurement,
          };

          form.setFieldsValue(formData);
          setMeasurement(formattedMeasurement);
          setImageBase64(chequeBookResponse?.result?.chequeImage);

        }
      }
    } catch (error) {
      console.log("Error fetching cheque book data:", error);
    } finally {
      setModelLoading(false);
    }
  };

  React.useEffect(() => {
    chequeBookId && getchequeBookData(chequeBookId);
  }, [chequeBookId]);

  return (
    <div>
      <Modal
        open={props.open}
        title={props?.id ? "Update Cheque Book" : "Add Cheque Book"}
        width={1350}
        onCancel={() => props.onCancel()}
        style={{ maxHeight: `calc(100% - 30px)`, overflowY: "auto", top: 20 }}
        footer={
          <div style={{ marginTop: "0px" }}>
            <Button
              onClick={() => {
                form.resetFields();
                props.onCancel();
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={handleSave}
              loading={saveButtonLoading}
            >
              {!props?.id ? "Save" : "Update"}
            </Button>
          </div>
        }
      >
        <Spin indicator={antIcon} spinning={modelLoading}>
          <Form
            form={form}
            name="item"
            autoCapitalize="false"
            requiredMark={false}
            initialValues={initialValues}
            colon={false}
            labelAlign="left"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 15 }}
            style={{ marginTop: "15px" }}
          >
            <div style={{ justifyContent: "space-between" }}>
              <Row>
                <Col span={8}>
                  <Divider
                    className="ca-model-css"
                    orientation="left"
                    orientationMargin="0"
                  >
                    <h4 style={{ fontSize: "14px", fontWeight: "600" }}>
                      Cheque Book Details
                    </h4>
                  </Divider>
                  <Row style={{ paddingLeft: "5px" }}>
                    <Col span={24}>
                      <Form.Item
                        className="inputBoxMb"
                        label="Cheque Book Name"
                        name="chequeBook_Name"
                        // wrapperCol={{ span: 22 }}
                        rules={[
                          {
                            required: true,
                            message: "Cheque Book Name is required",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    {/* <Col sm={12} xs={24}>
                  <Form.Item
                    label="Issued No"
                    name="issuedNo"
                    rules={[
                      { required: true, message: "Issued No is required" },
                    ]}
                  >
                    <InputNumber min={0} />
                  </Form.Item>
                  </Col> */}
                  </Row>

                  <Row style={{ paddingLeft: "5px" }} justify={"space-between"}>
                    <Col span={13}>
                      <Form.Item
                        className="inputBoxMb"
                        label="From Page"
                        name="fromPage"
                        rules={[
                          { required: true, message: "From Page is required" },
                        ]}
                        labelCol={{ span: "15" }}
                      >
                        <InputNumber min={0} />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item
                        className="inputBoxMb"
                        label="To Page"
                        name="toPage"
                        rules={[
                          { required: true, message: "To Page is required" },
                        ]}
                        labelCol={{ span: "10" }}
                      >
                        <InputNumber min={0} />
                      </Form.Item>
                    </Col>
                    <Col span={15}>
                      <Form.Item
                        className="inputBoxMb"
                        label="Print Type"
                        name="printPortrait"
                        rules={[
                          { required: true, message: "To Page is required" },
                        ]}
                        labelCol={{ span: "13" }}
                      >
                        <Select
                          options={[
                            {
                              value: true,
                              label: "Portrait",
                            },
                            {
                              value: false,
                              label: "Landscape",
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row style={{ paddingLeft: "5px" }}>
                    <Col span={8}>
                      <span>Selected Bank</span>
                    </Col>
                    <Col>
                      <Select
                        showSearch
                        optionFilterProp="label"
                        style={{ marginBottom: "3px", width: "200px" }}
                        placeholder="Select Ledger"
                        labelInValue
                        value={ledgerId}
                        options={ledgerList?.map((l: any) => ({
                          key: l?.key,
                          value: l?.id,
                          label: l?.ledgerName,
                        }))}
                        onChange={(e: any) => setLedgerId(e?.value)}
                      />
                    </Col>
                  </Row>

                  <Divider
                    orientation="left"
                    orientationMargin="0"
                    className="ca-model-css"
                  >
                    <h4 style={{ fontSize: "14px", fontWeight: "600" }}>
                      Measurements (In mm)
                    </h4>
                  </Divider>

                  <Row style={{ paddingLeft: "5px" }} gutter={12}>
                    <Col span={13} style={{ textAlign: "center" }}>
                      <Form.Item
                        className="inputBoxMb"
                        label="Cheque Height"
                        name={["measurement", "chequeHeight"]}
                        // wrapperCol={{ span: 17 }}
                        labelCol={{ span: 14 }}
                        // wrapperCol={{ span: 14 }}
                        rules={[
                          {
                            validator: (_, value) =>
                              value <= 100
                                ? Promise.resolve()
                                : Promise.reject(
                                    "Height should not be more than 100"
                                  ),
                          },
                        ]}
                      >
                        <InputNumber
                          style={{ marginLeft: "5px" }}
                          min={0}
                          max={120}
                          onChange={() =>
                            setRefresh((prev: number) => prev + 1)
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={11}>
                      <Form.Item
                        className="inputBoxMb"
                        label="Cheque Width"
                        name={["measurement", "chequeWidth"]}
                        labelCol={{ span: 12 }}
                        rules={[
                          {
                            validator: (_, value) =>
                              value <= 220
                                ? Promise.resolve()
                                : Promise.reject(
                                    "Width should not be more than 220"
                                  ),
                          },
                        ]}
                      >
                        <InputNumber
                          min={0}
                          max={230}
                          onChange={() =>
                            setRefresh((prev: number) => prev + 1)
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Divider
                    orientation="left"
                    orientationMargin="0"
                    className="ca-model-css"
                  >
                    <h4 style={{ fontSize: "14px", fontWeight: "600" }}>
                      Coordinates (In mm)
                    </h4>
                  </Divider>

                  <Form.List name={["measurement", "coordinates"]}>
                    {(fields) => (
                      <Row>
                        {fields.map((field) => (
                          <Col key={field.key} span={12}>
                            <Form.Item
                              style={{ paddingLeft: "6px" }}
                              labelCol={{ span: 4 }}
                              label={initialValues?.measurement?.coordinates[
                                field.name
                              ].label.toUpperCase()}
                              className="inputBoxMb"
                            >
                              <Space.Compact>
                                <Form.Item
                                  className="inputBoxMb"
                                  {...field}
                                  key={field.key + "1"}
                                  name={[field.key, "x"]}
                                  // fieldKey={[field.fieldKey, "x"]}
                                  noStyle
                                >
                                  <InputNumber
                                    style={{ width: "50%" }}
                                    placeholder="X"
                                    // min={0}
                                    onChange={() =>
                                      setRefresh((prev: number) => prev + 1)
                                    }
                                  />
                                </Form.Item>
                                <Form.Item
                                  className="inputBoxMb"
                                  {...field}
                                  key={field.key + "2"}
                                  name={[field.name, "y"]}
                                  // fieldKey={[field.fieldKey, "y"]}
                                  noStyle
                                >
                                  <InputNumber
                                    style={{ width: "50%" }}
                                    placeholder="Y"
                                    min={0}
                                    onChange={(e) => {
                                      setRefresh((prev: number) => prev + 1);
                                      setCoordinatesYAxis(e);
                                    }}
                                  />
                                </Form.Item>
                              </Space.Compact>
                            </Form.Item>
                          </Col>
                        ))}
                      </Row>
                    )}
                  </Form.List>
                </Col>
                <Col span={16}>
                  <Divider
                    orientation="left"
                    orientationMargin="0"
                    className="ca-model-css"
                  >
                    <h4 style={{ fontSize: "14px", fontWeight: "600" }}>
                      Cheque Preview
                    </h4>
                  </Divider>
                  <Row>
                    <Col span={3} offset={21}>
                      <ImageUpload
                        imageBase64={imageBase64}
                        setImageBase64={setImageBase64}
                      />
                    </Col>
                    <Col span={24}>
                      <DemoCheque
                        imageBase64={imageBase64}
                        measurement={measurement}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Divider
                orientation="left"
                orientationMargin="0"
                className="ca-model-css"
              >
                <h4 style={{ fontSize: "14px", fontWeight: "600" }}>
                  Fields (In mm)
                </h4>
              </Divider>

              <Form.List name={["measurement", "fields"]}>
                {(fields) => (
                  <Row gutter={16} style={{ paddingLeft: "6px" }}>
                    {fields.map((field) => (
                      <Col span={9} key={field.key}>
                        <Form.Item
                          className="inputBoxMb"
                          label={
                            initialValues?.measurement?.fields[field.name].label
                          }
                        >
                          <Space.Compact>
                            <Form.Item
                              className="inputBoxMb"
                              {...field}
                              name={[field.key, "x"]}
                              key={field.key + "3"}
                              // fieldKey={[field.fieldKey, "x"]}
                              noStyle
                              // rules={[
                              //   {
                              //     required: true,
                              //     message: `${
                              //       initialValues.measurement.fields[field.name]
                              //         .label
                              //     } X is required`,
                              //   },
                              // ]}
                            >
                              <InputNumber
                                style={{ width: "50%" }}
                                placeholder="X"
                                // min={0}
                                onChange={() =>
                                  setRefresh((prev: number) => prev + 1)
                                }
                              />
                            </Form.Item>
                            <Form.Item
                              className="inputBoxMb"
                              {...field}
                              key={field.key + "4"}
                              name={[field.name, "y"]}
                              // fieldKey={[field.fieldKey, "y"]}
                              noStyle
                              // rules={[
                              //   {
                              //     required: true,
                              //     message: `${
                              //       initialValues.measurement.fields[field.name]
                              //         .label
                              //     } Y is required`,
                              //   },
                              // ]}
                            >
                              <InputNumber
                                style={{ width: "50%" }}
                                placeholder="Y"
                                min={0}
                                onChange={() =>
                                  setRefresh((prev: number) => prev + 1)
                                }
                              />
                            </Form.Item>
                          </Space.Compact>
                        </Form.Item>
                      </Col>
                    ))}
                  </Row>
                )}
              </Form.List>
            </div>
          </Form>

          <Divider style={{ margin: "0px" }} />
        </Spin>
      </Modal>
    </div>
  );
};

export default AddChequeBookModal;
