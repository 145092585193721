import { ApiUtility } from "../../Utilities/ApiUtility.axios";
import { ReconcileTypes, VoucherFilterStatus, Voucher_Type } from "./VoucherServices";

class QuotationServices {
  route = "v1/API/Acc_New_Quotation";

  // https://localhost:7142/v1/API/Acc_New_Quotation/6646e10e5038e585a6b5e816/GetSalePurOrdersByPartyId?voucherType=1&partyId=6646fd205429e58bdc5f2703
  getOrderNumber = (
    companyId: string,
    voucherType: Voucher_Type,
    partyId: string
  ) =>
    ApiUtility.get(`${this.route}/${companyId}/GetSalePurOrdersByPartyId`, {
      voucherType,
      partyId,
    });

  // https://localhost:7142/v1/API/Acc_New_Voucher/65cdd05235241f48a55de738/Quotation?voucherType=11
  // https: //localhost:7142/v1/API/Acc_New_Voucher/65cdd05235241f48a55de738/Quotation/65cdd05235241f48a55de738?voucherType=10
  postQuotation = (
    companyId: string,
    voucherType: Voucher_Type,
    params: any,
    id?: string
  ) =>
    ApiUtility.post(
      `${this.route}/${companyId}/${id ? id : ""}?voucherType=${voucherType}`,
      params
    );

  // https: //localhost:7142/v1/API/Acc_New_Voucher/65cdd05235241f48a55de738/Quotation/65cdd05235241f48a55de738?voucherType=11
  getQuotationId = (
    companyId: string,
    voucharId: string,
    voucherType?: Voucher_Type
  ) =>
    ApiUtility.get(`${this.route}/${companyId}/${voucharId}`, {
      voucherType,
    });

  // https: //localhost:7142/v1/API/Acc_New_Voucher/65cdd05235241f48a55de738/Quotation/GetQuotationList?voucherType=11&start=0&length=15
  getQuotationList = (
    companyId: string,
    voucherType: Voucher_Type | any,
    start: number,
    length: number,
    search?: string,
    from?: string,
    to?: string,
    sortCol?: string,
    sortDir?: string,
    partyId?: string,
    status?: string
  ) =>
    ApiUtility.get(`${this.route}/${companyId}/GetQuotationList`, {
      voucherType,
      start,
      length,
      search,
      from,
      to,
      sortCol,
      sortDir,
      partyId,
      status,
    });

  // Download/CSV

  exportCSVQuotation = (
    companyId: any,
    voucherType: Voucher_Type | any,
    start: number,
    length: number,
    from?: string,
    to?: string,
    sortCol?: string,
    sortDir?: string,
    partyId?: string,
    reconciled?: ReconcileTypes | any,
    search?: string,
    status?: VoucherFilterStatus
  ) =>
    ApiUtility.downloadFile(`${this.route}/${companyId}/Download/CSV`, {
      voucherType,
      start,
      length,
      from,
      to,
      sortCol,
      sortDir,
      partyId,
      reconciled,
      search,
      status,
    });

  deleteData = (companyId: string | undefined, voucharId: string) =>
    ApiUtility?.delete(`${this.route}/${companyId}/${voucharId}`);
}
export default new QuotationServices();
