import {
  DownCircleFilled,
  DownloadOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Popover,
  Row,
  Select,
  Skeleton,
  Space,
  Spin,
} from "antd";
import React from "react";
import { dayjs } from "../../../Utilities/dayjs";
import { useClient } from "../../Context/ClientContext";
import ReportsServiceNew from "../../Services/ReportsServiceNew";
import Table, { ColumnsType } from "antd/es/table";
import LedgerService, {
  SearchLedgerByGroupsTypes,
} from "../../Services/LedgerService";
import ChartofAccountServicesNew from "../../Services/ChartofAccountServicesNew";
import {
  getBusinessTypeName,
  getIndianStates,
} from "../../Services/ClientService";
const { RangePicker } = DatePicker;

const PartyOutstanding = ({ Type }: any) => {
  const { currentClient, companySettings } = useClient();
  const [data, setData] = React?.useState<any[]>();
  const [searchLedger, setSearchLedger] = React?.useState<any>();
  const [groupData, setGroupData] = React.useState<any[]>([]);
  const [groupId, setGroupId] = React?.useState<any>();
  const [loading, setLoading] = React?.useState<any>(false);

  const [ledgers, setLedgers] = React?.useState<any>();

  const [filterDate, setFilterDate] = React.useState<{
    from: string;
    to: string;
  }>({
    from: dayjs(companySettings?.period?.from).format("DD/MM/YYYY"),
    // to: dayjs(companySettings?.period?.to).format("DD/MM/YYYY"),
    to: dayjs().format("DD/MM/YYYY"),
  });
  const [selectedColumns, setSelectedColumns] = React?.useState([
    "ledgerName",
    "pan",
    "state",
    "gstin",
    "email",
    "phone",
    "businessType",
    "gstType",
    "dueAmount",
    "overDueAmount",
  ]);

  const allColumns = [
    { title: "Ledger Name", dataIndex: "ledgerName", key: "ledgerName" },
    { title: "PAN", dataIndex: "pan", key: "pan" },
    { title: "Email", dataIndex: "email", key: "email" },
    { title: "Mobile No", dataIndex: "phone", key: "phone" },
    { title: "Balance", dataIndex: "balance", key: "balance" },
    { title: "State", dataIndex: "state", key: "state" },
    { title: "GSTIN", dataIndex: "gstin", key: "gstin" },
    { title: "Business Type", dataIndex: "businessType", key: "businessType" },
    { title: "GST Type", dataIndex: "gstType", key: "gstType" },
    { title: "Credit Period", dataIndex: "creditPeriod", key: "creditPeriod" },
    { title: "Credit Limit", dataIndex: "creditLimit", key: "creditLimit" },
    { title: "CC To", dataIndex: "ccTo", key: "ccTo" },
    {
      title: "Overdue Amount",
      dataIndex: "overDueAmount",
      key: "overDueAmount",
    },
    { title: "Due Amount", dataIndex: "dueAmount", key: "dueAmount" },
    { title: "Advance", dataIndex: "advance", key: "advance" },
  ];
  React?.useEffect(() => {
    getPartOutStandingData();
  }, [
    currentClient?.id,
    filterDate?.from,
    filterDate?.to,
    searchLedger?.value,
    Type,
  ]);

  const getPartOutStandingData = async () => {
    setLoading(true);
    let res = await ReportsServiceNew?.getPartyOutstandingReports(
      currentClient?.id,
      filterDate?.from,
      filterDate?.to,
      searchLedger?.value ? searchLedger?.value : "",
      Type
    );
    if (res) {
      setLoading(false);
      setData(
        res?.result?.map((d: any, index: number) => {
          return {
            ledgerName: d?.ledgerName,
            pan: d?.pan,
            state: getIndianStates(d?.state),
            gstin: d?.gstin,
            businessType: getBusinessTypeName(d?.businessType),
            gstType: d?.gstType,
            creditPeriod: d?.creditPeriod,
            dueAmount: d?.dueAmount,
            overDueAmount: d?.overDueAmount,
            balance: d?.balance,
            creditLimit: d?.creditLimit,
            ccTo: d?.contactInformation[0]?.ccTo,
            email: d?.contactInformation[0]?.email,
            phone: d?.contactInformation[0]?.phone,
          };
        })
      );
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: "Ledger Name",
      dataIndex: "ledgerName",
    },
    {
      title: "Business Type",
      dataIndex: "businessType",
    },
    {
      title: "State",
      dataIndex: "state",
    },
    {
      title: "GSTIN",
      dataIndex: "gstin",
    },
    {
      title: "PAN",
      dataIndex: "pan",
    },
    {
      title: "Mobile No.",
      dataIndex: "phone",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "CC To",
      dataIndex: "ccTo",
    },
    {
      title: "Due Amount",
      dataIndex: "dueAmount",
    },
    {
      title: "Overdue Amount",
      dataIndex: "overDueAmount",
    },
    {
      title: "Balance",
      dataIndex: "balance",
    },
    {
      title: "Credit Limit",
      dataIndex: "creditLimit",
    },
    {
      title: "Credit Period",
      dataIndex: "creditPeriod",
    },
    {
      title: "GST type",
      dataIndex: "gstType",
    },
    {
      title: "Advance",
      dataIndex: "advance",
    },
  ];

  const handleCheckboxChange = (value: any) => {
    setSelectedColumns((prev) =>
      prev.includes(value)
        ? prev.filter((col) => col !== value)
        : [...prev, value]
    );
  };

  const columnOptions = allColumns.map((col) => ({
    label: col.title,
    value: col.key,
  }));

  const content = (
    <Space direction="vertical">
      {columnOptions.map((option) => (
        <Checkbox
          key={option.value}
          checked={selectedColumns.includes(option.value)}
          onChange={() => handleCheckboxChange(option.value)}
        >
          {option.label}
        </Checkbox>
      ))}
    </Space>
  );

  React?.useEffect(() => {
    LedgerService.getLedgersByGroupName(
      currentClient?.id!,
      SearchLedgerByGroupsTypes?.PartiesOnly
    ).then((res: any) => {
      setLedgers(res);
    });
  }, [currentClient?.id]);

  React.useEffect(() => {
    // setLoading(true);
    const getListData = async () => {
      await ChartofAccountServicesNew.getGroupList(currentClient?.id!)
        .then((res: any) => {
          if (res.items) {
            setGroupData(res.items);
          } else {
            setGroupData([]);
            console.log("Error", res);
          }
        })
        .catch((err: any) => {
          console.log("Error", err);
        });
      //   setLoading(false);
    };
    getListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Spin
        spinning={loading}
        indicator={<LoadingOutlined />}
        style={{
          justifyContent: "center",
          marginTop: "180px",
          textAlign: "center",
        }}
      >
        <Card
          // className="ca-card-body"
          style={{
            boxShadow: "0px 0px 10px 0px #96A1C84D",
            height: "90vh",
            padding: "0px",
          }}
        >
          <div>
            <Row>
              <Col>
                {Type === 0 ? (
                  <>
                    <h1
                      style={{
                        color: "rgb(22, 119, 255)",
                        margin: "0px",
                        fontSize: "22px",
                        fontWeight: "500",
                      }}
                    >
                      Customer Outstanding
                    </h1>
                  </>
                ) : (
                  <>
                    <h1
                      style={{
                        color: "rgb(22, 119, 255)",
                        margin: "0px",
                        fontSize: "26px",
                        fontWeight: "500",
                      }}
                    >
                      Supplier Outstanding
                    </h1>
                  </>
                )}
              </Col>
            </Row>
            <Row justify={"space-between"} style={{ marginTop: "7px" }}>
              <Col>
                <Row>
                  <Col style={{ marginRight: "10px" }}>
                    <Select
                      showSearch
                      allowClear
                      optionFilterProp="label"
                      style={{ marginBottom: "3px", width: "200px" }}
                      placeholder="Select Ledger"
                      labelInValue
                      onChange={(val: any) => {
                        setSearchLedger(val);
                      }}
                      options={ledgers?.map((l: any) => ({
                        key: l?.key,
                        value: l?.id,
                        label: l?.ledgerName,
                      }))}
                    />{" "}
                  </Col>
                  {/* <Col>
                  <Select
                    style={{ width: "200px" }}
                    showSearch
                    onChange={(val: any) => setGroupId(val)}
                    placeholder="Select Group"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={groupData?.map((value: any, index: number) => ({
                      key: index + value?.id,
                      value: value?.id,
                      label: value?.group_Name,
                    }))}
                  />{" "}
                </Col> */}
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col>
                    <span>Colums </span>
                    <Popover content={content} trigger="click">
                      <Button
                        size="small"
                        type="primary"
                        icon={<PlusOutlined />}
                        style={{
                          paddingTop: "1px",
                          marginTop: "3px",
                        }}
                      />
                    </Popover>
                  </Col>
                  <Col>
                    <span style={{ margin: "0px 10px" }}>Date</span>
                    <RangePicker
                      defaultValue={[
                        dayjs(filterDate.from, "DD/MM/YYYY"),
                        dayjs(),
                        // dayjs(filterDate.to, "DD/MM/YYYY"),
                      ]}
                      allowClear
                      format="DD/MM/YYYY"
                      style={{ width: "220px", border: "1px solid #f1f3ff" }}
                      onChange={(e: any) => {
                        e === null
                          ? setFilterDate({ from: "", to: "" })
                          : setFilterDate({
                              ...filterDate,
                              from: dayjs(e[0]).format("DD/MM/YYYY"),
                              to: dayjs(e[1]).format("DD/MM/YYYY"),
                            });
                        //console.log(e, "dateCheck");
                      }}
                    />
                  </Col>
                  <Col>
                    <Button
                      type="default"
                      icon={<DownloadOutlined />}
                      style={{ margin: "0px 10px 0px 10px" }}
                    >
                      Export
                    </Button>
                  </Col>
                  <Col>
                    <DownloadOutlined
                      style={{ color: "rgb(22, 119, 255)", paddingTop: "9px" }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <div style={{ marginTop: "20px" }}>
              <Table
                dataSource={data}
                columns={columns.filter((cl: any) =>
                  selectedColumns.includes(cl?.dataIndex)
                )}
                size="small"
              />
            </div>
          </div>
        </Card>
      </Spin>
    </div>
  );
};

export default PartyOutstanding;
