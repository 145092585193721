import { Col, Divider, Modal, Row, Button, Spin } from "antd";
import React, { useContext, useEffect } from "react";
import SalesService, { PaymentMethodNames } from "../../Services/SalesService";
import { useClient } from "../../Context/ClientContext";
import noteContext from "../../Context/StyleContext";
import "../../Common/AddPageCommon.css";
import { LoadingOutlined } from "@ant-design/icons";
import { notificationContext } from "../../Common/PageRoute";
import { dayjs } from "../../../Utilities/dayjs";
import ViewSalesInvoice from "./ViewSalesInvoice";
import { NavLink, useLocation } from "react-router-dom";
import VoucherServices, {
  Party_Tax,
  Voucher_Type,
} from "../../Services/VoucherServices";
import { Utils } from "../../../Utilities/Utils";
import { useIndianState } from "../../Context/StateOptionsContext";
import { useNavigate } from "react-router";

const ViewPaymentIn: React.FC<{
  open: boolean;
  onCancel: any;
  paymentId: string | undefined;
  type?: any;
}> = (props) => {
  console.log(props);
  const { windowWidth } = React.useContext(noteContext);
  const { currentClient, companySettings } = useClient();
  const location = useLocation();
  const navigate = useNavigate();
  const { openNotification } = useContext(notificationContext);
  const [itemLength, setItemLength] = React.useState<number>(0);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [data, setData] = React.useState<any>({});
  const { stateOptions } = useIndianState();
  const [openModel, setOpenModel] = React.useState<{
    type: string;
    id: string;
  }>({
    type: "",
    id: "",
  });
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  let splitPathInVchType: Voucher_Type;
  if (location.pathname.split("/")[5] === "receipts") {
    splitPathInVchType = Voucher_Type.Receipt;
  } else {
    // splitPathInVchType = Voucher_Type.Payment;
    splitPathInVchType = props?.type;
  }

  useEffect(() => {
    if (props.paymentId) {
      setLoading(true);
      VoucherServices.getVoucharById(
        currentClient?.id!,
        props.paymentId,
        splitPathInVchType
      )
        .then((res: any) => {
          setLoading(false);
          console.log("paymentInData", res);

          setData({
            date: dayjs(res.result?.date).format("DD-MM-YYYY"),
            number: res.result?.number,
            narration: res.result?.narration,
            effectiveLedger: res.result?.effectiveLedger[0]?.ledgerName,
            effectiveLedgerId: res.result?.effectiveLedger[0]?.id,
            partyLedger: res.result?.partyLedger,
            voucherNetAmount: res.result?.amountSummary?.totalInvoiceAmount,
            amountInWords: res.result?.amountSummary.totalInvoiceAmountInWords,
            //refValue: res.result?.partyLedger?.[0]?.ref[0]?.value,
            //refName: res.result?.partyLedger?.[0]?.ref[0]?.name,
            // refValue: res.result?.partyLedger?.ref?.map(
            //   (el: any, indx: any) => {
            //     return el?.name, el?.amount;
            //   }
            // ),
          });
        })
        .catch((ex: any) => {
          openNotification("error", ex.message);
          setLoading(true);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient?.id, props.paymentId]);

  console.log("refDetails", data?.ref);
  console.log("refDetailsSSSSSS", data);

  return (
    <React.Fragment>
      <Modal
        open={props.open}
        width={"50%"}
        style={{ maxHeight: `calc(100% - 20%)`, overflowY: "auto" }}
        maskClosable={false}
        closable={false}
        onCancel={() => props.onCancel()}
        centered
        footer={
          <div style={{ marginTop: "0px" }} className="pe-15">
            <Button
              onClick={() => {
                props.onCancel();
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={async () => {
                splitPathInVchType === Voucher_Type.Receipt
                  ? navigate(
                      `/accounts/clients/${currentClient?.id}/sales/editReceipt/${props.paymentId}`
                    )
                  : navigate(
                      `/accounts/clients/${currentClient?.id}/purchase/editPaymentOut/${props.paymentId}`
                    );
              }}
            >
              Edit
            </Button>
            {/* <Button type="primary">Print</Button> */}
          </div>
        }
      >
        <div style={{ padding: 15 }}>
          <Spin indicator={antIcon} spinning={loading}>
            {/* {windowWidth <= 1024 ? (
              <h3 className="fs-35 fw-500 pt-20 themeColor textCenter ">
                Purchase Invoice
              </h3>
            ) : (
              ""
            )} */}
            <Row>
              <Col span={12}>
                <>
                  <div>
                    <span style={{ fontSize: "1.5em" }}>
                      {companySettings?.setting.companyInfo.name}
                    </span>
                  </div>
                  <div>
                    {companySettings?.setting?.companyInfo?.regAddress
                      ?.building !== null ? (
                      <>
                        <span>
                          {
                            companySettings?.setting?.companyInfo?.regAddress
                              ?.building!
                          }
                        </span>
                        {", "}
                      </>
                    ) : (
                      ""
                    )}
                    {companySettings?.setting?.companyInfo?.regAddress
                      ?.street !== null ? (
                      <>
                        <span>
                          {
                            companySettings?.setting?.companyInfo?.regAddress
                              ?.street
                          }
                        </span>
                        <br />
                      </>
                    ) : (
                      ""
                    )}
                    {companySettings?.setting?.companyInfo?.regAddress
                      ?.landmark !== null ? (
                      <>
                        <span>
                          {
                            companySettings?.setting?.companyInfo?.regAddress
                              ?.landmark
                          }
                        </span>
                        <br />
                      </>
                    ) : (
                      ""
                    )}
                    {companySettings?.setting?.companyInfo?.regAddress?.city !==
                    null ? (
                      <>
                        <span>
                          {
                            companySettings?.setting?.companyInfo?.regAddress
                              ?.city
                          }
                        </span>
                        {", "}
                      </>
                    ) : (
                      ""
                    )}
                    {companySettings?.setting?.companyInfo?.regAddress
                      ?.district !== null ? (
                      <>
                        <span>
                          {
                            companySettings?.setting?.companyInfo?.regAddress
                              ?.district
                          }
                        </span>
                        <br />
                      </>
                    ) : (
                      ""
                    )}
                    {companySettings?.setting?.companyInfo?.regAddress
                      ?.state !== null ? (
                      <>
                        <span>
                          <strong>State: </strong>
                          {stateOptions?.map((s: any) =>
                            s.id ===
                            companySettings?.setting?.companyInfo?.regAddress
                              ?.state
                              ? s.name
                              : ""
                          )}
                          , <strong>Code: </strong>
                          {companySettings?.setting?.companyInfo?.regAddress?.state
                            .toString()
                            .padStart(2, "0")}
                        </span>
                        <br />
                      </>
                    ) : (
                      ""
                    )}
                    {companySettings?.setting?.companyInfo?.gstNumber !==
                    null ? (
                      <>
                        <span>
                          <strong>GSTIN: </strong>
                          {companySettings?.setting?.companyInfo?.gstNumber}
                        </span>
                        <br />
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </>
              </Col>
              <Col span={12} style={{ height: "auto" }}>
                <Row
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    height: "100%",
                  }}
                >
                  <div
                    className="themeColor"
                    style={{
                      fontSize: "1.5em",
                      textAlign: "right",
                    }}
                  >
                    {splitPathInVchType === Voucher_Type.Receipt
                      ? companySettings?.setting?.receipt?.title
                      : companySettings?.setting?.payment?.title}
                  </div>
                  <div
                    style={{
                      marginTop: "auto",
                    }}
                  >
                    <div style={{ textAlign: "end" }}>
                      <strong>Date:</strong> {data?.date}
                    </div>
                    <div style={{ textAlign: "end" }}>
                      <strong>Voucher No.:</strong> {data?.number}
                    </div>
                  </div>
                </Row>
              </Col>
            </Row>
            <Row justify="space-between" style={{ marginTop: "20px" }}>
              <Col span={8}>
                <strong>
                  {props?.type === 5 ? "Transfer From " : "Transfer To "}
                  Account:
                </strong>{" "}
                <NavLink
                  to={`/accounts/clients/${currentClient?.id}/master/ledger/${data?.effectiveLedgerId}`}
                >
                  {data?.effectiveLedger}
                </NavLink>
              </Col>
            </Row>

            {/* <Row justify="space-between">
              <Col span={11} className="dashedBoxStyle">
                <Row gutter={[14, 10]}>
                  <Col span={10}>
                    <span className="themeColor fw-500">Account</span>
                    <div>
                      <NavLink
                        to={`/accounts/clients/${currentClient?.id}/master/ledger/${data?.effectiveLedgerId}`}
                      >
                        {data?.effectiveLedger}
                      </NavLink>
                    </div>
                  </Col>
                  <Col span={7}>
                    <span className="themeColor fw-500">Receipt No.</span>
                    <div>{data?.number}</div>
                  </Col>
                  <Col span={7}>
                    <span className="themeColor fw-500">Date</span>
                    <div>{data?.date}</div>
                  </Col>
                </Row>
              </Col>
              <Col span={7}>
                {props?.type === 5 ? (
                  <>
                    <div className="fs-35 fw-400 themeColor textEnd">
                      Payment Out
                    </div>
                  </>
                ) : (
                  <>
                    <div className="fs-35 fw-400 themeColor textEnd">
                      Payment In
                    </div>
                  </>
                )}
              </Col>
            </Row> */}
            <table
              className="ca-payment-table"
              style={{ fontSize: windowWidth <= 1024 ? "10.3px" : "" }}
            >
              <thead>
                <tr>
                  <th style={{ width: "72%" }} className="textStart ps-10">
                    {`Ledger(s)`}
                  </th>
                  <th style={{ width: "20%" }} className="textEnd pe-10">
                    {`Amount (in ₹)`}
                  </th>
                </tr>
              </thead>

              <tbody>
                {data.partyLedger?.map((itm: any, i: any) => {
                  return (
                    <>
                      <tr key={i}>
                        <td className="ps-10" style={{ verticalAlign: "top" }}>
                          <div>{itm?.ledgerName}</div>
                          {itm?.partyOrTax === Party_Tax.party ? (
                            <div>
                              <table style={{ fontSize: "12.5px" }}>
                                <tbody>
                                  {itm?.ref?.map((el: any, index: any) => {
                                    return (
                                      <tr key={index}>
                                        <td
                                          style={{
                                            width: "10%",
                                          }}
                                        >
                                          {index + 1}.
                                        </td>
                                        <td>Ref Name - {el?.name}</td>
                                        <td>
                                          Amount -{" "}
                                          {Utils.getFormattedNumber(el?.value)}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          ) : (
                            ""
                          )}
                        </td>
                        <td
                          className="textEnd pe-10"
                          style={{ verticalAlign: "top" }}
                        >
                          {Utils.getFormattedNumber(Math.abs(itm?.amount))}
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
            <Row className=" pt-10 pe-10">
              <Col lg={16} style={{ paddingRight: "10px" }}>
                <Row>
                  <div>
                    {`Amount (in words): `}
                    <em>INR {data.amountInWords}</em>
                  </div>
                </Row>
                <Row>
                  <strong>Description:</strong>
                </Row>
                <Row>
                  <div>{data?.narration}</div>
                </Row>
              </Col>
              <Col lg={8} md={24}>
                <Row>
                  <Col lg={12} md={12}>
                    <span
                      style={{
                        color: "rgb(11, 48, 142)",
                        fontWeight: "400",
                        fontSize: "18px",
                      }}
                    >
                      Total Amount
                    </span>
                  </Col>
                  <Col lg={12} md={12} className="textEnd">
                    <span
                      style={{
                        color: "rgb(11, 48, 142)",
                        fontWeight: "400",
                        fontSize: "18px",
                      }}
                    >
                      &#8377;
                      {Utils.getFormattedNumber(data?.voucherNetAmount)}
                    </span>
                  </Col>
                </Row>
                <Divider
                  style={{
                    borderBottom: "2px solid rgb(203, 203, 203)",
                  }}
                  className="my-5"
                />
              </Col>
            </Row>
            <Row style={{ marginTop: "60px" }}>
              <Col span={8}>
                <strong>Prepared by</strong>
              </Col>
              <Col span={8} style={{ textAlign: "center" }}>
                <strong>Checked by</strong>
              </Col>
              <Col span={8} style={{ textAlign: "end" }}>
                <strong>Authorized Signatory</strong>
              </Col>
            </Row>
          </Spin>
        </div>
      </Modal>
      {openModel.type === "viewSalesInvoice" && (
        <ViewSalesInvoice
          open={true}
          onCancel={() => setOpenModel({ type: "", id: "" })}
          invoiceId={openModel.id}
        />
      )}
    </React.Fragment>
  );
};
export default ViewPaymentIn;
