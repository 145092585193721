import Icon from "@ant-design/icons/lib/components/Icon";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  Upload,
} from "antd";
import React from "react";
import Papa from "papaparse";
import ChartofAccountServicesNew from "../../Services/ChartofAccountServicesNew";
import { useClient } from "../../Context/ClientContext";
import LedgerService from "../../Services/LedgerService";
import {
  CheckOutlined,
  DownloadOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Utils } from "../../../Utilities/Utils";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";

const ImportLedger = ({ onDismiss, setImportedLedgers, setTrigger }: any) => {
  const [form] = Form.useForm();

  // const [parsedData, setParsedData] = React.useState<any>([]);
  // const [tableRows, setTableRows] = React.useState<any>();
  // const [values, setValues] = React.useState<any>([]);
  const [groupData, setGroupData] = React.useState<any[]>();
  const [proceedDisabled, setProceedDisabled] = React.useState<boolean>(true);
  const [loading, isLoading] = React.useState<boolean>(false);
  // const [selectId, setSelectId] = React.useState<any>();
  const [importData, setImportData] = React.useState<any>();
  const { currentClient } = useClient();
  const navigate = useNavigate();
  React?.useEffect(() => {
    getListData();
  }, []);

  const handleDownload = () => {
    // Public folder ke andar Docs folder mein rakhi file ka URL
    const fileUrl = `${process.env.PUBLIC_URL}/Docs/LedgerSampleData.xlsx`;
    // Hidden anchor element banaye jo file ko download karega
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = "LedgerSampleData.xlsx"; // File ka naam jo download hoga
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getListData = async () => {
    let res = await ChartofAccountServicesNew.getGroupList(currentClient?.id!);
    if (res) {
      setGroupData(res.items);
    } else {
      setGroupData([]);
      console.log("Error", res);
    }
  };

  const handleBeforeUpload = (file: File) => {
    getListData();
    const reader = new FileReader();
    reader.onload = (e) => {
      const binaryStr = e.target?.result as string;
      const workbook = XLSX.read(binaryStr, { type: "binary" });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];

      // Convert sheet to JSON where headers are used as keys
      const jsonData = XLSX.utils.sheet_to_json(worksheet, {
        header: 1,
      }) as any[][];

      if (jsonData.length > 0) {
        // Extract header row

        const header = jsonData[0];
        const rowsArray = jsonData.slice(1); // Data rows

        // Convert rowsArray to array of objects with column headers as keys
        const csvData = rowsArray.map((row) => {
          const formattedRow: any = {};
          row.forEach((value, index) => {
            const key = header[index];
            formattedRow[key] = value;
          });

          // console.log("csvData", formattedRow);
          return {
            exist: false,
            ledgerName: formattedRow.LedgerName,
            aliasName: formattedRow.AliasName,
            debit: Utils.getFormattedNumber(formattedRow.Debit || 0),
            credit: Utils.getFormattedNumber(formattedRow.Credit || 0),
            groupId: groupData?.find(
              (option: any) => option?.id === formattedRow.GroupId
            )?.id,
          };
        });

        form.setFieldValue("items", csvData);
      }
    };

    reader.readAsBinaryString(file);
    return false;
  };

  const validateData = async () => {
    try {
      console.log("Form Values: ", form.getFieldsValue());
      isLoading(true);
      const csvData = form.getFieldValue("items");
      const data = csvData.map((c: any) => {
        return { ledgerName: c?.ledgerName, groupId: c.groupId };
      });
      const validateRes = await LedgerService.ValidateImportLedgers(
        currentClient?.id,
        data
      );
      if (validateRes.result) {
        const ledgersData = [...form.getFieldValue("items")];
        ledgersData.map((l: any) => {
          return (l.exist = validateRes?.result?.find(
            (r: any) => r?.ledgerName === l?.ledgerName
          )?.exist);
        });
        form.setFieldValue("items", ledgersData);
        setProceedDisabled(false);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      isLoading(false);
    }
  };

  const onFinish = async () => {
    try {
      isLoading(true);
      const csvData = await form.validateFields();

      const data = csvData["items"]?.map((c: any) => {
        return {
          ledgerName: c.ledgerName,
          groupId: c?.groupId?.value ? c?.groupId?.value : c?.groupId,
          aliasName: c.aliasName,
          debit: Utils.getNumber(c.debit),
          credit: Utils.getNumber(c.credit),
        };
      });
      const validateRes = await LedgerService.ImportLedgers(
        currentClient?.id,
        data
      );
      setTrigger(true);
      console.log("imp");
      if (validateRes?.result) {
        setImportData(validateRes?.result);
        navigate(`/accounts/clients/${currentClient?.id}/journals/add`, {
          state: { importData: validateRes?.result },
        });
      }
      setImportedLedgers(validateRes?.result || []);
      // setTrialBalanceData(validateRes?.result || []);
      onDismiss(false);
    } catch (error) {
      console.log("error", error);
    } finally {
      isLoading(false);
    }

    // const
  };

  const dataSource = [
    // {
    //   key: "1",
    //   name: "Ignore",
    //   required: "",
    //   notes: "Any data in this field is ignored",
    // },
    {
      key: "2",
      name: "Reference",
      required: "YES",
      notes:
        "Any transaction references like Cheque No., RTGS / NEFT / IMPS reference No., narration of transaction, etc. can be put here.",
    },
    // {
    //   key: "3",
    //   name: "Amount",
    //   required: "",
    //   notes:
    //     "Use this field for statements which provide single column for money-in and money-out.",
    // },
    {
      key: "4",
      name: "Money In",
      required: "YES",
      notes: "Please provide only positive values.",
      // notes:
      //   "Accepts only positive values. Use this field for statements which provide two columns for money-in and money-out.",
    },
    {
      key: "5",
      name: "Money Out",
      required: "YES",
      notes: "Please provide only positive values.",
      // notes:
      // "Accepts only positive values. Use this field for statements which provide two columns for money-in and money-out.",
    },
    {
      key: "6",
      name: "Date",
      required: "YES",
      notes: "Format: DD/MM/YYYY or DD-MM-YYYY",
    },
    // {
    //   key: "7",
    //   name: "Detail",
    //   required: "YES",
    //   notes: "Detail",
    // },
  ];

  return (
    <>
      <Modal
        width={1200}
        style={{ top: 40 }}
        title="Upload CSV FIle"
        onCancel={() => onDismiss(false)}
        open={true}
        footer={
          <>
            <Button type="default" onClick={() => onDismiss(false)}>
              Cancel
            </Button>
            <Button type="primary" onClick={() => validateData()}>
              Validate
            </Button>
            <Button
              type="primary"
              onClick={() => onFinish()}
              disabled={proceedDisabled}
            >
              Proceed
            </Button>
          </>
        }
      >
        <Spin spinning={loading}>
          <Row gutter={10} style={{ justifyContent: "space-between" }}>
            <Col span={7} className="card" style={{ padding: "15px" }}>
              <p
                style={{
                  fontSize: "15px",
                  paddingTop: "1px",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                Step 1. Download our ledger template file.
              </p>
              <p
                style={{
                  paddingTop: "10px",
                  lineHeight: "1.4rem",
                  color: "black",
                }}
              >
                You can download our XLSX ledger template file. The file has got
                accurate prescribed headings which are to be imported.{" "}
              </p>
              <p
                style={{ color: "blue", cursor: "pointer", paddingTop: "5px" }}
                onClick={handleDownload}
              >
                <span>
                  <DownloadOutlined /> Download Template
                </span>
              </p>
            </Col>
            <Col span={7} className="card" style={{ padding: "15px" }}>
              <p
                style={{
                  fontSize: "15px",
                  paddingTop: "1px",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                Step 2. Copy Data into template file.
              </p>
              <p
                style={{
                  paddingTop: "10px",
                  height: "100%",
                  lineHeight: "1.4rem",
                  color: "black",
                }}
              >
                You can copy your data into the template file using excel or any
                other spreadsheet editor. Make sure the data you copied, matches
                the column headings provided in the template. Headings are not
                changeable.
              </p>
            </Col>
            <Col span={7} className="card" style={{ padding: "15px" }}>
              <p
                style={{
                  fontSize: "15px",
                  paddingTop: "1px",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                Step 3. Import the filled template file.
              </p>
              <p
                style={{
                  paddingTop: "10px",
                  height: "100%",
                  lineHeight: "1.4rem",
                  color: "black",
                }}
              >
                <Upload
                  //   {...Props}
                  accept=".xlsx"
                  showUploadList={false}
                  beforeUpload={handleBeforeUpload}
                >
                  <Button type="primary">
                    <Icon type="upload" /> Click to Upload
                  </Button>
                </Upload>
              </p>
            </Col>
          </Row>

          {/* <pre style={{ maxHeight: "600px", overflowY: "scroll" }}> */}
          <div
            style={{
              width: "100%",
              marginTop: "40px",
              height: "550px",
              overflowY: "scroll",
            }}
          >
            <Form form={form}>
              <Form.List name="items">
                {(fields, { add, remove, move }) => {
                  return (
                    <>
                      <table
                        width={"100%"}
                        border={1}
                        className="ca-viewmodel-table"
                        style={{
                          borderCollapse: "collapse",
                          margin: "5px 0px",
                        }}
                      >
                        <thead>
                          <th
                            align="left"
                            style={{ padding: "4px 5px", width: "5%" }}
                          >
                            Match
                          </th>
                          <th
                            align="left"
                            style={{ padding: "4px 5px", width: "25%" }}
                          >
                            Ledger Name
                          </th>
                          <th
                            align="left"
                            style={{ padding: "4px 5px", width: "20%" }}
                          >
                            Alias Name
                          </th>
                          <th
                            align="center"
                            style={{ padding: "4px 5px", width: "15%" }}
                          >
                            Debit
                          </th>
                          <th
                            align="center"
                            style={{ padding: "4px 5px", width: "15%" }}
                          >
                            Credit
                          </th>
                          <th
                            align="center"
                            style={{ padding: "4px 5px", width: "25%" }}
                          >
                            Group
                          </th>
                        </thead>
                        <tbody>
                          {fields.map(({ key, name, ...restField }, index) => {
                            return (
                              <>
                                <tr>
                                  <td align="center" style={{ padding: "3px" }}>
                                    <Form.Item
                                      name={[name, "exist"]}
                                      className="m-0 text-center"
                                    >
                                      {form.getFieldValue("items")[index]
                                        ?.exist ? (
                                        <CheckOutlined
                                          style={{ color: "green" }}
                                        />
                                      ) : (
                                        <CheckOutlined
                                          style={{ color: "red" }}
                                        />
                                      )}
                                    </Form.Item>
                                  </td>
                                  <td style={{ padding: "3px" }}>
                                    <Form.Item
                                      name={[name, "ledgerName"]}
                                      className="m-0"
                                    >
                                      <Input />
                                    </Form.Item>
                                  </td>
                                  <td style={{ padding: "3px" }}>
                                    {" "}
                                    <Form.Item
                                      name={[name, "aliasName"]}
                                      className="m-0"
                                    >
                                      <Input />
                                    </Form.Item>
                                  </td>
                                  <td style={{ padding: "3px" }}>
                                    <Form.Item
                                      name={[name, "debit"]}
                                      className="m-0"
                                    >
                                      <Input defaultValue={"0.00"} />
                                    </Form.Item>
                                  </td>
                                  <td style={{ padding: "3px" }}>
                                    <Form.Item
                                      name={[name, "credit"]}
                                      className="m-0"
                                    >
                                      <Input defaultValue={"0.00"} />
                                    </Form.Item>
                                  </td>
                                  <td style={{ padding: "3px" }}>
                                    {" "}
                                    <Form.Item
                                      name={[name, "groupId"]}
                                      className="m-0"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Please select a group",
                                        },
                                      ]}
                                    >
                                      <Select
                                        style={{
                                          width: "100%",
                                        }}
                                        placeholder="Select Group"
                                        filterOption={(input, option) =>
                                          (option?.label?.toString() ?? "")
                                            .toLowerCase()
                                            .includes(input?.toLowerCase())
                                        }
                                        labelInValue
                                        options={groupData?.map((value) => ({
                                          value: value?.id,
                                          label: value?.group_Name,
                                          title: value?.parent,
                                        }))}
                                        showSearch
                                      />
                                    </Form.Item>
                                  </td>

                                  {/* <Row gutter={12}>
                                  <Col span={6}>
                                    <Form.Item name={[name, "ledgerName"]}>
                                      <Input />
                                    </Form.Item>
                                  </Col>
                                  <Col span={5}>
                                    <Form.Item name={[name, "aliasName"]}>
                                      <Input
                                        onChange={(e: any) => {
                                          console.log(
                                            "form",
                                            form.getFieldsValue()
                                          );
                                        }}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col span={4}>
                                    <Form.Item name={[name, "debit"]}>
                                      <Input />
                                    </Form.Item>
                                  </Col>
                                  <Col span={4}>
                                    <Form.Item name={[name, "credit"]}>
                                      <Input />
                                    </Form.Item>
                                  </Col>
                                  <Col span={5}>
                                    <Form.Item name={[name, "groupId"]}>
                                      <Select
                                        style={{
                                          width: "200px",
                                        }}
                                        placeholder="Select Group"
                                        filterOption={(input, option) =>
                                          (option?.label?.toString() ?? "")
                                            .toLowerCase()
                                            .includes(input?.toLowerCase())
                                        }
                                        labelInValue
                                        options={groupData?.map((value) => ({
                                          value: value.id,
                                          label: value.group_Name,
                                          title: value.parent,
                                        }))}
                                        onSelect={(e) => console.log(e)}
                                        showSearch
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row> */}
                                </tr>
                              </>
                            );
                          })}
                        </tbody>
                      </table>
                    </>
                  );
                }}
              </Form.List>
            </Form>
          </div>
        </Spin>
      </Modal>
    </>
  );
};

export default ImportLedger;
