import {
  CompressOutlined,
  DownCircleFilled,
  DownloadOutlined,
  LoadingOutlined,
  MinusCircleTwoTone,
  PlusOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Input,
  Popover,
  Row,
  Select,
  Space,
  Spin,
  Table,
  TableColumnsType,
  message,
} from "antd";
import React from "react";
import { useTheme } from "../../Context/ThemeContext";
import ReportsService from "../../Services/ReportsService";
import ReportsServiceNew from "../../Services/ReportsServiceNew";
import { useClient } from "../../Context/ClientContext";
import { dayjs } from "../../../Utilities/dayjs";
import ChartofAccountServicesNew from "../../Services/ChartofAccountServicesNew";
import LedgerService, {
  SearchLedgerByGroupsTypes,
} from "../../Services/LedgerService";
import { voucherType } from "./VoucherDetailsByLedger";
import { Utils } from "../../../Utilities/Utils";
import { viewVoucher, VoucherType } from "../../Services/ViewVoucher";
import { useNavigate } from "react-router-dom";
import ViewPaymentIn from "../Sales/ViewPaymentIn";
import ViewPreviewModel from "../Sales/ViewPreviewModel";
import ViewJournal from "../Journals/ViewJournal";
import ViewContra from "../Banking/ViewContra";
import ViewCreditNote from "../Sales/ViewCreditNote";
const { RangePicker } = DatePicker;
const { Search } = Input;

interface DraggedItem {
  box: string;
  fromList: string[];
  setFromList: React.Dispatch<React.SetStateAction<string[]>>;
  fromIndex: number;
}

const GeneralLedger = () => {
  const { themeData } = useTheme();
  const { Option } = Select;
  const { currentClient, companySettings } = useClient();
  const [data, setData] = React?.useState<any>();
  const [groupData, setGroupData] = React.useState<any[]>([]);
  const [groupId, setGroupId] = React?.useState<any>();
  const [loading, setLoading] = React.useState(false);
  const [width, setWidth] = React.useState<any>(window.innerWidth);
  const [searchLedger, setSearchLedger] = React?.useState<any>();
  const [printType, setPrintType] = React?.useState<string>("continuous");
  const [filterDate, setFilterDate] = React.useState<any>({
    from: dayjs(companySettings?.period?.from).format("DD/MM/YYYY"),
    to: dayjs(companySettings?.period?.to).format("DD/MM/YYYY"),
  });
  const [ledgers, setLedgers] = React?.useState<any>();
  const navigate = useNavigate();
  const [id, setId] = React?.useState<any>();
  const [open, setIsOpen] = React.useState<boolean>(false);
  const [paymentId, setPaymentId] = React.useState<string>("");
  const [type, setType] = React?.useState<any>();
  const [invoiceModel, setInvoiceModel] = React.useState<string>("");
  const [creditModalOpen, setCreditModalOpen] = React.useState<boolean>(false);
  const [journalModal, setJournalModal] = React.useState<boolean>(false);
  const [contraModalOpen, setContraModalOpen] = React.useState<boolean>(false);

  const [draggedItem, setDraggedItem] = React.useState<DraggedItem | null>(
    null
  );
  const [selectedColumns, setSelectedColumns] = React.useState<any>([
    "date",
    "number",
    "ledgerName",
    "voucherType",
    "debit",
    "credit",
  ]);
  React?.useEffect(() => {
    if (currentClient?.id) {
      getList(
        currentClient.id,
        filterDate.from,
        filterDate.to,
        groupId && groupId !== "" ? groupId : "",
        searchLedger?.label ? searchLedger?.label : ""
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentClient?.id,
    filterDate.from,
    filterDate.to,
    groupId,
    searchLedger?.label,
  ]);

  const getList = async (
    clientId: string,
    fromDate: string = "",
    toDate: string = "",
    groupId: string,
    searchLedger: string | ""
  ) => {
    setLoading(true);
    await ReportsServiceNew?.getGeneralReport(
      clientId,
      fromDate,
      toDate,
      groupId,
      searchLedger
    ).then((res: any) => {
      if (res?.result) {
        setData(res?.result);
        setLoading(false);
      }
    });
  };
  console.log("data", data);

  React.useEffect(() => {
    // setLoading(true);
    const getListData = async () => {
      await ChartofAccountServicesNew.getGroupList(currentClient?.id!)
        .then((res: any) => {
          if (res.items) {
            setGroupData(res.items);
          } else {
            setGroupData([]);
            console.log("Error", res);
          }
        })
        .catch((err: any) => {
          console.log("Error", err);
        });
      //   setLoading(false);
    };
    getListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePrint = () => {
    var css = "@page { size: A4 portrait; margin: 2.5cm}";
    var head = document.head || document.getElementsByTagName("head")[0];
    var style = document.createElement("style");

    style.type = "text/css";
    style.media = "print";
    style.appendChild(document.createTextNode(css));

    head.appendChild(style);
    const printWindow = window.open("", "");
    if (printWindow) {
      printWindow.document.write(
        "<html><head><title>General Ledger Report</title>"
      );
      printWindow.document.write(`</head><body style="font-size:13px">`);
      printWindow.document.write("<div>");
      let printDivStyle =
        printType === "continuous"
          ? "width:100%; padding:4px"
          : "page-break-before: always; width:100%; padding:4px";
      data.forEach((itm: any, index: any) => {
        printWindow?.document.write(`
          <div key=${index} style="${printDivStyle}">
            <div>
              <p style="text-align: center; font-size: 16px; padding: 5px; margin:5px 0px">
                ${itm?.name}
              </p>
            </div>
            <div style="width: 85%; margin: 0px auto">
                <p style="margin:5px 0px">
                  <strong>Group Name: </strong> ${itm?.group_Name}
                </p>
              <table class="ca-viewmodel-table" style="font-size: 13px; width: 100%; text-align: center; border-collapse: collapse; margin: 3px auto;">
                  <thead style="border: 1px solid gray; background-color: #F0F2F5;">
                    ${
                      selectedColumns.includes("date") &&
                      `<th
                          align="left"
                          style={{ width: "13%", padding: "6px" }}
                        >
                          Date
                        </th>`
                    }
                  ${
                    selectedColumns.includes("number") &&
                    `<th align="center" style="width: 13%">Reference</th>`
                  }
                  ${
                    selectedColumns.includes("ledgerName") &&
                    `<th
                          align="center" style="width: 13%">Ledger Name
                        </th>`
                  }

                        ${
                          selectedColumns.includes("voucherType") &&
                          `<th
                          align="center" style="width: 15%">Voucher Type
                        </th>`
                        }
                     ${
                       selectedColumns.includes("debit") &&
                       `<th align="center" style="width: 13%">Debit</th>`
                     }
                       ${
                         selectedColumns.includes("credit") &&
                         `<th align="center" style="width: 13%">Credit</th>`
                       }
                  </thead>
                  <tbody style="border: 1px solid black;">
              ${itm?.vouchers
                ?.map((items: any, idx: any) => {
                  let vchDetail = {
                    party: "",
                    amount: 0,
                  };
                  let tmpLedList;
                  switch (items?.voucherType) {
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                      if (
                        items?.partyLedger?.find(
                          (led: any) => led?.id === itm?.id
                        )
                      ) {
                        tmpLedList = items?.partyLedger?.filter(
                          (led: any) => led.id === itm?.id
                        );
                        vchDetail.party = items?.effectiveLedger[0].ledgerName;
                        vchDetail.amount = tmpLedList[0]?.amount;
                      } else if (
                        items?.freightInsLedgers?.find(
                          (led: any) => led?.id === itm?.id
                        )
                      ) {
                        tmpLedList = items?.freightInsLedgers?.filter(
                          (led: any) => led.id === itm?.id
                        );
                        vchDetail.party = items?.partyLedger[0].ledgerName;
                        vchDetail.amount = tmpLedList[0]?.amount;
                      } else if (
                        items?.statutoryLedgers?.find(
                          (led: any) => led?.id === itm?.id
                        )
                      ) {
                        tmpLedList = items?.statutoryLedgers?.filter(
                          (led: any) => led.id === itm?.id
                        );
                        vchDetail.party = items?.partyLedger[0].ledgerName;
                        vchDetail.amount = tmpLedList[0]?.amount;
                      } else {
                        tmpLedList = items?.effectiveLedger?.filter(
                          (led: any) => led.id === itm?.id
                        );
                        vchDetail.party = items?.partyLedger[0].ledgerName;
                        vchDetail.amount = tmpLedList[0]?.amount;
                      }
                      break;
                    case 5:
                    case 6:
                    case 7:
                    case 10:
                      if (
                        items?.partyLedger?.find(
                          (led: any) => led?.id === itm?.id
                        )
                      ) {
                        tmpLedList = items?.partyLedger?.filter(
                          (led: any) => led.id === itm?.id
                        );
                        vchDetail.party = items?.effectiveLedger[0].ledgerName;
                        vchDetail.amount = tmpLedList[0].amount;
                      } else {
                        tmpLedList = items?.effectiveLedger?.filter(
                          (led: any) => led.id === itm?.id
                        );
                        vchDetail.party = items?.partyLedger[0].ledgerName;
                        vchDetail.amount = tmpLedList[0].amount;
                      }
                      break;
                  }
                  return `
                    <tr style="border: 1px solid gray;">  
                       ${
                         selectedColumns.includes("date") &&
                         `<td style="width: 10%; padding: 4px;">
                             ${dayjs(items?.date).format("DD/MM/YYYY")}
                           </td>`
                       }
                            ${
                              selectedColumns.includes("number") &&
                              `<td style="width: 13%; padding: 4px;">
                                  ${items?.number}
                                </td>`
                            }

                             ${
                               selectedColumns.includes("ledgerName") &&
                               `
                      <td align="center" style="width: 38%; padding: 4px;">${vchDetail.party}</td>`
                             }

                              ${
                                selectedColumns.includes("voucherType") &&
                                `<td style="width: 13%; padding: 4px;">
                                    ${voucherType[items?.voucherType]}
                                  </td>`
                              }
                     
                              ${
                                selectedColumns.includes("debit") &&
                                `<td
                                    align="right"
                                    style="width: 13%;padding: 4px;"
                                  >
                                    $
                                    ${Utils?.getFormattedNumber(
                                      vchDetail.amount > 0
                                        ? Math.abs(vchDetail.amount)
                                        : 0
                                    )}
                                  </td>`
                              }

                                    ${
                                      selectedColumns.includes("credit") &&
                                      `<td
                                          align="right"
                                          style="width: 13%;padding: 4px;"
                                        >
                                          $
                                          ${Utils?.getFormattedNumber(
                                            vchDetail.amount < 0
                                              ? Math.abs(vchDetail.amount)
                                              : 0
                                          )}
                                        </td>`
                                    }
                    </tr>
                    `;
                })
                .join("")}
                    </tbody>
                  </table>
                  <div style="margin: 5px;">
                    <p style="text-align: end; margin: 2px;">
                      <strong>Balance : </strong> ${
                        itm?.value > 0
                          ? Math.abs(itm?.value) + " Dr."
                          : Math.abs(itm?.value) + " Cr."
                      }
                    </p>
                  </div>
            </div>
          </div>
        `);
      });
      printWindow.document.write("</div>");

      printWindow.document.write("</body></html>");
      printWindow.document.close();
      printWindow.print();
    }
  };

  React?.useEffect(() => {
    LedgerService.getLedgersByGroupName(
      currentClient?.id!,
      SearchLedgerByGroupsTypes?.All
    ).then((res: any) => {
      setLedgers(res);
    });
  }, [currentClient?.id]);

  const columnOptions = [
    { label: "Date", value: "date" },
    { label: "Reference", value: "number" },
    { label: "Ledger Name", value: "ledgerName" },
    { label: "Voucher Type", value: "voucherType" },
    { label: "Debit", value: "debit" },
    { label: "Credit", value: "credit" },
  ];

  const handleCheckboxChange = (value: string) => {
    setSelectedColumns((prev: any) =>
      prev.includes(value)
        ? prev.filter((col: any) => col !== value)
        : [...prev, value]
    );
  };

  const content = (
    <Space direction="vertical">
      {columnOptions?.map((option) => (
        <Checkbox
          key={option.value}
          checked={selectedColumns.includes(option.value)}
          onChange={() => handleCheckboxChange(option.value)}
        >
          {option.label}
        </Checkbox>
      ))}
      <Row>
        <Col>
          <Button size="small">Save</Button>
        </Col>
      </Row>
    </Space>
  );

  // const handleDragStart =
  //   (
  //     box: string,
  //     fromList: string[],
  //     setFromList: React.Dispatch<React.SetStateAction<string[]>>,
  //     fromIndex: number
  //   ) =>
  //   (event: React.DragEvent<HTMLDivElement>) => {
  //     setDraggedItem({ box, fromList, setFromList, fromIndex });
  //   };

  // const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
  //   event.preventDefault();
  // };

  // const handleDrop =
  //   (
  //     toList: string[],
  //     setToList: React.Dispatch<React.SetStateAction<string[]>>,
  //     toIndex: number
  //   ) =>
  //   (event: React.DragEvent<HTMLDivElement>) => {
  //     event.preventDefault();
  //     if (draggedItem) {
  //       const { box, fromList, setFromList, fromIndex } = draggedItem;

  //       if (fromList === toList) {
  //         const newList = [...fromList];
  //         newList[fromIndex] = toList[toIndex];
  //         newList[toIndex] = box;

  //         setFromList(newList);
  //       } else {
  //         const toBox = toList[toIndex];

  //         const newFromList = [...fromList];
  //         const newToList = [...toList];

  //         newFromList[fromIndex] = toBox;
  //         newToList[toIndex] = box;

  //         setFromList(newFromList);
  //         setToList(newToList);
  //       }

  //       setDraggedItem(null);
  //     }
  //   };

  return (
    <>
      <Spin
        spinning={loading}
        indicator={<LoadingOutlined />}
        style={{
          justifyContent: "center",
          marginTop: "180px",
          textAlign: "center",
        }}
      >
        <div>
          <Card
            className="ca-card-body"
            style={{
              boxShadow: "0px 0px 10px 0px #96A1C84D",
              height: "90vh",
              padding: "10px",
            }}
          >
            <Row justify={"space-between"}>
              <Col>
                <Row gutter={10}>
                  <Col>
                    {/* <Search
                      placeholder="Search"
                      allowClear
                      onSearch={(val: any) => setSearchLedger(val)}
                    /> */}

                    <Select
                      showSearch
                      allowClear
                      optionFilterProp="label"
                      style={{ marginBottom: "3px", width: "200px" }}
                      placeholder="Select Ledger"
                      labelInValue
                      onChange={(val: any) => {
                        setSearchLedger(val);
                        console.log("val", val);
                      }}
                      options={ledgers?.map((l: any) => ({
                        key: l?.key,
                        value: l?.id,
                        label: l?.ledgerName,
                      }))}
                    />
                  </Col>
                  <Col>
                    <Select
                      allowClear
                      style={{ width: "200px" }}
                      showSearch
                      onChange={(val: any) => setGroupId(val)}
                      placeholder="Select Group"
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={groupData?.map((value: any, index: number) => ({
                        key: index + value?.id,
                        value: value?.id,
                        label: value?.group_Name,
                      }))}
                    />
                  </Col>

                  <Col>
                    <RangePicker
                      format="DD/MM/YYYY"
                      defaultValue={[
                        dayjs(filterDate?.from, "DD/MM/YYYY"),
                        dayjs(filterDate?.to, "DD/MM/YYYY"),
                      ]}
                      style={{
                        width:
                          width <= 464
                            ? "330px"
                            : width === 375
                            ? "280px"
                            : "220px",
                        border: "1px solid #f1f3ff",
                      }}
                      onChange={(e: any) => {
                        e === null
                          ? setFilterDate({})
                          : setFilterDate({
                              ...filterDate,
                              from: dayjs(e[0]).format("DD/MM/YYYY"),
                              to: dayjs(e[1]).format("DD/MM/YYYY"),
                            });
                      }}
                      presets={[
                        {
                          label: "Today",
                          value: [dayjs().add(0, "d"), dayjs()],
                        },
                        {
                          label: "Last 7 Days",
                          value: [dayjs().add(-7, "d"), dayjs()],
                        },
                        {
                          label: "Last 15 Days",
                          value: [dayjs().add(-15, "d"), dayjs()],
                        },
                        {
                          label: "This Month",
                          value: [dayjs().startOf("month"), dayjs()],
                        },
                        {
                          label: "Last Month",
                          value: [
                            dayjs().date(0).startOf("month"),
                            dayjs().date(0),
                          ],
                        },
                        {
                          label: "Quarter 1",
                          value: [
                            dayjs().month(3).startOf("month"),
                            dayjs().month(5).endOf("month"),
                          ],
                        },
                        {
                          label: "Quarter 2",
                          value: [
                            dayjs().month(6).startOf("month"),
                            dayjs().month(8).endOf("month"),
                          ],
                        },
                        {
                          label: "Quarter 3",
                          value: [
                            dayjs().month(9).startOf("month"),
                            dayjs().month(11).endOf("month"),
                          ],
                        },
                        {
                          label: "Quarter 4",
                          value: [
                            dayjs().month(0).startOf("month"),
                            dayjs().month(2).endOf("month"),
                          ],
                        },
                        {
                          label: "This Year",
                          value: [dayjs().month(0).startOf("month"), dayjs()],
                        },
                        {
                          label: "Last Year",
                          value: [
                            dayjs().month(-12).startOf("month"),
                            dayjs().month(-1).endOf("month"),
                          ],
                        },
                      ]}
                    />
                  </Col>
                  <Col>
                    <span>Colums </span>
                    <Popover
                      content={content}
                      // title="Select Columns"
                      trigger="click"
                    >
                      <Button
                        size="small"
                        type="primary"
                        icon={<PlusOutlined />}
                        style={{
                          paddingTop: "1px",
                          marginTop: "5px",
                        }}
                      />
                    </Popover>
                  </Col>
                  {/* <Col>
                    <span>Position</span>
                    <Popover
                      trigger="click"
                      content={
                        <>
                          <Space>
                            {selectedColumns.map((box: any, index: number) => (
                              <Col
                                key={box}
                                className="drop"
                                draggable
                                onDragStart={handleDragStart(
                                  box,
                                  selectedColumns,
                                  setSelectedColumns,
                                  index
                                )}
                                onDragOver={handleDragOver}
                                onDrop={handleDrop(
                                  selectedColumns,
                                  setSelectedColumns,
                                  index
                                )}
                                style={{ cursor: "grab" }}
                              >
                                <h3
                                // style={{
                                //   marginBottom: "10px",
                                // }}
                                >
                                  {box}
                                </h3>
                              </Col>
                            ))}
                            <br />
                          </Space>
                        </>
                      }
                    >
                      <Button
                        size="small"
                        type="primary"
                        icon={<CompressOutlined />}
                        style={{
                          paddingTop: "1px",
                          margin: "5px",
                        }}
                      />
                    </Popover>
                  </Col> */}
                </Row>
              </Col>
              <Col>
                <Row gutter={10} justify={"end"}>
                  <Col>
                    <span style={{ paddingRight: "10px" }}>Print Options</span>
                    <Select
                      style={{ width: 200 }}
                      onChange={(val: any) => setPrintType(val)}
                      defaultValue="continuous"
                      options={[
                        { value: "continuous", label: "Continuous on page" },
                        {
                          value: "separatePage",
                          label: "Each on separate page",
                        },
                      ]}
                    />
                  </Col>
                  <Col>
                    <Button icon={<PrinterOutlined />} onClick={handlePrint}>
                      Print
                    </Button>
                  </Col>
                  <Col>
                    <Button icon={<DownloadOutlined />}>Export</Button>
                  </Col>
                </Row>
              </Col>
            </Row>

            <div
              style={{
                margin: " 25px auto",
                maxHeight: "80vh",
                overflowY: "scroll",
                width: "80%",
              }}
            >
              {data?.length === 0 ? (
                <>
                  <div
                    style={{
                      justifyContent: "center",
                      paddingTop: "250px",
                      fontSize: "15px",
                      textAlign: "center",
                    }}
                  >
                    <MinusCircleTwoTone />
                    <p>No Items</p>
                  </div>
                </>
              ) : (
                <>
                  {data?.map((itm: any, index: number) => (
                    <div
                      key={index}
                      style={{ maxWidth: "100%", margin: " 0 auto" }}
                    >
                      <div>
                        <p
                          style={{
                            textAlign: "center",
                            fontSize: "16px",
                            padding: "5px",
                          }}
                        >
                          {itm?.name}
                        </p>
                      </div>
                      <div
                        style={{
                          width: "100%",
                        }}
                      >
                        <div>
                          <p>
                            <strong>Group Name: </strong>
                            {itm?.group_Name}
                          </p>
                        </div>
                        <table
                          className="ca-viewmodel-table"
                          style={{
                            width: "100%",
                            textAlign: "center",
                            borderCollapse: "collapse",
                            margin: "3px auto",
                          }}
                        >
                          <thead>
                            <tr>
                              {selectedColumns.includes("date") && (
                                <th style={{ width: "10%" }}>Date</th>
                              )}
                              {/* <th style={{ width: "10%" }}>Due Date</th> */}
                              {selectedColumns.includes("number") && (
                                <th style={{ width: "13%" }}>Reference</th>
                              )}

                              {selectedColumns.includes("ledgerName") && (
                                <th align="center" style={{ width: "38%" }}>
                                  Ledger Name
                                </th>
                              )}
                              {selectedColumns.includes("voucherType") && (
                                <th style={{ width: "13%" }}>Voucher Type</th>
                              )}
                              {/* <th style={{ width: "13%" }}>Voucher Type</th> */}
                              {selectedColumns.includes("debit") && (
                                <th style={{ width: "13%" }}>Debit</th>
                              )}
                              {selectedColumns.includes("credit") && (
                                <th style={{ width: "13%" }}>Credit</th>
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {itm?.vouchers?.map((items: any, idx: number) => {
                              // <div key={idx}>{items?.number}</div>
                              let vchDetail = {
                                party: "",
                                amount: 0,
                              };
                              let tmpLedList;
                              switch (items?.voucherType) {
                                case 1:
                                case 2:
                                case 3:
                                case 4:
                                  if (
                                    items?.partyLedger?.find(
                                      (led: any) => led?.id === itm?.id
                                    )
                                  ) {
                                    tmpLedList = items?.partyLedger?.filter(
                                      (led: any) => led.id === itm?.id
                                    );
                                    vchDetail.party =
                                      items?.effectiveLedger[0].ledgerName;
                                    vchDetail.amount = tmpLedList[0]?.amount;
                                  } else if (
                                    items?.freightInsLedgers?.find(
                                      (led: any) => led?.id === itm?.id
                                    )
                                  ) {
                                    tmpLedList =
                                      items?.freightInsLedgers?.filter(
                                        (led: any) => led.id === itm?.id
                                      );
                                    vchDetail.party =
                                      items?.partyLedger[0].ledgerName;
                                    vchDetail.amount = tmpLedList[0]?.amount;
                                  } else if (
                                    items?.statutoryLedgers?.find(
                                      (led: any) => led?.id === itm?.id
                                    )
                                  ) {
                                    tmpLedList =
                                      items?.statutoryLedgers?.filter(
                                        (led: any) => led.id === itm?.id
                                      );
                                    vchDetail.party =
                                      items?.partyLedger[0].ledgerName;
                                    vchDetail.amount = tmpLedList[0]?.amount;
                                  } else {
                                    tmpLedList = items?.effectiveLedger?.filter(
                                      (led: any) => led.id === itm?.id
                                    );
                                    vchDetail.party =
                                      items?.partyLedger[0].ledgerName;
                                    vchDetail.amount = tmpLedList[0]?.amount;
                                  }
                                  break;
                                case 5:
                                case 6:
                                case 7:
                                case 10:
                                  if (
                                    items?.partyLedger?.find(
                                      (led: any) => led?.id === itm?.id
                                    )
                                  ) {
                                    tmpLedList = items?.partyLedger?.filter(
                                      (led: any) => led.id === itm?.id
                                    );
                                    vchDetail.party =
                                      items?.effectiveLedger[0].ledgerName;
                                    vchDetail.amount = tmpLedList[0].amount;
                                  } else {
                                    tmpLedList = items?.effectiveLedger?.filter(
                                      (led: any) => led.id === itm?.id
                                    );
                                    vchDetail.party =
                                      items?.partyLedger[0].ledgerName;
                                    vchDetail.amount = tmpLedList[0].amount;
                                  }
                                  break;
                              }
                              return (
                                <>
                                  <tr>
                                    {selectedColumns.includes("date") && (
                                      <td>
                                        {dayjs(items?.date).format(
                                          "DD-MM-YYYY"
                                        )}
                                      </td>
                                    )}
                                    {/* <td>
                                      {dayjs(items?.dueDate).format(
                                        "DD-MM-YYYY"
                                      )}
                                    </td> */}
                                    {selectedColumns.includes("number") && (
                                      <td
                                        style={{ padding: "4px" }}
                                        width={200}
                                      >
                                        <p
                                          style={{
                                            cursor: "pointer",
                                            color: "rgb(22, 119, 255)",
                                          }}
                                          onClick={() =>
                                            viewVoucher(
                                              navigate,
                                              currentClient,
                                              items.id,
                                              items.voucherType,
                                              setId,
                                              setIsOpen,
                                              setPaymentId,
                                              setType,
                                              setInvoiceModel,
                                              setCreditModalOpen,
                                              setJournalModal,
                                              setContraModalOpen
                                            )
                                          }
                                        >
                                          {items?.number}
                                        </p>
                                      </td>
                                    )}
                                    {selectedColumns.includes("ledgerName") && (
                                      <td align="center">{vchDetail.party}</td>
                                    )}

                                    {selectedColumns.includes(
                                      "voucherType"
                                    ) && (
                                      <td>
                                        {" "}
                                        {voucherType[items?.voucherType]}
                                      </td>
                                    )}
                                    {/* <td>{items?.amountSummary?.refundAmount}</td>
                                  <td>
                                    {items?.amountSummary?.receivedAmount}
                                  </td> */}

                                    {selectedColumns?.includes("debit") && (
                                      <td align="right">
                                        {Utils.getFormattedNumber(
                                          vchDetail?.amount > 0
                                            ? Math.abs(vchDetail.amount)
                                            : 0
                                        )}
                                      </td>
                                    )}

                                    {selectedColumns?.includes("credit") && (
                                      <td align="right">
                                        {Utils.getFormattedNumber(
                                          vchDetail.amount < 0
                                            ? Math.abs(vchDetail.amount)
                                            : 0
                                        )}
                                      </td>
                                    )}
                                  </tr>
                                </>
                              );
                            })}
                          </tbody>
                          {/* <tfoot
                            style={{
                              backgroundColor: "#F0F2F5",
                            }}
                          > */}
                          {/* <tr
                              style={{
                                backgroundColor: "#F0F2F5",
                              }}
                            >
                              <th
                                colSpan={5}
                                style={{ textAlign: "right", padding: "2px" }}
                              >
                                Balance:{" "}
                              </th>
                              <th
                                colSpan={2}
                                style={{ textAlign: "right", padding: "2px" }}
                              >
                                {itm?.value > 0
                                  ? Utils.getFormattedNumber(
                                      Math.abs(itm?.value)
                                    ) + " Dr."
                                  : Utils.getFormattedNumber(
                                      Math.abs(itm?.value)
                                    ) + " Cr."}
                              </th>
                            </tr> */}
                          {/* </tfoot> */}
                        </table>
                        <div
                          style={{
                            width: "100%",
                            backgroundColor: "#F0F2F5",
                            textAlign: "right",
                          }}
                        >
                          <span
                            style={{ paddingRight: "20px", fontWeight: "700" }}
                          >
                            {" "}
                            Balance:
                          </span>
                          {itm?.value > 0
                            ? Utils.getFormattedNumber(Math.abs(itm?.value)) +
                              " Dr."
                            : Utils.getFormattedNumber(Math.abs(itm?.value)) +
                              " Cr."}
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          </Card>
        </div>
      </Spin>
      {open && (
        <ViewPaymentIn
          open={open}
          onCancel={() => setIsOpen(false)}
          paymentId={id}
          type={type}
        />
      )}
      {invoiceModel === "viewSalesInvoice" && (
        <ViewPreviewModel
          open={invoiceModel === "viewSalesInvoice"}
          invVoucharType={type}
          onCancel={() => setInvoiceModel("")}
          voucherId={paymentId}
        />
      )}
      {journalModal && (
        <ViewJournal
          open={journalModal}
          onCancel={() => setJournalModal(false)}
          journalId={paymentId}
          type={type}
        />
      )}

      {contraModalOpen && (
        <ViewContra
          open={contraModalOpen}
          onCancel={() => setContraModalOpen(false)}
          voucherId={paymentId}
          type={type}
        />
      )}
      {creditModalOpen && (
        <ViewCreditNote
          open={creditModalOpen}
          onCancel={() => setCreditModalOpen(false)}
          creditNoteId={paymentId}
          voucharType={type}
        />
      )}
    </>
  );
};

export default GeneralLedger;
