import React, { useContext, useState } from "react";
import {
  Button,
  Table,
  Row,
  Col,
  Input,
  DatePicker,
  Select,
  Space,
  Card,
  Skeleton,
  Tag,
  Tooltip,
  Popover,
} from "antd";
import {
  PlusOutlined,
  DownloadOutlined,
  EditOutlined,
  DeleteTwoTone,
  SyncOutlined,
} from "@ant-design/icons";
import search from "../../Images/search.svg";
import { useLocation, useNavigate } from "react-router-dom";

import type { ColumnsType } from "antd/es/table";
import ViewCreditNote from "./ViewCreditNote";
import ViewSalesInvoice from "./ViewSalesInvoice";
import { dayjs } from "../../../Utilities/dayjs";
import { Utils } from "../../../Utilities/Utils";
import { useTheme } from "../../Context/ThemeContext";
import { useClient } from "../../Context/ClientContext";
import { notificationContext } from "../../Common/PageRoute";
import { ConfirmationModal } from "../../Common/ConfirmationModal";
import { FiDownload } from "react-icons/fi";
import VoucherServices, {
  VoucherFilterStatus,
  VoucherStatus_New,
  Voucher_Type,
} from "../../Services/VoucherServices";
import jsPDF from "jspdf";
import "jspdf-autotable";
import ExcelJS from "exceljs";
import InvoiceExportService from "../../Services/InvoiceExportDetails";

interface DataType {
  key: React.Key;
  id: string;
  invNumber: { id: string; name: string };
  invRefNumber: { id: string; name: string };
  customer: string;
  date: string;
  amount: string;
  invAmount: any;
  voucherStatus: VoucherStatus_New;
}
const CreditNotes: React.FC<{ partyId?: string }> = (props) => {
  const { themeData } = useTheme();
  const { RangePicker } = DatePicker;
  const { currentClient, companySettings } = useClient();
  const location = useLocation();
  const { openNotification } = useContext(notificationContext);
  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setIsOpen] = React.useState<boolean>(false);
  const [pageSize, setPageSize] = React.useState<number>(15);
  const [totalRecords, setTotalRecords] = useState<number>();
  const [totalAmount, setTotalAmount] = useState<number>();
  const [creditNoteList, setCreditNoteList] = useState<DataType[]>([]);
  const [exportCreditNoteData, setExportCreditNoteData] = useState<DataType[]>(
    []
  );
  const [openPopArray, setOpenPopArray] = useState<boolean[]>([]);
  const [confirmation, setConfirmation] = useState<boolean>(false);
  const [creditNoteId, setCreditNoteId] = React.useState<string>("");
  const [searchCustomer, setSearchCustomer] = React.useState<string>("");
  const [salesInvoiceId, setSalesInvoiceId] = React.useState<string>("");
  const [refreshAtDelete, setRefreshAtDelete] = React.useState<boolean>(false);
  const [filterDate, setFilterDate] = React.useState<any>({
    from: dayjs(companySettings?.period?.from).format("DD/MM/YYYY"),
    to: dayjs(companySettings?.period?.to).format("DD/MM/YYYY"),
  });
  const [width, setWidth] = React.useState<any>(window.innerWidth);
  const [exportType, setExportType] = useState<number>(-1);
  const [invoiceStatus, setInvoiceStatus] = React.useState<VoucherFilterStatus>(
    VoucherFilterStatus.Active
  );

  const [openSalesInvoice, setOpenSalesInvoice] =
    React.useState<boolean>(false);
  const [filter, setfilter] = React.useState<any>({
    sortCol: "",
    sortDir: "",
  });
  const [deleteCreditNote, setDeleteCreditNote] = React.useState<{
    id: string;
    isRev: boolean;
    number: string;
  }>({
    id: "",
    isRev: false,
    number: "",
  });

  let splitPathInVchType: Voucher_Type;
  // let splitPathLedgerByGroupsType: SearchLedgerByGroupsTypes;
  if (location.pathname.split("/")[4] === "sales") {
    splitPathInVchType = Voucher_Type.CreditNote;
    // splitPathLedgerByGroupsType = SearchLedgerByGroupsTypes.Sales;
  } else {
    splitPathInVchType = Voucher_Type.DebitNote;
    // splitPathLedgerByGroupsType = SearchLedgerByGroupsTypes.Purchase;
  }

  React.useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  const navigate = useNavigate();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  // Get the selected row data
  const selectedRows = creditNoteList?.filter((item) =>
    selectedRowKeys.includes(item.key)
  );

  console.log("Selected Row Keys:", selectedRowKeys);
  console.log("Selected Row Data:", selectedRows);

  const columns: ColumnsType<DataType> = [
    {
      title:
        splitPathInVchType === Voucher_Type.CreditNote
          ? "Credit Note No."
          : "Debit Note No.",
      dataIndex: "number",
      sorter: true,
      key: "number",
      width: "15%",
      render: (_, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.voucherStatus !== 1 ? (
            <>
              <span
                style={{ color: "#1677FF", cursor: "pointer" }}
                onClick={() => {
                  setIsOpen(true);
                  setCreditNoteId(record?.invNumber?.id);
                }}
              >
                {record?.invNumber?.name}
              </span>
            </>
          ) : (
            <>
              <span style={{ color: "red" }}>{record?.invNumber?.name}</span>
            </>
          )}
        </Skeleton>
      ),
    },
    {
      title: "Party Name",
      dataIndex: "customer",
      key: "pLedger",
      sorter: true,
      // width: "30%",
      render: (_, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.customerName?.name}
        </Skeleton>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      sorter: true,
      key: "date",
      width: "10%",
      render: (_, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.date}
        </Skeleton>
      ),
    },
    {
      title:
        splitPathInVchType === Voucher_Type.CreditNote
          ? "Invoice No."
          : "Bill No.",
      dataIndex: "invoiceNo",
      key: "PORef.Name",
      sorter: true,
      width: "15%",
      render: (_, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          <span
            style={{ color: "#1677FF", cursor: "pointer" }}
            onClick={() => {
              setOpenSalesInvoice(true);
              setSalesInvoiceId(record?.invRefNumber?.id);
            }}
          >
            {record?.invRefNumber?.name}
          </span>
        </Skeleton>
      ),
    },
    {
      title: "Amount",
      dataIndex: "C",
      sorter: true,
      key: "invAmount",
      align: "right",
      width: "15%",
      render: (_, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
            style: { display: "flex", justifyContent: "end" },
          }}
          title={false}
          style={{ display: "flex", justifyItems: "end" }}
        >
          {record?.voucherStatus !== 1 ? (
            <>₹ {Utils.getFormattedNumber(record?.invAmount)}</>
          ) : (
            <>
              <span style={{ textDecoration: "line-through" }}>
                ₹ {Utils.getFormattedNumber(record?.invAmount)}
              </span>
            </>
          )}
        </Skeleton>
      ),
    },
    {
      title: "Action",
      dataIndex: "",
      align: "center",
      width: "10%",
      render: (_, record, index) => (
        <Skeleton
          className="cursor"
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          <div style={{ justifyContent: "center", display: "flex" }}>
            <Row gutter={13}>
              <Col>
                {record?.voucherStatus !== 1 ? (
                  <>
                    <FiDownload style={{ color: "#395cd2" }} />
                  </>
                ) : (
                  <></>
                )}
              </Col>
              <Col>
                {record.voucherStatus === VoucherStatus_New.Active ? (
                  <>
                    <span>
                      <Tooltip title="Edit">
                        <EditOutlined
                          onClick={() => {
                            splitPathInVchType === Voucher_Type.CreditNote
                              ? navigate(
                                  `/accounts/clients/${currentClient?.id}/sales/editCreditNote/${record?.id}`
                                )
                              : navigate(
                                  `/accounts/clients/${currentClient?.id}/purchase/editDebitNote/${record?.id}`
                                );
                          }}
                        />
                      </Tooltip>
                    </span>
                    <span style={{ marginLeft: "10px" }}>
                      <Tooltip title="Delete" color="red">
                        <DeleteTwoTone
                          className="ca-delete-btn"
                          twoToneColor="#ff879d"
                          onClick={() => {
                            setConfirmation(true);
                            setDeleteCreditNote({
                              ...deleteCreditNote,
                              number: record?.invNumber?.name,
                            });
                            setCreditNoteId(record?.id);
                            handleOpenChange(index, false);
                          }}
                        />
                      </Tooltip>
                    </span>
                  </>
                ) : (
                  <Row>
                    <Col>
                      <Tooltip title="Revive Voucher">
                        <SyncOutlined
                          style={{
                            color: "green",
                            // textAlign: "center",
                            justifyContent: "end",
                          }}
                          // className="ca-delete-btn cursor"
                          onClick={() => {
                            splitPathInVchType === Voucher_Type.CreditNote
                              ? navigate(
                                  `/accounts/clients/${currentClient?.id}/sales/editCreditNote/${record?.id}`
                                )
                              : navigate(
                                  `/accounts/clients/${currentClient?.id}/purchase/editDebitNote/${record?.id}`
                                );
                          }}
                        />
                      </Tooltip>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </div>
        </Skeleton>
      ),
    },
  ];

  const handleOpenChange = (columnIndex: number, newOpen: boolean) => {
    const updatedOpenPopArray = [...openPopArray];
    updatedOpenPopArray[columnIndex] = newOpen;
    setOpenPopArray(updatedOpenPopArray);
  };

  React.useEffect(() => {
    console.log("useEffected Call");

    if (deleteCreditNote?.id) {
      const DeleteCreditNote = async () => {
        setLoading(true);
        await VoucherServices.deleteVoucher(
          currentClient?.id!,
          deleteCreditNote.id
        ).then((res) => {
          setLoading(false);
          if (res?.result) {
            setRefreshAtDelete(!refreshAtDelete);
            openNotification(
              "success",
              `Entry deleted successfully ${deleteCreditNote.number}`
            );
          }
        });
      };
      DeleteCreditNote();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteCreditNote?.id]);

  React.useEffect(() => {
    if (currentClient?.id && (exportType === -1 || exportType)) {
      getList(
        currentClient?.id,
        splitPathInVchType === Voucher_Type.CreditNote
          ? Voucher_Type.CreditNote
          : Voucher_Type.DebitNote,
        exportType >= 1 ? 0 : (page - 1) * pageSize,
        exportType >= 1 ? 0 : pageSize,
        filterDate.from,
        filterDate.to,
        filter.sortCol,
        filter.sortDir,
        undefined,
        undefined,
        searchCustomer,
        invoiceStatus
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentClient?.id,
    page,
    pageSize,
    filter.sortCol,
    filter.sortDir,
    filterDate.from,
    filterDate.to,
    searchCustomer,
    refreshAtDelete,
    invoiceStatus,
    exportType,
  ]);

  // ----- Table Ascending & Desending Order -----
  const getList = async (
    clientId: string,
    Voucher_Type: Voucher_Type,
    start: number,
    length: number,
    fromDate: string = "",
    toDate: string = "",
    sortCol?: string,
    sortDir?: string,
    noPartyId?: string,
    noReconciled?: string,
    search?: string,
    status?: VoucherFilterStatus
  ) => {
    console.log("export", exportType);

    if (exportType < 1) {
      setLoading(true);
    }
    await VoucherServices.getVoucharList(
      clientId,
      Voucher_Type,
      start,
      length,
      fromDate,
      toDate,
      sortCol,
      sortDir,
      noPartyId,
      noReconciled,
      search,
      status
    )
      .then((res: any) => {
        if (res?.result?.voucherItems?.length > 0) {
          // setTotAmount(res?.result?.totalAmount);
          let creditNoteData = res?.result?.voucherItems?.map(
            (v: any, index: number) => {
              return {
                key: index,
                id: v?.vId,
                date: dayjs(v?.date).format("DD/MM/YYYY"),
                invNumber: { name: v?.number, id: v?.vId },
                invRefNumber: {
                  name: v?.purchaseOrderRef?.name,
                  id: v?.purchaseOrderRef?.id,
                },
                customerName: {
                  id: v?.pLedger[0].id,
                  name: v?.pLedger[0]?.ledgerName,
                },
                invAmount: v?.invAmount,
                dueBalance: v?.dueAmount,
                voucherStatus: v?.voucherStatus,
              };
            }
          );
          if (exportType >= 1) {
            setExportCreditNoteData(creditNoteData);
            if (exportType === 1) {
              exportCsv(creditNoteData);
            } else if (exportType === 2) {
              exportPDF(creditNoteData);
            } else if (exportType === 3) {
              exportExcel(creditNoteData);
            }
          } else {
            setCreditNoteList(creditNoteData);
            setTotalAmount(res?.result?.totalAmount);
          }
        } else if (res?.result?.voucherItems?.length === 0) {
          setCreditNoteList([]);
          // props.setLoadSkeleton(false);
          setTotalRecords(res?.totalRecords);
        }
        setLoading(false);
      })
      .catch((ex: any) => {
        console.error(ex.message);
        setLoading(false);
        setCreditNoteList([]);
        setTotalRecords(0);
      });
  };

  // ----- Table Ascending & Desending Order -----
  const tableSort = (pagination?: any, sort?: any, filter?: any) => {
    setfilter({
      sortCol: filter.column !== undefined ? filter.columnKey : "date",
      sortDir:
        filter.column !== undefined
          ? filter.order === "descend"
            ? "desc"
            : "asc"
          : "desc",
    });
    console.log("filter", filter);
  };

  // document.addEventListener("keydown", (e) => {
  //   if (e.altKey && e.key.toLowerCase() === "o") {
  //     e.preventDefault();
  //     navigate(`/accounts/clients/${currentClient?.id}/sales/addCreditNote`);
  //     //console.log("event", e);
  //   }
  // });
  //----- Short-Cut for CreditNotes -----

  let path =
    splitPathInVchType === Voucher_Type.CreditNote
      ? "Credit Note List"
      : "Debit Note List";
  const generateFooterRow = (columns: string[]) => {
    return columns.map((col) => {
      if (col === "Amount") {
        return `${
          selectedRowKeys.length > 0
            ? Utils.getFormattedNumber(
                selectedRows.reduce(
                  (acc, data) => acc + (data?.invAmount || 0),
                  0
                )
              )
            : Utils.getFormattedNumber(totalAmount)
        }`;
      } else if (col === "Credit Note No.") {
        return "Total";
      } else if (col === "Debit Note No.") {
        return "Total";
      } else {
        return "";
      }
    });
  };

  const columnsA = [
    splitPathInVchType === Voucher_Type.CreditNote
      ? "Credit Note No."
      : "Debit Note No.",
    "Party Name",
    "Date",
    splitPathInVchType === Voucher_Type.CreditNote ? "Invoice No." : "Bill No.",
    "Amount",
  ];

  const fieldMapping = {
    "Credit Note No.": (item: any) => item?.invNumber?.name,
    "Debit Note No.": (item: any) => item?.invNumber?.name,
    "Party Name": (item: any) => item?.customerName?.name,
    Date: (item: any) => item?.date,
    "Invoice No.": (item: any) =>
      Utils.getFormattedNumber(item?.invRefNumber?.name),
    "Bill No.": (item: any) =>
      Utils.getFormattedNumber(item?.invRefNumber?.name),
    Amount: (item: any) => Utils?.getFormattedNumber(item?.invAmount),
  };

  const exportCsv = (invExportData: DataType[]) => {
    const exportData =
      selectedRowKeys.length > 0 ? selectedRows : invExportData;
    InvoiceExportService.generateCSV(
      exportData,
      columnsA,
      fieldMapping,
      generateFooterRow,
      "",
      filterDate,
      path,
      currentClient?.businessName
    );
    setExportType(0);
  };

  const exportPDF = (invExportData: DataType[]) => {
    const exportData =
      selectedRowKeys.length > 0 ? selectedRows : invExportData;
    InvoiceExportService.generatePDF(
      exportData,
      currentClient?.businessName,
      columnsA,
      fieldMapping,
      generateFooterRow,
      filterDate,
      path
    );
    setExportType(0);
  };

  const exportExcel = async (invExportData: DataType[]) => {
    const exportData =
      selectedRowKeys.length > 0 ? selectedRows : invExportData;
    await InvoiceExportService.generateExcel(
      exportData,
      currentClient?.businessName,
      columnsA,
      fieldMapping,
      generateFooterRow,
      filterDate
    );
    setExportType(0);
  };

  const content = (
    <Space direction="vertical">
      <div>
        <Button
          style={{ width: "80px" }}
          size="small"
          title="CSV"
          onClick={() => {
            if (exportCreditNoteData.length > 0) {
              exportCsv(exportCreditNoteData);
            } else {
              setExportType(1);
              // setApiCallExports(true);
            }
          }}
          icon={<DownloadOutlined />}
        >
          CSV
        </Button>
      </div>

      <div>
        <Button
          style={{ width: "80px" }}
          size="small"
          title="PDF"
          onClick={() => {
            if (exportCreditNoteData.length > 0) {
              exportPDF(exportCreditNoteData);
            } else {
              setExportType(2);
              // setApiCallExports(true);
            }
          }}
          icon={<DownloadOutlined />}
        >
          PDF
        </Button>
      </div>
      <div>
        <Button
          style={{ width: "80px" }}
          size="small"
          title="XLSL"
          onClick={() => {
            if (exportCreditNoteData.length > 0) {
              exportExcel(exportCreditNoteData);
            } else {
              setExportType(3);
              // setApiCallExports(true);
            }
          }}
          icon={<DownloadOutlined />}
        >
          XLSL
        </Button>
      </div>
    </Space>
  );
  return (
    <Card
      size="small"
      className="TableCard"
      style={{
        // width: "97%",
        // marginLeft: "16px",
        marginTop: 35,
        boxShadow: "0px 0px 10px 0px #96A1C84D",
        // border: "1px solid #eef2fe",
      }}
    >
      <Row
        justify="space-between"
        style={{
          // paddingTop: props?.partyId ? "10px" : "0px"
          // marginTop: 10,
          padding: "0px 0px 3px 0px",
        }}
      >
        <Col>
          <Space size={width <= 768 ? 440 : 5}>
            <Tooltip
              title={
                splitPathInVchType === Voucher_Type.CreditNote ? (
                  <>
                    Add Credit Note
                    <br />
                    <Tag style={{ color: "white" }}>
                      <kbd>Shift + F6</kbd>
                    </Tag>
                  </>
                ) : (
                  <>
                    Add Debit Note
                    <br />
                    <Tag style={{ color: "white" }}>
                      <kbd>Shift + F10</kbd>
                    </Tag>
                  </>
                )
              }
            >
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                  props?.partyId
                    ? navigate(
                        `/accounts/clients/${currentClient?.id}/sales/addCreditNote/${props?.partyId}`
                      )
                    : splitPathInVchType === Voucher_Type.CreditNote
                    ? navigate(
                        `/accounts/clients/${currentClient?.id}/sales/addCreditNote`
                      )
                    : navigate(
                        `/accounts/clients/${currentClient?.id}/purchase/addDebitNote`
                      );
                }}
              >
                {splitPathInVchType === Voucher_Type.CreditNote
                  ? "Credit Note"
                  : "Debit Note"}
              </Button>
            </Tooltip>

            <Popover content={content} placement="rightTop" trigger="hover">
              <Button
                type="default"
                icon={<DownloadOutlined />}
                // onClick={() => exportCsv()}
              >
                Export
              </Button>
            </Popover>
          </Space>
        </Col>

        <Col
          style={{
            marginTop: width <= 768 ? 15 : "",
          }}
        >
          <Row gutter={width <= 768 ? 60 : 8}>
            <Col>
              <Input
                style={{ width: "200px", border: "1px solid #f1f3ff" }}
                placeholder="Search Ledger"
                allowClear
                onChange={(e: any) => {
                  setExportCreditNoteData([]);
                  setExportType(-1);

                  setPage(1);
                  setSearchCustomer(e.target.value);
                }}
                suffix={
                  searchCustomer ? "" : <img alt="Search.." src={search} />
                }
              />
            </Col>
            <Col>
              <RangePicker
                defaultValue={[
                  dayjs(filterDate?.from, "DD/MM/YYYY"),
                  dayjs(filterDate?.to, "DD/MM/YYYY"),
                ]}
                format="DD/MM/YYYY"
                style={{ width: "220px", border: "1px solid #f1f3ff" }}
                onChange={(e: any) => {
                  console.log(e);
                  e === null
                    ? setFilterDate({})
                    : setFilterDate({
                        ...filterDate,
                        from: dayjs(e[0]).format("DD/MM/YYYY"),
                        to: dayjs(e[1]).format("DD/MM/YYYY"),
                      });
                  setExportCreditNoteData([]);
                  setExportType(-1);
                }}
                presets={[
                  {
                    label: "Today",
                    value: [dayjs().add(0, "d"), dayjs()],
                  },
                  {
                    label: "Last 7 Days",
                    value: [dayjs().add(-7, "d"), dayjs()],
                  },
                  {
                    label: "Last 15 Days",
                    value: [dayjs().add(-15, "d"), dayjs()],
                  },
                  {
                    label: "This Month",
                    value: [dayjs().startOf("month"), dayjs()],
                  },
                  {
                    label: "Last Month",
                    value: [dayjs().date(0).startOf("month"), dayjs().date(0)],
                  },
                  {
                    label: "Quarter 1",
                    value: [
                      dayjs().month(3).startOf("month"),
                      dayjs().month(5).endOf("month"),
                    ],
                  },
                  {
                    label: "Quarter 2",
                    value: [
                      dayjs().month(6).startOf("month"),
                      dayjs().month(8).endOf("month"),
                    ],
                  },
                  {
                    label: "Quarter 3",
                    value: [
                      dayjs().month(9).startOf("month"),
                      dayjs().month(11).endOf("month"),
                    ],
                  },
                  {
                    label: "Quarter 4",
                    value: [
                      dayjs().month(0).startOf("month"),
                      dayjs().month(2).endOf("month"),
                    ],
                  },
                  {
                    label: "This Year",
                    //           dayjs().month(0).startOf("month"),
                    // dayjs(),
                    value: [dayjs().month(0).startOf("month"), dayjs()],
                  },
                  {
                    label: "Last Year",
                    value: [
                      dayjs().month(-12).startOf("month"),
                      dayjs().month(-1).endOf("month"),
                    ],
                  },
                ]}
              />
            </Col>
            <Col>
              <Select
                clearIcon={true}
                value={invoiceStatus}
                style={{ width: 120 }}
                allowClear
                options={[
                  { value: VoucherFilterStatus.All, label: "All" },
                  { value: VoucherFilterStatus.Active, label: "Active" },
                  { value: VoucherFilterStatus.Deleted, label: "Deleted" },
                ]}
                onChange={(val: VoucherFilterStatus) => {
                  setPage(1);
                  setInvoiceStatus(val);
                  setExportCreditNoteData([]);
                  setExportType(-1);
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <div>
        <Table
          className={`Tabel-style table-${
            themeData?.componentSize ?? "middle"
          }`}
          locale={{
            triggerDesc: "",
            triggerAsc: "",
            cancelSort: "",
          }}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={
            loading
              ? (Array.from({ length: pageSize }, (_, index) => ({
                  key: `loading-${index}`,
                })) as any[])
              : creditNoteList
          }
          onChange={tableSort}
          summary={() => {
            return (
              <Table.Summary fixed>
                <Table.Summary.Row
                  style={{ backgroundColor: "#f5f8ff", fontWeight: "bold" }}
                >
                  <Table.Summary.Cell
                    index={1}
                    className="fw600"
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={2}
                    className="fw600"
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell index={3} className="fw600">
                    Total
                  </Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={4}
                    className="fw600"
                  ></Table.Summary.Cell>

                  {/* <Table.Summary.Cell index={5} className="fw600">
                    {"   "}
                  </Table.Summary.Cell> */}

                  <Table.Summary.Cell index={6} className="fw600" align="right">
                    ₹ {Utils.getFormattedNumber(totalAmount)}
                  </Table.Summary.Cell>

                  <Table.Summary.Cell index={7} className="fw600">
                    {"   "}
                  </Table.Summary.Cell>

                  <Table.Summary.Cell index={8} className="fw600">
                    {"   "}
                  </Table.Summary.Cell>

                  <Table.Summary.Cell index={8} className="fw600">
                    {"   "}
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
          pagination={{
            total: totalRecords,
            current: page,
            pageSize: pageSize,
            showSizeChanger: true,
            onChange: (page: number, pageSize: number) => {
              setPage(page);
              setPageSize(pageSize);
            },
            pageSizeOptions: ["10", "15", "25", "50", "100"],
            showTotal: (totalRecords, page) =>
              `${page[0]}-${page[1]} of ${totalRecords} items`,
          }}
          scroll={{ x: 1150, y: window.innerHeight - 330 }}
        />
      </div>
      {confirmation && (
        <ConfirmationModal
          open={confirmation}
          onNo={() => setConfirmation(false)}
          onYes={() => {
            setDeleteCreditNote({
              ...deleteCreditNote,
              id: creditNoteId,
              isRev:
                invoiceStatus === VoucherFilterStatus.Deleted ? true : false,
            });
            setConfirmation(false);
          }}
          text={
            invoiceStatus === VoucherFilterStatus.Deleted
              ? `Are you sure you want to revive "${deleteCreditNote.number}" ?`
              : `Are you sure you want to delete "${deleteCreditNote.number}" ?`
          }
        />
      )}
      {open && (
        <ViewCreditNote
          open={open}
          onCancel={() => setIsOpen(false)}
          creditNoteId={creditNoteId}
          voucharType={splitPathInVchType}
        />
      )}
      {openSalesInvoice && (
        <ViewSalesInvoice
          open={openSalesInvoice}
          onCancel={() => setOpenSalesInvoice(false)}
          invoiceId={salesInvoiceId}
          invVoucharType={
            splitPathInVchType === Voucher_Type.CreditNote
              ? Voucher_Type.SalesVoucher
              : Voucher_Type.PurchaseVoucher
          }
        />
      )}
    </Card>
  );
};
export default CreditNotes;
