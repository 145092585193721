import { Fragment, useRef, useState } from "react";
import {
  Button,
  Modal,
  Form,
  Input,
  Divider,
  Spin,
  InputRef,
  Checkbox,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const AddSalesInvoiceExportDetail: React.FC<{
  open: boolean;
  onCancel: any;
  data: any;
}> = (props) => {
  console.log("props", props);
  const inputRef = useRef<InputRef>(null);
  const [form] = Form.useForm();
  const [saveButtonLoading, setSaveButtonLoading] = useState<boolean>(false);
  // const [groupData, setGroupData] = React.useState<any[]>();
  const [loading, setLoading] = useState<boolean>(false);

  const saveGroup = () => {
    setSaveButtonLoading(true);

    form.validateFields().then(async (value) => {
      setSaveButtonLoading(false);
      props.data(value);
      props.onCancel();
      console.log(value);
    });
  };

  return (
    <Fragment>
      <Modal
        open={props.open}
        title="Add Export Details"
        maskClosable={false}
        width={400}
        style={{ top: 44 }}
        onCancel={() => props.onCancel({})}
        footer={
          <div style={{ marginTop: "0px" }}>
            <Button
              onClick={() => {
                form.resetFields();
                props.onCancel({});
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={() => saveGroup()}
              style={{ width: "80px" }}
              loading={saveButtonLoading}
            >
              Save
            </Button>
          </div>
        }
      >
        <Spin
          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
          spinning={loading}
        >
          <Form
            name="exportDetail"
            form={form}
            autoComplete="off"
            colon={false}
            labelAlign="left"
            // initialValues={intialValue}
            labelCol={{ xs: 7, sm: 10 }}
            wrapperCol={{ xs: 16, sm: 14 }}
            labelWrap={true}
          >
            <Divider className="ca-model-css" />
            <Form.Item
              name="goodsReceiptPlace"
              label="Receipt Place"
              className="mb-14"
              //   rules={[{ required: true, message: "Account Code is required" }]}
            >
              <Input autoFocus={true} ref={inputRef} />
            </Form.Item>
            <Form.Item name="vesselNo" label="vesselNo" className="mb-12">
              <Input />
            </Form.Item>
            <Form.Item
              name="loadingPort"
              label="Loading Port"
              className="mb-14"
              //   rules={[{ required: true, message: "Account Type is required" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="dischargePort"
              label="Discharge Port"
              className="mb-14"
              //   rules={[{ required: true, message: "Nature is required" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="countryTo"
              label="Country To"
              className="mb-14"
              valuePropName="checked"
              //   rules={[{ required: true, message: "Statement  is required" }]}
            >
              <Checkbox />
            </Form.Item>
          </Form>
          <Divider className="divider-account" />
        </Spin>
      </Modal>
    </Fragment>
  );
};

export default AddSalesInvoiceExportDetail;
