import React, { useRef } from "react";
import {
  CloseOutlined,
  DeleteTwoTone,
  LoadingOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  AutoComplete,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Input,
  InputNumber,
  InputRef,
  message,
  Row,
  Select,
  Space,
  Spin,
} from "antd";
import { useClient } from "../../../Context/ClientContext";
import { notificationContext } from "../../../Common/PageRoute";
import { ConfirmationModal } from "../../../Common/ConfirmationModal";
import { useIndianState } from "../../../Context/StateOptionsContext";
import phoneImg from "../../../../images/phoneIcon.svg";

import emailImg from "../../../../images/emailIcon.svg";
import { dayjs } from "../../../../Utilities/dayjs";
import {
  BusinessNaturesTypeOptions,
  BusinessTypeOptions,
  GSTTypeOptions,
} from "../../Clients/Clients";
import "../../../Common/AddPageCommon.css";
import LedgerService, {
  Calc_Methods,
  Duty_Type,
  GSTTax_Type,
  SearchLedgerByGroupsTypes,
  TypeOfAddress,
} from "../../../Services/LedgerService";
import ChartofAccountServicesNew from "../../../Services/ChartofAccountServicesNew";
import AddGroup from "../Group/AddGroup";
import { CompanyGSTTypes } from "../../../../Types/CommonConstants";
import { BankAccountTypes } from "../../../Services/ClientService";
import { useHotkeys } from "react-hotkeys-hook";
import { useKeyboardShortcuts } from "../../../Common/KeyboardShortcuts ";
import EntryLevelKeys from "../../../Common/EntryLevelKeys";

export const AddLedger: React.FC<{
  openDrawer: boolean;
  id: any;
  setOpenDrawer: React.Dispatch<{}>;
  setTrigger?: any;
  name?: string;
  type?: SearchLedgerByGroupsTypes;
  setAddLedgerResponse?: any;
}> = (props) => {
  // console.log("props............", props?.name);
  const inputRef = useRef<InputRef>(null);
  const { currentClient, companySettings } = useClient();
  const [form] = Form.useForm();
  const { stateOptions } = useIndianState();
  const [width, SetWidth] = React.useState<any>(window.innerWidth);
  const [groupListTrigger, setGroupListTrigger] =
    React.useState<boolean>(false);
  const [bankTypeValue, setBankTypeValue] = React.useState<BankAccountTypes>(
    BankAccountTypes.CurrentAccount
  );
  const [bankDefaultChecked, setBankDefaultChecked] = React.useState<boolean[]>(
    [false]
  );
  const [saveButtonLoading, setSaveButtonLoading] =
    React.useState<boolean>(false);
  const [checkbox, setCheckbox] = React.useState<number>(0);
  const [gSTTypeValue, setGSTTypeValue] = React.useState<CompanyGSTTypes>(
    CompanyGSTTypes.Unregistered
  );
  const { openNotification } = React.useContext(notificationContext);
  const [confirmation, setConfirmation] = React.useState<boolean>(false);
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const [loading, setLoading] = React.useState<boolean>(false);
  const [groupData, setGroupData] = React.useState<any[]>([]);
  const [ledgerList, setLedgerList] = React.useState<any[]>();
  const [partyViewDetails, setPartyViewDetails] = React.useState<{
    sundryCreditor: boolean;
    sundryDebtors: boolean;
  }>({
    sundryCreditor: false,
    sundryDebtors: false,
  });

  const [openModel, setOpenModel] = React.useState<{
    open: boolean;
    param: any;
  }>({
    open: false,
    param: "",
  });

  const [bankViewDetails, setBankViewDetails] = React.useState<boolean>(false);
  const [dutiesAndTaxesDetails, setDutiesAndTaxesDetails] =
    React.useState<boolean>(false);
  const [expensesAndIncomesDetails, setExpensesAndIncomesDetails] =
    React.useState<boolean>(false);
  const [showFiledDutiedandTaxes, setShowFiledDutiedandTaxes] = React.useState<{
    taxInDigit: boolean;
    taxInSelect: boolean;
    gstType: boolean;
    calcMethod: boolean;
  }>({
    taxInDigit: false,
    taxInSelect: false,
    gstType: false,
    calcMethod: false,
  });
  const [showDetailsTSC, setShowDetailsTSC] = React.useState<boolean>(false);
  const [inputValue, setInputValue] = React.useState("");
  const [open, setOpen] = React.useState(false);

  const handleInputChange = (value: any) => {
    setInputValue(value);
    setOpen(value.length > 0);
  };

  const handleDelBankDetail = (nameIndex: number) => {
    setBankDefaultChecked((prevDetail: any) => {
      let newDetail;
      let selIndex = prevDetail.indexOf(true);
      if (selIndex === -1) {
        newDetail = prevDetail.filter(
          (_: any, index: number) => index !== nameIndex
        );
        newDetail = newDetail.map((d: any) => (d = false));
        newDetail[0] = true;
      } else if (selIndex === nameIndex) {
        newDetail = prevDetail.filter(
          (_: any, index: number) => index !== nameIndex
        );
        if (newDetail !== 0) {
          newDetail[0] = true;
        }
      } else if (selIndex < nameIndex) {
        newDetail = prevDetail.filter(
          (_: any, index: number) => index !== nameIndex
        );
      } else {
        newDetail = prevDetail.filter(
          (_: any, index: number) => index !== nameIndex
        );
        newDetail = newDetail.map((d: any) => (d = false));
        newDetail[selIndex - 1] = true;
      }
      console.log("Udated BankDetail", newDetail);

      return newDetail;
    });
  };

  const setBankDefaultValueHandler = (val: any, nameIndex: number) => {
    setBankDefaultChecked(() => {
      let bankVal = form.getFieldValue("bankDetail");
      if (bankVal === null) {
        return [false];
      } else {
        return bankVal?.map((bankVal: any, index: number) =>
          index === nameIndex && val === true ? true : false
        );
      }
    });
  };

  const gstOption = companySettings?.setting?.gstDetails?.gst;
  const handlePANNo = (e: any) => {
    const { value } = e.target;
    form.setFieldValue("pan", value.slice(2, 12));
    form.setFieldValue("state", Number(value.slice(0, 2)));
  };

  const onDismissConfirm = () => {
    setConfirmation(false);
    props.setOpenDrawer({ open: false, param: "" });
  };

  const onDismissBothModels = () => {
    // setTouched(false);
    setConfirmation(false);
    props.setOpenDrawer({ open: false, param: "" });
    // setErrorForBName(false);
    form.resetFields();
  };

  // ----- get group list api -----
  React.useEffect(() => {
    // inputRef.current?.focus();
    setLoading(true);
    const getListData = async () => {
      let temp = await ChartofAccountServicesNew.getGroupList(
        currentClient?.id!,
        "",
        "",
        "",
        "",
        props.type
      );
      if (temp) {
        setGroupData(temp.items);
        // if (props.editData.id) {
        //   setGroupType(props.editData?.group_Type);
        //   form.setFieldsValue(props.editData);
        // }

        setLoading(false);
      } else {
        setGroupData([]);
        console.log("Error", temp);
        setLoading(false);
      }
    };

    getListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupListTrigger]);

  //Edit get group data list api (update api)
  React.useEffect(() => {
    // inputRef.current?.focus();
    if (props?.id && groupData.length > 0) {
      setLoading(true);
      const getGroupDeatils = async (id: string) => {
        await LedgerService.getLedgerDetails(currentClient?.id!, id)
          .then((res: any) => {
            console.log("result...........", res);
            if (res?.result) {
              console.log("getdata---------", res.result, res.result.parent);
              findParentGroup(res.result?.parent);
              // const contactInfo = res.result?.contactInformation?.map(
              //   (itm: any) => {
              //     return {
              //       ...itm,
              //       name: {
              //         ...itm?.name,
              //         title:
              //           itm?.name?.title !== 0 ? itm?.name?.title : undefined,
              //       },
              //     };
              //   }
              // );
              dutiesAndTaxesOnChange(res.result?.typeOfDuty);
              form.setFieldsValue({
                ...res.result,
                parent: res.result?.parent,
                // gstRate: {
                //   rate: value?.gstRate?.title ?? value?.gstRate,
                //   id: value?.gstRate?.value,
                //   name: value?.gstRate?.label,
                // },
                bankDetail: res.result?.bankDetail
                  ? res.result?.bankDetail
                  : [{}],
                businessNature:
                  res?.result?.businessNature !== null
                    ? res?.result?.businessNature
                    : [],

                gstRate: res?.result?.id
                  ? {
                      title: res?.result?.gstRate?.rate,
                      value: res?.result?.gstRate?.id,
                      label: res?.result?.gstRate?.name,
                    }
                  : res?.result?.gstRate?.rate,
                // contactInformation: res.result?.contactInformation,
                // openingBalanceDate:
                //   res.result?.openingBalanceDate === ""
                //     ? undefined
                //     : dayjs(res.result?.openingBalanceDate),
                // sameasAddress:
                //   res.result?.billingAddress === res.result?.shippingAddress,
              });
              setBankDefaultChecked(
                res?.result?.bankDetail !== null
                  ? res?.result?.bankDetail?.map(
                      (bankVal: any, index: number) =>
                        bankVal?.isDefault === true ? true : false
                    )
                  : [false]
              );

              setGSTTypeValue(res?.result?.gstType);
              setCheckbox(
                res.result?.contactInformation
                  ?.map((e: any, i: number) => {
                    return e.isDefault;
                  })
                  .indexOf(true)
              );
              setShowDetailsTSC(res.result?.ledgerTCSApplicability);
              setLoading(false);
            } else {
              openNotification(res?.message);
            }
          })
          .catch((err: any) => {
            setLoading(false);
            openNotification(err?.message);
            //console.log("error", err);
          });
      };
      getGroupDeatils(props?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.id, groupData]);

  const getwidth = () => {
    if (window.innerWidth) {
      return window.innerWidth === 1024 ? 680 : 920;
    }
  };

  // Show drawer fileds function
  const findParentGroup = (parent: string, defaultGroupName?: string) => {
    console.log("parent", parent, defaultGroupName, "defaultGroupName");
    setPartyViewDetails({ sundryCreditor: false, sundryDebtors: false });
    setBankViewDetails(false);
    setDutiesAndTaxesDetails(false);
    setExpensesAndIncomesDetails(false);

    var x = groupData.find((x) => x.id === parent);
    console.log("findgroup....", x);
    if (
      !x?.editable ||
      defaultGroupName === "Trade Payables - Sundry Creditors" ||
      // defaultGroupName === "Trade Payables - Others" ||
      defaultGroupName === "Trade Receivables - Sundry Debtors" ||
      // defaultGroupName === "Trade Receivables - Others" ||
      defaultGroupName === "Bank Account" ||
      defaultGroupName === "Bank OD A/c" ||
      defaultGroupName === "Duties & Taxes" ||
      defaultGroupName === "Indirect Expenses - Others" ||
      defaultGroupName === "Indirect Incomes" ||
      defaultGroupName === "Direct Expenses" ||
      defaultGroupName === "Direct Incomes"
    ) {
      // if (
      //   x?.group_Name === "Trade Payables - Sundry Creditors" ||
      //   x?.group_Name === "Trade Receivables - Sundry Debtors" ||
      //   defaultGroupName === "Trade Payables - Sundry Creditors" ||
      //   defaultGroupName === "Trade Receivables - Sundry Debtors"
      // ) {
      //   setPartyViewDetails({ sundryDebtors: true, sundryCreditor: false });
      //   if (
      //     defaultGroupName === "Trade Payables - Sundry Creditors" ||
      //     x?.group_Name === "Trade Payables - Sundry Creditors"
      //   ) {
      //     setPartyViewDetails({ sundryDebtors: true, sundryCreditor: true });
      //   }
      // }
      if (
        x?.group_Name === "Trade Payables - Sundry Creditors" ||
        defaultGroupName === "Trade Payables - Sundry Creditors"
        // x?.group_Name === "Trade Payables - Others" ||
        // defaultGroupName === "Trade Payables - Others"
      ) {
        setPartyViewDetails({ sundryDebtors: true, sundryCreditor: true });
      } else if (
        x?.group_Name === "Trade Receivables - Sundry Debtors" ||
        defaultGroupName === "Trade Receivables - Sundry Debtors"
        // x?.group_Name === "Trade Receivables - Others" ||
        // defaultGroupName === "Trade Receivables - Others"
      ) {
        setPartyViewDetails({ sundryDebtors: true, sundryCreditor: false });
      } else if (
        x?.group_Name === "Bank Account" ||
        defaultGroupName === "Bank Account" ||
        defaultGroupName === "Bank OD A/c"
      ) {
        setBankViewDetails(true);
      } else if (
        x?.group_Name === "Duties & Taxes" ||
        defaultGroupName === "Duties & Taxes"
      ) {
        setDutiesAndTaxesDetails(true);
        setShowFiledDutiedandTaxes({
          taxInDigit: false,
          taxInSelect: true,
          gstType: true,
          calcMethod: false,
        });
      } else if (
        x?.group_Name === "Indirect Expenses - Others" ||
        defaultGroupName === "Indirect Expenses - Others" ||
        x?.group_Name === "Indirect Incomes" ||
        defaultGroupName === "Indirect Incomes"
      ) {
        form.setFieldValue("typeOfDuty", Duty_Type.GST);
        setExpensesAndIncomesDetails(true);
      }
    } else {
      findParentGroup(x?.parent);
    }
  };

  // post api
  const saveLedger = () => {
    form
      .validateFields()
      .then(async (value) => {
        // console.log("post Data....", value);
        // payTerms;
        setSaveButtonLoading(true);
        let data = {
          ...value,
          gstRate: {
            rate: value?.gstRate?.title ?? value?.gstRate,
            id: value?.gstRate?.value,
            name: value?.gstRate?.label,
          },
          parent: value?.parent?.value ?? value?.parent,
          order: value?.order ?? 1000,
          contactInformation: value?.contactInformation?.map(
            (index: any, i: number) => {
              console.log(checkbox);
              if (i === checkbox) {
                return Object.assign(index, { isDefault: true });
              } else if (i !== checkbox) {
                return Object.assign(index, { isDefault: false });
              }
            }
          ),
          system: false,
          isActive: true,
          isAudited: false,
          editable: true,
          bankDetail: value?.bankDetail?.map((b: any, index: number) => {
            b.isDefault = bankDefaultChecked[index];
            return b;
          }),
          partyDisRate: value?.partyDisRate ?? 0,
          payTerms: {
            creditLimit: value?.payTerms?.creditLimit ?? 0,
            creditPeriod: value?.payTerms?.creditPeriod ?? 0,
          },
        };

        //console.log("post Data....", data);

        await LedgerService.postLedger(currentClient?.id!, data, props?.id)
          .then((res: any) => {
            if (res.result) {
              // setProps1(true);
              props.setTrigger();
              props?.setOpenDrawer(false);
              openNotification(
                "success",
                `${res.result?.ledgerName} Ledger ${
                  props?.id ? "Edit successfully" : "Add successfully"
                }`
              );
              props?.setAddLedgerResponse &&
                props?.setAddLedgerResponse(res.result);
              props.setOpenDrawer({});
            } else if (res?.message) {
              openNotification("error", res?.message);
            }
            setSaveButtonLoading(false);
          })
          .catch((ex: any) => {
            console.error(ex);
            openNotification("error", ex.message);
            setSaveButtonLoading(false);
          });
      })
      .catch((e) => {
        form.scrollToField(e?.errorFields[0]?.name[0]);
      });
  };

  // EntryLevelKeys({
  //   save: () => saveLedger(),
  //   cancel: () => props?.setOpenDrawer({}),
  // });

  useKeyboardShortcuts("s", ["altKey"], saveLedger);
  useKeyboardShortcuts("c", ["altKey"], () => props?.setOpenDrawer({}));
  // function handleRemoveKeyPress(
  //   e: React.KeyboardEvent<HTMLSpanElement>,
  //   arg1: () => void
  // ): void {
  //   throw new Error("Function not implemented.");
  // }

  const dutiesAndTaxesOnChange = (e: number) => {
    if (e === 0) {
      setShowFiledDutiedandTaxes({
        taxInDigit: false,
        taxInSelect: true,
        gstType: true,
        calcMethod: false,
      });
    } else if (e === 1 || e === 2) {
      setShowFiledDutiedandTaxes({
        taxInDigit: false,
        taxInSelect: false,
        gstType: false,
        calcMethod: false,
      });
    } else if (e === 3 || e === 4 || e === 5) {
      setShowFiledDutiedandTaxes({
        taxInDigit: true,
        taxInSelect: false,
        gstType: false,
        calcMethod: true,
      });
    }
  };

  React?.useEffect(() => {
    getLedgerData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLedgerData = async () => {
    let res = await LedgerService?.getLedgerList(currentClient?.id);
    if (res) {
      console.log("ledgerlist", res);
      setLedgerList(res?.items);
    }
  };

  return (
    <React.Fragment>
      <Drawer
        title={props?.id === "" ? "Add Ledger" : "Edit Ledger"}
        open={props.openDrawer}
        // onClose={onClose}
        width={getwidth()}
        maskClosable={false}
        closable={false}
        extra={
          <Space>
            <CloseOutlined onClick={() => props.setOpenDrawer({})} />
          </Space>
        }
        footer={
          <Space
            style={{
              display: "flex",
              justifyContent: "end",
              marginRight: 20,
            }}
          >
            <Button onClick={() => props.setOpenDrawer({})}>Cancel</Button>
            <Button
              onClick={() => saveLedger()}
              type="primary"
              loading={saveButtonLoading}
            >
              {props?.id === "" ? "Save" : "Update"}
            </Button>
          </Space>
        }
        className="ledgerdrawerstyle"
      >
        <Spin indicator={antIcon} spinning={loading}>
          <Form
            form={form}
            name="customers"
            colon={false}
            requiredMark={true}
            initialValues={{
              // openingstock: Utils.getFormattedNumber(0),
              // atprice: 0,
              // totalprice: 0,
              bankDetail: [{}],
              contactInformation: [{}],
              addresses: [{}],
              parent: props?.name,
              gstType: 0,
              // typeOfDuty: Duty_Type.GST,
              // type: 0,
              // gstType: 0,
            }}
            scrollToFirstError
            autoComplete="off"
            layout="vertical"
          >
            <Row
              gutter={14}
              style={{ justifyContent: width <= 375 ? "center" : "" }}
            >
              <Col lg={8} md={8} sm={12} xs={24}>
                <Form.Item
                  name="ledgerName"
                  className="inputBoxMb8 inputLabel33"
                  label="Ledger Name"
                  rules={[
                    {
                      required: true,
                      message: "Ledger name is required",
                      pattern: /^[A-Za-z][ A-Za-z0-9_()&-.]*$/,
                    },
                  ]}
                >
                  <AutoComplete
                    style={{ width: "100%" }}
                    options={ledgerList?.map((value: any) => ({
                      // value: value?.id,
                      value: value?.ledgerName,
                      title: value?.parent,
                    }))}
                    placeholder="Type here"
                    filterOption={(inputValue, option) =>
                      option!?.value
                        ?.toUpperCase()
                        ?.indexOf(inputValue?.toUpperCase()) !== -1
                    }
                    value={inputValue}
                    autoFocus={true}
                    onChange={handleInputChange}
                    open={open}
                  />
                </Form.Item>
              </Col>
              <Col lg={8} md={8} sm={12} xs={24}>
                <Form.Item
                  name="aliasName"
                  className="inputBoxMb8 inputLabel33"
                  label="Alias/Short Name"
                >
                  <Input
                    onChange={(e: any) => {
                      const newvalue = e.target.value;
                      const capitalvalue =
                        newvalue.charAt(0).toUpperCase() + newvalue.slice(1);
                      form.setFieldValue("aliasName", capitalvalue);
                    }}
                    // style={{
                    //   width: window.innerWidth <= 425 ? "226px" : " ",
                    // }}
                  />
                </Form.Item>
              </Col>
              {/* <Col lg={8} md={8} sm={12} xs={24}>
                <Form.Item
                  name="order"
                  label="Order"
                  className="inputBoxMb8 inputLabel33"
                >
                  <InputNumber style={{ width: "100%" }} controls={false} />
                </Form.Item>
              </Col> */}

              <Col lg={8} md={8} sm={12} xs={24}>
                <Form.Item
                  name="parent"
                  label="Parent Group"
                  className="inputBoxMb8 inputLabel33"
                  rules={[
                    { required: true, message: "Account Type is required" },
                  ]}
                >
                  <Select
                    placeholder="Select Group"
                    filterOption={(input, option) =>
                      (option?.label?.toString() ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    labelInValue
                    options={groupData?.map((value: any) => ({
                      value: value?.id,
                      label: value?.group_Name.concat(
                        value?.aliasName
                          ? `${" (" + value?.aliasName + ")"}`
                          : ""
                      ),
                      title: value?.parent,
                    }))}
                    onSelect={(e) => console.log(e)}
                    onChange={(e) => findParentGroup(e.title, e.label)}
                    showSearch
                    dropdownRender={(customerOptions) => (
                      <>
                        {customerOptions}
                        <Divider style={{ margin: "5px 0px" }} />
                        <Button
                          style={{ width: "100%" }}
                          icon={<PlusOutlined />}
                          type="link"
                          onClick={() =>
                            setOpenModel({ open: true, param: "" })
                          }
                        >
                          New Group
                        </Button>
                      </>
                    )}
                  />
                </Form.Item>
              </Col>
            </Row>
            {/* Bank Details */}
            {(partyViewDetails.sundryDebtors || bankViewDetails) && (
              <Form.List name="bankDetail">
                {(fields, { add, remove }) => (
                  <>
                    <div>
                      <Divider
                        orientation="left"
                        orientationMargin="0"
                        className="dividertext"
                        style={{ marginTop: "11px", marginBottom: "11px" }}
                      >
                        <p style={{ fontWeight: 600 }}>Bank Details</p>
                      </Divider>
                      {fields.map(({ key, name, ...restField }, index) => (
                        <Row key={key} gutter={14}>
                          <Col lg={6} md={8} sm={12} xs={24}>
                            <Form.Item
                              name={[name, "accountHolderName"]}
                              className="inputBoxMb8 inputLabel33"
                              label="Account Holder Name"
                              rules={[
                                {
                                  // required: true,
                                  pattern: /^[A-Za-z][ A-Za-z0-9_()&-.]*$/,
                                  message: "Enter Valid Account Holder Name",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col lg={6} md={8} sm={12} xs={24}>
                            <Form.Item
                              name={[name, "accountNumber"]}
                              className="inputBoxMb8 inputLabel33"
                              label="Account Number"
                              // rules={[
                              //   {
                              //     pattern: new RegExp(/^\d{9,18}$/),
                              //     message: "Invalid number",
                              //   },
                              // ]}
                            >
                              <Input
                                minLength={9}
                                maxLength={20}
                                style={{ width: "100%" }}
                                // onKeyPress={(e) =>
                                //   !/[0-9]/.test(e.key) && e.preventDefault()
                                // }
                              />
                            </Form.Item>
                          </Col>
                          <Col lg={6} md={8} sm={12} xs={24}>
                            <Form.Item
                              name={[name, "accountType"]}
                              label="Account Type"
                              className="inputBoxMb8 inputLabel33"
                            >
                              <Select
                                placeholder="Account Type"
                                onChange={(val) => {
                                  setBankTypeValue(val);
                                }}
                                options={[
                                  {
                                    label: "Select Account Type",
                                    value: BankAccountTypes.All,
                                  },
                                  {
                                    label: "Saving Account",
                                    value: BankAccountTypes.SavingAccount,
                                  },
                                  {
                                    label: "Current Account",
                                    value: BankAccountTypes.CurrentAccount,
                                  },
                                  {
                                    label: "Cash Credit Account",
                                    value: BankAccountTypes.CashCreditAccount,
                                  },
                                  {
                                    label: "Overdraft Account",
                                    value: BankAccountTypes.OverdraftAccount,
                                  },
                                ]}
                                style={{
                                  width:
                                    window.innerWidth <= 425 ? "226px" : " ",
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col lg={6} md={8} sm={12} xs={24}>
                            <Form.Item
                              name={[name, "bankName"]}
                              label="Bank Name"
                              className="inputBoxMb8 inputLabel33"
                              rules={[
                                {
                                  pattern: /^[A-Za-z][ A-Za-z0-9_()&-.]*$/,
                                  message: "Enter Valid Bank Name",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col lg={6} md={8} sm={12} xs={24}>
                            <Form.Item
                              name={[name, "ifsCode"]}
                              label="IFSC Code"
                              className="inputBoxMb8 inputLabel33"
                              rules={[
                                {
                                  pattern: /^[A-Z]{4}[0][0-9]{6}$/,
                                  message: "Input valid IFSC Code ",
                                },
                              ]}
                            >
                              <Input placeholder="BANK0123456" />
                            </Form.Item>
                          </Col>

                          <Col lg={6} md={8} sm={12} xs={24}>
                            <Form.Item
                              name={[name, "branch"]}
                              label="Branch"
                              className="inputBoxMb8 inputLabel33"
                              rules={[
                                {
                                  pattern: /^[A-Za-z][ A-Za-z]*$/,
                                  message: "Enter Valid Branch Name",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>

                          <Col lg={6} md={8} sm={12} xs={24}>
                            <Form.Item
                              name={[name, "upiId"]}
                              label="UPI Id"
                              className="inputBoxMb8 inputLabel33"
                              rules={[
                                {
                                  pattern:
                                    /^[a-zA-Z0-9.\-_]{2,256}@[a-zA-Z]{2,64}$/,
                                  message: "Please enter a valid UPI Id!",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col lg={2} md={2} sm={2} xs={2}>
                            <Form.Item
                              name={[name, "isDefault"]}
                              label="Default"
                              className="inputBoxMb8 inputLabel33"
                            >
                              <Checkbox
                                checked={bankDefaultChecked[name]}
                                onChange={({ target }) => {
                                  setBankDefaultValueHandler(
                                    target.checked,
                                    name
                                  );
                                  //console.log("checkbox result: ", e);
                                }}
                              />
                            </Form.Item>
                          </Col>

                          {bankTypeValue ===
                            BankAccountTypes.CashCreditAccount ||
                          bankTypeValue ===
                            BankAccountTypes.OverdraftAccount ? (
                            <Col lg={3} md={6} sm={10} xs={22}>
                              <Form.Item
                                name={[name, "oDLimit"]}
                                label={
                                  bankTypeValue ===
                                  BankAccountTypes.CashCreditAccount
                                    ? "CC Limit"
                                    : "Over Draft Limit"
                                }
                                rules={[
                                  {
                                    pattern: /^[0-9]$/,
                                    message: "Please enter a valid amount.",
                                  },
                                ]}
                                className="inputBoxMb8 inputLabel33"
                              >
                                <InputNumber style={{ width: "100%" }} />
                              </Form.Item>
                            </Col>
                          ) : (
                            <Col lg={3} md={6} sm={10} xs={22}></Col>
                          )}

                          {partyViewDetails.sundryDebtors && (
                            <Col
                              span={1}
                              className="pb"
                              style={{
                                textAlign: "end",
                                marginTop: "28px",
                                paddingRight: "10px",
                              }}
                            >
                              <Button
                                style={{
                                  padding: "0px",
                                  borderColor: "#fff",
                                  boxShadow: "none",
                                }}
                                onClick={() => {
                                  remove(name);
                                  handleDelBankDetail(name);
                                }}
                              >
                                <DeleteTwoTone
                                  className="ca-delete-btn"
                                  twoToneColor="#ff879d"
                                />
                              </Button>
                            </Col>
                          )}
                          {/* <Col lg={6} md={8} sm={12} xs={24}>
                    <Form.Item
                      name={["bankDetail", "odLimit"]}
                      label="Over Draft Limit"
                      className="inputBoxMb8 inputLabel33"
                    >
                      <Input />
                    </Form.Item>
                  </Col> */}
                          <Divider style={{ margin: "7px" }} />
                        </Row>
                      ))}
                      {partyViewDetails.sundryDebtors && (
                        <Form.Item className="inputBoxMb">
                          <Row key={"add Bank Detail"}>
                            <Col>
                              <Button
                                type="link"
                                onClick={() => add()}
                                block
                                icon={<PlusOutlined />}
                              >
                                Add Bank Detail
                              </Button>
                            </Col>
                          </Row>
                        </Form.Item>
                      )}
                    </div>
                  </>
                )}
              </Form.List>
            )}

            {/* Duties & Taxes details  */}
            {dutiesAndTaxesDetails && (
              <div>
                <Divider
                  orientation="left"
                  orientationMargin="0"
                  className="dividertext"
                  style={{ marginTop: "11px", marginBottom: "11px" }}
                >
                  <p style={{ fontWeight: 600 }}>Tax Details</p>
                </Divider>
                <Row gutter={14}>
                  <Col lg={6} md={8} sm={12} xs={24}>
                    <Form.Item
                      name="typeOfDuty"
                      label="Duty / Type"
                      className="inputBoxMb8 inputLabel33"
                    >
                      <Select
                        options={[
                          {
                            label: "GST",
                            value: Duty_Type.GST,
                          },
                          {
                            label: "TDS",
                            value: Duty_Type.TDS,
                          },
                          {
                            label: "TCS",
                            value: Duty_Type.TCS,
                          },
                          {
                            label: "Cess",
                            value: Duty_Type.Cess,
                          },
                          {
                            label: "Surcharge",
                            value: Duty_Type.Surcharge,
                          },
                          {
                            label: "Other",
                            value: Duty_Type.Other,
                          },
                        ]}
                        onChange={(e: any) => {
                          dutiesAndTaxesOnChange(e);
                        }}
                      />
                    </Form.Item>
                  </Col>
                  {showFiledDutiedandTaxes.gstType && (
                    <Col lg={6} md={8} sm={12} xs={24}>
                      <Form.Item
                        name="gstTaxType"
                        label="GST Type"
                        className="inputBoxMb8 inputLabel33"
                      >
                        <Select
                          options={[
                            {
                              label: "IGST",
                              value: GSTTax_Type.IntegratedTax,
                            },
                            {
                              label: "CGST",
                              value: GSTTax_Type.CentralTax,
                            },
                            {
                              label: "SGST",
                              value: GSTTax_Type.StateTax,
                            },
                            {
                              label: "UTGST",
                              value: GSTTax_Type.UnionTerritoryTax,
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                  )}

                  {showFiledDutiedandTaxes.taxInDigit && (
                    <Col lg={6} md={8} sm={12} xs={24}>
                      <Form.Item
                        name="gstRate"
                        label="Tax Rate"
                        className="inputBoxMb8 inputLabel33"
                      >
                        <InputNumber
                          placeholder="0"
                          addonAfter="%"
                          style={{ width: "100%" }}
                          controls={false}
                        />
                      </Form.Item>
                    </Col>
                  )}

                  {showFiledDutiedandTaxes.taxInSelect && (
                    <Col lg={6} md={8} sm={12} xs={24}>
                      <Form.Item
                        name="gstRate"
                        label="Tax Rate"
                        className="inputBoxMb8 inputLabel33"
                      >
                        <Select
                          filterOption={(input, option) =>
                            (option?.label?.toString() ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          showSearch
                          labelInValue={true}
                          options={companySettings?.setting?.gstDetails?.gst?.map(
                            (gst: any) => ({
                              key: gst.name,
                              value: gst.id,
                              title: gst.rate,
                              label: gst.name,
                            })
                          )}
                        />
                      </Form.Item>
                    </Col>
                  )}

                  {showFiledDutiedandTaxes.calcMethod && (
                    <Col lg={6} md={8} sm={12} xs={24}>
                      <Form.Item
                        name="methodOfCalculation"
                        label="Calc Methods"
                        className="inputBoxMb8 inputLabel33"
                      >
                        <Select
                          options={[
                            {
                              label: "TaxBased On ItemRate",
                              value: Calc_Methods.TaxBasedOnItemRate,
                            },
                            {
                              label: "On Total Sales",
                              value: Calc_Methods.OnTotalSales,
                            },
                            {
                              label: "SurchargeOnTax",
                              value: Calc_Methods.SurchargeOnTax,
                            },
                            {
                              label: "Additional Duty",
                              value: Calc_Methods.AdditionalDuty,
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                  )}
                </Row>
              </div>
            )}

            {/* { Income & Expensice Tax details } */}
            {expensesAndIncomesDetails && (
              <div>
                <Divider
                  orientation="left"
                  orientationMargin="0"
                  className="dividertext"
                  style={{ marginTop: "11px", marginBottom: "11px" }}
                >
                  <p style={{ fontWeight: 600 }}>Tax Details</p>
                </Divider>
                <Row gutter={14}>
                  <Col lg={6} md={8} sm={12} xs={24}>
                    <Form.Item
                      label="Duty / Type"
                      name="typeOfDuty"
                      className="inputBoxMb8 inputLabel33"
                    >
                      <Select
                        disabled
                        options={[
                          {
                            label: "GST",
                            value: Duty_Type.GST,
                          },
                        ]}
                      />
                    </Form.Item>
                  </Col>

                  <Col lg={6} md={8} sm={12} xs={24}>
                    <Form.Item
                      name="gstRate"
                      label="Tax Rate"
                      className="inputBoxMb8 inputLabel33"
                    >
                      <Select
                        filterOption={(input, option) =>
                          (option?.label?.toString() ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        showSearch
                        labelInValue={true}
                        options={companySettings?.setting?.gstDetails?.gst?.map(
                          (gst: any) => ({
                            key: gst.name,
                            value: gst.id,
                            title: gst.rate,
                            label: gst.name,
                          })
                        )}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            )}

            {/* Party details */}
            {partyViewDetails.sundryDebtors && (
              <>
                <Divider
                  orientation="left"
                  orientationMargin="0"
                  className="dividertext"
                  style={{ marginTop: "11px", marginBottom: "11px" }}
                >
                  <p style={{ fontWeight: 600 }}>Business Details</p>
                </Divider>
                <Row
                  gutter={14}
                  style={{ justifyContent: width <= 375 ? "center" : "" }}
                >
                  {/* GST */}
                  <Col lg={6} md={8} sm={12} xs={24}>
                    <Form.Item
                      name="gstType"
                      label="GST Type"
                      className="inputBoxMb8 inputLabel33"
                      rules={[
                        {
                          required: true,
                          message: "Please select the appropriate GST Type",
                        },
                      ]}
                    >
                      <Select
                        onChange={(val) => {
                          setGSTTypeValue(val);
                        }}
                        options={GSTTypeOptions}
                      />
                    </Form.Item>
                  </Col>

                  <Col lg={6} md={8} sm={12} xs={24}>
                    <Form.Item
                      name="gstin"
                      className="inputBoxMb8 inputLabel33"
                      label="GSTIN"
                      rules={[
                        {
                          required:
                            gSTTypeValue === CompanyGSTTypes.Unregistered ||
                            gSTTypeValue === CompanyGSTTypes.Consumer
                              ? false
                              : true,
                          pattern:
                            /^(?!00)[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}Z[0-9A-Za-z]{1}$/,
                          message: "Enter Correct Gst Number",
                        },
                      ]}
                    >
                      <Input
                        placeholder="00AABCC1234D1ZZ"
                        allowClear={true}
                        onChange={(e: any) => {
                          const capitalvalue = e.target.value.toUpperCase();
                          form.setFieldValue("gstin", capitalvalue);
                        }}
                        onBlur={handlePANNo}
                        disabled={
                          gSTTypeValue === CompanyGSTTypes.Unregistered ||
                          gSTTypeValue === CompanyGSTTypes.Consumer
                            ? true
                            : false
                        }
                        // onChange={(e: any) => {
                        //   if (e.target.value) setTouched(true);
                        //   else setTouched(false);
                        // }}
                        suffix={<SearchOutlined />}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={6} md={8} sm={12} xs={24}>
                    <Form.Item
                      name="pan"
                      className="inputBoxMb8 inputLabel33"
                      label="PAN Card"
                      rules={[
                        {
                          required: false,
                          pattern: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
                          message: "Enter Correct PAN Number",
                        },
                      ]}
                    >
                      <Input
                        placeholder="AABCC1234D"
                        onChange={(e: any) => {
                          const capitalvalue = e.target.value.toUpperCase();
                          form.setFieldValue("pan", capitalvalue);
                        }}
                        // style={{
                        //   width: window.innerWidth <= 425 ? "226px" : " ",
                        // }}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={6} md={8} sm={12} xs={24}>
                    <Form.Item
                      name="state"
                      label="State"
                      className="inputBoxMb8 inputLabel33"
                      rules={[
                        {
                          required:
                            gSTTypeValue === CompanyGSTTypes.Unregistered ||
                            gSTTypeValue === CompanyGSTTypes.Consumer
                              ? true
                              : false,
                          message: "Please select the State",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="State"
                        options={stateOptions?.map((x: any) => ({
                          value: x.id,
                          label: x.name,
                        }))}
                        filterOption={(input, option) =>
                          (option?.label.toString() ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        style={{
                          width: window.innerWidth <= 425 ? "226px" : " ",
                        }}
                        onChange={(e: any) => {
                          console.log(e);
                        }}
                      />
                    </Form.Item>
                  </Col>
                  {/* Business  */}
                  <Col lg={6} md={8} sm={12} xs={24}>
                    <Form.Item
                      name="businessType"
                      label="Business Type"
                      className="inputBoxMb8 inputLabel33"
                    >
                      <Select
                        allowClear
                        showSearch
                        options={BusinessTypeOptions}
                        placeholder="Select Type"
                        filterOption={(input, option) =>
                          (option?.label?.toString() ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={6} md={8} sm={12} xs={24}>
                    <Form.Item
                      name="businessNature"
                      label="Business Nature"
                      className="inputBoxMb8 inputLabel33"
                    >
                      <Select
                        mode="multiple"
                        showSearch={false}
                        placeholder="Select Nature"
                        //onChange={handleChange}
                        options={BusinessNaturesTypeOptions}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={6} md={8} sm={12} xs={24}>
                    <Form.Item
                      name="website"
                      label="Website"
                      className="inputBoxMb8 inputLabel33"
                      rules={[
                        {
                          type: "url",
                          message: "Please enter a valid URL!",
                        },
                      ]}
                    >
                      <Input placeholder="www.example.com" />
                    </Form.Item>
                  </Col>
                  <Col lg={6} md={8} sm={12} xs={24}>
                    <Form.Item
                      name="partyDisRate"
                      label="Party Discount Rate"
                      className="inputBoxMb8 inputLabel33"
                      rules={[
                        {
                          required: false,
                          message: "Please input the party discount rate!",
                        },
                        {
                          type: "number",
                          min: 0,
                          max: 100,
                          message: "Discount rate must be between 0 and 100 %",
                        },
                      ]}
                    >
                      <InputNumber
                        onKeyPress={(e) =>
                          !/[0-9]/.test(e.key) && e.preventDefault()
                        }
                        style={{ width: "100%" }}
                        controls={false}
                      />
                    </Form.Item>
                  </Col>
                  {/* isTransporter */}
                  <Col lg={6} md={8} sm={12} xs={24}>
                    <Form.Item
                      name="Tan"
                      label="TAN"
                      className="inputBoxMb8 inputLabel33"
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col lg={6} md={8} sm={12} xs={24}>
                    <Form.Item
                      name="iec"
                      label="IEC"
                      className="inputBoxMb8 inputLabel33"
                      rules={[
                        { required: false, message: "Please input your IEC!" },
                        {
                          pattern: /^[A-Z0-9]{10}$/,
                          message:
                            "IEC must be a 10-character alphanumeric code!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col lg={6} md={8} sm={12} xs={24}>
                    <Form.Item
                      name="msmeNo"
                      label="MSME Number"
                      className="inputBoxMb8 inputLabel33"
                      rules={[
                        {
                          required: false,
                          message: "Please input your MSME Number!",
                        },
                        {
                          pattern: /^[A-Z0-9]+$/,
                          message: "MSME Number must be alphanumeric!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  {partyViewDetails.sundryCreditor ? (
                    <Col lg={6} md={8} sm={12} xs={24}>
                      <Form.Item
                        name="isTransporter"
                        label="Is Transporter"
                        className="inputBoxMb8 inputLabel33"
                        valuePropName="checked"
                      >
                        <Checkbox />
                      </Form.Item>
                    </Col>
                  ) : (
                    <Col lg={6} md={8} sm={0}></Col>
                  )}
                  {/* ----- TCS ----- */}
                  <Col lg={6} md={8} sm={12} xs={24}>
                    <Form.Item
                      name="ledgerTCSApplicability"
                      label="TCS Applicability"
                      className="inputBoxMb8 inputLabel33"
                      valuePropName="checked"
                    >
                      <Checkbox
                        onChange={(e) => setShowDetailsTSC(e.target.checked)}
                      />
                    </Form.Item>
                  </Col>
                  {showDetailsTSC && (
                    <>
                      <Col lg={6} md={8} sm={12} xs={24}>
                        <Form.Item
                          name="ledgerTCSRate"
                          label="TCS Rate"
                          className="inputBoxMb8 inputLabel33"
                          rules={[
                            {
                              required: true,
                              message: "Please input your Rate!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col lg={12} md={8} sm={12} xs={24}>
                        <Form.Item
                          name="ledgerTCSNture"
                          label="TCS Nature"
                          className="inputBoxMb8 inputLabel33"
                          rules={[
                            {
                              required: true,
                              message: "TCS nature is required!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </>
                  )}
                </Row>
                {/*  Contact */}
                <div>
                  <Divider
                    orientation="left"
                    orientationMargin="0"
                    className="dividertext"
                    style={{ marginTop: "11px", marginBottom: "11px" }}
                  >
                    <p style={{ fontWeight: 600 }}>Contact Details</p>
                  </Divider>
                </div>
                <div
                  style={{
                    maxWidth: window.innerWidth <= 425 ? "800px" : "",
                    overflowX: "auto",
                  }}
                >
                  <table style={{ width: "100%" }}>
                    <thead>
                      <tr>
                        <th
                          style={{ width: "20%" }}
                          className="textStart fw-500 "
                        >
                          Name
                        </th>
                        <th
                          style={{ width: "20%" }}
                          className="textStart fw-500 "
                        >
                          Designation
                        </th>
                        <th
                          style={{ width: "15%" }}
                          className="textStart fw-500"
                        >
                          Phone No.
                        </th>
                        <th
                          style={{ width: "18.5%", paddingLeft: "6px" }}
                          className="textStart fw-500"
                        >
                          Email
                        </th>
                        <th
                          style={{ width: "18.5%", paddingLeft: "6px" }}
                          className="textStart fw-500"
                        >
                          CC
                        </th>
                        <th style={{ width: "2%" }}></th>
                      </tr>
                    </thead>
                    <Form.List name="contactInformation">
                      {(fields, { add, remove }) => (
                        <>
                          <tbody>
                            {fields.map(
                              ({ key, name, ...restField }, index) => (
                                <tr key={key}>
                                  <td>
                                    <Form.Item
                                      name={[name, "name"]}
                                      {...restField}
                                      className="inputBoxMb8"
                                      rules={[
                                        {
                                          required: false,
                                          message: "Name is required",
                                        },
                                      ]}
                                    >
                                      <Input placeholder="Name" />
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item
                                      name={[name, "designation"]}
                                      {...restField}
                                      className="inputBoxMb8"
                                      rules={[
                                        {
                                          required: false,
                                          message: "Designation is required",
                                        },
                                      ]}
                                    >
                                      <Input placeholder="Designation" />
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item
                                      name={[name, "phone"]}
                                      {...restField}
                                      className="inputBoxMb8"
                                      rules={[
                                        {
                                          min: 10,
                                          max: 10,
                                          message:
                                            "Please input valid mobile number",
                                          pattern: /^[6-9]{1}[0-9]{9}$/,
                                        },
                                      ]}
                                    >
                                      <Input
                                        placeholder="0123456789"
                                        suffix={
                                          <img src={phoneImg} alt="phone"></img>
                                        }
                                        // addonBefore={
                                        //   <Form.Item
                                        //     name={[name, "prefix"]}
                                        //     {...restField}
                                        //     noStyle
                                        //   >
                                        //     <Select
                                        //       style={{ width: 67 }}
                                        //       options={[
                                        //         { value: "0", label: "+81" },
                                        //         { value: "1", label: "+91" },
                                        //       ]}
                                        //     />
                                        //   </Form.Item>
                                        // }
                                        // maxLength={10}
                                        onKeyPress={(e) =>
                                          !/[0-9]/.test(e.key) &&
                                          e.preventDefault()
                                        }
                                        // onChange={(e) => {
                                        //   if (e.target.value) setTouched(true);
                                        //   else setTouched(false);
                                        // }}
                                        style={{
                                          width:
                                            window.innerWidth <= 425
                                              ? "160px"
                                              : "",
                                        }}
                                      />
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item
                                      name={[name, "email"]}
                                      {...restField}
                                      className="inputBoxMb8"
                                      rules={[
                                        {
                                          type: "email",
                                          message: "Enter valid email address",
                                        },
                                      ]}
                                    >
                                      <Input
                                        placeholder="user@exam.com"
                                        suffix={
                                          <img src={emailImg} alt="email"></img>
                                        }
                                        // onChange={(e) => {
                                        //   if (e.target.value) setTouched(true);
                                        //   else setTouched(false);
                                        // }}
                                        style={{
                                          width:
                                            window.innerWidth <= 425
                                              ? "180px"
                                              : "",
                                        }}
                                      />
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item
                                      name={[name, "ccTo"]}
                                      {...restField}
                                      className="inputBoxMb8"
                                      rules={[
                                        {
                                          type: "email",
                                          message: "Enter valid email address",
                                        },
                                      ]}
                                    >
                                      <Input
                                        placeholder="user@exam.com"
                                        suffix={
                                          <img src={emailImg} alt="email"></img>
                                        }
                                        // onChange={(e) => {
                                        //   if (e.target.value) setTouched(true);
                                        //   else setTouched(false);
                                        // }}
                                        style={{
                                          width:
                                            window.innerWidth <= 425
                                              ? "180px"
                                              : "",
                                        }}
                                      />
                                    </Form.Item>
                                  </td>

                                  <td>
                                    <Form.Item className="inputBoxMb8 mleft">
                                      <Checkbox
                                        checked={checkbox === index}
                                        onChange={() => {
                                          if (checkbox !== index) {
                                            setCheckbox(index);
                                            console.log("checkbox", checkbox);
                                            console.log("index", index);
                                          }
                                        }}
                                      />
                                    </Form.Item>
                                  </td>
                                  <td className="pb">
                                    <Button
                                      style={{
                                        padding: "0px",
                                        borderColor: "#fff",
                                        boxShadow: "none",
                                      }}
                                    >
                                      <DeleteTwoTone
                                        className="ca-delete-btn"
                                        onClick={() => {
                                          if (index === 0 && checkbox !== 0) {
                                            setCheckbox(checkbox - 1);
                                          } else if (
                                            checkbox === 0 &&
                                            fields.length > 1
                                          ) {
                                            // console.log("first delete");
                                            setCheckbox(0);
                                          } else if (
                                            checkbox !== 0 &&
                                            checkbox !== 4
                                          ) {
                                            // console.log("center delete");
                                            checkbox !== index - 1 &&
                                              setCheckbox(checkbox - 1);
                                          } else if (
                                            fields.length === index + 1 &&
                                            fields.length !== 1
                                          ) {
                                            // console.log("// last Delete");
                                            setCheckbox(index - 1);
                                          } else if (
                                            fields.length === index + 1 &&
                                            fields.length !== 1
                                          ) {
                                            setCheckbox(index - 1);
                                          }
                                          return fields.length === 1
                                            ? ""
                                            : remove(name);
                                        }}
                                        twoToneColor="#ff879d"
                                      />
                                    </Button>
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>

                          <tfoot>
                            <tr>
                              <td>
                                <Form.Item className="inputBoxMb">
                                  <Row>
                                    <Col>
                                      <Button
                                        type="link"
                                        onClick={() => add()}
                                        block
                                        icon={<PlusOutlined />}
                                        disabled={
                                          fields[4]?.name === 4 ? true : false
                                        }
                                      >
                                        Add Contact
                                      </Button>
                                    </Col>
                                  </Row>
                                </Form.Item>
                              </td>
                            </tr>
                          </tfoot>
                        </>
                      )}
                    </Form.List>
                  </table>
                </div>
                {/* Address Details */}

                <Form.List name="addresses">
                  {(fields, { add, remove }) => (
                    <>
                      <Divider
                        orientation="left"
                        orientationMargin="0"
                        className="dividertext"
                        style={{
                          marginTop: "11px",
                          marginBottom: "11px",
                          fontWeight: 600,
                        }}
                      >
                        Address Details
                      </Divider>
                      {fields.map(({ key, name, ...restField }, index) => (
                        <Row
                          gutter={12}
                          style={{
                            justifyContent: width <= 375 ? "center" : "",
                          }}
                        >
                          <Col lg={7} md={7} sm={12}>
                            <Form.Item
                              name={[name, "type"]}
                              label="Addresses Type"
                              className="inputBoxMb inputLabel33 inputBoxMb8"
                            >
                              <Select
                                placeholder="Type"
                                options={[
                                  {
                                    label: "Registered Address",
                                    value: TypeOfAddress.RegisteredAddress,
                                  },
                                  {
                                    label: "Business Address",
                                    value: TypeOfAddress.BusinessAddress,
                                  },
                                  {
                                    label: "Branch Address",
                                    value: TypeOfAddress.BranchAddress,
                                  },
                                  {
                                    label: "Unit Address",
                                    value: TypeOfAddress.UnitAddress,
                                  },
                                  {
                                    label: "Godown Address",
                                    value: TypeOfAddress.GodownAddress,
                                  },
                                ]}
                                // TypeOfAddress
                                style={{
                                  width:
                                    window.innerWidth <= 425 ? "226px" : " ",
                                }}
                              />
                            </Form.Item>
                            <Form.Item
                              name={[name, "addressName"]}
                              label="Name"
                              className="inputLabel33 inputBoxMb8"
                              // rules={[
                              //   {
                              //     required: true,
                              //     message: "Please assign a name to address",
                              //   },
                              // ]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>

                          <Col lg={17} md={7} sm={12}>
                            <Form.Item
                              label="Billing Address"
                              className="inputLabel33 inputBoxMb8"
                            >
                              <Row>
                                <Col span={8}>
                                  <Form.Item
                                    name={[name, "address", "building"]}
                                    className="inputBoxMb"
                                  >
                                    <Input
                                      //  className="inputBgColor"
                                      placeholder="Building"
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={8}>
                                  <Form.Item
                                    name={[name, "address", "street"]}
                                    className="inputBoxMb"
                                  >
                                    <Input placeholder="Street" />
                                  </Form.Item>
                                </Col>
                                <Col span={8}>
                                  <Form.Item
                                    name={[name, "address", "landmark"]}
                                    className="inputBoxMb"
                                  >
                                    <Input
                                      // className="inputBgColor"
                                      placeholder="Landmark"
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>

                              <Row>
                                <Col span={8}>
                                  <Form.Item
                                    name={[name, "address", "city"]}
                                    className="inputBoxMb"
                                  >
                                    <Input
                                      // className="inputBgColor"
                                      placeholder="City"
                                    />
                                  </Form.Item>
                                </Col>

                                <Col span={8}>
                                  <Form.Item
                                    className="inputBoxMb"
                                    name={[name, "address", "district"]}
                                  >
                                    <Input
                                      // className="inputBgColor"
                                      placeholder="District"
                                    />
                                  </Form.Item>
                                </Col>

                                <Col span={8}>
                                  <Form.Item
                                    name={[name, "address", "pincode"]}
                                    className="inputBoxMb"
                                    rules={[
                                      { required: false },
                                      {
                                        pattern: /^[0-9]{6}$/,
                                        message:
                                          "Pincode must be 6 digits long!",
                                      },
                                    ]}
                                  >
                                    <Input
                                      // controls={false}
                                      style={{ width: "100%" }}
                                      // className="inputBgColor"
                                      placeholder="Pincode"
                                      onKeyPress={(e) =>
                                        !/[0-9]/.test(e.key) &&
                                        e.preventDefault()
                                      }
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>

                              <Row>
                                <Col span={8}>
                                  <Form.Item
                                    name={[name, "address", "state"]}
                                    className="inputBoxMb"
                                    // rules={[
                                    //   {
                                    //     required: true,
                                    //     message: "Please Select State",
                                    //   },
                                    // ]}
                                  >
                                    <Select
                                      showSearch
                                      placeholder="State"
                                      options={stateOptions?.map((x: any) => ({
                                        value: x.id,
                                        label: x.name,
                                      }))}
                                      filterOption={(input, option) =>
                                        (option?.label.toString() ?? "")
                                          .toLowerCase()
                                          .includes(input.toLowerCase())
                                      }
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={8}>
                                  <Form.Item
                                    name={[name, "address", "country"]}
                                    className="inputBoxMb"
                                  >
                                    <Input placeholder="Country" />
                                    {/* <Select
                                      // className="selectBgColor"
                                      showSearch
                                      placeholder="country"
                                      optionFilterProp="children"
                                      // filterOption={(input, option) =>
                                      //   (option?.label ?? "")
                                      //     .toLowerCase()
                                      //     .includes(input.toLowerCase())
                                      // }
                                      // options={statesOption.map((x: any) => ({
                                      //   value: x.id,
                                      //   label: x.name,
                                      // }))}
                                    /> */}
                                  </Form.Item>
                                </Col>
                                <Col
                                  span={8}
                                  className="pb"
                                  style={{
                                    textAlign: "end",
                                    paddingTop: "10px",
                                    paddingRight: "19px",
                                  }}
                                >
                                  <Button
                                    style={{
                                      padding: "0px",
                                      borderColor: "#fff",
                                      boxShadow: "none",
                                    }}
                                    onClick={() => remove(name)}
                                  >
                                    <DeleteTwoTone
                                      className="ca-delete-btn"
                                      twoToneColor="#ff879d"
                                    />
                                  </Button>
                                </Col>
                              </Row>
                            </Form.Item>
                          </Col>
                          <Divider style={{ margin: "7px" }} />
                        </Row>
                      ))}
                      <Form.Item className="inputBoxMb">
                        <Row key={"add contact"}>
                          <Col>
                            <Button
                              type="link"
                              onClick={() => add()}
                              block
                              icon={<PlusOutlined />}
                              //disabled={fields[4]?.name === 4 ? true : false}
                            >
                              Add Address
                            </Button>
                          </Col>
                        </Row>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
                {/* payTerms */}
                <Divider
                  orientation="left"
                  orientationMargin="0"
                  className="dividertext"
                  style={{ marginTop: "11px", marginBottom: "11px" }}
                >
                  <p style={{ fontWeight: 600 }}> Payment Term</p>
                </Divider>
                <Row gutter={12}>
                  <Col lg={6} md={8} sm={12} xs={24}>
                    <Form.Item
                      name={["payTerms", "creditPeriod"]}
                      label="Credit Time (in days)"
                      className="inputBoxMb8 inputLabel33"
                      rules={[
                        {
                          pattern: /^[0-9]+$/,
                          message: "Credit Period must be numeric!",
                        },
                      ]}
                    >
                      <Input
                        placeholder="0"
                        style={{ width: "100%" }}
                        onKeyPress={(e) =>
                          !/[0-9]/.test(e.key) && e.preventDefault()
                        }
                        // onChange={(e) => {
                        //   const newValue = e.target.value.replace(/[^0-9]/g,"");
                        //   form.setFieldValue(["payTerms", "creditPeriod"],newValue);
                        // }}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={6} md={8} sm={12} xs={24}>
                    <Form.Item
                      name={["payTerms", "creditLimit"]}
                      label="Credit Limit"
                      className="inputBoxMb8 inputLabel33"
                      rules={[
                        {
                          // type: "number",
                          pattern: /^[0-9]+$/,
                          message: "Credit limit must be numeric!",
                        },
                      ]}
                    >
                      <Input
                        onKeyPress={(e) =>
                          !/[0-9]/.test(e.key) && e.preventDefault()
                        }
                        // type="number"
                        // onChange={(e) => {
                        //   const newValue = e.target.value.replace(
                        //     /[^0-9]/g,
                        //     ""
                        //   );
                        //   form.setFieldValue(
                        //     ["payTerms", "creditLimit"],
                        //     newValue
                        //   );
                        // }}
                        placeholder="0"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}

            {/* {fileList.map((x: any) => {
              return <Row>{x?.name}</Row>;
            })} */}
            {confirmation && (
              <ConfirmationModal
                open={confirmation}
                text="The changes you made will not going to save, are you sure you want to continue?"
                onNo={onDismissConfirm}
                onYes={onDismissBothModels}
              />
            )}
          </Form>
        </Spin>
      </Drawer>

      {openModel.open && (
        <AddGroup
          open={openModel.open}
          editData={openModel?.param}
          onCancel={() => setOpenModel({ open: false, param: "" })}
          setTrigger={() => setGroupListTrigger(!groupListTrigger)}
          groupData={groupData}
        />
      )}
    </React.Fragment>
  );
};

export default AddLedger;
