import { Tabs, TabsProps } from "antd";
import React from "react";
import History from "./History";
import BankTransactionsDetails from "./BankTransactionsDetails";
import { useLocation, useNavigate } from "react-router-dom";
import { useClient } from "../../../Context/ClientContext";
// import BankTransactionsDetails from "./BankTransactionsDetails";

const BankTransactionsTab = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentClient } = useClient();
  const [defaultActiveKey, setDefaultActiveKey] = React.useState<string>("-1");
  const [refresh, setRefresh] = React.useState<boolean>(false);
  // location.pathname.split("/");

  React.useEffect(() => {
    if (location.pathname.split("/")[5] === "bankTransactionsDetails") {
      setDefaultActiveKey("1");
    } else if (location.pathname.split("/")[5] === "bankTransactionsHistory") {
      setDefaultActiveKey("2");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  console.log("BankTransactionsTab", location.state);

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: (
        <div
          onClick={() => {
            navigate(
              `/accounts/clients/${currentClient?.id}/banking/bankTransactionsDetails`,
              {
                state: {
                  ledgerId: location.state?.ledgerId,
                  CSVData: location.state?.CSVData,
                  parsedData: location.state?.parsedData,
                  partyOrTax: location.state?.partyOrTax,
                  fileName: location.state?.fileName,
                  setRefresh: setRefresh,
                },
              }
            );
          }}
        >
          Bank Transaction
        </div>
      ),
      children: <BankTransactionsDetails />,
    },
    {
      key: "2",
      label: (
        <div
          onClick={() => {
            navigate(
              `/accounts/clients/${currentClient?.id}/banking/bankTransactionsHistory`,
              {
                state: {
                  ledgerId: location.state?.ledgerId,
                  refresh: refresh,
                },
              }
            );
          }}
        >
          History
        </div>
      ),
      children: <History />,
      // children:"",
    },
  ];

  return (
    <Tabs
      // defaultActiveKey={props?.defaultActiveKey}
      onChange={(key: any) => setDefaultActiveKey(key)}
      activeKey={defaultActiveKey}
      items={items}
    />
  );
};

export default BankTransactionsTab;
