import {
  DownCircleFilled,
  DownloadOutlined,
  EyeTwoTone,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Popover,
  Row,
  Select,
  Skeleton,
  Space,
  Spin,
} from "antd";
import React from "react";
import { dayjs } from "../../../Utilities/dayjs";
import { useClient } from "../../Context/ClientContext";
import ReportsServiceNew from "../../Services/ReportsServiceNew";
import Table, { ColumnsType } from "antd/es/table";
import LedgerService, {
  SearchLedgerByGroupsTypes,
} from "../../Services/LedgerService";
import ChartofAccountServicesNew from "../../Services/ChartofAccountServicesNew";

import VoucherServices from "../../Services/VoucherServices";
import { Utils } from "../../../Utilities/Utils";
const { RangePicker } = DatePicker;

const BRSReport = ({ Type }: any) => {
  const { currentClient, companySettings } = useClient();
  const [data, setData] = React?.useState<any | undefined>();
  const [ledgerId, setLedgerId] = React?.useState<any>();
  const [groupData, setGroupData] = React.useState<any[]>([]);
  const [groupId, setGroupId] = React?.useState<any>();
  const [loading, setLoading] = React?.useState<any>(false);
  const [voucherList, setVoucherList] = React?.useState<any>();
  const [ledgers, setLedgers] = React?.useState<any>();

  const [filterDate, setFilterDate] = React.useState<{
    from: string;
    to: string;
  }>({
    from: dayjs(companySettings?.period?.from).format("DD/MM/YYYY"),
    // to: dayjs(companySettings?.period?.to).format("DD/MM/YYYY"),
    to: dayjs().format("DD/MM/YYYY"),
  });

  React?.useEffect(() => {
    getAllReconcileHistory();
  }, [currentClient?.id, ledgerId?.value, filterDate?.from, filterDate?.to]);

  console.log("ffff", ledgerId?.value);

  const getAllReconcileHistory = async () => {
    setLoading(true);
    let res = await VoucherServices?.GetAllReconcileHistory(
      currentClient?.id,
      ledgerId?.value,
      filterDate?.from,
      filterDate?.to
    );
    if (res) {
      setLoading(false);
      setData(
        res?.result?.map((d: any, index: number) => {
          return {
            closingBalAmount: Utils?.getFormattedNumber(d?.closingBalAmount),
            closingBalDate: dayjs(d?.closingBalDate).format("DD/MM/YYYY"),
            creditTotalAmount: Utils?.getFormattedNumber(d?.creditTotalAmount),
            debitTotalAmount: Utils?.getFormattedNumber(d?.debitTotalAmount),
            openingBalAmount: Utils.getFormattedNumber(d?.openingBalAmount),
            openingBalDate: dayjs(d?.openingBalDate).format("DD/MM/YYYY"),
            reconcileDate: dayjs(d?.reconcileDate).format("DD/MM/YYYY"),
            reconciledVouchersNo: d?.reconciledVouchersNo,
            voucherList: d?.voucherList,
          };
        })
      );
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: "Reconcile On",
      dataIndex: "reconcileDate",
    },
    {
      title: "Opening Balance Date",
      dataIndex: "openingBalDate",
    },
    {
      title: "Closing Balance Date",
      dataIndex: "closingBalDate",
    },
    {
      title: "Opening Balance",
      dataIndex: "openingBalAmount",
      align: "right",
    },
    {
      title: "Closing Balance",
      dataIndex: "closingBalAmount",
      align: "right",
    },
    {
      title: "Credit Amount",
      dataIndex: "creditTotalAmount",
      align: "right",
    },
    {
      title: "Debit Amount",
      dataIndex: "debitTotalAmount",
      align: "right",
    },
    {
      title: "Vouchers No",
      dataIndex: "reconciledVouchersNo",
      align: "center",

      render: (text, record) => (
        <Popover
          content={content(record.voucherList)}
          title="Vouchers Details"
          trigger="hover"
        >
          {text}{" "}
          <span style={{ marginTop: "5px" }}>
            <EyeTwoTone />
          </span>
        </Popover>
      ),
    },
  ];

  console.log(voucherList, "da");

  const content = (voucherList: any) => (
    <div>
      {voucherList?.map((v: any, index: any) => (
        <p key={index}>{v?.name}</p>
      ))}
    </div>
  );

  React?.useEffect(() => {
    LedgerService.getLedgersByGroupName(
      currentClient?.id!,
      SearchLedgerByGroupsTypes?.BankOnly
    ).then((res: any) => {
      setLedgers(res);
    });
  }, [currentClient?.id]);

  React.useEffect(() => {
    setLoading(true);
    const getListData = async () => {
      await ChartofAccountServicesNew.getGroupList(currentClient?.id!)
        .then((res: any) => {
          if (res.items) {
            setGroupData(res.items);
            // setLoading(false);
          } else {
            setGroupData([]);
            console.log("Error", res);
          }
        })
        .catch((err: any) => {
          console.log("Error", err);
        });
      //   setLoading(false);
    };
    getListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Spin
        spinning={loading}
        indicator={<LoadingOutlined />}
        style={{
          justifyContent: "center",
          marginTop: "180px",
          textAlign: "center",
        }}
      >
        <Card
          // className="ca-card-body"
          style={{
            boxShadow: "0px 0px 10px 0px #96A1C84D",
            height: "90vh",
            padding: "0px",
          }}
        >
          <div>
            <Row>
              <Col>
                <h1 style={{ color: "rgb(57, 92, 210) ", padding: "0px 2px" }}>
                  Bank Reconcilation Report
                </h1>
              </Col>
            </Row>
            <Row justify={"space-between"} style={{ marginTop: "7px" }}>
              <Col>
                <Row>
                  <Col style={{ marginRight: "10px" }}>
                    <Select
                      showSearch
                      allowClear
                      optionFilterProp="label"
                      style={{ marginBottom: "3px", width: "200px" }}
                      placeholder="Select Ledger"
                      labelInValue
                      onChange={(val: any) => {
                        setLedgerId(val);
                      }}
                      options={ledgers?.map((l: any) => ({
                        key: l?.key,
                        value: l?.id,
                        label: l?.ledgerName,
                      }))}
                    />{" "}
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col>
                    <span style={{ margin: "0px 10px" }}>Date</span>
                    <RangePicker
                      defaultValue={[
                        dayjs(filterDate.from, "DD/MM/YYYY"),
                        dayjs(),
                        // dayjs(filterDate.to, "DD/MM/YYYY"),
                      ]}
                      allowClear
                      format="DD/MM/YYYY"
                      style={{ width: "220px", border: "1px solid #f1f3ff" }}
                      onChange={(e: any) => {
                        e === null
                          ? setFilterDate({ from: "", to: "" })
                          : setFilterDate({
                              ...filterDate,
                              from: dayjs(e[0]).format("DD/MM/YYYY"),
                              to: dayjs(e[1]).format("DD/MM/YYYY"),
                            });
                        //console.log(e, "dateCheck");
                      }}
                    />
                  </Col>
                  <Col>
                    <Button
                      type="default"
                      icon={<DownloadOutlined />}
                      style={{ margin: "0px 10px 0px 10px" }}
                    >
                      Export
                    </Button>
                  </Col>
                  <Col>
                    <DownloadOutlined
                      style={{ color: "rgb(22, 119, 255)", paddingTop: "9px" }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <div style={{ marginTop: "20px" }}>
              <Table
                dataSource={data}
                columns={columns}
                // columns={columns.filter((cl: any) =>
                //   selectedColumns.includes(cl?.dataIndex)
                // )}
                size="small"
              />
            </div>
          </div>
        </Card>
      </Spin>
    </div>
  );
};

export default BRSReport;
