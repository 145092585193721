import { Checkbox, Col, Divider, Form, Input, Modal, Row, Select } from "antd";

interface IPropsTds {
  open: boolean;
  setOpen: (value: boolean) => void;
}

const AddTdsTaxRate = (props: IPropsTds) => {
  const [form] = Form.useForm();
  return (
    <>
      <Modal
        title="Manage TDS"
        open={props.open}
        width={600}
        centered
        // onOk={handleOk}
        onCancel={() => props.setOpen(false)}
      >
        <Divider />
        <Form form={form} layout="vertical">
          <Row gutter={20}>
            <Col lg={12}>
              <Form.Item className="inputBoxMb8" label="Rate (%)">
                <Input />
              </Form.Item>
            </Col>
            <Col lg={12}>
              <Form.Item className="inputBoxMb8" label="Tax Name">
                <Input />
              </Form.Item>
            </Col>
            <Col lg={12}>
              <Form.Item className="inputBoxMb8" label="Nature">
                <Select placeholder="Select a Tax Type" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col lg={12}>
              <Form.Item className="inputBoxMb8" label="TDS Payable Account">
                <Select placeholder="Select Account" />
              </Form.Item>
            </Col>
            <Col lg={12}>
              <Form.Item className="inputBoxMb8" label="TDS Receivable Account">
                <Select placeholder="Select Account" />
              </Form.Item>
            </Col>
          </Row>
          <div className="mt-10">
            <Checkbox>This is a Higher TDS Rate</Checkbox>
          </div>
        </Form>
        <Divider />
      </Modal>
    </>
  );
};

export default AddTdsTaxRate;
