import { ApiUtility } from "../../Utilities/ApiUtility.axios";
import { CAPContactTypes } from "./PurchaseService";

export enum DBRecordStatus {
  Active,
  Deleted,
  MarkAsDeleted,
  Archived,
}

export enum CAPItemTypes {
  Sales,
  Purchase,
}

export enum TradingPartnerType {
  All = 0,
  Customer = 1,
  Supplier = 2,
}

export enum InvoiceTradeType {
  Unknown = 0,
  Sales = 1,
  Purchase = 2,
}

export enum InvoiceTypes {
  Invoice = 1,
  CreditNote = 2,
  Inventory = 3,
  Recurring = 4,
  //CISInvoice = 5,
}

export enum DeliveryChallanTypes {
  DeliveryChallan = 1,
  Recurring = 2,
}

export enum EstimateTypes {
  Estimate = 1,
  Recurring = 2,
}

export enum SalesOrderTypes {
  SalesOrder = 1,
  Recurring = 2,
}

export enum InvoiceStatus {
  All,
  Due,
  Overdue,
  Paid,
  Estimate,
  SalesOrder,
  PurchaseOrder,
  Deleted,
  Active,
}

export enum DeliveryChallanStatus {
  Active,
  Pending,
  Delivered,
  Deleted,
  All,
}

export enum EstimateStatus {
  Active,
  Pending,
  Accpected,
  Deleted,
  All,
}

export enum InvoicePaymentMethod {
  credit,
  cash,
}
export enum VoucherMode {
  Opening,
  Normal,
  Closing,
}

export enum NameTitles {
  Mr = 1,
  Mrs = 2,
  Miss = 3,
  Sir = 4,
  Ms = 5,
  Dr = 6,
  Prof = 7,
  Madam = 8,
  Maam = 9,
  Lord = 10,
  Lady = 11,
  Revere = 12,
}

export interface INameModel {
  title: NameTitles;
  first: string | undefined;
  last: string | undefined;
}

export const PaymentMethodNames = [
  "Cash",
  "Cheque",
  "Credit Card",
  "Debit Card",
  "Direct Debit",
  "Standing Order",
  "Website Payment",
  "PayPal",
  "Amex",
  "Other",
  "Bank Transfer",
];

class SalesService {
  route = "V1/API";

  postCustomer = (clientId: string, params: any, id?: string) => {
    return ApiUtility.postForm(
      `${this.route}/Acc_TradingPartners/${clientId}${id ? "/" + id : ""}`,
      params
    );
  };

  getCustomerList = (
    clientId: any,
    type: CAPContactTypes.Customer,
    start: any,
    length: any,
    sortCol?: any,
    sortDir?: any,
    search?: any,
    status?: any
  ) => {
    return ApiUtility.getResult(
      `${this.route}/Acc_TradingPartners/${clientId}`,
      {
        type,
        start,
        length,
        sortCol,
        sortDir,
        search,
        status,
        InvoiceTypes,
      }
    );
  };

  getCustomerById = (clientId: string, id?: string) => {
    return ApiUtility.get(
      `${this.route}/Acc_TradingPartners/${clientId}/${id}`
    );
  };

  // ---- Sales Invoice Apis -----

  // initialInvoice = (clientId: string, type: InvoiceTypes) => {
  //   return ApiUtility.get(
  //     `${this.route}/Acc_Invoice/${clientId}/Initial?type=${type}`
  //   );
  // };

  // used as initial value, get data by id and for view only data
  getSalesInvoices = (
    clientId: string,
    type?: InvoiceTypes,
    viewOnly?: boolean,
    id?: string,
    isCopy?: boolean,
    invDate?: string
  ) =>
    ApiUtility.get(
      `${this.route}/Acc_Invoice/${clientId}/Get?${id ? "id=" + id : ""}`,
      {
        isCopy,
        type,
        viewOnly,
        invDate,
      }
    );

  postSalesInvoice = (clientId: string, params: any, id?: string) => {
    return ApiUtility.postForm(
      `${this.route}/Acc_Invoice/${clientId}${id ? "/" + id : ""}`,
      params
    );
  };

  getSalesInvoiceList = (
    clientId: any,
    // status: InvoiceStatus,
    type: InvoiceTypes,
    start: number,
    length: number,
    sortCol?: any,
    sortDir?: any,
    search?: any,
    from?: any,
    to?: any,
    partyId?: string
  ) => {
    return ApiUtility.getResult(`${this.route}/Acc_Invoice/${clientId}`, {
      // status,
      type,
      start,
      length,
      sortCol,
      sortDir,
      search,
      from,
      to,
      partyId,
    });
  };

  // getSalesInvoice = (clientId: string, id?: string) =>
  //   ApiUtility.get(`${this.route}/Acc_Invoice/${clientId}/${id}`);

  searchItem = (clientId: string, term: string = "", type: CAPItemTypes) =>
    ApiUtility.getResult(
      `${this.route}/Acc_Item/${clientId}/Search?term=${
        term ?? ""
      }&type=${type}`
    );

  searchParties = (
    clientId: string,
    term: string = "",
    type: TradingPartnerType,
    length: number
  ) =>
    ApiUtility.get(
      `${this.route}/Acc_TradingPartners/${clientId}/Search?term=${term}&length=${length}&type=${type}`
    );

  searchInvoice = (
    clientId: string,
    partyId: string,
    tradeType: TradingPartnerType
  ) =>
    ApiUtility.get(
      `${this.route}/Acc_Invoice/${clientId}/SearchByParty?partyId=${partyId}&tradeType=${tradeType}`
    );

  deleteSalesInvoice = (
    clientId: string,
    id: string,
    reactivate: boolean,
    isCreditNote: boolean
  ) =>
    ApiUtility.get(
      `${this.route}/Acc_Invoice/${clientId}/${id}/Delete?reactivate=${reactivate}&isCreditNote=${isCreditNote}`
    );

  // https: //localhost:7142/v1/API/Acc_Invoice/6463611020350584c0d5c017/invoice/6548d0eab91ac656b9998292?start=0&length=15
  getSalesInvoiceNumberDetails = (
    clientId: string,
    invoiceId: string,
    start: number,
    length: number,
    sortCol?: string,
    sortDir?: string
  ) =>
    ApiUtility.getResult(
      `${this.route}/Acc_Invoice/${clientId}/salesInvoice/${invoiceId}`,
      {
        start,
        length,
        sortCol,
        sortDir,
      }
    );

  // https://localhost:7142/v1/API/Acc_Invoice/6463611020350584c0d5c017/PartyItemsDetails/659cedf9bc5990291f399e97?type=1&status=0&start=0&length=15

  getPartyWiseItemDetails = (
    clientId: string,
    partyId: string | undefined,
    type: InvoiceTypes,
    status: DBRecordStatus,
    tradeType: InvoiceTradeType,
    start?: number,
    length?: number,
    search?: string,
    from?: string,
    to?: string,
    sortCol?: string,
    sortDir?: string,
    gstRate?: number[]
  ) =>
    ApiUtility.getResult(
      `${this.route}/Acc_Invoice/${clientId}/PartyItemsDetails/${partyId}`,
      {
        type,
        status,
        tradeType,
        start,
        length,
        search,
        from,
        to,
        sortCol,
        sortDir,
        gstRate,
      }
    );

  // ------- Estimate Api All -------

  getQuotation = (clientId: string, type: EstimateTypes, id?: string) =>
    ApiUtility.get(
      `${this.route}/Estimate/${clientId}/Get?${
        id ? `id=${id}&` : ""
      }type=${type}`
    );

  postEstimate = (clientId: string, params: any, id?: string) =>
    ApiUtility.postForm(
      `${this.route}/Estimate/${clientId}${id ? "/" + id : ""}`,
      params
    );

  getEstimateList = (
    clientId: any,
    // status: InvoiceStatus,
    type: InvoiceTypes,
    start: number,
    length: number,
    sortCol?: any,
    sortDir?: any,
    search?: any,
    from?: any,
    to?: any,
    partyId?: string
  ) =>
    ApiUtility.getResult(`${this.route}/Estimate/${clientId}`, {
      // status,
      type,
      start,
      length,
      sortCol,
      sortDir,
      search,
      from,
      to,
      partyId,
    });

  deleteEstimate = (
    clientId: string,
    id: string,
    recordStatus: DBRecordStatus
  ) =>
    ApiUtility.post(
      `${this.route}/Estimate/${clientId}/Delete/${id}/${recordStatus}`,
      ""
    );
  // ------- Sales Order Api All -------
  initialValueSalesOrder = (clientId: string, type: number) =>
    ApiUtility.get(`${this.route}/SalesOrder/${clientId}/Initial?type=${type}`);

  postSalesOrder = (clientId: string, params: any, id?: string) =>
    ApiUtility.postForm(
      `${this.route}/SalesOrder/${clientId}${id ? "/" + id : ""}`,
      params
    );

  getSalesOrderList = (
    clientId: any,
    // status: InvoiceStatus,
    type: InvoiceTypes,
    start: number,
    length: number,
    sortCol?: any,
    sortDir?: any,
    search?: any,
    from?: any,
    to?: any,
    partyId?: string
  ) =>
    ApiUtility.getResult(`${this.route}/SalesOrder/${clientId}`, {
      // status,
      type,
      start,
      length,
      sortCol,
      sortDir,
      search,
      from,
      to,
      partyId,
    });

  getSalesOrder = (clientId: string, type: SalesOrderTypes, id?: string) =>
    ApiUtility.get(
      `${this.route}/SalesOrder/${clientId}/Get?${id ? "id=" + id : ""}`,
      type
    );

  // ------- Delivery Challan Api All -------

  postDeliveryChallan = (clientId: string, params: any, id?: string) =>
    ApiUtility.postForm(
      `${this.route}/DeliveryChallan/${clientId}${id ? "/" + id : ""}`,
      params
    );

  getDeliveryChallanList = (
    clientId: any,
    status: DeliveryChallanStatus,
    type: InvoiceTypes,
    start: number,
    length: number,
    sortCol?: any,
    sortDir?: any,
    search?: any,
    from?: any,
    to?: any,
    partyId?: string
  ) =>
    ApiUtility.getResult(`${this.route}/DeliveryChallan/${clientId}`, {
      status,
      type,
      start,
      length,
      sortCol,
      sortDir,
      search,
      from,
      to,
      partyId,
    });

  getDeliveryChallan = (
    clientId: string,
    type: DeliveryChallanTypes,
    id?: string
  ) =>
    ApiUtility.get(
      `${this.route}/DeliveryChallan/${clientId}/Get?${id ? "id=" + id : ""}`,
      type
    );
  // https://localhost:7142/V1/API/DeliveryChallan/11/Get?id=22&type=1
  // https://localhost:7142/V1/API/DeliveryChallan/11/Get?type=1

  deleteDeliveryChallan = (
    clientId: string,
    id: string,
    recordStatus: DBRecordStatus
  ) =>
    ApiUtility.post(
      `${this.route}/DeliveryChallan/${clientId}/Delete/${id}/${recordStatus}`,
      ""
    );

  deleteSalesOrder = (
    clientId: string,
    id: string,
    recordStatus: DBRecordStatus
  ) =>
    ApiUtility.post(
      `${this.route}/SalesOrder/${clientId}/Delete/${id}/${recordStatus}`,
      ""
    );

  // ------- Receipt All Api -------

  getReceiptData = (
    clientId: string,
    id?: string,
    invoiceId?: string,
    accounts?: boolean,
    partyId?: string
  ) =>
    ApiUtility.get(`${this.route}/Acc_Receipt/Get?clientId=${clientId}`, {
      id,
      invoiceId,
      accounts,
      partyId,
    });

  postReceipt = (clientId: string, params: any, id?: string) => {
    return ApiUtility.postForm(
      `${this.route}/Acc_Receipt/${id ? "/" + id : ""}?clientId=${clientId}`,
      params
    );
  };

  getReceiptList = (
    clientId: string,
    start: number,
    length: number,
    search?: string,
    fromDate?: string,
    toDate?: string,
    status?: string,
    sortCol?: string,
    sortDir?: string,
    partyId?: string
  ) => {
    return ApiUtility.getResult(
      `${this.route}/Acc_Receipt?clientId=${clientId}`,
      {
        start,
        length,
        search,
        fromDate,
        toDate,
        status,
        sortCol,
        sortDir,
        partyId,
      }
    );
  };

  deletePaymentIn = (clientId: string, reactivate: boolean, id: string) =>
    ApiUtility.get(
      `${this.route}/Acc_Receipt/${id}/Delete?clientId=${clientId}&reactivate=${reactivate}`
    );
}
export default new SalesService();
