import { ApiUtility } from "../../Utilities/ApiUtility.axios";
export enum EntryStatus {
  All = 0,
  Active = 1,
  Deleted = 2,
  Completed = 3,
}

export enum BankImportEntriesStatus {
  ActionPending,
  ActionTaken,
  ActionDeclined,
}

class BankTransactions {
  private route = "V1/Api/Acc_New_BankEntries";

  // https://localhost:7142/v1/api/Acc_New_BankEntries/6683abcf4818982f5c3ad2f4/BankEntry/6603d2e2213d7a79139f42dc/Get?BankEntriesId=66a7346d923c28cfda30d256
  getImportList = (
    companyId: string | undefined,
    LedgerId: string,
    BankEntriesId: string
  ) =>
    ApiUtility.get(
      `${this.route}/${companyId}/BankEntry/${LedgerId}/Get?BankEntriesId=${BankEntriesId}`
    );

  getAllBankHistoryList = (
    companyId: string | undefined,
    LedgerId: any,
    from: string,
    to: string,
    status: EntryStatus
  ) =>
    ApiUtility.get(
      `${this.route}/${companyId}/BankEntry/${LedgerId}/GetList?from=${from}&to=${to}&status=${status}`
    );

  getVoucherList = (
    companyId: string | undefined,
    LedgerId: string,
    BankEntriesId: string
  ) =>
    ApiUtility.get(
      `${this.route}/${companyId}/BankEntry/${LedgerId}/Get?BankEntriesId=${BankEntriesId}`
    );

  GetVoucherList = (
    companyId: string | undefined,
    LedgerId: any,
    amount: number,
    refText: any,
    exactMatch: boolean,
    date: string
  ) =>
    ApiUtility.get(
      `${this.route}/${companyId}/BankEntry/${LedgerId}/GetVoucherList?amount=${amount}&refText=${refText}&exactMatch=${exactMatch}&date=${date}`
    );

  postImportData = (
    companyId: string | undefined,
    ledgerId: string,
    model: any
  ) =>
    ApiUtility.post(`${this.route}/${companyId}/BankEntry/${ledgerId}`, model);

  deleteHistory = (
    companyId: string,
    ledgerId: string,
    BankEntriesId: string
  ) =>
    ApiUtility.delete(
      `${this.route}/${companyId}/BankEntry/${ledgerId}/${BankEntriesId}`
    );

  saveSearchVoucherDetails = (
    companyId: string | undefined,
    LedgerId: string,
    RequestData: any
  ) =>
    ApiUtility.put(
      `${this.route}/${companyId}/BankEntry/${LedgerId}/MarkVouchersReconciled`,
      RequestData
    );
}

export default new BankTransactions();
