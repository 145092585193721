import { Button, Col, Divider, Row } from "antd";
import React from "react";
import { useClient } from "../../Context/ClientContext";
import { EditOutlined, PlusOutlined, SettingFilled } from "@ant-design/icons";
import Shortcut from "./Shortcut";
import { useLocation } from "react-router-dom";

const CommonSettingButton = () => {
  const { currentClient } = useClient();
  const [openSettingModal, setOpenSettingModal] = React.useState<any>({
    type: "",
    params: {},
  });
  const [isPopoverVisible, setIsPopoverVisible] = React?.useState(false);
  const [type, setType] = React.useState<any>();
  const location = useLocation();

  return (
    <div>
      <div>
        <Row justify={"center"}>
          {location?.pathname ===
          `/accounts/clients/${currentClient?.id}/master/ledger` ? (
            <>
              <Col>
                <Button
                  onClick={() => {
                    setOpenSettingModal({ type: "settingModal", params: {} });
                    setIsPopoverVisible(false);
                    setType("undefined");
                  }}
                  shape="circle"
                  type="primary"
                  size="large"
                  icon={<EditOutlined />}
                />
                <p style={{ textAlign: "center" }}>Edit</p>
              </Col>
              <Col>
                <Divider type="vertical" style={{ height: "100%" }} />
              </Col>
              <Col>
                <Button
                  onClick={() => {
                    setOpenSettingModal({ type: "settingModal", params: {} });
                    setIsPopoverVisible(false);
                  }}
                  shape="circle"
                  type="primary"
                  size="large"
                  icon={<SettingFilled />}
                />
                <p style={{ textAlign: "center" }}>Text</p>
              </Col>
              <Col>
                <Divider type="vertical" style={{ height: "100%" }} />
              </Col>
            </>
          ) : location?.pathname ===
            `/accounts/clients/${currentClient?.id}/master/group` ? (
            <>
              <div>
                <Row justify={"center"}>
                  <Col style={{ textAlign: "center" }}>
                    <Button
                      onClick={() => {
                        setOpenSettingModal({
                          type: "settingModal",
                          params: {},
                        });
                        setIsPopoverVisible(false);
                        setType("group");
                      }}
                      shape="circle"
                      type="primary"
                      size="large"
                      icon={<PlusOutlined />}
                    ></Button>
                    <p style={{ textAlign: "center" }}>Group</p>
                  </Col>
                  <Col>
                    <Divider type="vertical" style={{ height: "100%" }} />
                  </Col>
                  <Col>
                    <Button
                      shape="circle"
                      type="primary"
                      size="large"
                      icon={<EditOutlined />}
                    />
                    <p style={{ textAlign: "center" }}>Edit</p>
                  </Col>
                  <Col>
                    <Divider type="vertical" style={{ height: "100%" }} />
                  </Col>
                </Row>
              </div>{" "}
            </>
          ) : location?.pathname ===
            `/accounts/clients/${currentClient?.id}/master/items` ? (
            <>
              <div>
                <Row justify={"center"}>
                  <Col style={{ textAlign: "center" }}>
                    <Button
                      onClick={() => {
                        setOpenSettingModal({
                          type: "settingModal",
                          params: {},
                        });
                        setIsPopoverVisible(false);
                        setType("item");
                      }}
                      shape="circle"
                      type="primary"
                      size="large"
                      icon={<PlusOutlined />}
                    ></Button>
                    <p style={{ textAlign: "center" }}>Items</p>
                  </Col>
                  <Col>
                    <Divider type="vertical" style={{ height: "100%" }} />
                  </Col>
                  <Col>
                    <Button
                      shape="circle"
                      type="primary"
                      size="large"
                      icon={<EditOutlined />}
                    />
                    <p style={{ textAlign: "center" }}>Edit</p>
                  </Col>
                  <Col>
                    <Divider type="vertical" style={{ height: "100%" }} />
                  </Col>
                </Row>
              </div>{" "}
            </>
          ) : location?.pathname ===
            `/accounts/clients/${currentClient?.id}/sales/invoices` ? (
            <>
              <div>
                <Row justify={"center"}>
                  <Col style={{ textAlign: "center" }}>
                    <Button
                      shape="circle"
                      type="primary"
                      size="large"
                      icon={<PlusOutlined />}
                    ></Button>
                    <p style={{ textAlign: "center" }}>Sales</p>
                  </Col>
                  <Col>
                    <Divider type="vertical" style={{ height: "100%" }} />
                  </Col>
                  <Col>
                    <Button
                      shape="circle"
                      type="primary"
                      size="large"
                      icon={<EditOutlined />}
                    />
                    <p style={{ textAlign: "center" }}>Edit</p>
                  </Col>
                  <Col>
                    <Divider type="vertical" style={{ height: "100%" }} />
                  </Col>
                </Row>
              </div>{" "}
            </>
          ) : location?.pathname ===
            `/accounts/clients/${currentClient?.id}/sales/creditNotes` ? (
            <>
              <div>
                <Row justify={"center"}>
                  <Col style={{ textAlign: "center" }}>
                    <Button
                      shape="circle"
                      type="primary"
                      size="large"
                      icon={<PlusOutlined />}
                    ></Button>
                    <p style={{ textAlign: "center" }}>Credit Note</p>
                  </Col>
                  <Col>
                    <Divider type="vertical" style={{ height: "100%" }} />
                  </Col>
                  <Col>
                    <Button
                      shape="circle"
                      type="primary"
                      size="large"
                      icon={<EditOutlined />}
                    />
                    <p style={{ textAlign: "center" }}>Edit</p>
                  </Col>
                  <Col>
                    <Divider type="vertical" style={{ height: "100%" }} />
                  </Col>
                </Row>
              </div>{" "}
            </>
          ) : location?.pathname ===
            `/accounts/clients/${currentClient?.id}/sales/receipts` ? (
            <>
              <div>
                <Row justify={"center"}>
                  <Col style={{ textAlign: "center" }}>
                    <Button
                      shape="circle"
                      type="primary"
                      size="large"
                      icon={<PlusOutlined />}
                    ></Button>
                    <p style={{ textAlign: "center" }}>Receipts</p>
                  </Col>
                  <Col>
                    <Divider type="vertical" style={{ height: "100%" }} />
                  </Col>
                  <Col>
                    <Button
                      shape="circle"
                      type="primary"
                      size="large"
                      icon={<EditOutlined />}
                    />
                    <p style={{ textAlign: "center" }}>Edit</p>
                  </Col>
                  <Col>
                    <Divider type="vertical" style={{ height: "100%" }} />
                  </Col>
                </Row>
              </div>{" "}
            </>
          ) : location?.pathname ===
            `/accounts/clients/${currentClient?.id}/sales/quotation` ? (
            <>
              <div>
                <Row justify={"center"}>
                  <Col style={{ textAlign: "center" }}>
                    <Button
                      shape="circle"
                      type="primary"
                      size="large"
                      icon={<PlusOutlined />}
                    ></Button>
                    <p style={{ textAlign: "center" }}>Quotation</p>
                  </Col>
                  <Col>
                    <Divider type="vertical" style={{ height: "100%" }} />
                  </Col>
                  <Col>
                    <Button
                      shape="circle"
                      type="primary"
                      size="large"
                      icon={<EditOutlined />}
                    />
                    <p style={{ textAlign: "center" }}>Edit</p>
                  </Col>
                  <Col>
                    <Divider type="vertical" style={{ height: "100%" }} />
                  </Col>
                </Row>
              </div>{" "}
            </>
          ) : location?.pathname ===
            `/accounts/clients/${currentClient?.id}/sales/order` ? (
            <>
              <div>
                <Row justify={"center"}>
                  <Col style={{ textAlign: "center" }}>
                    <Button
                      shape="circle"
                      type="primary"
                      size="large"
                      icon={<PlusOutlined />}
                    ></Button>
                    <p style={{ textAlign: "center" }}>Order</p>
                  </Col>
                  <Col>
                    <Divider type="vertical" style={{ height: "100%" }} />
                  </Col>
                  <Col>
                    <Button
                      shape="circle"
                      type="primary"
                      size="large"
                      icon={<EditOutlined />}
                    />
                    <p style={{ textAlign: "center" }}>Edit</p>
                  </Col>
                  <Col>
                    <Divider type="vertical" style={{ height: "100%" }} />
                  </Col>
                </Row>
              </div>{" "}
            </>
          ) : location?.pathname ===
            `/accounts/clients/${currentClient?.id}/sales/customers` ? (
            <>
              <div>
                <Row justify={"center"}>
                  <Col style={{ textAlign: "center" }}>
                    <Button
                      shape="circle"
                      type="primary"
                      size="large"
                      icon={<PlusOutlined />}
                    ></Button>
                    <p style={{ textAlign: "center" }}>Customers</p>
                  </Col>
                  <Col>
                    <Divider type="vertical" style={{ height: "100%" }} />
                  </Col>
                  <Col>
                    <Button
                      shape="circle"
                      type="primary"
                      size="large"
                      icon={<EditOutlined />}
                    />
                    <p style={{ textAlign: "center" }}>Edit</p>
                  </Col>
                  <Col>
                    <Divider type="vertical" style={{ height: "100%" }} />
                  </Col>
                </Row>
              </div>{" "}
            </>
          ) : location?.pathname ===
            `/accounts/clients/${currentClient?.id}/dashboard` ? (
            <>
              <div>
                <Row justify={"center"}>
                  <Col style={{ textAlign: "center" }}>
                    <Button
                      shape="circle"
                      type="primary"
                      size="large"
                      icon={<PlusOutlined />}
                    ></Button>
                    <p style={{ textAlign: "center" }}>Dashboard</p>
                  </Col>
                </Row>
              </div>
            </>
          ) : (
            <></>
          )}
          <Shortcut
            setOpenSettingModal={setOpenSettingModal}
            setIsPopoverVisible={setIsPopoverVisible}
            setType={setType}
            type={type}
            isPopoverVisible={isPopoverVisible}
            openSettingModal={openSettingModal}
          ></Shortcut>
        </Row>
      </div>
    </div>
  );
};

export default CommonSettingButton;
