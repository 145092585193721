import React, { useContext, useState } from "react";
import ButtonComponent from "../../Common/ButtonComponent";
import {
  Button,
  Table,
  Row,
  Col,
  Input,
  DatePicker,
  Select,
  Space,
  Tooltip,
  Popover,
  Divider,
  Skeleton,
  Tag,
  Spin,
} from "antd";
import type { ColumnsType } from "antd/es/table";

import {
  PlusOutlined,
  SyncOutlined,
  EditOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import noteContext from "../../Context/StyleContext";
import ViewSalesInvoice from "./ViewSalesInvoice";
import { Utils } from "../../../Utilities/Utils";
import { dayjs } from "../../../Utilities/dayjs";
import { useTheme } from "../../Context/ThemeContext";
import { useClient } from "../../Context/ClientContext";
import { BsCurrencyRupee } from "react-icons/bs";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ConfirmationModal } from "../../Common/ConfirmationModal";
import { notificationContext } from "../../Common/PageRoute";
import { HiDotsHorizontal } from "react-icons/hi";
import search from "../../Images/search.svg";
import ViewSalesInvoiceNoDetails from "./ViewSalesInvoiceNoDetails";
import downloadimg from "../../../images/downloadimg.svg";
import { useKeyboardShortcuts } from "../../Common/KeyboardShortcuts ";
import VoucherServices, {
  VoucherFilterStatus,
  VoucherStatus_New,
  Voucher_Type,
} from "../../Services/VoucherServices";
import DownloadService, { ViewType } from "../../Services/DownloadService";
import { voucherType } from "../Reports/VoucherDetailsByLedger";
import ViewPreviewModel from "./ViewPreviewModel";
import EmailSendParty from "./EmailSendParty";
import { FiDownload } from "react-icons/fi";
// import { VoucherFilterStatus } from "../../Services/SalesService";
import jsPDF from "jspdf";
import "jspdf-autotable";
import ExcelJS from "exceljs";
import InvoiceExportService from "../../Services/InvoiceExportDetails";
import { LocalChunkSize } from "papaparse";

export interface InvoiceDataType {
  key: React.Key;
  id: string;
  invNumber: {
    name: string;
    id: string;
  };
  customerName: {
    id: string;
    name: string;
  };
  date: string;
  dueDate: string;
  invAmount: number;
  refundAmount: number;
  receivedAmount: number;
  dueBalance: number;
  status: string;
  voucherStatus: VoucherStatus_New;
  //totalReceived: number;
  //totalUnpaid: number;
}

interface ISalesInvoiceProps {
  salesInvoiceList: any;
  setSalesInvoiceList: (value: any) => void;
  LoadSkeleton: boolean;
  setLoadSkeleton: (value: boolean) => void;
  partyId?: string;
  setLoading?: any;
}

const SalesInvoiceTable = (props: ISalesInvoiceProps) => {
  const navigate = useNavigate();
  const { RangePicker } = DatePicker;
  const { themeData } = useTheme();
  const location = useLocation();
  const { currentClient, companySettings } = useClient();
  const { windowWidth } = useContext(noteContext);
  const { openNotification } = useContext(notificationContext);
  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setIsOpen] = React.useState<string>("");
  const [pageSize, setPageSize] = React.useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>();
  const [invoiceId, setInvoiceId] = React.useState<string>("");
  const [partyId, setPartyId] = React.useState<string>("");
  const [openPopArray, setOpenPopArray] = useState<boolean[]>([]);
  const [confirmation, setConfirmation] = useState<boolean>(false);
  const [searchCustomer, setSearchCustomer] = React.useState<string>("");
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [refreshAtDelete, setRefreshAtDelete] = React.useState<boolean>(false);
  const [filterDate, setFilterDate] = React.useState<any>({
    from: dayjs(companySettings?.period?.from).format("DD/MM/YYYY"),
    to: dayjs(companySettings?.period?.to).format("DD/MM/YYYY"),
  });
  const [width, setWidth] = React.useState<any>(window.innerWidth);
  const [invoiceStatus, setInvoiceStatus] = React.useState<number>(
    VoucherFilterStatus.Active
  );
  const [deleteOrRevive, setDeleteOrRevive] = React.useState<{
    id: string;
    isRev: boolean;
    number: string;
  }>({
    id: "",
    isRev: false,
    number: "",
  });
  const [filter, setfilter] = React.useState<any>({
    sortCol: "",
    sortDir: "",
  });
  const [invoiceListData, setInvoiceListData] = React.useState<
    InvoiceDataType[]
  >([]);
  const [exportInvoiceData, setExportInvoiceData] = useState<InvoiceDataType[]>(
    []
  );
  const [emailLoading, setEmailLoading] = React.useState<boolean>(false);
  // const
  const [loadingButtons, setLoadingButtons] = useState<any>({});
  const [exportType, setExportType] = useState<number>(-1);
  // const [apiCallExports, setApiCallExports] = useState<boolean>(false);

  let splitPathInVchType: Voucher_Type;
  // let splitPathLedgerByGroupsType: SearchLedgerByGroupsTypes;
  if (location.pathname.split("/")[4] === "sales") {
    splitPathInVchType = Voucher_Type.SalesVoucher;
    // splitPathLedgerByGroupsType = SearchLedgerByGroupsTypes.Sales;
  } else {
    splitPathInVchType = Voucher_Type.PurchaseVoucher;
    // splitPathLedgerByGroupsType = SearchLedgerByGroupsTypes.Purchase;
  }

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  // Get the selected row data
  const selectedRows = invoiceListData.filter((item) =>
    selectedRowKeys.includes(item.key)
  );

  console.log("Selected Row Keys:", selectedRowKeys);
  console.log("Selected Row Data:", selectedRows);
  // --------- Table Columns  ---------
  const columns: ColumnsType<InvoiceDataType> = [
    {
      title:
        splitPathInVchType === Voucher_Type.SalesVoucher
          ? "Invoice No."
          : "Bill No.",
      dataIndex: "invNumber",
      width: width <= 1440 ? "10%" : "9%",
      sorter: true,
      key: "Number",
      render: (_, record: InvoiceDataType) => (
        <Skeleton
          active
          loading={props?.LoadSkeleton}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.voucherStatus !== 1 ? (
            <>
              <span
                style={{
                  color: "#395cd2",
                  cursor: "pointer",
                }}
                onClick={() => {
                  // downloadAndView(
                  //   currentClient?.id!,
                  //   record.invNumber?.id,
                  //   ViewType.Preview
                  // );
                  setIsOpen("viewPreview");
                  setInvoiceId(record.invNumber?.id);
                  setPartyId(record.customerName?.id);
                  // setIsOpen("viewSalesInvoice");
                }}
              >
                {record?.invNumber?.name}
              </span>
            </>
          ) : (
            <>
              <span style={{ color: "red" }}>{record?.invNumber?.name}</span>
            </>
          )}
        </Skeleton>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      sorter: true,
      ellipsis: true,
      width: "100px",
      key: "Date",
      render: (_, record: any) => (
        <Skeleton
          active
          loading={props?.LoadSkeleton}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.date}
        </Skeleton>
      ),
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      sorter: true,
      ellipsis: true,
      width: width <= 1440 ? "9%" : "8%",
      key: "DueDate",
      render: (_, record: any) => (
        <Skeleton
          active
          loading={props?.LoadSkeleton}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {dayjs(record?.dueDate).format("DD/MM/YYYY")}
        </Skeleton>
      ),
    },
    {
      title:
        splitPathInVchType === Voucher_Type.SalesVoucher
          ? "Invoice Amt."
          : "Bill Amount.",
      dataIndex: "invAmount",
      sorter: true,
      align: "right",
      ellipsis: true,
      width: "9%",
      key: "invAmount",
      render: (_, record: any) => (
        <Skeleton
          active
          loading={props?.LoadSkeleton}
          paragraph={{
            rows: 1,
            style: { display: "flex", justifyContent: "end" },
          }}
          title={false}
        >
          {/* <Tooltip
            placement="topLeft"
            title={<>₹ {Utils.getFormattedNumber(record?.invAmount)}</>}
          > */}
          {/* </Tooltip> */}
          {record.voucherStatus !== 1 ? (
            <>₹ {Utils.getFormattedNumber(record?.invAmount)}</>
          ) : (
            <>
              <span style={{ textDecoration: "line-through" }}>
                ₹ {Utils.getFormattedNumber(record?.invAmount)}
              </span>
            </>
          )}
        </Skeleton>
      ),
    },
    {
      title:
        splitPathInVchType === Voucher_Type.SalesVoucher
          ? "Credit Note"
          : "Debit Note",
      dataIndex: "refundAmount",
      align: "right",
      sorter: true,
      key: "refundAmount",
      width: "9%",
      render: (_, record: any) => (
        <Skeleton
          active
          loading={props?.LoadSkeleton}
          paragraph={{
            rows: 1,
            style: { display: "flex", justifyContent: "end" },
          }}
          title={false}
        >
          {record.voucherStatus !== 1 ? (
            <> ₹ {Utils.getFormattedNumber(record?.refundAmount)}</>
          ) : (
            <>
              <span style={{ textDecoration: "line-through" }}>
                ₹ {Utils.getFormattedNumber(record?.refundAmount)}
              </span>
            </>
          )}
        </Skeleton>
      ),
    },
    {
      title:
        splitPathInVchType === Voucher_Type.SalesVoucher ? "Received" : "Paid",
      dataIndex: "receivedAmount",
      sorter: true,
      align: "right",
      key: "ReceivedAmount",
      ellipsis: {
        showTitle: false,
      },
      width: "9%",
      render: (_, record: any) => (
        <Skeleton
          active
          loading={props?.LoadSkeleton}
          paragraph={{
            rows: 1,
            style: { display: "flex", justifyContent: "end" },
          }}
          title={false}
        >
          {/* <Tooltip
            placement="topLeft"
            title={
              record?.bank === 0 ? (
                <span>
                  ₹{Utils.getFormattedNumber(record?.paid - record?.bank)}
                </span>
              ) : (
                <>
                  <span>paid: ₹{Utils.getFormattedNumber(record?.paid)}</span>
                  <br />
                  <span>bank: ₹{Utils.getFormattedNumber(record?.bank)}</span>
                </>
              )
            }
          > */}

          {record.voucherStatus !== 1 ? (
            <>
              <span onClick={() => console.log(record?.bank)}>
                ₹ {Utils.getFormattedNumber(record?.receivedAmount)}
              </span>
            </>
          ) : (
            <>
              <span style={{ textDecoration: "line-through" }}>
                ₹ {Utils.getFormattedNumber(record?.receivedAmount)}
              </span>
            </>
          )}
          {/* </Tooltip> */}
        </Skeleton>
      ),
    },
    {
      title: "Due Amount",
      dataIndex: "dueBalance",
      sorter: true,
      align: "right",
      key: "PendingAmount",
      ellipsis: {
        showTitle: false,
      },
      width: "9%",
      render: (_, record: any) => (
        <Skeleton
          active
          loading={props?.LoadSkeleton}
          paragraph={{
            rows: 1,
            style: { display: "flex", justifyContent: "end" },
          }}
          title={false}
        >
          {/* <Tooltip
            placement="topLeft"
            title={
              <span>₹ {Utils.getFormattedNumber(record?.dueBalance)}</span>
            }
          > */}

          {record.voucherStatus !== 1 ? (
            <>
              <span>₹ {Utils.getFormattedNumber(record?.dueBalance)}</span>
            </>
          ) : (
            <>
              <span style={{ textDecoration: "line-through" }}>
                <span>₹ {Utils.getFormattedNumber(record?.dueBalance)}</span>
              </span>
            </>
          )}
          {/* </Tooltip> */}
        </Skeleton>
      ),
    },
    {
      title: "Status",
      dataIndex: "payStatus",
      key: "payStatus",
      width: "7%",
      align: "center",
      render: (_, record) => (
        <Skeleton
          active
          loading={props?.LoadSkeleton}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.voucherStatus !== 1 ? (
            <>
              <span
                style={{
                  border: "1px solid #E4E8F0",
                  fontWeight: 500,
                  display: "block",
                  textAlign: "center",
                  fontSize: "13px",
                  borderRadius: 4,
                  color:
                    record.status === "Due"
                      ? "#FAAD14"
                      : record.status === "Overdue"
                      ? "#FF4D4F"
                      : record.status === "Paid"
                      ? "#52C41A"
                      : "geekblue",
                  backgroundColor: "white",
                }}
              >
                {record.status}
              </span>
            </>
          ) : (
            <>
              <Tag color="red">Deleted</Tag>
            </>
          )}
          {/* <InvoiceStatusShow
            dueDate={record?.dueDate}
            due={record?.dueBalance}
            refund={record?.refundAmount}
            invAmount={record?.invAmount}
          /> */}{" "}
        </Skeleton>
      ),
    },
    {
      title: "Action",
      dataIndex: "icon",
      width: "10%",
      align: "center",
      render: (_, record, index) => (
        <Skeleton
          className="cursor"
          active
          loading={props?.LoadSkeleton}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {/* <div style={{ display: "flex", justifyContent: "space-around" }}> */}
          <div
            style={{ display: "flex", justifyContent: "space-around", gap: 1 }}
          >
            {/* <FiDownload
              onClick={() => CreatePdf(record)}
              style={{ color: "#395cd2" }}
              /> 
              */}
            {/* {invoiceStatus === VoucherFilterStatus.Deleted || invoiceStatus ===
            VoucherFilterStatus.All */}

            {record?.voucherStatus !== 1 && (
              <>
                <Button
                  size="small"
                  icon={<FiDownload />}
                  style={{ border: "none", color: "#395cd2" }}
                  loading={!!loadingButtons[record.invNumber?.id]}
                  onClick={() =>
                    downloadAndView(
                      currentClient?.id!,
                      record.invNumber?.id,
                      ViewType.Pdf
                      // record.invNumber?.id
                    )
                  }
                />
              </>
            )}
            {record.voucherStatus === VoucherStatus_New.Active ? (
              <Tooltip title="Edit" placement="top">
                <EditOutlined
                  onClick={() => {
                    splitPathInVchType === Voucher_Type.SalesVoucher
                      ? navigate(
                          `/accounts/clients/${currentClient?.id}/sales/editInvoice/${record?.invNumber?.id}`,
                          {
                            state: { copy: false },
                          }
                        )
                      : navigate(
                          `/accounts/clients/${currentClient?.id}/purchase/editPurchaseBill/${record?.invNumber?.id}`,
                          {
                            state: { copy: false },
                          }
                        );
                  }}
                />
              </Tooltip>
            ) : (
              <Tooltip title="Reviev Voucher">
                <SyncOutlined
                  style={{ color: "green" }}
                  // className="ca-delete-btn cursor"
                  onClick={() => {
                    splitPathInVchType === Voucher_Type.SalesVoucher
                      ? navigate(
                          `/accounts/clients/${currentClient?.id}/sales/editInvoice/${record?.invNumber?.id}`,
                          {
                            state: { copy: false },
                          }
                        )
                      : navigate(
                          `/accounts/clients/${currentClient?.id}/purchase/editPurchaseBill/${record?.invNumber?.id}`,
                          {
                            state: { copy: false },
                          }
                        );
                  }}
                />
              </Tooltip>
            )}

            {record?.voucherStatus !== 1 && (
              <>
                <Popover
                  arrow={{ pointAtCenter: true }}
                  placement="bottomRight"
                  content={ellipsisContent(record, index, record.voucherStatus)}
                  trigger="click"
                  open={openPopArray[index]}
                  onOpenChange={(newOpen) =>
                    handlePopoverChange(index, newOpen)
                  }
                >
                  <HiDotsHorizontal
                    style={{
                      color: "#395cd2",
                      cursor: "pointer",
                      marginTop: "5px",
                    }}
                  />
                </Popover>
              </>
            )}
          </div>
        </Skeleton>
      ),
    },
  ];

  if (!props.partyId) {
    columns.splice(1, 0, {
      title: "Party Name",
      key: "pLedger",
      sorter: true,
      dataIndex: "customerName",
      width: width <= 1440 ? "18%" : "25%",
      ellipsis: true,
      render: (record: any) => (
        <Skeleton
          active
          loading={props?.LoadSkeleton}
          paragraph={{ rows: 1 }}
          title={false}
        >
          <Link
            to={`/accounts/clients/${currentClient?.id!}/master/ledger/${
              record?.id
            }`}
            style={{ color: "#3a5dd3" }}
          >
            {record?.name}
          </Link>
        </Skeleton>
      ),
    });
  }

  // condition for delete and revive
  // if (
  //   invoiceStatus === VoucherFilterStatus.Deleted ||
  //   invoiceStatus === VoucherFilterStatus.All
  // ) {
  //   columns.push({
  //     width: "10%",
  //     align: "left",
  // render: (_, record) => (
  //   <Skeleton
  //     className="cursor"
  //     active
  //     loading={props?.LoadSkeleton}
  //     paragraph={{ rows: 1 }}
  //     title={false}
  //   >
  //     <Tooltip title="Revive invoice and its effects on calculations">
  //       <SyncOutlined
  //         onClick={() => {
  //           setConfirmation(true);
  //           setInvoiceId(record?.invNumber?.id);
  //         }}
  //       />
  //     </Tooltip>
  //   </Skeleton>
  // ),
  // });
  // }

  if (false) {
    columns.push(
      {
        title: "  ",
        dataIndex: "icon",
        width: "3%",
        align: "center",
        render: (_, record) => (
          <Skeleton
            className="cursor"
            active
            loading={props?.LoadSkeleton}
            paragraph={{ rows: 1 }}
            title={false}
          >
            {/* <FiDownload
              onClick={() => CreatePdf(record)}
              style={{ color: "#395cd2" }}
            /> */}
            <img
              alt="download"
              style={{ cursor: "pointer" }}
              src={downloadimg}
            />
          </Skeleton>
        ),
      },
      {
        title: "  ",
        dataIndex: "icon2",
        width: "3%",
        align: "center",
        render: (_, record) => (
          <Skeleton
            active
            loading={props?.LoadSkeleton}
            paragraph={{ rows: 1 }}
            title={false}
          >
            <Tooltip title="Edit" placement="top">
              <EditOutlined
                onClick={() => {
                  splitPathInVchType === Voucher_Type.SalesVoucher
                    ? navigate(
                        `/accounts/clients/${currentClient?.id}/sales/editInvoice/${record?.invNumber?.id}`,
                        {
                          state: { copy: false },
                        }
                      )
                    : navigate(
                        `/accounts/clients/${currentClient?.id}/purchase/editPurchaseBill/${record?.invNumber?.id}`,
                        {
                          state: { copy: false },
                        }
                      );
                }}
              />
            </Tooltip>
          </Skeleton>
        ),
      },
      // {
      //   title: "  ",
      //   dataIndex: "icon2",
      //   width: "3%",
      //   align: "center",
      //   render: (_, record) => (
      //     <Skeleton
      //       active
      //       loading={props?.LoadSkeleton}
      //       paragraph={{ rows: 1 }}
      //       title={false}
      //     >
      //       <Tooltip
      //         title={
      //           record?.receivedAmount?.paid === 0 && record?.refundAmount === 0
      //             ? "Edit"
      //             : "Invoice is either paid or refunded"
      //         }
      //         placement="top"
      //         color={
      //           record?.receivedAmount?.paid === 0 && record?.refundAmount === 0
      //             ? "#3D4998"
      //             : "gray"
      //         }
      //       >
      //         <EditOutlined
      //           style={{
      //             color:
      //               record?.receivedAmount?.paid === 0 &&
      //               record?.refundAmount === 0
      //                 ? "#444653"
      //                 : "rgb(216 207 207)",
      //           }}
      //           className={
      //             record?.receivedAmount?.paid === 0 &&
      //             record?.refundAmount === 0
      //               ? "ca-edit-btn"
      //               : ""
      //           }
      //           onClick={() => {
      //             if (
      //               record?.receivedAmount?.paid === 0 &&
      //               record?.refundAmount === 0
      //             )
      //               navigate(
      //                 `/accounts/clients/${currentClient?.id}/sales/editInvoice/${record?.id}`,
      //                 {
      //                   state: { copy: false },
      //                 }
      //               );
      //           }}
      //         />
      //       </Tooltip>
      //     </Skeleton>
      //   ),
      // },
      // {
      //   title: "  ",
      //   dataIndex: "icon3",
      //   width: "2%",
      //   align: "center",
      //   render: (_, record) => (
      //     <Skeleton
      //       active
      //       loading={props?.LoadSkeleton}
      //       paragraph={{ rows: 1 }}
      //       title={false}
      //     >
      //       <div
      //         className={record?.dueBalance === 0 ? "" : "cursor"}
      //         style={{
      //           // backgroundColor:
      //           //   record?.dueBalance === 0 ? "rgba(239, 239, 239, 0.6)" : "",
      //           textAlign: "center",
      //         }}
      //       >
      //         <Tooltip
      //           title={
      //             record?.dueBalance === 0
      //               ? "Invoice is either paid or refunded"
      //               : "Create Receipt"
      //           }
      //           placement="top"
      //           color={record?.dueBalance === 0 ? "gray" : ""}
      //         >
      //           <BsCurrencyRupee
      //             color={
      //               record?.dueBalance === 0 ? "rgb(216 207 207)" : "#868ea5"
      //             }
      //             onClick={() => {
      //               if (record?.dueBalance !== 0) {
      //                 navigate(
      //                   `/accounts/clients/${currentClient?.id}/sales/addPaymentIn`,
      //                   {
      //                     state: {
      //                       invoiceId: record?.invNumber?.id,
      //                       values: {
      //                         value: record?.dueBalance,
      //                       },
      //                     },
      //                   }
      //                 );
      //               }
      //             }}
      //           />
      //         </Tooltip>
      //       </div>
      //     </Skeleton>
      //   ),
      // },
      {
        title: "  ",
        width: "2%",
        align: "center",
        render: (_, record, index) => (
          <Skeleton
            active
            loading={props?.LoadSkeleton}
            paragraph={{ rows: 1 }}
            title={false}
          >
            <Popover
              arrow={{ pointAtCenter: true }}
              placement="bottomRight"
              content={ellipsisContent(record, index, record.voucherStatus)}
              trigger="click"
              open={openPopArray[index]}
              onOpenChange={(newOpen) => handlePopoverChange(index, newOpen)}
            >
              <HiDotsHorizontal
                style={{ color: "#395cd2", cursor: "pointer" }}
              />
            </Popover>
          </Skeleton>
        ),
      }
      // {
      //   title: " total ",
      //   width: "15%",
      //   align: "center",
      //   fixed: "right",
      // }
    );
  }

  const handlePopoverChange = (columnIndex: number, newOpen: boolean) => {
    const updatedOpenPopArray = [...openPopArray];
    updatedOpenPopArray[columnIndex] = newOpen;
    setOpenPopArray(updatedOpenPopArray);
  };

  const ellipsisContent = (
    record: InvoiceDataType,
    index: number,
    status: VoucherStatus_New
  ) => {
    return (
      <div>
        {status === VoucherStatus_New.Active && (
          <>
            <Button
              style={{ width: 100 }}
              onClick={() => {
                splitPathInVchType === Voucher_Type.SalesVoucher
                  ? navigate(
                      `/accounts/clients/${currentClient?.id}/sales/cloneInvoice/${record.id}`
                    )
                  : navigate(
                      `/accounts/clients/${currentClient?.id}/purchase/cloneBill/${record.id}`
                    );
              }}
            >
              Clone
            </Button>
            <Divider className="my-5" />
            <Tooltip
              title={
                // record?.receivedAmount?.paid > 0 || record?.refundAmount > 0
                // ? "This record can't be deleted because it has an associated transaction."
                "Delete temporarily"
              }
            >
              <Button
                disabled={
                  // record?.receivedAmount?.paid > 0 || record?.refundAmount > 0
                  // ? true
                  // : false
                  false
                }
                style={{ width: 100 }}
                onClick={() => {
                  setConfirmation(true);
                  setInvoiceId(record?.id);
                  setDeleteOrRevive({
                    ...deleteOrRevive,
                    number: record.invNumber.name,
                  });
                  handlePopoverChange(index, false);
                }}
              >
                Delete
              </Button>
            </Tooltip>
            <Divider className="my-5" />
            <Tooltip
              title={
                record?.invAmount === record?.refundAmount
                  ? `This record can't be create ${
                      splitPathInVchType === Voucher_Type.SalesVoucher
                        ? "credit"
                        : "debit"
                    } note because it invoice all refund`
                  : //  record?.invAmount === record?.receivedAmount
                    // ? `This record can't be create  ${
                    //     splitPathInVchType === Voucher_Type.SalesVoucher
                    //       ? "credit"
                    //       : "debit"
                    //   } note because it invoice all Paid`
                    // :
                    ""
              }
            >
              <Button
                style={{ width: 100 }}
                disabled={
                  record?.invAmount === record?.refundAmount
                    ? // || record?.invAmount === record?.receivedAmount
                      true
                    : false
                }
                onClick={() => {
                  splitPathInVchType === Voucher_Type.SalesVoucher
                    ? navigate(
                        `/accounts/clients/${currentClient?.id}/sales/addCreditNote`,
                        {
                          state: {
                            invoice: record.invNumber,
                            partyId: record?.customerName.id,
                          },
                        }
                      )
                    : navigate(
                        `/accounts/clients/${currentClient?.id}/purchase/addDebitNote`,
                        {
                          state: {
                            invoice: record.invNumber,
                            partyId: record?.customerName.id,
                          },
                        }
                      );
                }}
              >
                {splitPathInVchType === Voucher_Type.SalesVoucher
                  ? "Credit Note"
                  : "Debit Note"}
              </Button>
            </Tooltip>
            <Divider className="my-5" />
          </>
        )}

        <Button
          style={{ width: 100 }}
          loading={emailLoading}
          onClick={() => {
            setEmailLoading(openPopArray[index] ? true : false);
            sendEmail(
              currentClient?.id!,
              record.invNumber?.id,
              ViewType.Pdf,
              record.customerName?.id
            );
          }}
        >
          Email Pdf
        </Button>

        <Divider className="my-5" />

        <Button
          style={{ width: 100 }}
          onClick={() => {
            handlePopoverChange(index, false);
            setPartyId(record.customerName?.id);
            setInvoiceId(record.id);
            setIsOpen("emailSendParty");
          }}
        >
          Email
        </Button>

        <Divider className="my-5" />
        <Button
          style={{ width: 100 }}
          onClick={() => {
            setIsOpen("viewInvoiceNoAllDetails");
            setInvoiceId(record.id);
            handlePopoverChange(index, false);
          }}
        >
          All Details
        </Button>
      </div>
    );
  };

  React.useEffect(() => {
    // console.log(width);
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  // ----- Delete or revive invoices api -----
  React.useEffect(() => {
    if (deleteOrRevive.id) {
      const DeleteOrRevive = async () => {
        setLoading(true);
        try {
          const res = await VoucherServices.deleteVoucher(
            currentClient?.id!,
            deleteOrRevive.id
          );
          if (res.result) {
            setRefreshAtDelete(!refreshAtDelete);
            setExportType(-1);
            setExportInvoiceData([]);
            openNotification(
              "success",
              `Entry ${
                invoiceStatus === VoucherFilterStatus.Deleted
                  ? `revived`
                  : `deleted`
              } successfully ${deleteOrRevive.number} `
            );
          } else {
            openNotification("error", res.message);
            setLoading(false);
          }
        } catch (err: any) {
          console.error("error..", err);
          openNotification("error", err.message);
        }
        setDeleteOrRevive({ id: "", isRev: false, number: "" });
      };
      DeleteOrRevive();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteOrRevive?.id]);

  // --------- Get TableData API ---------
  React.useEffect(() => {
    if (currentClient?.id && (exportType === -1 || exportType)) {
      getList(
        currentClient?.id,
        splitPathInVchType === Voucher_Type.SalesVoucher
          ? Voucher_Type.SalesVoucher
          : Voucher_Type.PurchaseVoucher,
        // apiCallExports && exportType ? 0 : (page - 1) * pageSize,
        // apiCallExports && exportType ? 0 : pageSize,
        exportType >= 1 ? 0 : (page - 1) * pageSize,
        exportType >= 1 ? 0 : pageSize,
        filterDate.from,
        filterDate.to,
        filter.sortCol,
        filter.sortDir,
        undefined,
        undefined,
        searchCustomer,
        invoiceStatus
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentClient?.id,
    page,
    pageSize,
    filter.sortCol,
    filter.sortDir,
    filterDate.from,
    filterDate.to,
    searchCustomer,
    refreshAtDelete,
    invoiceStatus,
    // apiCallExports,
    exportType,
  ]);

  // ----- Table Ascending & Desending Order -----
  const getList = async (
    clientId: string,
    Voucher_Type: Voucher_Type,
    start: number,
    length: number,
    fromDate: string = "",
    toDate: string = "",
    sortCol?: string,
    sortDir?: string,
    noPartyId?: string,
    npReconciled?: string,
    search?: string,
    invoiceStatus?: VoucherFilterStatus
  ) => {
    if (exportType < 1) {
      setLoading(true);
      props.setLoadSkeleton(true);
      props?.setLoading(true);
    }
    await VoucherServices.getVoucharList(
      clientId,
      Voucher_Type,
      start,
      length,
      fromDate,
      toDate,
      sortCol,
      sortDir,
      noPartyId,
      npReconciled,
      search,
      invoiceStatus
    )
      .then((res: any) => {
        if (res?.result?.voucherItems?.length > 0) {
          props.setLoadSkeleton(false);
          // setTotAmount(res?.result?.totalAmount);
          let invoiceData: InvoiceDataType[] = res?.result?.voucherItems?.map(
            (v: any, index: number) => {
              // console.log("This is my data", v);
              return {
                key: index,
                id: v?.vId,
                date: dayjs(v?.date).format("DD/MM/YYYY"),
                dueDate: v?.dueDate,
                invNumber: { name: v?.number, id: v?.vId },
                customerName: {
                  id: v?.pLedger[0].id,
                  name: v?.pLedger[0]?.ledgerName,
                },
                invAmount: v?.invAmount,
                refundAmount: v?.refundAmount,
                receivedAmount: v?.receivedAmount,
                status: v?.status,
                voucherStatus: v?.voucherStatus,
                // receivedAmount: {
                // paid: v?.amount?.paid,
                // bank: v?.amount?.bankAmount,
                // },
                dueBalance: v?.pendingAmount,
                //dueBalance: v?.dueAmount,
              };
            }
          );
          if (exportType >= 1) {
            setExportInvoiceData(invoiceData);
            if (exportType === 1) {
              exportCsv(invoiceData);
            } else if (exportType === 2) {
              exportPDF(invoiceData);
            } else if (exportType === 3) {
              exportExcel(invoiceData);
            }
          } else {
            setInvoiceListData(invoiceData);
            props.setSalesInvoiceList({
              //// Previous declarations :
              //totalReceived: res?.total?.recieved,
              //totalUnpaid: res?.total?.unpaid,
              //totalReceived: res?.result?.totalReceivedAmount,
              //totalUnpaid: res?.result?.totalDueAmount,
              //totalOverdue: res?.result?.totalOverDueAmount,
              //totalRefund: res?.total?.refund,
              //totalRefund: res?.result?.totalRefundAmount,
              // totalSalesAmount:
              //   res?.result?.totalAmount - res?.result?.totalRefundAmount,
              // totalRefundAmount: res?.total?.pageItemTotal?.tRefund,
              // totalReceivedAmount: res?.total?.pageItemTotal?.tReceived,
              // totalDueAmount: res?.total?.pageItemTotal?.tDueAmt,

              //total According to per page------
              totalInvoiceAmount: res?.result?.totalAmount,
              totalRefundAmount: res?.result?.totalRefundAmount,
              totalReceivedAmount: res?.result?.totalReceivedAmount,
              totalDueAmount: res?.result?.totalPendingAmount,

              //total According to all records------
              overallSalesAmount:
                res?.result.overallOverDueAmount +
                res?.result.overallDueAmount +
                res?.result.overallReceivedAmount,
              overallOverDueAmount: res?.result.overallOverDueAmount,
              OverallDueAmount: res?.result.overallDueAmount,
              overallReceivedAmount: res?.result.overallReceivedAmount,
            });
            setLoading(false);
            // setVoucherDetailsData(res);
            props?.setLoading(false);
            setTotalRecords(res?.result?.totalRecords);
          }
        } else if (res.items.length === 0) {
          props.setSalesInvoiceList([]);
          props.setLoadSkeleton(false);
        }
        setLoading(false);
        props?.setLoading(false);
      })
      .catch((ex: any) => {
        setLoading(false);
        props.setSalesInvoiceList([]);
        props.setLoadSkeleton(false);
        setInvoiceListData([]);
      });
  };

  const tableSort = (pagination?: any, sort?: any, filter?: any) => {
    setfilter({
      sortCol: filter.column !== undefined ? filter.columnKey : "AutoNumber",
      sortDir:
        filter.column !== undefined
          ? filter.order === "descend"
            ? "desc"
            : "asc"
          : "desc",
    });
  };

  const isDraft = (cliendId: string) => {
    const res = JSON.parse(sessionStorage.getItem(`${cliendId} Sales`) + "");
    if (res) return true;
    else return false;
  };

  // const exportCsv = () => {
  //   VoucherServices.exportCsv(
  //     currentClient?.id,
  //     splitPathInVchType === Voucher_Type.SalesVoucher
  //       ? Voucher_Type.SalesVoucher
  //       : Voucher_Type.PurchaseVoucher,
  //     (page - 1) * pageSize,
  //     pageSize,
  //     filterDate.from,
  //     filterDate.to,
  //     filter.sortCol,
  //     filter.sortDir,
  //     undefined,
  //     undefined,
  //     searchCustomer,
  //     invoiceStatus
  //   )
  //     .then((res) => {
  //       if (res) {
  //         console.log("download sucess", res);
  //       }
  //     })
  //     .catch((ex: any) => {
  //       openNotification("error", ex.message);
  //     });
  // };

  const downloadAndView = async (
    cliendId: string,
    voucherId: string,
    type: ViewType
    // invId: any
  ) => {
    if (cliendId && voucherId) {
      setLoadingButtons((prev: any) => ({ ...prev, [voucherId]: true }));
      await DownloadService.downloadPdf(cliendId, voucherId, type)
        .then((res) => {
          console.log("res", res);
          if (res) {
            console.log("download sucess");
            setLoadingButtons((prev: any) => ({ ...prev, [voucherId]: false }));
          }
        })
        .catch((ex) => {
          openNotification("error", ex.message);
        });
    } else {
      openNotification("error", "Invalid Details");
      setLoadingButtons(false);
    }
  };

  const sendEmail = async (
    cliendId: string,
    voucherId: string,
    type: ViewType = ViewType.Pdf,
    partyId: string
  ) => {
    if (cliendId && voucherId && partyId) {
      await DownloadService.sendPdfEmail(cliendId, voucherId, type, partyId)
        .then((res) => {
          if (res.result) {
            openNotification("success", "Email sent successfully");
          } else {
            openNotification("error", res?.message);
          }
          setEmailLoading(false);
        })
        .catch((err) => {
          //  setEmailLoading(false);
          openNotification("error", err?.message);
        });
    } else {
      openNotification("error", "Invalid Details");
    }
    setEmailLoading(false);
  };

  let path =
    splitPathInVchType === Voucher_Type.SalesVoucher
      ? "Invoice List"
      : "Bill List";
  const generateFooterRow = (columns: string[]) => {
    return columns.map((col) => {
      if (col === "Invoice Amount") {
        return `${
          selectedRowKeys.length > 0
            ? Utils.getFormattedNumber(
                selectedRows.reduce(
                  (acc, data) => acc + (data?.invAmount || 0),
                  0
                )
              )
            : Utils.getFormattedNumber(
                props?.salesInvoiceList?.totalInvoiceAmount
              )
        }`;
      } else if (col === "Credit Note") {
        return `${
          selectedRowKeys.length > 0
            ? Utils.getFormattedNumber(
                selectedRows.reduce(
                  (acc, data) => acc + (data?.refundAmount || 0),
                  0
                )
              )
            : Utils.getFormattedNumber(
                props?.salesInvoiceList?.totalRefundAmount
              )
        }`;
      } else if (col === "Received") {
        return `${
          selectedRowKeys.length > 0
            ? Utils.getFormattedNumber(
                selectedRows.reduce(
                  (acc, data) => acc + (data?.receivedAmount || 0),
                  0
                )
              )
            : Utils.getFormattedNumber(
                props?.salesInvoiceList?.totalReceivedAmount
              )
        }`;
      } else if (col === "Due Amount") {
        return `${
          selectedRowKeys.length > 0
            ? Utils.getFormattedNumber(
                selectedRows.reduce(
                  (acc, data) => acc + (data?.dueBalance || 0),
                  0
                )
              )
            : Utils.getFormattedNumber(props?.salesInvoiceList?.totalDueAmount)
        }`;
      } else if (col === "Invoice Number") {
        return "Total";
      } else if (col === "Bill No.") {
        return "Total";
      } else if (col === "Bill Amount") {
        return `${
          selectedRowKeys.length > 0
            ? Utils.getFormattedNumber(
                selectedRows.reduce(
                  (acc, data) => acc + (data?.invAmount || 0),
                  0
                )
              )
            : Utils.getFormattedNumber(
                props?.salesInvoiceList?.totalInvoiceAmount
              )
        }`;
      } else if (col === "Debit Note") {
        return `${
          selectedRowKeys.length > 0
            ? Utils.getFormattedNumber(
                selectedRows.reduce(
                  (acc, data) => acc + (data?.refundAmount || 0),
                  0
                )
              )
            : Utils.getFormattedNumber(
                props?.salesInvoiceList?.totalRefundAmount
              )
        }`;
      } else if (col === "Paid") {
        return `${
          selectedRowKeys.length > 0
            ? Utils.getFormattedNumber(
                selectedRows.reduce(
                  (acc, data) => acc + (data?.receivedAmount || 0),
                  0
                )
              )
            : Utils.getFormattedNumber(
                props?.salesInvoiceList?.totalReceivedAmount
              )
        }`;
      }
    });
  };

  const columnsA = [
    splitPathInVchType === Voucher_Type.SalesVoucher
      ? "Invoice Number"
      : "Bill No.",
    "Party Name",
    "Date",
    "Due Date",
    splitPathInVchType === Voucher_Type.SalesVoucher
      ? "Invoice Amount"
      : "Bill Amount",
    splitPathInVchType === Voucher_Type.SalesVoucher
      ? "Credit Note"
      : "Debit Note",
    splitPathInVchType === Voucher_Type.SalesVoucher ? "Received" : "Paid",
    "Due Amount",
    "Status",
  ];

  const fieldMapping = {
    "Invoice Number": (item: any) => item?.invNumber?.name,
    "Bill No.": (item: any) => item?.invNumber?.name,
    "Party Name": (item: any) => item?.customerName?.name,
    Date: (item: any) => item?.date,
    "Due Date": (item: any) => dayjs(item?.dueDate).format("DD/MM/YYYY"),
    "Invoice Amount": (item: any) => Utils.getFormattedNumber(item?.invAmount),
    "Bill Amount": (item: any) => Utils.getFormattedNumber(item?.invAmount),
    "Credit Note": (item: any) => Utils.getFormattedNumber(item?.refundAmount),
    "Debit Note": (item: any) => Utils.getFormattedNumber(item?.refundAmount),
    Received: (item: any) => Utils.getFormattedNumber(item?.receivedAmount),
    Paid: (item: any) => Utils.getFormattedNumber(item?.receivedAmount),
    "Due Amount": (item: any) => Utils.getFormattedNumber(item?.dueBalance),
    Status: (item: any) => item?.status,
  };
  const exportCsv = (invExportData: InvoiceDataType[]) => {
    const exportData =
      selectedRowKeys.length > 0 ? selectedRows : invExportData;
    InvoiceExportService.generateCSV(
      exportData,
      columnsA,
      fieldMapping,
      generateFooterRow,
      "",
      filterDate,
      path,
      currentClient?.businessName
    );
    setExportType(0);
  };

  const exportPDF = (invExportData: InvoiceDataType[]) => {
    const exportData =
      selectedRowKeys.length > 0 ? selectedRows : invExportData;

    InvoiceExportService.generatePDF(
      exportData,
      currentClient?.businessName,
      columnsA,
      fieldMapping,
      generateFooterRow,
      filterDate,
      path
    );
    setExportType(0);
  };

  const exportExcel = async (invExportData: InvoiceDataType[]) => {
    const exportData =
      selectedRowKeys.length > 0 ? selectedRows : invExportData;
    await InvoiceExportService.generateExcel(
      exportData,
      currentClient?.businessName,
      columnsA,
      fieldMapping,
      generateFooterRow,
      filterDate,
      path
    );
    setExportType(0);
  };

  const content = (
    <Space direction="vertical">
      <div>
        <Button
          style={{ width: "80px" }}
          size="small"
          title="CSV"
          onClick={() => {
            if (exportInvoiceData.length > 0) {
              exportCsv(exportInvoiceData);
            } else {
              setExportType(1);
              // setApiCallExports(true);
            }
          }}
          icon={<DownloadOutlined />}
        >
          CSV
        </Button>
      </div>

      <div>
        <Button
          style={{ width: "80px" }}
          size="small"
          title="PDF"
          onClick={() => {
            if (exportInvoiceData.length > 0) {
              exportPDF(exportInvoiceData);
            } else {
              setExportType(2);
              // setApiCallExports(true);
            }
          }}
          icon={<DownloadOutlined />}
        >
          PDF
        </Button>
      </div>
      <div>
        <Button
          style={{ width: "80px" }}
          size="small"
          title="XLSL"
          onClick={() => {
            if (exportInvoiceData.length > 0) {
              exportExcel(exportInvoiceData);
            } else {
              setExportType(3);
              // setApiCallExports(true);
            }
          }}
          icon={<DownloadOutlined />}
        >
          XLSL
        </Button>
      </div>
    </Space>
  );

  return (
    <div>
      <Row
        justify={width <= 768 ? "space-evenly" : "space-between"}
        gutter={windowWidth <= 1024 ? 65 : 0}
        // style={{ marginTop: 10, padding: "10px 18px 3px 18px" }}
      >
        <Col xl={6} lg={4}>
          <Space size={5}>
            <Tooltip
              title={
                splitPathInVchType === Voucher_Type.SalesVoucher ? (
                  <>
                    Add Invoice
                    <br />
                    <Tag style={{ color: "white" }}>
                      <kbd>Shift + F5</kbd>
                    </Tag>
                  </>
                ) : (
                  <>
                    Add Purchase Bill
                    <br />
                    <Tag style={{ color: "white" }}>
                      <kbd>Shift + F9</kbd>
                    </Tag>
                  </>
                )
              }
            >
              <Button
                className="customButtonCss"
                type="primary"
                id="gfg"
                icon={<PlusOutlined />}
                onClick={() => {
                  // props.partyId
                  //   ? navigate(
                  //       `/accounts/clients/${currentClient?.id}/sales/addInvoice/${props.partyId}`
                  //     )
                  //   : navigate(
                  //       `/accounts/clients/${currentClient?.id}/sales/addInvoice`
                  //     );
                  splitPathInVchType === Voucher_Type.SalesVoucher
                    ? navigate(
                        `/accounts/clients/${currentClient?.id}/sales/addInvoice`
                      )
                    : navigate(
                        `/accounts/clients/${currentClient?.id}/purchase/addPurchaseBill`
                      );
                }}
              >
                {splitPathInVchType === Voucher_Type.SalesVoucher
                  ? "Invoice"
                  : "Bill"}
              </Button>
            </Tooltip>

            {/* <Dropdown.Button menu={{ items }} onClick={() => exportCsv()}>
              <DownloadOutlined /> Export CSV
            </Dropdown.Button> */}

            <Popover content={content} placement="rightTop" trigger="hover">
              <Button
                type="default"
                icon={<DownloadOutlined />}
                // onClick={() => exportCsv()}
              >
                Export
              </Button>
            </Popover>
            {/* <Badge
              dot={isDraft(`${currentClient?.id}`) ?? false}
              style={{ marginTop: "6px" }}
            >
              <MdOutlineDrafts
                size={22}
                className="cursor"
                style={{
                  marginTop: "6px",
                  display: "flex",
                  alignItems: "center",
                  color: isDraft(`${currentClient?.id}`)
                    ? "#5d616c"
                    : "lightgray",
                }}
                onClick={() => {
                  if (isDraft(`${currentClient?.id}`)) {
                    navigate(
                      `/accounts/clients/${currentClient?.id}/sales/addInvoice`,
                      {
                        state: {
                          isOpenDraft: true,
                        },
                      }
                    );
                  }
                }}
              />
            </Badge> */}
            {/* <p
              style={{
                color: isDraft(`${currentClient?.id}`) ? "" : "lightgray",
              }}
            >
              {isDraft(`${currentClient?.id}`) ? `New Draft` : `Drafts`}
            </p> */}
          </Space>
        </Col>
        <Col
          xl={18}
          lg={20}
          style={{ marginTop: width <= 768 ? "6px" : "0px" }}
        >
          <Row justify="end" gutter={[8, width <= 425 ? 10 : 0]}>
            <Col>
              <Input
                style={{
                  width:
                    width <= 464 ? "330px" : width == 375 ? "280px" : "200px",
                  border: "1px solid #f1f3ff",
                }}
                placeholder="Search Party"
                allowClear
                onChange={(e: any) => {
                  setLoading(true);
                  setExportInvoiceData([]);
                  setExportType(-1);
                  if (!e.cancelable) {
                    const searchItem = setTimeout(() => {
                      setPage(1);
                      setSearchCustomer(e.target.value);
                    }, 500);
                    return () => clearTimeout(searchItem);
                  } else {
                    setSearchCustomer(e.target.value);
                  }
                }}
                suffix={
                  loading || searchCustomer ? (
                    ""
                  ) : (
                    <img alt="search" src={search} />
                  )
                }
              />
            </Col>
            <Col>
              <RangePicker
                format="DD/MM/YYYY"
                defaultValue={[
                  dayjs(filterDate?.from, "DD/MM/YYYY"),
                  dayjs(filterDate?.to, "DD/MM/YYYY"),
                ]}
                style={{
                  width:
                    width <= 464 ? "330px" : width == 375 ? "280px" : "220px",
                  border: "1px solid #f1f3ff",
                }}
                onChange={(e: any) => {
                  e === null
                    ? setFilterDate({})
                    : setFilterDate({
                        ...filterDate,
                        from: dayjs(e[0]).format("DD/MM/YYYY"),
                        to: dayjs(e[1]).format("DD/MM/YYYY"),
                      });
                }}
                presets={[
                  {
                    label: "Today",
                    value: [dayjs().add(0, "d"), dayjs()],
                  },
                  {
                    label: "Last 7 Days",
                    value: [dayjs().add(-7, "d"), dayjs()],
                  },
                  {
                    label: "Last 15 Days",
                    value: [dayjs().add(-15, "d"), dayjs()],
                  },
                  {
                    label: "This Month",
                    value: [dayjs().startOf("month"), dayjs()],
                  },
                  {
                    label: "Last Month",
                    value: [dayjs().date(0).startOf("month"), dayjs().date(0)],
                  },
                  {
                    label: "Quarter 1",
                    value: [
                      dayjs().month(3).startOf("month"),
                      dayjs().month(5).endOf("month"),
                    ],
                  },
                  {
                    label: "Quarter 2",
                    value: [
                      dayjs().month(6).startOf("month"),
                      dayjs().month(8).endOf("month"),
                    ],
                  },
                  {
                    label: "Quarter 3",
                    value: [
                      dayjs().month(9).startOf("month"),
                      dayjs().month(11).endOf("month"),
                    ],
                  },
                  {
                    label: "Quarter 4",
                    value: [
                      dayjs().month(0).startOf("month"),
                      dayjs().month(2).endOf("month"),
                    ],
                  },
                  {
                    label: "This Year",
                    value: [dayjs().month(0).startOf("month"), dayjs()],
                  },
                  {
                    label: "Last Year",
                    value: [
                      dayjs().month(-12).startOf("month"),
                      dayjs().month(-1).endOf("month"),
                    ],
                  },
                ]}
              />
            </Col>
            <Col>
              <Select
                value={invoiceStatus}
                style={{
                  width: width === 1024 ? 80 : 120,
                }}
                options={[
                  { value: VoucherFilterStatus.All, label: "All" },
                  { value: VoucherFilterStatus.Active, label: "Active" },
                  { value: VoucherFilterStatus.Due, label: "Due" },
                  { value: VoucherFilterStatus.Overdue, label: "Overdue" },
                  { value: VoucherFilterStatus.Paid, label: "Paid" },
                  // { value: VoucherFilterStatus.Estimate, label: "Estimate" },
                  // { value: VoucherFilterStatus.SalesOrder, label: "Sales Order" },
                  { value: VoucherFilterStatus.Deleted, label: "Deleted" },
                ]}
                onSelect={(val) => {
                  setPage(1);
                  setInvoiceStatus(val);
                  setExportInvoiceData([]);
                  setExportType(-1);
                }}
              />
            </Col>
            <Col>
              <ButtonComponent />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col lg={24}>
          <Table
            className={`Tabel-style table-${
              themeData?.componentSize ?? "middle"
            }`}
            loading={false}
            summary={() => {
              return (
                <Table.Summary fixed>
                  <Table.Summary.Row
                    style={{ backgroundColor: "#f5f8ff", fontWeight: "bold" }}
                  >
                    <Table.Summary.Cell
                      index={1}
                      className="fw600"
                    ></Table.Summary.Cell>
                    <Table.Summary.Cell index={2} className="fw600">
                      Page Total
                    </Table.Summary.Cell>
                    <Table.Summary.Cell
                      index={3}
                      className="fw600"
                    ></Table.Summary.Cell>
                    <Table.Summary.Cell index={4}>{"   "}</Table.Summary.Cell>
                    <Table.Summary.Cell index={5} className="fw600">
                      {/* ₹{" "}
                      {tableData?.data?.reduce((acc: any, curr: any) => {
                        return (acc += Number(curr?.amount));
                      }, 0) || 0} */}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell
                      index={5}
                      className="fw600"
                      align="right"
                    >
                      ₹{" "}
                      {Utils.getFormattedNumber(
                        props.salesInvoiceList?.totalInvoiceAmount
                      )}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell
                      index={6}
                      className="fw600"
                      align="right"
                    >
                      ₹{" "}
                      {Utils.getFormattedNumber(
                        props.salesInvoiceList?.totalRefundAmount
                      )}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell
                      index={7}
                      className="fw600"
                      align="right"
                    >
                      ₹{" "}
                      {Utils.getFormattedNumber(
                        props.salesInvoiceList?.totalReceivedAmount
                      )}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell
                      index={8}
                      className="fw600"
                      align="right"
                    >
                      ₹{" "}
                      {Utils.getFormattedNumber(
                        props.salesInvoiceList?.totalDueAmount
                      )}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={9} className="fw600">
                      {"   "}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={10} className="fw600">
                      {"   "}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={11} className="fw600">
                      {"   "}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={12} className="fw600">
                      {"   "}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={13} className="fw600">
                      {"   "}
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              );
            }}
            locale={{
              triggerDesc: "",
              triggerAsc: "",
              cancelSort: "",
            }}
            rowSelection={rowSelection}
            columns={columns}
            style={{ width: "100%" }}
            dataSource={
              props?.LoadSkeleton
                ? (Array.from({ length: pageSize }, (_, index) => ({
                    key: `loading-${index}`,
                  })) as InvoiceDataType[])
                : invoiceListData
            }
            onChange={tableSort}
            // style={{ overflowY: "scroll" }}
            //  marginTop: "-22px"
            scroll={
              pageSize > 10
                ? { x: width <= 1024 ? 1500 : 700, y: 370 }
                : { x: width <= 1024 ? 1500 : 600 }
            }
            pagination={{
              total: totalRecords,
              current: page,
              pageSize: pageSize,
              showSizeChanger: true,
              onChange: (page: number, pageSize: number) => {
                setPage(page);
                setPageSize(pageSize);
                setExportType(-1);
              },
              pageSizeOptions: ["10", "15", "25", "50", "100"],
              showTotal: (totalRecords, page) =>
                `${page[0]}-${page[1]} of ${totalRecords} items`,
            }}
          />
        </Col>
      </Row>

      {/* <AddRefno open={open} handleCancel={() => setIsOpen(false)} type={type} /> */}
      {confirmation && (
        <ConfirmationModal
          open={confirmation}
          onNo={() => setConfirmation(false)}
          onYes={() => {
            setDeleteOrRevive({
              ...deleteOrRevive,
              id: invoiceId,
              isRev:
                invoiceStatus === VoucherFilterStatus.Deleted ? true : false,
            });
            setConfirmation(false);
          }}
          text={
            invoiceStatus === VoucherFilterStatus.Deleted
              ? `Are you sure you want to revive "${deleteOrRevive.number}"?`
              : `Are you sure you want to delete this row "${deleteOrRevive.number}" ?`
          }
        />
      )}
      {open === "viewSalesInvoice" && (
        <ViewSalesInvoice
          open={open === "viewSalesInvoice"}
          invVoucharType={splitPathInVchType}
          onCancel={() => setIsOpen("")}
          invoiceId={invoiceId}
        />
      )}
      {open === "viewInvoiceNoAllDetails" && (
        <ViewSalesInvoiceNoDetails
          open={open === "viewInvoiceNoAllDetails"}
          onCancel={() => setIsOpen("")}
          invoiceId={invoiceId}
        />
      )}
      {open === "viewPreview" && (
        <ViewPreviewModel
          open={open === "viewPreview"}
          onCancel={() => setIsOpen("")}
          voucherId={invoiceId}
          partyId={partyId}
        />
      )}
      {open === "emailSendParty" && (
        <EmailSendParty
          open={true}
          onCancel={() => setIsOpen("")}
          voucherId={invoiceId}
          partyId={partyId}
        />
      )}
    </div>
  );
};
export default SalesInvoiceTable;

export const InvoiceStatusShow = ({ dueDate, due, refund, invAmount }: any) => {
  // console.log( dueDate, "this is due dte");
  let col: string = "",
    back = "",
    txt = "",
    status;
  let diff = dayjs().diff(dayjs(dueDate), "day", true);
  // console.log("diff..........", diff);
  let splitPathInVchType: Voucher_Type;
  // let splitPathLedgerByGroupsType: SearchLedgerByGroupsTypes;
  // eslint-disable-next-line no-restricted-globals
  if (location.pathname.split("/")[4] === "sales") {
    splitPathInVchType = Voucher_Type.SalesVoucher;
    // splitPathLedgerByGroupsType = SearchLedgerByGroupsTypes.Sales;
  } else {
    splitPathInVchType = Voucher_Type.PurchaseVoucher;
    // splitPathLedgerByGroupsType = SearchLedgerByGroupsTypes.Purchase;
  }
  if (due > 0 && diff < 1) status = 0; // Due
  else if (diff >= 1 && due > 0) status = 1; // OverDue
  else if (due === 0 && refund !== invAmount) status = 2; // Paid
  else if (due === 0 && refund === invAmount) status = 3; // Return
  switch (status) {
    case 0:
      col = "#faad14";
      back = "white";
      txt = "Due";
      break;
    case 1:
      col = "#FF4D4F";
      // back = "#fff2f0";
      back = "white";
      txt = "Overdue";
      break;
    case 2:
      col = "#52c41a";
      back = "white";
      txt =
        splitPathInVchType === Voucher_Type.SalesVoucher ? "Received" : "Paid";
      break;
    case 3:
      col = "geekblue";
      back = "white";
      txt =
        splitPathInVchType === Voucher_Type.SalesVoucher
          ? "Credit Note"
          : "Debit Note";
      break;
    default:
  }
  return (
    <>
      <span
        style={{
          border: "1px solid #E4E8F0",
          fontWeight: 500,
          display: "block",
          textAlign: "center",
          fontSize: "13px",
          borderRadius: 4,
          color: col,
          backgroundColor: back,
        }}
      >
        {txt}
      </span>
    </>
  );
};
