import { useCallback, useEffect, useLayoutEffect, useRef } from "react";
export const useKeyboardShortcuts = (
  key: string,
  modifiers: ("ctrlKey" | "altKey" | "shiftKey")[],
  callback: () => void
) => {
  const callbackRef = useRef(callback);
  useLayoutEffect(() => {
    callbackRef.current = callback;
  });
  // activeElement.tagName === "BODY" ||
  // activeElement.tagName === "BUTTON" ||
  // activeElement.tagName === "DIV");

  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      const activeElement = document.activeElement;
      console.log("activeElement", activeElement);

      const isInputField =
        activeElement &&
        (activeElement.tagName === "INPUT" ||
          activeElement.tagName === "TEXTAREA");
      if (
        !isInputField &&
        (event.key === key || event.key.toUpperCase() === key) &&
        (modifiers.length === 0 || modifiers.every((m) => event[m]))
      ) {
        event.preventDefault();
        callbackRef.current();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [key, modifiers]
  );
  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    // document.onkeydown = handleKeyPress;
    return () => document.removeEventListener("keydown", handleKeyPress);
  }, [handleKeyPress]);
};
