import { KeyOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Row } from "antd";
import { CiKeyboard } from "react-icons/ci";

import React from "react";
import MainSettingModal from "../MainSettingModal";

const Shortcut = ({
  setOpenSettingModal,
  setIsPopoverVisible,
  setType,
  type,
  openSettingModal,
  isPopoverVisible,
}: any) => {
  return (
    <>
      <Row >
        <Col style={{ textAlign: "center" }}>
          <Button
            onClick={() => {
              setOpenSettingModal({ type: "settingModal", params: {} });
              setIsPopoverVisible(false);
              setType("ledger");
            }}
            shape="circle"
            type="primary"
            size="large"
            icon={<CiKeyboard size={"25px"} />}
          ></Button>
          <p style={{ textAlign: "center" }}>Shortcut</p>
        </Col>
      </Row>
      {openSettingModal.type === "settingModal" && (
        <MainSettingModal
          type={type}
          onDismiss={(confirmed: boolean) => {
            setOpenSettingModal({ type: "", params: {} });
          }}
        />
      )}
    </>
  );
};

export default Shortcut;
