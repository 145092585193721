import jsPDF from "jspdf";
import "jspdf-autotable";
import ExcelJS from "exceljs";
import dayjs from "dayjs";
import Papa from "papaparse";
import { InvoiceDataType } from "../Pages/Sales/SalesInvoiceTable";
import { Footer } from "antd/es/layout/layout";

interface SalesInvoice {
  InvoiceAmount: string | number;
}
class InvoiceExportDetails {
  // Method to generate PDF
  static generatePDF(
    invoiceListData: any[],
    currentClient: any,
    columns: any,
    fieldMapping: any,
    generateFooterRow: any,
    filterDate?: any,
    path?: string,
    oneMoreFooter?: any
  ) {
    const doc: any = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();

    const clientTextWidth = doc.getTextWidth(currentClient);
    const dateText =
      filterDate && (filterDate.from || filterDate.to)
        ? `From: ${filterDate.from || ""} To: ${filterDate.to || ""}`
        : "";
    const dateTextWidth = doc.getTextWidth(dateText);
    const pathTextWidth = doc.getTextWidth(path);
    doc.text(currentClient, (pageWidth - clientTextWidth) / 2, 16);
    doc.text(dateText, (pageWidth - dateTextWidth) / 2, 24);
    doc.text(path, (pageWidth - pathTextWidth) / 2, 40);

    const startY = 50;
    const amountColumns = [
      "Invoice Amount",
      "Credit Note",
      "Received",
      "Due Amount",
      "Amount",
      "Adjust Amount",
      "Pending Amount",
      "Total Qty",
      "Supplied Qty",
      "Pending Qty",
      "Balance",
      "Paid",
      "Debit",
      "Credit",
    ];

    const tableHead = [columns];
    const tableBody = invoiceListData.map((item) =>
      columns.map((col: any) => {
        const value = fieldMapping[col] ? fieldMapping[col](item) : "";
        const isAmountColumn = amountColumns.includes(col);
        return isAmountColumn
          ? { content: value, styles: { halign: "right" } }
          : value;
      })
    );

    const footerRow = generateFooterRow(columns).map(
      (cell: any, index: any) => {
        const columnName = columns[index];
        const isAmountColumn = amountColumns.includes(columnName);
        return isAmountColumn
          ? { content: cell, styles: { halign: "right" } }
          : cell;
      }
    );

    tableBody.push(footerRow);

    if (typeof oneMoreFooter === "function") {
      const additionalFooterRow = oneMoreFooter(columns).map(
        (cell: any, index: any) => {
          const columnName = columns[index];
          const isAmountColumn = amountColumns.includes(columnName);
          return isAmountColumn
            ? { content: cell, styles: { halign: "right" } }
            : cell;
        }
      );
      tableBody.push(additionalFooterRow);
    }

    doc.autoTable({
      startY: startY,
      head: tableHead,
      body: tableBody,
    });

    doc.save("invoice_data_export.pdf");
  }

  static generateCSV(
    invoiceListData: any,
    columns: string[],
    fieldMapping: any,
    generateFooterRow: any,
    oneMoreFooter?: any,
    filterDate?: any,
    title?: any,
    companyName?: any
  ) {
    try {
      const csvData = [];
      const addCenteredRow = (text: string) => {
        if (text) {
          const emptyCellsCount = Math.floor(columns.length / 2);
          const centeredRow = Array(emptyCellsCount)
            .fill("")
            .concat(text)
            .concat(Array(columns.length - emptyCellsCount - 1).fill(""));
          csvData.push(centeredRow);
        }
      };

      if (companyName) addCenteredRow(companyName);
      if (filterDate && (filterDate.from || filterDate.to)) {
        addCenteredRow(
          `From: ${filterDate.from || ""} To: ${filterDate.to || ""}`
        );
      }

      if (title) addCenteredRow(title);
      csvData.push(columns);
      csvData.push(
        ...invoiceListData?.map((item: any) =>
          columns.map((col) =>
            fieldMapping[col] ? fieldMapping[col](item) : ""
          )
        )
      );

      if (typeof generateFooterRow === "function") {
        const footerRow = generateFooterRow(columns);
        if (footerRow) {
          csvData.push(footerRow);
        }
      }
      if (typeof oneMoreFooter === "function") {
        const addRow = oneMoreFooter(columns);
        if (addRow) {
          csvData.push(addRow);
        }
      }
      const csv = Papa.unparse(csvData);
      const csvBlob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const url = window.URL.createObjectURL(csvBlob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "invoice_data_export.csv";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      console.log("CSV file created successfully");
    } catch (error) {
      console.error("Error generating CSV file:", error);
    }
  }

  static async generateExcel(
    invoiceListData: any[],
    currentClient: any,
    columns: string[],
    fieldMapping: any,
    generateFooterRow: any,
    filterDate?: any,
    path?: any,
    oneMoreFooter?: any
  ) {
    try {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Invoice Data");

      worksheet.mergeCells("A1:I1");
      const titleCell = worksheet.getCell("A1");
      titleCell.value = `${currentClient}`;
      titleCell.alignment = { horizontal: "center", vertical: "middle" };
      titleCell.font = { size: 16, bold: true };

      worksheet.mergeCells("A2:I2");
      const companyNameCell = worksheet.getCell("A2");
      companyNameCell.value =
        filterDate && (filterDate.from || filterDate.to)
          ? `From: ${filterDate.from || ""} To: ${filterDate.to || ""}`
          : "";
      companyNameCell.alignment = { horizontal: "center", vertical: "middle" };
      companyNameCell.font = { size: 12 };

      worksheet.mergeCells("A3:I3");
      const pathCell = worksheet.getCell("A3");
      pathCell.value = `${path}`;
      pathCell.alignment = { horizontal: "center", vertical: "middle" };
      pathCell.font = { size: 12 };

      const headerRow = worksheet.addRow(columns);
      worksheet.columns = columns.map(() => ({ width: 18 }));

      headerRow.font = { bold: true };
      headerRow.eachCell({ includeEmpty: true }, (cell: any) => {
        cell.alignment = { horizontal: "center", vertical: "middle" };
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "78b396" },
        };
      });

      const amountColumns = [
        "Invoice Amount",
        "Credit Note",
        "Received",
        "Due Amount",
        "Amount",
        "Adjust Amount",
        "Pending Amount",
        "Total Qty",
        "Supplied Qty",
        "Pending Qty",
        "Balance",
        "Paid",
        "Debit",
        "Credit",
      ];

      invoiceListData?.forEach((item) => {
        const row = worksheet.addRow(
          columns.map((col) =>
            fieldMapping[col] ? fieldMapping[col](item) : ""
          )
        );

        row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
          const columnName = columns[colNumber - 1];
          if (amountColumns.includes(columnName)) {
            cell.alignment = { horizontal: "right" };
          } else {
            cell.alignment = { horizontal: "left" };
          }
        });
      });

      if (typeof generateFooterRow === "function") {
        const footerRowValues = generateFooterRow(columns);
        const footerRow = worksheet.addRow(footerRowValues);
        footerRow.eachCell((cell) => {
          cell.alignment = { horizontal: "right" };
        });
      }
      if (typeof oneMoreFooter === "function") {
        const additionalFooterRowValues = oneMoreFooter(columns);
        const additionalFooterRow = worksheet.addRow(additionalFooterRowValues);
        additionalFooterRow.eachCell((cell) => {
          cell.alignment = { horizontal: "right" };
        });
      }

      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "invoice_data_export.xlsx";
      link.click();
      window.URL.revokeObjectURL(url);
      console.log("Excel file created successfully");
    } catch (error) {
      console.error("Error generating Excel file:", error);
    }
  }
}

export default InvoiceExportDetails;
