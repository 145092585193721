import {
  CloseOutlined,
  EditOutlined,
  FormOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Space,
  Tooltip,
} from "antd";
import React, { useContext } from "react";
import { useClient } from "../../Context/ClientContext";
import { SettingsSections } from "../../Services/SettingService";
import { notificationContext } from "../../Common/PageRoute";

const SettingPaymentTerm = () => {
  const { companySettings, updateCompanySettings } = useClient();
  const [form] = Form.useForm();
  const [disable, setDisable] = React.useState<boolean>(true);
  const [savebuttonloading, setSaveButtonLoading] =
    React.useState<boolean>(false);
  const [checkbox, setCheckbox] = React.useState<number>(1);
  const { openNotification } = useContext(notificationContext);

  console.log("companySettings", companySettings?.setting?.dueOn);

  const sumbit = () => {
    form.validateFields().then(async (val: any) => {
      setSaveButtonLoading(true);
      setDisable(true);
      console.log("post data",val);
      const data = {
        dueOn: {
          ...val,
        },
      };
      await updateCompanySettings!(
        SettingsSections.DueOn,
        {
          ...companySettings,
          setting: {
            ...companySettings?.setting!,
            dueOn: data?.dueOn,
          },
        },
        data
      ).then((result: any) => {
        if (result?.status) {
          openNotification("success", "Setting Updated successfully");
          setSaveButtonLoading(false);
        } else {
          setSaveButtonLoading(false);
          openNotification("error", "there is some error");
        }
      });
    });
  };

  React.useEffect(() => {
    form?.setFieldsValue({
      // type: companySettings?.setting?.dueOn?.type,
      duration: companySettings?.setting?.dueOn?.duration,
      // ordinal: companySettings?.setting?.dueOn?.ordinal,
      // ordinalUnit: companySettings?.setting?.dueOn?.ordinalUnit,
      // ordinalTarget: companySettings?.setting?.dueOn?.ordinalTarget,
      durationUnit: companySettings?.setting?.dueOn?.durationUnit,
      creditLimit: companySettings?.setting?.dueOn?.creditLimit,
    });
  }, []);

  return (
    <div style={{ padding: "0px 0px 3px 0px" }}>
      <Row>
        <Col span={9} className="fs-18 fw-600">
          Payment Term
        </Col>
        <Col
          style={{
            justifyContent: "end",
            display: "flex",
            // paddingBottom: "24px",
          }}
        >
          {disable && (
            <Tooltip title="Edit">
              <FormOutlined
                style={{ color: "rgb(106 119 227)" }}
                className="ca-edit-btn"
                onClick={() => setDisable(false)}
              />
            </Tooltip>
          )}
        </Col>
      </Row>
      <Divider className="my-12" />
      <Form form={form} colon={false} disabled={disable}>
        <Form.Item className="input-setting-paymentTerm">
          <Row>
            {/* <Checkbox
              style={{ paddingRight: "20px" }}
              checked={checkbox === 1}
              onChange={() => {
                if (checkbox !== 1) {
                  setCheckbox(1);
                }
              }}
            /> */}

            <Col span={2} style={{ paddingRight: "10px" }}>
              <Form.Item
                label="After"
                name="duration"
                className="input-setting-paymentTerm"
                rules={[
                  {
                    required: true,
                    message: "Enter a number",
                  },
                ]}
              >
                <Input
                  onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                />
              </Form.Item>
            </Col>

            <Col span={4}>
              <Form.Item
                name="durationUnit"
                className="input-setting-paymentTerm"
              >
                <Select
                  // disabled={checkbox !== 1}
                  defaultValue="Days"
                  options={[
                    { label: "Days", value: 0 },
                    { label: "Weeks", value: 1 },
                    { label: "Months", value: 2 },
                    { label: "Years", value: 3 },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>
        {/* <Row>
          <Checkbox
            style={{ paddingRight: "20px" }}
            checked={checkbox === 2}
            onChange={() => {
              if (checkbox !== 2) {
                setCheckbox(2);
              }
            }}
          />

           <Col span={2} style={{ paddingRight: "10px" }}>
            <Form.Item name="ordinal" className="input-setting-paymentTerm">
              <Select
                disabled={checkbox !== 2}
                defaultValue="Last"
                options={[
                  { label: "Last", value: 0 },
                  { label: "1st", value: 1 },
                  { label: "2nd", value: 2 },
                  { label: "3rd", value: 3 },
                  { label: "4th", value: 4 },
                  { label: "5th", value: 5 },
                  { label: "6th", value: 6 },
                  { label: "7th", value: 7 },
                  { label: "8th", value: 8 },
                  { label: "9th", value: 9 },
                  { label: "10th", value: 10 },
                  { label: "11th", value: 11 },
                  { label: "12th", value: 12 },
                  { label: "13th", value: 13 },
                  { label: "14th", value: 14 },
                  { label: "15th", value: 15 },
                  { label: "16th", value: 16 },
                  { label: "17th", value: 17 },
                  { label: "18th", value: 18 },
                  { label: "19th", value: 19 },
                  { label: "20th", value: 20 },
                  { label: "21th", value: 21 },
                  { label: "22th", value: 22 },
                  { label: "23th", value: 23 },
                  { label: "24th", value: 24 },
                  { label: "25th", value: 25 },
                  { label: "26th", value: 26 },
                  { label: "27th", value: 27 },
                  { label: "28th", value: 28 },
                  { label: "29th", value: 29 },
                  { label: "30th", value: 30 },
                  { label: "31th", value: 31 },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={2} style={{ paddingRight: "10px" }}>
            <Form.Item name="ordinalUnit" className="input-setting-paymentTerm">
              <Select
                disabled={checkbox !== 2}
                defaultValue="Monday"
                options={[
                  { label: "Sunday", value: 0 },
                  { label: "Monday", value: 1 },
                  { label: "Thesday", value: 2 },
                  { label: "Wednesday", value: 3 },
                  { label: "Thursday", value: 4 },
                  { label: "Friday", value: 5 },
                  { label: "Saturday", value: 6 },
                  { label: "Day", value: 1 },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={3} style={{ paddingRight: "10px" }}>
            <Form.Item
              label="of the"
              name="ordinalTarget"
              className="input-setting-paymentTerm"
            >
              <Select
                disabled={checkbox !== 2}
                defaultValue="Next"
                options={[
                  { label: "Current", value: 0 },
                  { label: "Next", value: 1 },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item
              name="durationUnit"
              className="input-setting-paymentTerm"
            >
              <Select
                disabled={checkbox !== 2}
                defaultValue="Week"
                options={[
                  { label: "Week", value: 1 },
                  { label: "Month", value: 2 },
                  { label: "Years", value: 3 },
                ]}
              />
            </Form.Item>
          </Col> 
        </Row> */}

        {/* <Checkbox
          style={{ gap: "20px", paddingBottom: "15px" }}
          checked={checkbox === 3}
          onChange={() => {
            if (checkbox !== 3) {
              setCheckbox(3);
            }
          }}
        />
        <span>Due on receipt</span> */}

        <Form.Item
          label="Credit Limit"
          name="creditLimit"
          wrapperCol={{ span: 2 }}
        >
          <Input />
        </Form.Item>
      </Form>
      {!disable && (
        <Space>
          <Button icon={<CloseOutlined />} onClick={() => setDisable(true)}>
            Cancel
          </Button>

          <Button
            type="primary"
            loading={savebuttonloading}
              onClick={() => {
                sumbit();
              
            }}
            icon={<SaveOutlined />}
          >
            Save
          </Button>
        </Space>
      )}
    </div>
  );
};

export default SettingPaymentTerm;
