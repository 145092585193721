import { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useClient } from "../../Context/ClientContext";
import { useIndianState } from "../../Context/StateOptionsContext";
import {
  Avatar,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Dropdown,
  Empty,
  Form,
  Input,
  InputNumber,
  MenuProps,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Tooltip,
  Typography,
} from "antd";
import noteContext from "../../Context/StyleContext";
import { notificationContext } from "../../Common/PageRoute";
import { Utils } from "../../../Utilities/Utils";
import { percentCal, percentToRs } from "../../Common/CAPComponents";
import {
  DownOutlined,
  EyeOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import VoucherServices, {
  AddressEnum,
  Party_Tax,
  Voucher_Type,
} from "../../Services/VoucherServices";
import { dayjs } from "../../../Utilities/dayjs";
import LedgerService, {
  SearchLedgerByGroupsTypes,
  TypeOfAddress,
} from "../../Services/LedgerService";
import SalesService, { CAPItemTypes } from "../../Services/SalesService";
import TextArea from "antd/es/input/TextArea";
import { getIndianStates } from "../../Services/ClientService";
import { ConfirmationModal } from "../../Common/ConfirmationModal";
import deleteImg from "../../../images/deletebutton.svg";
import AddItems from "../Purchase/AddItems";
import { AddLedger } from "../Master/Ledger/AddLedger";
import QuotationServices from "../../Services/QuotationServices";
// import { CompanyGSTTypes } from "../../../Types";
import { CompanyGSTTypes } from "../../../Types/CommonConstants";
import {
  SalesDropdownItems,
  PurchaseDropdownItems,
} from "./SalesandPurchaseDropdownList";
import EntryLevelKeys from "../../Common/EntryLevelKeys";

const AddOrder = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const inputRef: any = useRef(null);
  const { stateOptions } = useIndianState();
  const { windowWidth } = useContext(noteContext);
  const { currentClient, companySettings } = useClient();
  const { id, salesOrderId, quotationId, partyId } = useParams();
  const { openNotification } = useContext(notificationContext);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [trigger, setTrigger] = useState(false);
  const [dueOn, setDueOn] = useState<number>(0);
  const [balance, setBalance] = useState<number>(0);
  const [totalTcs, setTotalTcs] = useState<number>(0);
  const [totalTds, setTotalTds] = useState<number>(0);
  const [tdsOrTcs, setTdsOrTcs] = useState<number>(0);
  // const [totalgst, setTotalgst] = useState<number>(0);
  // const [totalQty, setTotalQty] = useState<number>(0);
  const [newCustomer, setNewCustomer] = useState<any>();
  const [isOpenDueDate, setIsDueDate] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [itemLength, setItemLength] = useState<number>(0);
  const [estimateNo, setEstimateNo] = useState<string>("");
  const [searchItem, setSearchItem] = useState<string>("");
  const [isInCash, setIsInCash] = useState<boolean>(false);
  const [itemsOption, setItemsOption] = useState<any[]>([]);
  const [account, setAccount] = useState<any[]>([]);
  const [deleteItemList, setDeleteItemList] = useState<any>();
  // const [invoiceTotal, setInvoiceTotal] = useState<number>(0.0);
  // const [salesOrderNo, setSalesOrderNo] = useState<string>("");
  // const [salesInvoiceNo, setSalesInvoiceNo] = useState<any>("");
  const [editParty, setEditParty] = useState<{ id: string }>({ id: "" });
  const [selectedParty, setSelectedParty] = useState<string>("");
  const [isGSTEnabled, setGstEnabled] = useState<boolean>(true);
  const [confirmation, setConfirmation] = useState<boolean>(false);
  const [ledgerconfirmation, setLedgerConfirmation] = useState<boolean>(false);
  const [tcsModelOpen, setTcsModelOpen] = useState<boolean>(false);
  const [tdsModelOpen, setTdsModelOpen] = useState<boolean>(false);
  const [customerOptions, setCustomerOptions] = useState<any[]>([]);
  const [onlyEditTimeCustomerOptions, setOnlyEditTimeCustomerOptions] =
    useState<boolean>(false);
  // const [addressList, setAddressList] = useState<any[]>([]);
  const [effectiveLedger, setEffectiveLedger] = useState<any[]>([]);
  const [currentCustomerState, setCurrentCustomerState] = useState<number>();
  const [saveButtonLoading, setSaveButtonLoading] = useState<boolean>(false);
  const [saveNewLoading, setSaveNewLoading] = useState<boolean>(false);
  // const [openCustomerDrawer, setOpenCustomerDrawer] = useState<boolean>(false);
  // const [initialRender, seInitialRender] = useState<boolean>(false);
  // const [deleteBankAccountRow, setDeleteBankAccountRow] = useState<any>();
  // const [bankConfirmation, setBankConfirmation] = useState<boolean>(false);
  // const [countDraft, setCountDraft] = useState<boolean>(false);
  // const [searchBankAccount, setSearchBankAccount] = useState<string>("");
  // const [isSaveDraft, setIsSaveDraft] = useState<Boolean>(true);
  // const [bankAccountAmt, setBankAccountAmt] = useState<number>(0);
  const [customerAddress, setCustomerAddress] = useState<any>();
  // const [addressEnum, setAddressEnum] = useState<number>(0);
  const [openModel, setOpenModel] = useState<{ type: string; param: any }>({
    type: "",
    param: {},
  });
  const [newItem, setNewItem] = useState<any>({
    data: {},
    isSaved: false,
  });
  const [itemFields, setItemFields] = useState<any>({
    field: 0,
    index: 0,
    add: null,
  });
  const [searchCustomer, setSearchCustomer] = useState<{
    search: string;
    length: number;
  }>({
    search: " ",
    length: 15,
  });

  const [footerTotal, setFooterTotal] = useState<{
    qty: number;
    unitPrice: number;
    taxableAmount: number;
    discount: number;
    gst: number;
    amount: number;
  }>({
    qty: 0,
    unitPrice: 0,
    taxableAmount: 0,
    discount: 0,
    gst: 0,
    amount: 0,
  });

  const [footerLedger, setFooterLedger] = useState<{
    ledgerAmount: number;
    ledgerGstAmount: number;
    ledgerTotalAmount: number;
  }>({
    ledgerAmount: 0,
    ledgerGstAmount: 0,
    ledgerTotalAmount: 0,
  });

  // const [directExpenses, setDirectExpenses] = useState<boolean>(false);
  const [ledgerLength, setLedgerLength] = useState<number>(0);
  const [freightAndInsuranceOptions, setFreightAndInsuranceOptions] =
    useState<any>([]);
  const [deleteLedgerList, setDeleteLedgerList] = useState<any>();
  const [ledgerAmount, setLedgerAmount] = useState<any>();
  const [showFreightInsurance, setShowFreightInsurance] =
    useState<boolean>(false);
  const [currentInvoiceDetails, setCurrentInvoiceDetails] = useState<any>();

  const [transactionId, setTransactionId] = useState<{
    partyId: string;
    effectiveId: string;
  }>({ partyId: "", effectiveId: "" });
  const [selectedOtherExpenses, setSelectedOtherExpenses] = useState<any[]>([]);
  const [dateFiled, setDateFiled] = useState<any>();
  const [editDate, setEditDate] = useState<any>();

  const gstOption = companySettings?.setting?.gstDetails?.gst;
  const isStateSame = currentCustomerState === currentClient?.state!;

  const amountInNumber = (val: any, key: any) => {
    return Utils.getNumber(val?.amount?.[key]);
  };
  console.log("location", location.pathname.split("/"));

  const price = (curr: any) => amountInNumber(curr, "price");
  const qty = (curr: any) => amountInNumber(curr, "qty");
  const discount = (curr: any) => amountInNumber(curr, "discount");
  const taxRate = (curr: any) => Utils.getNumber(curr?.amount?.tax?.value);
  const netAmount = (curr: any) => price(curr) * qty(curr);
  const discountRupee = (curr: any) =>
    percentCal(netAmount(curr), discount(curr));
  const amountWithoutTax = (curr: any) => netAmount(curr) - discountRupee(curr);
  const itemTaxAmt = (curr: any) =>
    percentCal(amountWithoutTax(curr), taxRate(curr));
  const amountWithTax = (curr: any) =>
    amountWithoutTax(curr) + itemTaxAmt(curr);
  const amountWithOutTax = (curr: any) => amountWithoutTax(curr);

  const amount = (curr: any) => amountInNumber(curr, "amount");
  const gstrate = (curr: any) => Utils.getNumber(curr?.gstRate?.title);
  const gstamount = (curr: any) => percentCal(curr?.amount, gstrate(curr));
  const ledgertotalamout = (curr: any) =>
    Utils.getNumber(curr?.amount) + gstamount(curr);
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  let splitPathInVchType: Voucher_Type =
    location.pathname.split("/")[4] === "sales"
      ? Voucher_Type.SalesOrder
      : Voucher_Type.PurchaseOrder;

  // Date Api
  useEffect(() => {
    const getDate = async () => {
      if (currentClient?.id && !quotationId) {
        await VoucherServices.getMaxVoucherDate(
          currentClient?.id!,
          Voucher_Type.Quotation
        ).then((res) => {
          const givenDate = new Date(res?.result);
          const currentTime = new Date();

          // Set the time part of givenDate to match currentTime
          givenDate?.setHours(
            currentTime.getHours(),
            currentTime.getMinutes(),
            currentTime.getSeconds(),
            currentTime.getMilliseconds()
          );
          form.setFieldValue("date", dayjs(givenDate));

          setDateFiled(res.result);
        });
      }
    };
    getDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient?.id]);

  // initital value
  useEffect(() => {
    if ((currentClient?.id && !salesOrderId, dateFiled)) {
      setLoading(true);
      VoucherServices.getVoucherMaxNo(
        currentClient?.id!,
        splitPathInVchType,
        dateFiled
      )
        .then((res) => {
          form.setFieldValue("number", res?.result);
        })
        .catch((err: any) => {
          openNotification("error", err.message);
          console.error(err.message);
        });
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient?.id, dateFiled]);

  // ----- Serch customer Api ------
  useEffect(() => {
    LedgerService.getLedgersByGroupName(
      currentClient?.id!,
      SearchLedgerByGroupsTypes.PartiesOnly
    )
      .then((res: any) => {
        if (res) {
          setCustomerOptions(res);
          setOnlyEditTimeCustomerOptions(true);
          console.log(customerOptions, "getledger");
        }
      })
      .catch((ex: any) => {
        console.error(ex);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient?.id, trigger]);

  // ----- Serch effectiveLedger Api ------
  useEffect(() => {
    LedgerService.getLedgersByGroupName(
      currentClient?.id!,
      splitPathInVchType === Voucher_Type.SalesOrder
        ? SearchLedgerByGroupsTypes.Sales
        : SearchLedgerByGroupsTypes.Purchase
    )
      .then((res: any) => {
        if (res) {
          setEffectiveLedger(res);
        }
      })
      .catch((ex: any) => {
        console.error(ex);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient?.id, trigger]);

  // Edit get api
  useEffect(() => {
    if (id || quotationId) {
      const editData = async (id: any, type: Voucher_Type) => {
        setLoading(true);
        await QuotationServices.getQuotationId(currentClient?.id!, id, type)
          .then((res: any) => {
            if (res?.result) {
              // console.log("true............", res.result?.partyLedger[0]?.ref);
              setSelectedParty(res.result?.partyLedger[0]?.id);
              // setEditParty({ id: res.result?.partyLedger[0]?.id });
              setFooterTotal({
                qty: res.result?.amountSummary?.totalQty,
                unitPrice: res.result?.amountSummary?.totalPrice,
                taxableAmount: res.result?.amountSummary?.taxableAmount,
                discount: res.result?.amountSummary?.totalDis,
                gst: res.result?.amountSummary?.totalItemGSTAmt,
                amount:
                  Math.abs(res.result?.effectiveLedger[0]?.amount) +
                  res.result?.amountSummary?.totalItemGSTAmt,
              });
              setFooterLedger({
                ledgerAmount: res.result?.amountSummary?.cifExpenses,
                ledgerGstAmount: res.result?.amountSummary?.totalCIFGSTAmt,
                ledgerTotalAmount:
                  res.result?.amountSummary?.cifExpenses +
                  res.result?.amountSummary?.totalCIFGSTAmt,
              });
              setBalance(res.result.amountSummary?.receivedAmount);

              setIsInCash(res.result.paymentType === 1 ? true : false);
              setItemLength(res.result.items.length);
              setCurrentCustomerState(res.result.supplyState);
              // setGstEnabled(res?.result?.isGstEnabled);
              setShowFreightInsurance(
                res.result?.freightInsLedgers?.length > 0
              );

              const Items = res.result?.items?.map((itm: any) => {
                return {
                  ...itm,
                  amount: {
                    ...itm?.amount,

                    tax: {
                      key: itm?.amount?.tax?.id,
                      label: itm?.amount?.tax?.name,
                      value: itm?.amount?.tax?.rate,
                    },
                  },
                };
              });

              const freightInsLedgers: any[] =
                res.result?.freightInsLedgers?.map((exp: any) => {
                  setSelectedOtherExpenses((select) => [...select, exp?.id]);
                  return {
                    ...exp,
                    id: {
                      value: exp?.id,
                      label: exp?.ledgerName,
                    },
                    amount: Math.abs(exp?.amount),
                    transactionId: exp?.transactionId,
                    gstRate: {
                      value: exp?.gstRate?.id,
                      title: exp?.gstRate?.rate,
                      label: exp?.gstRate?.name,
                    },
                  };
                });

              setLedgerLength(freightInsLedgers?.length ?? 1);
              setEditDate(res.result?.date);

              form.setFieldsValue({
                ...res.result,
                showFreightInsLedgers: freightInsLedgers?.length > 0,
                items: [
                  ...Items,
                  { amount: { price: 0.0, qty: 0, grossAmount: 0 } },
                ],
                freightInsLedgers:
                  freightInsLedgers?.length > 0
                    ? [
                        ...freightInsLedgers,
                        {
                          id: null,
                          gstRate: 0,
                          gstAmount: 0,
                          totalAmount: 0,
                          amount: 0,
                        },
                      ]
                    : [
                        {
                          id: null,
                          gstRate: 0,
                          gstAmount: 0,
                          totalAmount: 0,
                          amount: 0,
                        },
                      ],
                effectiveLedger: {
                  value: res.result?.effectiveLedger[0]?.id,
                  label: res.result?.effectiveLedger[0]?.ledgerName,
                },
                // phoneNo: res.result?.phoneNo,
                partyLedger: {
                  value: res.result?.partyLedger[0]?.id,
                  label: res.result?.partyLedger[0]?.ledgerName,
                },
                number: res.result.number,
                narration: res?.result?.narration,
                // billingAddress: res.result?.billingAddress,
                // shippingAddress: res.result?.shippingAddress.address,
                supplyState: res.result.supplyState,
                receivedAmount: res.result.amountSummary?.receivedAmount,
                paymentType: res.result.paymentType === 1 ? true : false,
                date: dayjs(res.result.date),
                dueDate: dayjs(
                  dayjs(res.result.dueDate).format("DD/MM/YYYY"),
                  "DD/MM/YYYY"
                ),
              });

              setLoading(false);
            } else setLoading(false);
          })
          .catch((err: any) => {
            setLoading(false);
            console.error("Error..", err.message);
            openNotification("error", err.message);
          });
        setLoading(false);
      };

      // editData(id);
      if (quotationId) {
        editData(quotationId, Voucher_Type.Quotation);
      } else if (id) {
        editData(id, Voucher_Type.SalesOrder);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  //------ Get a Include Other Expenses(Direct Income) Ledger
  useEffect(() => {
    LedgerService.getLedgersByGroupName(
      currentClient?.id!,
      splitPathInVchType === Voucher_Type.SalesOrder
        ? SearchLedgerByGroupsTypes.IndirectIncomeOnly
        : SearchLedgerByGroupsTypes.DirectExpenses
    )
      .then((res: any) => {
        // console.log(res, "indirExpLedgers");
        if (res) {
          setFreightAndInsuranceOptions(res);
        }
      })
      .catch((ex: any) => {
        console.error(ex);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient?.id, trigger]);

  // -------- Add Sales Invoice Post API ---------
  const save = (isSaveAndNew: boolean) => {
    form.validateFields().then(async (val: any) => {
      console.log("post Val....", val);

      isSaveAndNew ? setSaveNewLoading(true) : setSaveButtonLoading(true);

      const freightInsLedgers = val?.freightInsLedgers?.map((exp: any) => {
        return {
          ...exp,
          id: exp.id?.value,
          gstRate: {
            id: exp?.gstRate?.value,
            rate: exp?.gstRate?.title,
            name: exp?.gstRate?.label,
          },
          // value: gst?.id,
          // title: gst?.rate,
          // label: gst?.name,
          // gstAmount: exp?.gstAmount,
          // amount: exp?.amount,
          // totalAmount: exp?.totalAmount,
        };
      });

      const partyLedger = new Array({
        id: val?.partyLedger?.value,
        PartyOrTax: Party_Tax.party,
        amount:
          Utils.getNumber(footerTotal.amount) +
          Utils.getNumber(footerLedger.ledgerTotalAmount),
        transactionId:
          transactionId.partyId === "" ? null : transactionId.partyId,
      });

      const effectiveLedger = new Array({
        id: val?.effectiveLedger?.value,
        PartyOrTax: Party_Tax.other,
        amount: Utils.getNumber(footerTotal.taxableAmount),
        transactionId:
          transactionId.partyId === "" ? null : transactionId.partyId,
      });

      const Items = val?.items?.map((itm: any) => {
        return {
          ...itm,
          amount: {
            ...itm?.amount,
            grossAmount: Utils.getNumber(itm?.amount?.price) * itm?.amount?.qty,
            tax: {
              id: itm?.amount?.tax?.key,
              rate: itm?.amount?.tax?.value,
              name: itm?.amount?.tax?.label,
            },
          },
        };
      });

      let givenDate;
      if (quotationId && dayjs(val.date).isSame(dayjs(editDate), "day")) {
        givenDate = editDate;
      } else {
        givenDate = new Date(val.date);
        const currentTime = new Date();

        // Set the time part of givenDate to match currentTime
        givenDate?.setHours(
          currentTime.getHours(),
          currentTime.getMinutes(),
          currentTime.getSeconds(),
          currentTime.getMilliseconds()
        );
      }

      const invoice = {
        ...val,
        items: Items,
        date: dayjs(givenDate).toISOString(),
        // dueDate: dayjs(val?.dueDate).toString(),
        purchaseOrderRef: { id: quotationId },
        freightInsLedgers,
        partyLedger,
        effectiveLedger,
        // despatchDetail: invoiceDespatchDetails,
        // exportDetail: invoiceExportDetails,
        // statutoryLedgers,
        shippingAddress: val?.shippingAddress.type
          ? val.shippingAddress
          : { address: val?.shippingAddress?.address },
        isStateSame: isStateSame,
        amountSummary: {
          totalQty: Utils.getFormattedNumber(footerTotal?.qty),
          taxableAmount: Utils.getFormattedNumber(footerTotal?.taxableAmount), // befor gst
          totalDis: Utils.getFormattedNumber(footerTotal?.discount),
          freightAmount: 0,
          cessAmount: 0,
          igstAmt: !isStateSame
            ? Utils.getFormattedNumber(
                footerTotal?.gst + footerLedger.ledgerGstAmount
              )
            : 0,
          cgstAmt: isStateSame
            ? Utils.getFormattedNumber(
                (footerTotal?.gst + footerLedger.ledgerGstAmount) / 2
              )
            : 0,
          sgstAmt: isStateSame
            ? Utils.getFormattedNumber(
                (footerTotal?.gst + footerLedger.ledgerGstAmount) / 2
              )
            : 0,
          tcsRate: 0,
          tcsAmt: 0,
          totalInvoiceAmount:
            Utils.getNumber(footerTotal.amount) +
            Utils.getNumber(footerLedger.ledgerTotalAmount),
          receivedAmount: 0,
          voucherAmountWithoutTCS:
            Utils.getNumber(footerTotal.amount) +
            Utils.getNumber(footerLedger.ledgerTotalAmount) -
            balance,
        },
        // voucherGrossAmount,

        // totalUnitPrice: Utils.getFormattedNumber(footerTotal.unitPrice),
      };

      console.log("viewdatavoucher post...........", invoice);
      if (footerTotal.discount <= footerTotal.taxableAmount) {
        if (
          Utils.getNumber(footerTotal.amount) +
            Utils.getNumber(footerLedger.ledgerTotalAmount) >
          0
        ) {
          setOpenModel({
            type: "addInvoiceReferenceModel",
            param: {
              open: true,
            },
          });
          setCurrentInvoiceDetails(invoice);
          await QuotationServices.postQuotation(
            currentClient?.id!,
            splitPathInVchType,
            invoice,
            id
          )
            .then((res: any) => {
              if (res?.status) {
                sessionStorage.removeItem(`${currentClient?.id} Sales`);
                sessionStorage.removeItem("NotificationState");
                openNotification(
                  "success",
                  `${
                    splitPathInVchType === Voucher_Type.SalesOrder
                      ? "Sales Order"
                      : "Purhcase Order"
                  } ${res.result?.name} ${
                    id ? "Edit Successfully" : "Add Successfully"
                  }`
                );
                splitPathInVchType === Voucher_Type.SalesOrder
                  ? navigate(
                      `/accounts/clients/${currentClient?.id}/sales/order`
                    )
                  : partyId
                  ? navigate(
                      `/accounts/clients/${currentClient?.id}/parties/${editParty?.id}`
                    )
                  : navigate(
                      `/accounts/clients/${currentClient?.id}/purchase/order`
                    );

                setFooterTotal({
                  qty: 0,
                  unitPrice: 0,
                  taxableAmount: 0,
                  discount: 0,
                  gst: 0,
                  amount: 0,
                });
                form.resetFields();
                // setInvoiceTotal(0.0);
                setItemLength(0);
                setSaveButtonLoading(false);
              } else if (res?.message) {
                setSaveButtonLoading(false);
                openNotification("error", res?.message);
              }
            })
            .catch((ex) => openNotification("error", ex));
          setSaveButtonLoading(false);
          setSaveNewLoading(false);
          // isSaveAndNew && seInitialRender(!initialRender);
        } else {
          setSaveNewLoading(false);
          openNotification("error", "Order price must be grated than 0");
          setSaveButtonLoading(false);
        }
      } else {
        openNotification("error", "discount should be less than Order price");
        setSaveButtonLoading(false);
      }
    });
  };
  EntryLevelKeys({
    save: () => save(false),
    cancel: () => navigate(-1),
  });
  //-------- All Item List-----------
  useEffect(() => {
    SalesService.searchItem(
      currentClient?.id!,
      searchItem,
      splitPathInVchType === Voucher_Type.SalesOrder
        ? CAPItemTypes.Sales
        : CAPItemTypes.Purchase
    ).then((res) => {
      setItemsOption(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient?.id, searchItem]);

  // ----- Add new customer ----------
  useEffect(() => {
    if (newCustomer) {
      form.setFieldValue("partyLedger", {
        value: newCustomer?.id,
        label: newCustomer?.businessName,
      });
      form.setFieldValue("billingAddress", newCustomer?.billingAddress);
      form.setFieldValue("shippingAddress", newCustomer?.shippingAddress);
      form.setFieldValue("supplyState", newCustomer?.state);
      const defaultPhone = newCustomer?.contactInformation?.find(
        (item: any) => item?.isDefault === true
      );
      form.setFieldValue("phoneNo", defaultPhone?.phone);
      setEditParty({ id: newCustomer?.id });
      setSelectedParty(newCustomer?.id);
      setCurrentCustomerState(newCustomer?.state);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newCustomer]);

  //------- Add new item ------
  useEffect(() => {
    if (newItem?.isSaved && newItem?.data?.salesItem?.isActive) {
      // Set the new item's data into the form field
      const newItemFields = {
        name: newItem.data?.name,
        isService: newItem.data?.isService,
        isTaxable: newItem.data?.isTaxable,
        id: newItem.data?.id,
        description: newItem.data?.salesItem?.description,
        account: {
          value: newItem.data?.salesItem?.account?.id,
          lable: newItem.data?.salesItem?.account?.name,
        },
        amount: {
          qty: 1,
          price: newItem.data?.isTaxable
            ? Utils.getFormattedNumber(
                (newItem.data?.salesItem?.price /
                  (100 + newItem.data?.taxRate.rate)) *
                  100
              )
            : newItem.data?.salesItem?.price,
          tax: {
            key: newItem.data?.taxRate?.id,
            label: newItem.data?.taxRate?.name,
            value: newItem.data?.taxRate?.rate,
          },
        },
      };

      // Update the current item at index itemFields.index
      setItemLength(itemFields.index + 1);
      form.setFieldValue(["items", itemFields.index], newItemFields);

      // If the current item index is at the end, add a new item with initial values
      if (itemFields?.field.length === itemFields?.index + 1) {
        form.setFieldValue(["items", itemFields.index + 1], {
          amount: { price: 0.0, qty: 0, totalAmount: 0 },
        });
      }

      // Prepare the new item data to be added to itemsOption
      const newItemData = {
        name: newItem.data?.name,
        isService: newItem.data?.isService,
        isTaxable: newItem.data?.isTaxable,
        id: newItem.data?.id,
        description: newItem.data?.salesItem?.description,
        account: {
          value: newItem.data?.salesItem?.account?.id,
          lable: newItem.data?.salesItem?.account?.name,
        },
        quantity: 1,
        price: newItem.data?.isTaxable
          ? Utils.getFormattedNumber(
              (newItem.data?.salesItem?.price /
                (100 + newItem.data?.taxRate.rate)) *
                100
            )
          : newItem.data?.salesItem?.price,
        tax: {
          key: newItem.data?.taxRate?.id,
          label: newItem.data?.taxRate?.name,
          value: newItem.data?.taxRate?.rate,
        },
      };

      // Add the new item data to itemsOption
      setItemsOption([...itemsOption, newItemData]);

      // Call the function to handle the total values
      handleItemsNetAmmount(itemFields.index);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newItem.data, newItem.isSaved]);

  const TdsOptions = [
    { label: "None", value: 0 },
    { label: "5 %", value: 5 },
    { label: "10 %", value: 10 },
    { label: "2 %", value: 2 },
    { label: "1 %", value: 1 },
  ];

  const handleDeleteRowButton = () => {
    handleItemsNetAmmount(0);
    setConfirmation(false);
  };

  useEffect(() => {
    // update invoice time Customer address set
    if (id && selectedParty && onlyEditTimeCustomerOptions) {
      CustomerSelect(selectedParty);
      setOnlyEditTimeCustomerOptions(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerOptions, selectedParty]);

  const CustomerSelect: any = async (value: string) => {
    if (value) {
      let findCutomerDetails = customerOptions.find(
        (Ledgerlist: any) => Ledgerlist?.id === value
      );
      // console.log("findCutomerDetails", findCutomerDetails);
      setCustomerAddress(findCutomerDetails?.addresses);
      customerAddSelect(
        TypeOfAddress.RegisteredAddress,
        AddressEnum.BillingAddress,
        findCutomerDetails?.addresses
      );
      setEditParty({ id: value });
      setSelectedParty(value);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  };

  // onChange in address type
  const customerAddSelect: any = (
    typeOfAddress: TypeOfAddress,
    addressEnum: AddressEnum,
    addressTotal: any[]
  ) => {
    // console.log(
    //   "customerAddress",
    //   customerAddress,
    //   addressTotal,
    //   addressTotal.length > 0
    // );

    let findAddress: any;
    if (addressTotal?.length > 0) {
      findAddress = addressTotal?.find(
        (type: any) => type?.type === typeOfAddress
      );
    } else if (customerAddress?.length > 0) {
      findAddress = customerAddress?.find(
        (type: any) => type?.type === typeOfAddress
      );
      console.log("Hello findAddress", findAddress);
    }

    if (findAddress?.address) {
      // console.log("findAddress.....", findAddress);
      // const objectofaddress1: any[] = [];
      let objectofaddress1: string = "";
      Object.keys(findAddress?.address).forEach((key: any) => {
        // console.log("key...........", key);
        switch (key) {
          case "building":
            if (findAddress?.address[key])
              objectofaddress1 += findAddress?.address[key] + ", ";
            break;
          case "street":
            if (findAddress?.address[key])
              objectofaddress1 += findAddress?.address[key] + ", " + "\n";
            else if (findAddress?.address[key] === "building")
              objectofaddress1 += "\n";
            break;
          case "landmark":
            if (findAddress?.address[key])
              objectofaddress1 += findAddress?.address[key] + ", ";
            break;
          case "city":
            if (findAddress?.address[key])
              objectofaddress1 += findAddress?.address[key] + ", " + "\n";
            else if (findAddress?.address[key] === "landmark")
              objectofaddress1 += "\n";
            break;
          case "district":
            if (findAddress?.address[key])
              objectofaddress1 += findAddress?.address[key] + ", ";
            break;
          case "pincode":
            if (findAddress?.address[key])
              objectofaddress1 += findAddress?.address[key] + ", " + "\n";
            else if (findAddress?.address[key] === "district")
              objectofaddress1 += "\n";
            break;
          case "state":
            objectofaddress1 +=
              getIndianStates(findAddress?.address[key]) + ", ";
            if (addressEnum === AddressEnum.BillingAddress) {
              setCurrentCustomerState(findAddress?.address[key]);
              form.setFieldValue("supplyState", findAddress?.address[key]);
              form.validateFields(["supplyState"]);
            }
            break;
          case "country":
            if (findAddress?.address[key])
              objectofaddress1 += findAddress?.address[key];
            break;
          default:
            if (findAddress?.address[key])
              objectofaddress1 += findAddress?.address[key] + ", ";
        }
      });

      if (addressEnum === AddressEnum.BillingAddress) {
        form.setFieldValue(["billingAddress", "address"], objectofaddress1);
        form.setFieldValue(["billingAddress", "type"], typeOfAddress);
      } else {
        form.setFieldValue(["shippingAddress", "address"], objectofaddress1);
        form.setFieldValue(["shippingAddress", "type"], typeOfAddress);
      }
    } else {
      if (addressEnum === AddressEnum.BillingAddress) {
        form.setFieldValue(["billingAddress", "address"], "");
        form.setFieldValue(["billingAddress", "type"], typeOfAddress);
      } else {
        form.setFieldValue(["shippingAddress", "address"], "");
        form.setFieldValue(["shippingAddress", "type"], typeOfAddress);
      }
    }
  };

  // select in Items
  const ItemSelect: any = async (value: string, index: number) => {
    setItemLength(index + 1);
    const selectedItem = itemsOption?.find((Item: any) => Item?.id === value);

    const IsService = selectedItem.isService;
    if (selectedItem) {
      form.setFieldValue(["items", index], {
        name: selectedItem.name,
        isService: IsService,
        isTaxable: selectedItem.isTaxable,
        id: selectedItem.id,
        description: selectedItem.description,
        // account: {
        //   label: selectedItem.account?.name,
        //   value: selectedItem.account?.id,
        // },
        amount: {
          qty: selectedItem.quantity,
          price: selectedItem.isTaxable
            ? Utils.getFormattedNumber(
                (selectedItem.price / (100 + selectedItem.taxRate.rate)) * 100
              )
            : selectedItem.price,
          tax: {
            value: selectedItem.taxRate.rate,
            label: selectedItem.taxRate.name,
            key: selectedItem.taxRate.id,
          },
        },
      });
      handleItemsNetAmmount(index);
    }

    const items = form.getFieldValue("items");
    const currentItems = items.filter((itm: any) => itm?.id !== undefined);

    if (value) {
      const data = itemsOption?.map((o: any) => {
        if (currentItems.some((item: any) => item?.id === o.id)) {
          return {
            ...o,
            disabled: true,
          };
        } else {
          return {
            ...o,
            disabled: false,
          };
        }
      });
      console.log("data", data);
      setItemsOption(data);
    }
  };

  const handleAllTheTotalValues = () => {
    const ItemList = form.getFieldValue(["items"]);

    // console.log("ItemList", ItemList);
    //------- Total Row Calculations -------
    const Total = ItemList.reduce(
      (
        acc: {
          TotalQty: number;
          TotalUnitPrice: number;
          ItemSubTotal: number;
          TotalDiscount: number;
          Totalgst: number;
          TotalAmount: number;
        },
        curr: any
      ) => {
        acc.TotalQty += qty(curr);
        acc.TotalUnitPrice += price(curr);
        acc.ItemSubTotal += price(curr) * qty(curr);
        acc.TotalDiscount += amountInNumber(curr, "discountRupee");
        acc.Totalgst += amountInNumber(curr, "itemTaxAmt");
        acc.TotalAmount += amountInNumber(curr, "totalAmount");
        return acc;
      },
      {
        TotalQty: 0,
        TotalUnitPrice: 0,
        ItemSubTotal: 0,
        TotalDiscount: 0,
        Totalgst: 0,
        TotalAmount: 0,
      }
    );

    console.log("Total", Total);

    setFooterTotal({
      qty: Total.TotalQty,
      unitPrice: Total.TotalUnitPrice,
      taxableAmount: Total.ItemSubTotal,
      discount: Total.TotalDiscount,
      gst: Total.Totalgst,
      amount: Total.TotalAmount,
    });

    // ----- Calculate item's total amount with discount -----
    const AmountAfterDisc = ItemList.reduce((acc: number, curr: any) => {
      return (acc += amountWithoutTax(curr));
    }, 0);

    //----- Calculate TDS amount -----
    const TdsRate = form.getFieldValue("tdsRate");
    const TotalTds = percentCal(AmountAfterDisc, TdsRate);
    setTotalTds(TotalTds);

    //----- Calculate TCS amount -----
    const TcsRate = form.getFieldValue("tcsRate");
    const TotalTcs = percentCal(footerTotal.amount, TcsRate);

    setTotalTcs(TotalTcs);

    //----- Calculate invoice total (WITH GST AND DISCOUNT AND TDS) -----
    const InvoiceTotal = ItemList.reduce((acc: number, curr: any) => {
      return (acc +=
        amountInNumber(curr, "totalAmount") +
        (tdsOrTcs === 0
          ? percentCal(amountWithoutTax(curr), TdsRate)
          : tdsOrTcs === 1
          ? percentCal(footerTotal.amount, TcsRate)
          : 0));
    }, 0);
    // setInvoiceTotal(InvoiceTotal);
  };

  // on change in items files amount chnage
  const handleItemsNetAmmount = (index: number) => {
    // console.log(index);
    const ItemList = form.getFieldValue(["items"]);
    const currentIndexData = ItemList[index];
    console.log("items........", itemTaxAmt(currentIndexData));
    if (currentIndexData) {
      form.setFieldValue(["items", index], {
        ...currentIndexData,
        amount: {
          ...currentIndexData.amount,
          discountRupee: Utils.getFormattedNumber(
            discountRupee(currentIndexData)
          ),
          itemTaxAmt: Utils.getFormattedNumber(itemTaxAmt(currentIndexData)),
          totalAmount: Utils.getFormattedNumber(
            amountWithTax(currentIndexData)
          ),
        },
      });
    }

    // console.log("checkinvoicedat", ItemList);
    handleAllTheTotalValues();
    // addTotalTaxAmountChange(currentIndexData);
  };

  const onDiscountAmountChange = (index: number) => {
    const ItemList = form.getFieldValue(["items"]);
    const currentIndexData = ItemList[index];

    if (currentIndexData) {
      // Calculate the new values based on the discount change
      const newDiscountAmount = percentToRs(
        amountInNumber(currentIndexData, "discountRupee"),
        netAmount(currentIndexData)
      );

      const newTaxRs = percentCal(
        netAmount(currentIndexData) -
          amountInNumber(currentIndexData, "discountRupee"),
        taxRate(currentIndexData)
      );

      const newTotalAmount =
        netAmount(currentIndexData) -
        amountInNumber(currentIndexData, "discountRupee") +
        newTaxRs;

      // Update the form field with the new calculated values
      form.setFieldValue(["items", index], {
        ...currentIndexData,
        amount: {
          ...currentIndexData.amount,
          discount: Utils.getFormattedNumber(newDiscountAmount),
          itemTaxAmt: Utils.getFormattedNumber(newTaxRs),
          totalAmount: Utils.getFormattedNumber(newTotalAmount),
        },
      });

      // Call the function to handle the total values if needed
      handleAllTheTotalValues();
    }
  };

  const onItemsTotalAmountChange = async (index: number) => {
    console.log("index...........", index);

    const ItemList = form.getFieldValue(["items"]);
    const currentIndexData = ItemList[index];

    // Calculate the denominator for various calculations
    const denominator =
      100 -
      discount(currentIndexData) +
      percentCal(100 - discount(currentIndexData), taxRate(currentIndexData));

    // Calculate the price based on the given formula
    const calculatedPrice =
      (amountInNumber(currentIndexData, "totalAmount") * 100) /
      (denominator * qty(currentIndexData));

    // Calculate the discountRupee based on the given formula
    const calculatedDiscountRupee =
      (amountInNumber(currentIndexData, "totalAmount") *
        discount(currentIndexData)) /
      denominator;

    // Calculate the itemTaxAmt based on the given formula
    const calculatedTaxRs =
      (amountInNumber(currentIndexData, "totalAmount") *
        percentCal(
          100 - discount(currentIndexData),
          taxRate(currentIndexData)
        )) /
      denominator;

    if (denominator > 0 && currentIndexData?.amount?.qty > 0) {
      // Update the form field values with the calculated values
      form.setFieldValue(["items", index], {
        ...currentIndexData,
        amount: {
          ...currentIndexData?.amount,
          price: Utils.getFormattedNumber(calculatedPrice),
          discountRupee: Utils.getFormattedNumber(calculatedDiscountRupee),
          itemTaxAmt: Utils.getFormattedNumber(calculatedTaxRs),
        },
      });
    } else {
      // Denominator is zero or negative, set all fields to 0
      form.setFieldValue(["items", index], {
        ...currentIndexData,
        amount: {
          ...currentIndexData?.amount,
          price: 0,
          discountRupee: 0,
          itemTaxAmt: 0,
          totalAmount: 0,
        },
      });
    }
    handleAllTheTotalValues();
  };

  // Freight And InsLedger total in fotter
  const handleFreightAndInsLedgerTotal = () => {
    const LedgerAllList = form.getFieldValue(["freightInsLedgers"]);

    //----- Total Row Calculations---------
    const AmountLedger = LedgerAllList.reduce((acc: number, curr: any) => {
      return (acc += Utils.getNumber(curr?.amount));
    }, 0);
    console.log("AmountAfterDisc...........", AmountLedger);

    const Total = LedgerAllList.reduce(
      (
        acc: {
          amount: number;
          gstAmount: number;
          totalAmount: number;
        },
        curr: any
      ) => {
        acc.amount += Utils.getNumber(curr?.amount);
        acc.gstAmount += Utils.getNumber(curr?.gstAmount);
        acc.totalAmount += Utils.getNumber(curr?.totalAmount);
        return acc;
      },
      {
        amount: 0,
        gstAmount: 0,
        totalAmount: 0,
      }
    );
    // console.log("totalvalueoftheledger", Total);

    setFooterLedger({
      ledgerAmount: Total.amount,
      ledgerGstAmount: Total.gstAmount,
      ledgerTotalAmount: Total.totalAmount,
    });

    // ----- Calculate items total unit Price -----
    const TotalLedgerAmount = LedgerAllList.reduce((acc: number, curr: any) => {
      return (acc += amountInNumber(curr, "totalAmount"));
    }, 0);
    setLedgerAmount(TotalLedgerAmount);

    console.log("ledgeralllist", LedgerAllList);
  };

  // on chnage in Freight And InsLedger unit price & gst
  const handleLedgerNetAmount = (index: number) => {
    const Ledgerlist = form.getFieldValue(["freightInsLedgers"]);
    const currentLedgerIndexData = Ledgerlist[index];
    console.log("currentLedgerIndexData", currentLedgerIndexData);

    if (currentLedgerIndexData) {
      form.setFieldValue(["freightInsLedgers", index], {
        ...currentLedgerIndexData,
        gstAmount: Utils.getFormattedNumber(gstamount(currentLedgerIndexData)),
        // totalAmount: Utils.getFormattedNumber(currentLedgerIndexData?.amount),
        totalAmount: Utils.getFormattedNumber(
          ledgertotalamout(currentLedgerIndexData)
        ),
      });
      handleFreightAndInsLedgerTotal();
    }
  };

  const onLedgerTotalAmountChange = async (index: number) => {
    const LedgerList = form.getFieldValue(["freightInsLedgers"]);
    const currentIndexData = LedgerList[index];
    // console.log(currentIndexData);

    //Calculate the denominator for various calculations
    const denominators = gstrate(currentIndexData);
    console.log("denominators", denominators);

    const gstAmount =
      (Utils.getNumber(currentIndexData.totalAmount) * denominators) / 100;
    console.log("gstAmount", gstAmount);

    const ledgernetAmount =
      Utils.getNumber(currentIndexData.totalAmount) - gstAmount;
    console.log("ledgernetAmount...", ledgernetAmount);

    if (ledgernetAmount > 0) {
      form.setFieldValue(["freightInsLedgers", index], {
        ...currentIndexData,
        amount: Utils.getFormattedNumber(ledgernetAmount),
        gstAmount: Utils.getFormattedNumber(gstAmount),
        // totalAmount: Utils.getFormattedNumber(
        //   ledgertotalamout(currentIndexData)
        // ),
      });
    } else {
      // ledgernetAmount is zero or negative, set all fields to 0
      form.setFieldValue(["freightInsLedgers", index], {
        ...currentIndexData,
        amount: Utils.getFormattedNumber(ledgernetAmount),
        gstAmount: Utils.getFormattedNumber(gstAmount),
      });
    }
    handleFreightAndInsLedgerTotal();
  };

  // Include Other Expenses (Indirect Income expenses) select
  const freightInsLedgersSelect: any = async (value: any, index: number) => {
    // console.log("value11.............", value, index);
    const currentSelectLedger = freightAndInsuranceOptions.find(
      (x: any) => x.id === value
    );

    console.log("currentSelectLedger.............", currentSelectLedger);
    if (currentSelectLedger) {
      const currentData = form.getFieldValue(["freightInsLedgers", index]);

      form.setFieldValue(["freightInsLedgers", index], {
        id: {
          value: currentSelectLedger?.id,
          label: currentSelectLedger.ledgerName,
        },
        amount: currentData?.amount,
        gstRate: {
          value: currentSelectLedger?.gstRate?.id,
          label: currentSelectLedger?.gstRate?.name,
          title: currentSelectLedger?.gstRate?.rate,
        },
        gstAmount: Utils.getFormattedNumber(gstamount(currentData)),
        totalAmount: Utils.getFormattedNumber(ledgertotalamout(currentData)),
      });
    }
    setLedgerLength(index + 1);
    handleFreightAndInsLedgerTotal();
    setSelectedOtherExpenses((prevState) => {
      const updatedSelectedOptions = [...prevState];
      updatedSelectedOptions[index] = value;
      return updatedSelectedOptions;
    });
    // console.log("currentSelectLedger", currentSelectLedger);
  };

  const [addLedgerResponse, setAddLedgerResponse] = useState<any>(null);

  //------- Set partner Data After Adding ledger ------
  useEffect(() => {
    if (addLedgerResponse) {
      const exists = customerOptions.some(
        (option) => option.id === addLedgerResponse.id
      );
      if (exists) {
        form.setFieldValue("partyLedger", {
          key: addLedgerResponse?.id,
          value: addLedgerResponse?.id,
          label: addLedgerResponse?.ledgerName,
        });
        CustomerSelect(addLedgerResponse?.id);
        setSelectedParty(addLedgerResponse?.id);
      }
      setAddLedgerResponse(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerOptions]);

  //------- Set other expense Data After Adding ledger ------
  useEffect(() => {
    try {
      if (addLedgerResponse) {
        const exists = freightAndInsuranceOptions.some(
          (option: any) => option.id === addLedgerResponse.id
        );
        if (exists) {
          let freightInsLedgers = [
            ...(form.getFieldValue("freightInsLedgers") || []),
          ];
          freightInsLedgers.pop();
          freightInsLedgers.push({
            gstRate: 0,
            gstAmount: 0,
            totalAmount: 0,
            amount: 0,
            id: {
              label: addLedgerResponse?.ledgerName,
              value: addLedgerResponse.id,
              key: addLedgerResponse.id,
            },
          });
          freightInsLedgers.push({
            gstRate: 0,
            gstAmount: 0,
            totalAmount: 0,
            amount: 0,
            id: {
              label: "",
              value: "",
              key: "",
            },
          });
          const index = freightInsLedgers.findIndex(
            (ledgerArray) => ledgerArray?.id?.value === addLedgerResponse.id
          );
          form.setFieldValue("freightInsLedgers", freightInsLedgers);
          freightInsLedgersSelect(addLedgerResponse.id, index);
        }
        setAddLedgerResponse(null);
      }
    } catch (error) {
      console.log("error", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [freightAndInsuranceOptions]);

  //------- Set sales ledger Data After Adding ledger ------
  useEffect(() => {
    if (addLedgerResponse) {
      const exists = effectiveLedger.some(
        (option) => option.id === addLedgerResponse.id
      );
      if (exists) {
        form.setFieldValue("effectiveLedger", {
          ...form.getFieldValue("effectiveLedger"),
          key: addLedgerResponse?.id,
          value: addLedgerResponse?.id,
          label: addLedgerResponse?.ledgerName,
        });
        CustomerSelect(addLedgerResponse.id);
        setSelectedParty(addLedgerResponse?.id);
      }
      setAddLedgerResponse(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [effectiveLedger]);

  return (
    <div>
      <Spin indicator={antIcon} spinning={loading}>
        <Form
          form={form}
          autoComplete="off"
          name="salesInvoice"
          initialValues={{
            tcsRate: 0,
            tdsRate: 0,
            showFreightInsLedgers: showFreightInsurance,
            items: [
              {
                amount: { price: 0.0, qty: 0, totalAmount: 0 },
              },
            ],
            freightInsLedgers: [
              {
                gstRate: 0,
                gstAmount: 0,
                totalAmount: 0,
                amount: 0,
              },
            ],
            bankDts: [{}],
          }}
        >
          {windowWidth <= 1024 ? (
            <h3 className="fs-35 fw-500 themeColor textCenter">
              Sales Invoice
            </h3>
          ) : (
            ""
          )}
          <Row justify="space-between">
            <Col xl={17} lg={24} md={24} className="dashedBoxStyle">
              <Row gutter={24} className="ps-14" justify="space-between">
                <Col
                  lg={14}
                  md={11}
                  xs={23}
                  sm={23}
                  className="themeLightBgColor p-10"
                >
                  <Row gutter={[17, 2]}>
                    <Col lg={12}>
                      <Row justify="space-between">
                        <Col className="themeColor fw-500">Party</Col>
                        {selectedParty && (
                          <Col className="fw-500">
                            <Tooltip title="View Party">
                              <EyeOutlined
                                onClick={() => {
                                  setEditParty({ id: selectedParty });
                                  setOpenModel({
                                    type: "addLedger",
                                    param: {
                                      open: true,
                                    },
                                  });
                                }}
                              />
                            </Tooltip>
                          </Col>
                        )}
                      </Row>
                      <Form.Item
                        name="partyLedger"
                        className="m-0 selectPlaceholder selectedItem errormsg"
                        rules={[{ required: true, message: "Select a Party" }]}
                      >
                        <Select
                          showSearch
                          allowClear
                          labelInValue
                          optionFilterProp="children"
                          placeholder="Party Name"
                          filterOption={false}
                          className="selectedItem"
                          disabled={salesOrderId || quotationId ? true : false}
                          autoFocus={true}
                          ref={inputRef}
                          onSearch={(value: string) =>
                            setSearchCustomer({ search: value, length: 15 })
                          }
                          // onClick={() =>
                          //   setSearchCustomer({ ...searchCustomer, search: "" })
                          // }
                          onChange={(e: any) => {
                            form.setFieldValue(
                              ["shippingAddress", "address"],
                              ""
                            );
                            form.setFieldValue(["shippingAddress", "type"], "");
                            CustomerSelect(e?.value);
                          }}
                          options={customerOptions?.map((value: any) => ({
                            value: value?.id,
                            label: value?.ledgerName.concat(
                              value?.aliasName
                                ? `${" (" + value?.aliasName + ")"}`
                                : ""
                            ),
                          }))}
                          dropdownRender={(customerOptions) => (
                            <>
                              {customerOptions}
                              <Divider style={{ margin: "5px 0px" }} />
                              <Button
                                style={{ width: "100%" }}
                                icon={<PlusOutlined />}
                                type="link"
                                onClick={() => {
                                  setEditParty({ id: "" });
                                  setOpenModel({
                                    type: "addLedger",
                                    param: {
                                      open: true,
                                    },
                                  });
                                }}
                              >
                                New Customer
                              </Button>
                            </>
                          )}
                        />
                      </Form.Item>
                    </Col>
                    {/* <Col lg={12}>
                      <span className="themeColor fw-500">Phone No.</span>
                      <Form.Item
                        name="phoneNo"
                        className="inputBoxMb"
                        rules={[
                          {
                            min: 10,
                            message: "phone no. must be 10 digits",
                          },
                        ]}
                      >
                        <Input
                          placeholder="9876543210"
                          onKeyPress={(e) =>
                            !/[0-9]/.test(e.key) && e.preventDefault()
                          }
                        />
                      </Form.Item>
                    </Col> */}
                    <Col lg={12}>
                      <span className="themeColor fw-500">
                        {splitPathInVchType === Voucher_Type.PurchaseOrder
                          ? "Purchase Ledger"
                          : "Sales Ledger"}
                      </span>
                      <Form.Item
                        name="effectiveLedger"
                        className="mb-3 errormsg"
                        rules={[
                          {
                            required: true,
                            message: `Please select effectiveLedger`,
                          },
                        ]}
                      >
                        <Select
                          labelInValue
                          showSearch
                          allowClear={false}
                          placeholder="Select ledger"
                          popupMatchSelectWidth={false}
                          placement="bottomLeft"
                          optionFilterProp="children"
                          options={effectiveLedger?.map((x: any) => ({
                            value: x?.id,
                            label: x?.ledgerName.concat(
                              x?.aliasName ? `${" (" + x?.aliasName + ")"}` : ""
                            ),
                          }))}
                          dropdownRender={(customerOptions) => (
                            <>
                              {customerOptions}
                              <Divider style={{ margin: "5px 0px" }} />
                              <Button
                                style={{ width: "100%" }}
                                icon={<PlusOutlined />}
                                type="link"
                                onClick={() => {
                                  setEditParty({ id: "" });
                                  setOpenModel({
                                    type: "addLedger",
                                    param: {
                                      open: true,
                                    },
                                  });
                                }}
                              >
                                New Ledger
                              </Button>
                            </>
                          )}
                        />
                      </Form.Item>
                    </Col>

                    <Col lg={12}>
                      <span className="themeColor fw-500">Billing Address</span>
                      <Form.Item
                        name={["billingAddress", "type"]}
                        className="inputBoxMb"
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: "Address type is requied",
                        //   },
                        // ]}
                      >
                        <Select
                          showSearch
                          allowClear
                          optionFilterProp="children"
                          placeholder="Select type"
                          filterOption={false}
                          className="selectedItem"
                          options={[
                            {
                              label: "Registered Address",
                              value: TypeOfAddress.RegisteredAddress,
                            },
                            {
                              label: "Business Address",
                              value: TypeOfAddress.BusinessAddress,
                            },
                            {
                              label: "Branch Address",
                              value: TypeOfAddress.BranchAddress,
                            },
                            {
                              label: "Unit Address",
                              value: TypeOfAddress.UnitAddress,
                            },
                            {
                              label: "Godown Address",
                              value: TypeOfAddress.GodownAddress,
                            },
                          ]}
                          onChange={(value: any) =>
                            customerAddSelect(
                              value,
                              AddressEnum.BillingAddress,
                              []
                            )
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        name={["billingAddress", "address"]}
                        className="inputBoxMb"
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: "Billing address is required",
                        //   },
                        // ]}
                        style={{ paddingTop: "4px" }}
                      >
                        <TextArea
                          placeholder="Billing address"
                          rows={3}
                          // style={{ borderTop: "none" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={12}>
                      <span className="themeColor fw-500">
                        Shipping Address
                      </span>
                      <Form.Item
                        name={["shippingAddress", "type"]}
                        className="inputBoxMb"
                      >
                        <Select
                          showSearch
                          allowClear
                          optionFilterProp="children"
                          placeholder="Select type"
                          filterOption={false}
                          className="selectedItem"
                          options={[
                            {
                              label: "Registered Address",
                              value: TypeOfAddress.RegisteredAddress,
                            },
                            {
                              label: "Business Address",
                              value: TypeOfAddress.BusinessAddress,
                            },
                            {
                              label: "Branch Address",
                              value: TypeOfAddress.BranchAddress,
                            },
                            {
                              label: "Unit Address",
                              value: TypeOfAddress.UnitAddress,
                            },
                            {
                              label: "Godown Address",
                              value: TypeOfAddress.GodownAddress,
                            },
                          ]}
                          onChange={(value: any) =>
                            customerAddSelect(
                              value,
                              AddressEnum.ShippingAddress,
                              []
                            )
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        name={["shippingAddress", "address"]}
                        className="inputBoxMb"
                        style={{ paddingTop: "4px" }}
                      >
                        <TextArea rows={3} placeholder="Shipping address" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col lg={9} md={4} xs={23} sm={23}>
                  <Row gutter={[17, 8]}>
                    {/* <Col lg={12}>
                      <span style={{ display: "flex", justifyContent: "end" }}>
                        <span
                          className="pe-10 fw-500"
                          style={{
                            color: isInCash ? "gray" : "rgb(11, 48, 142)",
                            paddingTop: "7px",
                          }}
                        >
                          Credit
                        </span>
                        <Form.Item
                          name="paymentType"
                          className="m-0"
                          valuePropName="checked"
                        >
                          <Switch
                            disabled
                            size="small"
                            className="unCheckSwitch"
                            onChange={(value) => setIsInCash(value)}
                          />
                        </Form.Item>
                        <span
                          className="ps-11 fw-500"
                          style={{
                            color: isInCash ? "rgb(11, 48, 142)" : "gray",
                            paddingTop: "7px",
                          }}
                        >
                          Cash
                        </span>
                      </span>
                    </Col> */}

                    <Col lg={12}>
                      <span className="themeColor fw-500">Place Of Supply</span>
                      <Form.Item
                        name="supplyState"
                        className="mb-3 errormsg"
                        rules={[
                          {
                            required: true,
                            message: "Place of supply is required",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          allowClear={false}
                          placeholder="Select state"
                          popupMatchSelectWidth={false}
                          placement="bottomLeft"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          onSelect={(val) => setCurrentCustomerState(val)}
                          options={stateOptions?.map((x: any) => ({
                            value: x?.id,
                            label: x?.name,
                          }))}
                        />
                      </Form.Item>
                    </Col>

                    <Col lg={12}>
                      <span className="themeColor fw-500">
                        {splitPathInVchType === Voucher_Type.SalesOrder
                          ? "Sales Order No."
                          : "Purchase Order No."}
                      </span>
                      <Form.Item
                        name="number"
                        className="mb-3"
                        rules={[
                          {
                            required: true,
                            message: "Please enter order No.",
                          },
                        ]}
                      >
                        <Input disabled />
                      </Form.Item>
                    </Col>

                    <Col lg={12}>
                      <span className="themeColor fw-500">Date</span>
                      <Form.Item
                        name="date"
                        className="m-0"
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: "Select Date",
                        //   },
                        // ]}
                      >
                        <DatePicker
                          format="DD/MM/YYYY"
                          // disabledDate={(current) =>
                          //   current && current > dayjs().endOf("day")
                          // }
                          style={{ width: "100%" }}
                          allowClear={false}
                          onChange={(val) => {
                            if (dueOn) {
                              let dueDate = dayjs(val, "DD/MM/YYYY").add(
                                dueOn,
                                "day"
                              );
                              form.setFieldValue("dueDate", dueDate);
                            }
                            setDateFiled(dayjs(val).toISOString());
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={12}>
                      <span className="themeColor fw-500">
                        Include Other Expenses
                      </span>
                      <Form.Item
                        name="showFreightInsLedgers"
                        className="mb-3 errormsg"
                        valuePropName="checked"
                      >
                        <Checkbox
                          // defaultChecked={showFreightInsurance}
                          checked={showFreightInsurance}
                          onChange={(e) => {
                            setShowFreightInsurance(e.target.checked);
                            setSelectedOtherExpenses([]);
                            setFooterLedger({
                              ledgerAmount: 0,
                              ledgerGstAmount: 0,
                              ledgerTotalAmount: 0,
                            });

                            form.setFieldValue(
                              ["freightInsLedgers"],
                              [
                                {
                                  gstRate: 0,
                                  gstAmount: 0,
                                  totalAmount: 0,
                                  amount: 0,
                                },
                              ]
                            );
                          }}
                        />
                      </Form.Item>
                    </Col>

                    {/* <Col lg={12}>
                      <span className="themeColor fw-500">
                        Include Other Expenses
                      </span>
                      <Button
                        icon={<PlusOutlined />}
                        type="primary"
                        onClick={() =>
                          setOpenModel({
                            type: "addDespatchDetail",
                            param: { open: true },
                          })
                        }
                      >
                        Add
                      </Button>
                    </Col> */}
                  </Row>
                </Col>
              </Row>
            </Col>
            {windowWidth <= 1024 ? (
              ""
            ) : (
              <Col
                xl={6}
                lg={7}
                md={7}
                className="fs-35 fw-400 themeColor textEnd"
              >
                {splitPathInVchType === Voucher_Type.SalesOrder
                  ? companySettings?.setting?.sOrder?.title
                  : companySettings?.setting?.pOrder?.title}
                {/*  */}

                {/* location.pathname.split("/")[4] === "sales" */}
                <Dropdown
                  menu={{
                    items:
                      splitPathInVchType === Voucher_Type?.SalesOrder
                        ? SalesDropdownItems()
                        : PurchaseDropdownItems(),
                    selectable: true,
                    defaultSelectedKeys: [
                      location?.pathname?.split("/")[5] === "addOrder" ||
                      "editSalesOrder"
                        ? "4"
                        : "-1",
                    ],
                  }}
                  trigger={["click"]}
                  autoFocus={true}
                >
                  <Typography.Link>
                    <Space>
                      <DownOutlined
                        style={{
                          fontSize: "12px",
                          color: "black",
                          padding: "0px 0px 10px 10px",
                        }}
                      />
                    </Space>
                  </Typography.Link>
                </Dropdown>
              </Col>
            )}
          </Row>
          {/* Item Table */}
          <div
            // className="tableWrapper"
            style={{ overflowY: "auto" }}
          >
            <table
              className="AddPageTabelDesign dashedBorderTop"
              style={{ fontSize: windowWidth <= 1024 ? "10.3px" : "" }}
            >
              <thead>
                <tr>
                  <th
                    colSpan={2}
                    rowSpan={2}
                    style={{ fontWeight: "600", width: "5%" }}
                  >
                    S No.
                  </th>
                  <th
                    rowSpan={2}
                    style={{
                      fontWeight: "600",
                      width: "17%",
                      // maxWidth: "14%",
                      borderLeft: "1px solid rgb(240, 245, 255)",
                    }}
                  >
                    Item(s)
                  </th>
                  <th
                    rowSpan={2}
                    style={{
                      fontWeight: "600",
                      // width: windowWidth <= 1024 ? "22%" : "",
                      width: "22%",
                    }}
                  >
                    Description
                  </th>
                  {/* <th rowSpan={2} style={{ fontWeight: "600", width: "10%" }}>
                    Account
                  </th> */}
                  <th rowSpan={2} style={{ fontWeight: "600", width: "7%" }}>
                    Qty
                  </th>
                  {/* <th rowSpan={2} style={{ fontWeight: "600", width: "6%" }}>
                    Unit
                  </th> */}
                  <th rowSpan={3} style={{ fontWeight: "600", width: "9%" }}>
                    Unit Price
                  </th>
                  {/* <th colSpan={2} style={{ fontWeight: "600", width: "10%" }}>
                    Discount
                  </th> */}
                  {companySettings?.setting?.gstDetails?.scheme ===
                    CompanyGSTTypes.Regular && (
                    <th colSpan={2} style={{ fontWeight: "600", width: "14%" }}>
                      GST
                    </th>
                  )}
                  <th
                    colSpan={2}
                    rowSpan={2}
                    style={{ fontWeight: "600", width: "14%" }}
                  >
                    Gross Amount
                  </th>
                </tr>
                <tr>
                  {/* <th style={{ fontWeight: "600", width: "5%" }}>%</th> */}
                  {/* <th style={{ fontWeight: "600", width: "5%" }}>₹</th> */}
                  {companySettings?.setting?.gstDetails?.scheme ===
                    CompanyGSTTypes.Regular && (
                    <th style={{ fontWeight: "600", width: "6%" }}>%</th>
                  )}
                  {companySettings?.setting?.gstDetails?.scheme ===
                    CompanyGSTTypes.Regular && (
                    <th style={{ fontWeight: "600", width: "6%" }}>₹</th>
                  )}
                </tr>
              </thead>
              <tbody>
                <Form.List name="items">
                  {(fields, { add, remove, move }) => {
                    return (
                      <>
                        {confirmation && (
                          <ConfirmationModal
                            open={confirmation}
                            text={`Do you really want to delete this item "${form.getFieldValue(
                              ["items", deleteItemList, "name"]
                            )}" ?`}
                            // text="Do you really want to delete this row?"
                            onNo={() => setConfirmation(false)}
                            onYes={() => {
                              remove(deleteItemList);
                              handleDeleteRowButton();
                              setItemLength(fields.length - 2);
                            }}
                          />
                        )}
                        {fields.map(({ key, name, ...restField }, index) => {
                          return (
                            <tr key={key}>
                              <td>
                                {fields.length !== index + 1 && (
                                  <div>{index + 1}.</div>
                                )}
                              </td>
                              <td>
                                {fields.length !== index + 1 && (
                                  <Form.Item
                                    name={[name, "isService"]}
                                    className="m-0"
                                    {...restField}
                                  >
                                    {form.getFieldValue([
                                      "items",
                                      index,
                                      "isService",
                                    ]) ? (
                                      <Tooltip
                                        title="Service"
                                        placement="left"
                                        color="#1d39c4"
                                      >
                                        <Avatar
                                          style={{
                                            cursor: "pointer",
                                            verticalAlign: "middle",
                                            color: "#1d39c4",
                                            backgroundColor: "#f0f5ff",
                                            borderColor: "#adc6ff",
                                            fontWeight: "bold",
                                          }}
                                          size="small"
                                        >
                                          S
                                        </Avatar>
                                      </Tooltip>
                                    ) : (
                                      <Tooltip
                                        title="Goods"
                                        placement="left"
                                        color="#389e0d"
                                      >
                                        <Avatar
                                          style={{
                                            cursor: "pointer",
                                            verticalAlign: "middle",
                                            color: "#389e0d",
                                            backgroundColor: "#f6ffed",
                                            borderColor: "#b7eb8f",
                                            fontWeight: "bold",
                                          }}
                                          size="small"
                                        >
                                          G
                                        </Avatar>
                                      </Tooltip>
                                    )}
                                  </Form.Item>
                                )}
                              </td>

                              <td>
                                <Form.Item
                                  name={[name, "name"]}
                                  className="m-0"
                                  {...restField}
                                >
                                  <Select
                                    // allowClear={
                                    //   fields.length !== index + 1 && true
                                    // }
                                    // clearIcon={<span>{<EyeOutlined />}</span>}
                                    // onClear={() => remove(name)}
                                    // setConfirmation(true);
                                    showSearch
                                    placeholder="Search for items"
                                    optionFilterProp="children"
                                    filterOption={false}
                                    popupMatchSelectWidth={false}
                                    disabled={
                                      salesOrderId || quotationId ? true : false
                                    }
                                    listItemHeight={1}
                                    style={{
                                      minWidth: "100%",
                                      maxWidth: "240px",
                                    }}
                                    dropdownStyle={{ width: "250px" }}
                                    placement="bottomRight"
                                    notFoundContent={
                                      <Empty
                                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                                        description={
                                          <span>Sales item not found.</span>
                                        }
                                      />
                                    }
                                    // prefixCls={<EyeOutlined />}
                                    onSearch={(value: string) =>
                                      setSearchItem(value)
                                    }
                                    onClick={() => {
                                      setSearchItem("");
                                      setNewItem({
                                        data: {},
                                        isSaved: false,
                                      });
                                    }}
                                    onChange={(value: any) => {
                                      if (fields.length === index + 1) add();
                                      ItemSelect(value, index);
                                    }}
                                    options={itemsOption?.map((itm: any) => ({
                                      value: itm?.id,
                                      label: itm?.name,
                                    }))}
                                    dropdownRender={(itemsOption) => (
                                      <>
                                        {itemsOption}
                                        <Divider
                                          style={{ margin: "5px 0px" }}
                                        />
                                        <Button
                                          style={{ width: "100%" }}
                                          icon={<PlusOutlined />}
                                          type="link"
                                          onClick={() => {
                                            setOpenModel({
                                              type: "addItem",
                                              param: {
                                                open: true,
                                              },
                                            });
                                            setNewItem({
                                              data: {},
                                              isSaved: false,
                                            });
                                            setItemFields({
                                              field: fields,
                                              index: index,
                                            });
                                          }}
                                        >
                                          New Item
                                        </Button>
                                      </>
                                    )}
                                  />
                                </Form.Item>
                              </td>
                              <td>
                                <Form.Item
                                  className="m-0"
                                  {...restField}
                                  name={[name, "description"]}
                                >
                                  <Input />
                                </Form.Item>
                              </td>

                              {/* Qty */}
                              <td>
                                <Form.Item
                                  className="m-0"
                                  {...restField}
                                  name={[name, "amount", "qty"]}
                                  initialValue={0.0}
                                >
                                  <InputNumber
                                    min={0}
                                    step={0.01}
                                    style={{ width: "100%" }}
                                    controls={false}
                                    className="inputNumberTextEnd"
                                    onChange={() =>
                                      handleItemsNetAmmount(index)
                                    }
                                    // formatter={(value: any) =>
                                    //   Utils.getInputNumberFormat(value)
                                    // }
                                    // onKeyPress={(e) =>
                                    //   handelTwoDigitAfterDecimal(e)
                                    // }
                                    // formatter={(value) =>
                                    //   `${value}`.replace(/^(.*\.\d{2}).*$/, "$1")
                                    // }
                                    // parser={(value: any) =>
                                    //   isNaN(parseFloat(value)) ? '' : parseFloat(value).toFixed(2)
                                    // }
                                  />
                                </Form.Item>
                              </td>
                              {/* <td>
                                <Form.Item
                                  className="m-0"
                                  {...restField}
                                  name={[name, "amount", "unit"]}
                                >
                                  <Select
                                    popupMatchSelectWidth={false}
                                    placement="bottomRight"
                                  />
                                </Form.Item>
                              </td> */}
                              {/* Unit Price */}
                              <td>
                                <Form.Item
                                  className="m-0"
                                  {...restField}
                                  name={[name, "amount", "price"]}
                                  initialValue={0.0}
                                >
                                  <InputNumber
                                    min={0}
                                    step={0.01}
                                    style={{ width: "100%" }}
                                    controls={false}
                                    className="inputNumberTextEnd"
                                    // formatter={(value: any) =>
                                    //   Utils.getInputNumberFormat(value)
                                    // }
                                    // onKeyPress={(e) =>
                                    //   handelTwoDigitAfterDecimal(e)
                                    // }
                                    onChange={() =>
                                      handleItemsNetAmmount(index)
                                    }
                                  />
                                </Form.Item>
                              </td>
                              {/* <td>
                                <Form.Item
                                  className="m-0"
                                  {...restField}
                                  name={[name, "amount", "discount"]}
                                >
                                  <InputNumber
                                    disabled
                                    className="inputNumberTextEnd"
                                    controls={false}
                                    min={0}
                                    max={100}
                                    // formatter={(value: any) =>
                                    //   Utils.getInputNumberFormat(value)
                                    // }
                                    // onKeyPress={(e) =>
                                    //   handelTwoDigitAfterDecimal(e)
                                    // }
                                    onChange={() =>
                                      handleItemsNetAmmount(index)
                                    }
                                  />
                                </Form.Item>
                              </td> */}
                              {/* <td>
                                <Form.Item
                                  className="m-0"
                                  {...restField}
                                  name={[name, "amount", "discountRupee"]}
                                  initialValue={0}
                                >
                                  <InputNumber
                                    disabled
                                    min={0}
                                    step={0.01}
                                    controls={false}
                                    className="inputNumberTextEnd"
                                    max={returnNetAmount(
                                      index,
                                      form.getFieldValue(["items"])
                                    )}
                                    // formatter={(value: any) =>
                                    //   Utils.getInputNumberFormat(value)
                                    // }
                                    // onKeyPress={(e) =>
                                    //   handelTwoDigitAfterDecimal(e)
                                    // }F
                                    onChange={() => {
                                      onDiscountAmountChange(index);
                                    }}
                                  />
                                </Form.Item>
                              </td> */}
                              {companySettings?.setting?.gstDetails?.scheme ===
                                CompanyGSTTypes.Regular && (
                                <>
                                  <td>
                                    <Form.Item
                                      name={[name, "amount", "tax"]}
                                      className="m-0"
                                      {...restField}
                                    >
                                      <Select
                                        labelInValue
                                        disabled
                                        placeholder="%"
                                        popupMatchSelectWidth={false}
                                        placement="bottomRight"
                                        // onChange={() => {
                                        //   handleItemsNetAmmount(index);
                                        // }}
                                        options={gstOption?.map((gst: any) => ({
                                          key: gst.id,
                                          value: gst.rate,
                                          label: gst.name,
                                        }))}
                                        /* {gstSlabs?.map((option: any) => (
                                        <Select.Option
                                          key={option?.id}
                                          value={option?.rate}
                                          title={option?.name}
                                        >
                                          {option?.rate + `%`}
                                        </Select.Option>
                                      ))} */
                                      />
                                    </Form.Item>
                                  </td>

                                  <td>
                                    <Tooltip
                                      title={form.getFieldValue([
                                        "items",
                                        index,
                                        "amount",
                                        "itemTaxAmt",
                                      ])}
                                      placement="top"
                                    >
                                      <Form.Item
                                        className="m-0"
                                        {...restField}
                                        name={[name, "amount", "itemTaxAmt"]}
                                      >
                                        <InputNumber
                                          disabled
                                          style={{ width: "100%" }}
                                          // step={0.01}
                                          // controls={false}
                                          className="inputNumberTextEnd"
                                          // formatter={(value: any) =>
                                          //   Utils.getInputNumberFormat(value)
                                          // }
                                          // onKeyPress={(e) =>
                                          //   handelTwoDigitAfterDecimal(e)
                                          // }
                                          // onChange={() => handleItemsNetAmmount(index)}
                                        />
                                      </Form.Item>
                                    </Tooltip>
                                  </td>
                                </>
                              )}
                              {/* Total Amount */}
                              <td>
                                <Form.Item
                                  className="m-0"
                                  {...restField}
                                  name={[name, "amount", "totalAmount"]}
                                  initialValue={0}
                                >
                                  <InputNumber
                                    // min={0}
                                    step={0.01}
                                    style={{ width: "100%" }}
                                    controls={false}
                                    className="inputNumberTextEnd"
                                    // formatter={(value: any) =>
                                    //   Utils.getInputNumberFormat(value)
                                    // }
                                    // onKeyPress={(e) =>
                                    //   handelTwoDigitAfterDecimal(e)
                                    // }
                                    onChange={(value) => {
                                      onItemsTotalAmountChange(index);
                                    }}
                                  />
                                </Form.Item>
                              </td>
                              <td style={{ width: 25 }}>
                                {fields.length !== index + 1 && (
                                  <img
                                    className="cursor mx-1"
                                    onClick={() => {
                                      setDeleteItemList(name);
                                      setConfirmation(true);
                                    }}
                                    src={deleteImg}
                                    alt="Delete"
                                  />
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </>
                    );
                  }}
                </Form.List>
                {itemLength > 0 && (
                  // <tfoot>
                  <>
                    <tr>
                      <th colSpan={4} className="textEnd pe-14">
                        Total
                      </th>
                      <th colSpan={1} className="textEnd pe-14">
                        {Utils.getFormattedNumber(footerTotal.qty)}
                      </th>
                      {/* <th colSpan={1} className="textEnd pe-12">
                      ₹ {Utils.getFormattedNumber(footerTotal.unitPrice)}
                    </th> */}
                      {/* <th colSpan={2} className="textEnd pe-12">
                      ₹ {Utils.getFormattedNumber(footerTotal.discount)}
                    </th> */}
                      {companySettings?.setting?.gstDetails?.scheme ===
                        CompanyGSTTypes.Regular && (
                        <th colSpan={3} className="textEnd pe-12">
                          ₹ {Utils.getFormattedNumber(footerTotal.gst)}
                        </th>
                      )}
                      <th
                        className="textEnd pe-12"
                        colSpan={
                          companySettings?.setting?.gstDetails?.scheme ===
                          CompanyGSTTypes.Regular
                            ? 0
                            : 2
                        }
                      >
                        ₹ {Utils.getFormattedNumber(footerTotal.amount)}
                      </th>
                      <th></th>
                      {/* {isGSTEnabled && (
                      <th colSpan={2} className="textEnd pe-12">
                        ₹ {Utils.getFormattedNumber(footerTotal.)}
                      </th>
                    )}
                    <th className="textEnd pe-12">
                      ₹ {Utils.getFormattedNumber(footerTotal.amount)}
                    </th> */}
                    </tr>

                    <tr>
                      <td colSpan={10}>
                        <Divider className="my-5 grayBg" />
                      </td>
                    </tr>
                  </>
                )}

                {/* Show Include Other Expenses */}
                {showFreightInsurance && (
                  <>
                    {/* <table className="AddPageTabelDesignExpenses"> */}
                    <Form.List name="freightInsLedgers">
                      {(fields, { add, remove, move }) => {
                        return (
                          <>
                            {ledgerconfirmation && (
                              <ConfirmationModal
                                open={ledgerconfirmation}
                                text={"Do you really want to delete this item"}
                                onNo={() => setLedgerConfirmation(false)}
                                onYes={() => {
                                  setLedgerConfirmation(false);
                                  setLedgerLength(fields.length - 2);
                                  handleLedgerNetAmount(0);
                                  setSelectedOtherExpenses((prev) => {
                                    const newDisabledInputs = [...prev];
                                    newDisabledInputs.splice(deleteItemList, 1);
                                    return newDisabledInputs;
                                  });
                                  remove(deleteLedgerList);
                                }}
                              />
                            )}
                            {fields.map(
                              ({ key, name, ...restField }, index) => {
                                return (
                                  <tr key={key}>
                                    <td colSpan={2}></td>
                                    <td>
                                      <Form.Item
                                        name={[name, "id"]}
                                        className="m-0"
                                        {...restField}
                                      >
                                        <Select
                                          labelInValue
                                          // style={{ width: "338px" }}
                                          placeholder="Search for Other Expenses"
                                          showSearch
                                          optionFilterProp="children"
                                          filterOption={(input, option) =>
                                            (option?.label?.toString() ?? "")
                                              .toLowerCase()
                                              .includes(input.toLowerCase())
                                          }
                                          // onSearch={}
                                          onChange={(e: any) => {
                                            if (fields.length === index + 1)
                                              add();
                                            freightInsLedgersSelect(
                                              e?.value,
                                              index
                                            );
                                          }}
                                          options={freightAndInsuranceOptions
                                            ?.filter(
                                              (option: any) =>
                                                !selectedOtherExpenses.includes(
                                                  option.id
                                                )
                                            )
                                            ?.map((value: any) => ({
                                              value: value?.id,
                                              label: value?.ledgerName.concat(
                                                value?.aliasName
                                                  ? `${
                                                      " (" +
                                                      value?.aliasName +
                                                      ")"
                                                    }`
                                                  : ""
                                              ),
                                            }))}
                                          dropdownRender={(customerOptions) => (
                                            <>
                                              {customerOptions}
                                              <Divider
                                                style={{ margin: "5px 0px" }}
                                              />
                                              <Button
                                                style={{ width: "100%" }}
                                                icon={<PlusOutlined />}
                                                type="link"
                                                onClick={() => {
                                                  setEditParty({ id: "" });
                                                  setOpenModel({
                                                    type: "addLedger",
                                                    param: {
                                                      open: true,
                                                    },
                                                  });
                                                }}
                                              >
                                                New Ledger
                                              </Button>
                                            </>
                                          )}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td colSpan={2}></td>

                                    <td>
                                      <Form.Item
                                        name={[name, "amount"]}
                                        className="m-0"
                                        {...restField}
                                        initialValue={0.0}
                                      >
                                        <InputNumber
                                          min={0}
                                          step={0.01}
                                          style={{
                                            width: "100%",
                                            // marginLeft: "10px",
                                          }}
                                          controls={false}
                                          className="inputNumberTextEnd"
                                          onChange={() => {
                                            handleLedgerNetAmount(index);
                                          }}
                                        />
                                      </Form.Item>
                                    </td>
                                    {companySettings?.setting?.gstDetails
                                      ?.scheme === CompanyGSTTypes.Regular && (
                                      <>
                                        <td>
                                          <Form.Item
                                            name={[name, "gstRate"]}
                                            className="m-0"
                                            {...restField}
                                          >
                                            <Select
                                              labelInValue
                                              // style={{ width: "100%" }}
                                              placeholder={"%"}
                                              popupMatchSelectWidth={false}
                                              onChange={() => {
                                                handleLedgerNetAmount(index);
                                              }}
                                              filterOption={(input, option) =>
                                                (
                                                  option?.label?.toString() ??
                                                  ""
                                                )
                                                  .toLowerCase()
                                                  .includes(input.toLowerCase())
                                              }
                                              options={gstOption?.map(
                                                (gst: any) => ({
                                                  // key: gst.id,
                                                  // value: gst.rate,
                                                  // label: gst.name,
                                                  value: gst?.id,
                                                  title: gst?.rate,
                                                  label: gst?.name,
                                                })
                                              )}
                                            />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item
                                            className="m-0 inputNumberTextEnd"
                                            name={[name, "gstAmount"]}
                                            {...restField}
                                          >
                                            <InputNumber
                                              disabled
                                              style={{ width: "100%" }}
                                              onChange={() => {
                                                handleLedgerNetAmount(index);
                                              }}
                                            />
                                          </Form.Item>
                                        </td>
                                      </>
                                    )}

                                    <td>
                                      <Form.Item
                                        name={[name, "totalAmount"]}
                                        className="m-0"
                                        {...restField}
                                        initialValue={0.0}
                                      >
                                        <InputNumber
                                          // disabled
                                          style={{ width: "100%" }}
                                          className="inputNumberTextEnd"
                                          controls={false}
                                          step={0.01}
                                          onChange={() => {
                                            onLedgerTotalAmountChange(index);
                                          }}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      {fields.length !== index + 1 && (
                                        <img
                                          className="cursor mx-1"
                                          onClick={() => {
                                            setDeleteLedgerList(name);
                                            setLedgerConfirmation(true);
                                          }}
                                          src={deleteImg}
                                          alt="Delete"
                                        />
                                      )}
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                          </>
                        );
                      }}
                    </Form.List>

                    {ledgerLength > 0 && (
                      // <tfoot>
                      <>
                        <tr>
                          <th colSpan={4} className="textEnd pe-14">
                            Total
                          </th>
                          <th colSpan={2} className="textEnd pe-14">
                            ₹{" "}
                            {Utils.getFormattedNumber(
                              footerLedger.ledgerAmount
                            )}
                          </th>
                          {companySettings?.setting?.gstDetails?.scheme ===
                            CompanyGSTTypes.Regular && (
                            <th colSpan={2} className="textEnd pe-12">
                              ₹
                              {Utils.getFormattedNumber(
                                footerLedger.ledgerGstAmount
                              )}
                            </th>
                          )}
                          <th className="textEnd pe-12">
                            <span>
                              ₹
                              {Utils.getFormattedNumber(
                                footerLedger.ledgerTotalAmount
                              )}
                            </span>
                          </th>
                        </tr>
                      </>
                    )}
                    {/* </table> */}
                  </>
                )}
              </tbody>
            </table>
          </div>

          <Row className=" pt-10">
            <Col lg={17} md={24}>
              <Col lg={12}>
                <Form.Item name="narration" className="m-0">
                  <TextArea placeholder="Write Narration" />
                </Form.Item>
              </Col>
            </Col>
            {/* <Col lg={17} md={24}>
              <div style={{ overflowY: "auto" }}></div>
            </Col> */}
            <Col lg={6} md={24}>
              <Row gutter={[0, 8]}>
                <Col lg={9}>Selling Price</Col>
                <Col lg={15} className="textEnd">
                  ₹ {Utils.getFormattedNumber(footerTotal.taxableAmount)}
                </Col>
                {footerTotal.discount > 0 && (
                  <>
                    <Col lg={9}>Discount</Col>
                    <Col lg={15} className="textEnd">
                      ₹ {Utils.getFormattedNumber(footerTotal.discount)}
                    </Col>
                  </>
                )}
                {showFreightInsurance && (
                  <>
                    <Col lg={9}>Other Expenses</Col>
                    <Col lg={15} className="textEnd">
                      ₹{Utils.getFormattedNumber(footerLedger.ledgerAmount)}
                    </Col>
                  </>
                )}
                {companySettings?.setting?.gstDetails?.scheme ===
                  CompanyGSTTypes.Regular && (
                  <>
                    {isStateSame ? (
                      <>
                        <Col lg={9}>CGST</Col>
                        <Col lg={15} className="textEnd">
                          ₹{" "}
                          {Utils.getFormattedNumber(
                            (footerTotal.gst + footerLedger.ledgerGstAmount) / 2
                          )}
                        </Col>
                        <Col lg={9}>SGST</Col>
                        <Col lg={15} className="textEnd">
                          ₹{" "}
                          {Utils.getFormattedNumber(
                            (footerTotal.gst + footerLedger.ledgerGstAmount) / 2
                          )}
                        </Col>
                      </>
                    ) : currentCustomerState ? (
                      <>
                        <Col lg={9}>IGST</Col>
                        <Col lg={15} className="textEnd">
                          ₹{" "}
                          {Utils.getFormattedNumber(
                            footerTotal.gst + footerLedger.ledgerGstAmount
                          )}
                        </Col>
                      </>
                    ) : (
                      <></>
                    )}

                    <Col lg={15}>
                      <Row>
                        <Col lg={14}>
                          <Radio.Group
                            disabled
                            onChange={(e) => {
                              setTdsOrTcs(e.target.value);
                              handleAllTheTotalValues();
                            }}
                            value={tdsOrTcs}
                          >
                            <Radio value={0}>TDS</Radio>
                            <Radio value={1}>TCS</Radio>
                          </Radio.Group>
                        </Col>
                        <Col lg={10}>
                          {tdsOrTcs === 1 ? (
                            <Form.Item name="tcsRate" className="m-0">
                              <Select
                                disabled
                                size="small"
                                dropdownRender={(menu) => (
                                  <>
                                    {menu}
                                    <div
                                      className="themeColor ps-6"
                                      onClick={() => setTcsModelOpen(true)}
                                    >
                                      TCS Details
                                    </div>
                                  </>
                                )}
                                onSelect={() => handleAllTheTotalValues()}
                                options={[
                                  { value: 0, label: "None" },
                                  { value: 0.1, label: "0.1 %" },
                                ]}
                              />
                            </Form.Item>
                          ) : tdsOrTcs === 0 ? (
                            <Form.Item name="tdsRate" className="m-0">
                              <Select
                                disabled
                                size="small"
                                dropdownRender={(menu) => (
                                  <>
                                    {menu}
                                    <div
                                      className="themeColor ps-6"
                                      onClick={() => setTdsModelOpen(true)}
                                    >
                                      TDS Details
                                    </div>
                                  </>
                                )}
                                onChange={() => handleAllTheTotalValues()}
                                options={TdsOptions?.map((itm: any) => ({
                                  value: itm?.value,
                                  label: itm?.label,
                                }))}
                              />
                            </Form.Item>
                          ) : (
                            <></>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    {tdsOrTcs === 1 ? (
                      <Col lg={9} className="textEnd">
                        ₹ {Utils.getFormattedNumber(totalTcs)}
                      </Col>
                    ) : tdsOrTcs === 0 ? (
                      <Col lg={9} className="textEnd">
                        ₹ {Utils.getFormattedNumber(totalTds)}
                      </Col>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </Row>
              <Divider className="my-5 grayBg" />
              <Row gutter={[12, 2]}>
                <Col lg={11} style={{ fontWeight: 600, fontSize: "16px" }}>
                  Total Invoice Value
                </Col>
                {/* <Col lg={8} className="themeColor fw-500">
                GBP
              </Col> */}
                <Col
                  lg={13}
                  className="textEnd"
                  style={{ fontWeight: 600, fontSize: "16px" }}
                >
                  ₹
                  {Utils.getFormattedNumber(
                    Utils.getNumber(footerTotal.amount) +
                      Utils.getNumber(footerLedger.ledgerTotalAmount)
                  )}
                </Col>
                {!isInCash && itemLength > 0 && (
                  <>
                    {/* <Divider className="my-10 grayBg" /> */}
                    {/* <Col lg={12} className="pt-5">
                      <Checkbox
                        disabled
                        onChange={(e) => {
                          e.target.checked
                            ? form.setFieldValue("receivedAmount", footerTotal.amount)
                            : form.setFieldValue("receivedAmount", 0);
                          onAmountReceived();
                        }}
                      >
                        Received
                      </Checkbox>
                    </Col> */}
                    {/* <Col lg={12} className="textEnd">
                      <Form.Item className="m-0" name="receivedAmount">
                        <InputNumber
                          disabled
                          style={{ width: "100%" }}
                          size="small"
                          controls={false}
                          className="inputNumberTextEnd "
                          onChange={(e) => onAmountReceived()}
                        />
                      </Form.Item>
                    </Col> */}
                    {/* <Divider className="my-10 grayBg" /> */}
                    {/* <Col lg={9}>Balance</Col>
                    <Col lg={15} className="textEnd">
                      ₹{Utils.getFormattedNumber(invoiceTotal - balance)}
                    </Col> */}
                  </>
                )}
              </Row>
              {/* <Divider
                style={{
                  borderBottom: "2px solid rgb(203, 203, 203)",
                }}
                className="my-5"
              /> */}

              <Row justify="end" className="mt-10">
                <Col>
                  <Button
                    onClick={() => {
                      sessionStorage.removeItem(`${currentClient?.id} Sales`);
                      sessionStorage.removeItem("NotificationState");
                      splitPathInVchType === Voucher_Type.SalesOrder
                        ? navigate(
                            `/accounts/clients/${currentClient?.id}/sales/order`
                          )
                        : partyId
                        ? navigate(
                            `/accounts/clients/${currentClient?.id}/parties/${editParty?.id}`
                          )
                        : navigate(
                            `/accounts/clients/${currentClient?.id}/purchase/order`
                          );
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col className="ps-14">
                  <Form.Item>
                    <Button
                      type="primary"
                      loading={saveButtonLoading}
                      onClick={() => {
                        save(false);
                      }}
                    >
                      {id ? "Update" : "Save"}
                    </Button>
                  </Form.Item>
                </Col>
                {/* <Col className="ps-14">
                <Form.Item>
                  <Button
                    // loading={saveNewLoading}
                    onClick={() => {
                      save(true);
                    }}
                    type="primary"
                  >
                    Save & New
                  </Button>
                </Form.Item>
              </Col> */}
              </Row>
            </Col>
          </Row>
        </Form>
      </Spin>

      {openModel.type === "addItem" && (
        <AddItems
          open={openModel?.param.open}
          onCancel={() => setOpenModel({ type: "", param: "" })}
          setTrigger={setTrigger}
          setNewItem={setNewItem}
        />
      )}
      {openModel.type === "addLedger" && (
        <AddLedger
          openDrawer={openModel?.param.open}
          id={editParty?.id}
          setOpenDrawer={() => setOpenModel({ type: "", param: "" })}
          setTrigger={setTrigger}
          setAddLedgerResponse={setAddLedgerResponse}
        />
      )}
    </div>
  );
};

export default AddOrder;
