import {
  DeleteTwoTone,
  DownloadOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Input,
  InputRef,
  notification,
  Row,
  Select,
  Skeleton,
  Space,
  Table,
  TableColumnType,
  Tag,
  Tooltip,
} from "antd";
import React from "react";
import search from "../../Images/search.svg";
import { ColumnsType } from "antd/es/table";
import { FilterDropdownProps } from "antd/es/table/interface";
import { NavLink, useLocation } from "react-router-dom";
import { IndianStates } from "../../../../src/Types/CommonConstants";
import LedgerService, {
  LedgerStatus,
  SearchLedgerByGroupsTypes,
} from "../../Services/LedgerService";
import { useTheme } from "../../Context/ThemeContext";
import { useClient } from "../../Context/ClientContext";
import { useIndianState } from "../../Context/StateOptionsContext";
import { Utils } from "../../../Utilities/Utils";
import { getIndianStates } from "../../Services/ClientService";
import { useKeyboardShortcuts } from "../../Common/KeyboardShortcuts ";
import AddLedger from "../Master/Ledger/AddLedger";
import { ConfirmationModal } from "../../Common/ConfirmationModal";
import { GroupTypes } from "../../Services/ChartofAccountServicesNew";
import { Voucher_Type } from "../../Services/VoucherServices";

interface IItemListData {
  key: string;
  parent: string;
  id: string;
  ledgerName: string;
  parentGroup: string;
  balance: number;
  gstin: string;
  state: IndianStates;
  name: string;
  phone: string;
  system: boolean;
  editable: boolean;
  status: LedgerStatus;
  isActive: boolean;
}

type NotificationType = "success" | "error";

type DataIndex = keyof IItemListData;

const Customers = () => {
  const { themeData } = useTheme();
  const location = useLocation();
  const { currentClient } = useClient();
  const { stateOptions } = useIndianState();
  const [api, contextHolder] = notification.useNotification();
  const [width, setWidth] = React.useState<any>(window.innerWidth);
  const [openModel, setOpenModel] = React.useState<{
    open: boolean;
    id: any;
  }>({
    open: false,
    id: "",
  });
  const [loading, setLoading] = React.useState<boolean>(false);
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState<number>(15);
  const [serchAllField, setSerchAllField] = React.useState<string>("");
  const [totalRecords, setTotalRecords] = React.useState<number>(0);
  const [ledgerData, setLedgerData] = React.useState<any[]>();
  const [filterGroupTypes, setFilterGroupTypes] = React.useState<GroupTypes>();
  const [filter, setfilter] = React.useState<any>({
    sortCol: "",
    sortDir: "",
  });
  const [trigger, setTrigger] = React.useState<boolean>(false);
  const [searchColumns, setSearchColumns] = React.useState<{
    ledgerName: string;
    parentGroup: string;
    GSTNo: string;
    contPerson: string;
    contPhone: string;
  }>({
    ledgerName: "",
    parentGroup: "",
    GSTNo: "",
    contPerson: "",
    contPhone: "",
  });
  const [selectIndState, setSelectIndState] = React.useState<IndianStates>(
    IndianStates.Unspecified
  );
  const [confirmation, setConfirmation] = React.useState<boolean>(false);
  const [selectChange, setSelectChange] = React.useState<boolean>(true);
  const [ledgerStatus, setLedgerStatus] = React.useState<LedgerStatus>(
    LedgerStatus.active
  );
  // const [searchedColumn, setSearchedColumn] = React.useState("");
  const searchInput = React.useRef<InputRef>(null);

  let splitPathInVchType: SearchLedgerByGroupsTypes;
  if (location.pathname.split("/")[4] === "sales") {
    splitPathInVchType = SearchLedgerByGroupsTypes.CreditorsOnly;
  } else {
    splitPathInVchType = SearchLedgerByGroupsTypes.DebtorsOnly;
  }

  const openNotification = (type: NotificationType, message: string) => {
    api[type]({
      message: "",
      description: message,
      duration: 2,
      closeIcon: false,
      style: {
        width: 350,
      },
    });
  };

  const handleSearch = (
    selectedKeys: string[] | any,
    confirm: any,
    dataIndex: DataIndex
  ) => {
    // console.log(selectedKeys, dataIndex);
    if (dataIndex === "ledgerName") {
      setSearchColumns({
        ...searchColumns,
        ledgerName: selectedKeys[0],
      });
    } else if (dataIndex === "parentGroup") {
      setSearchColumns({ ...searchColumns, parentGroup: selectedKeys[0] });
    } else if (dataIndex === "gstin") {
      setSearchColumns({ ...searchColumns, GSTNo: selectedKeys[0] });
    } else if (dataIndex === "name") {
      setSearchColumns({ ...searchColumns, contPerson: selectedKeys[0] });
    } else if (dataIndex === "phone") {
      setSearchColumns({ ...searchColumns, contPhone: selectedKeys[0] });
    } else if (dataIndex === "state") {
      setSelectIndState(selectedKeys[0]);
    }
    // console.log("Search.......", selectedKeys, dataIndex);
    confirm();
  };

  const handleReset = (
    clearFilters: () => void,
    confirm: FilterDropdownProps["confirm"]
  ) => {
    clearFilters();
    confirm();
    setSelectIndState(IndianStates.Unspecified);
    setSearchColumns({
      ledgerName: "",
      parentGroup: "",
      GSTNo: "",
      contPerson: "",
      contPhone: "",
    });
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): TableColumnType<IItemListData> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        {dataIndex === "state" ? (
          <Select
            allowClear
            showSearch
            placeholder="Select.."
            value={selectedKeys[0]}
            options={stateOptions?.map((x: any) => ({
              value: x.id,
              label: x.name,
            }))}
            onChange={(e) => setSelectedKeys(e ? [e] : [])}
            filterOption={(input: string, option: any) =>
              (option?.label?.toString() ?? "")
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            // style={{
            //   width: "100%",
            // }}
            style={{ marginBottom: 8, display: "block" }}
          />
        ) : (
          <Input
            ref={searchInput}
            placeholder={`Search..`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            style={{ marginBottom: 8, display: "block" }}
          />
        )}
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            // icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters, confirm);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Clear
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    // onFilter: (value, record) =>
    //   record[dataIndex]
    //     .toString()
    //     .toLowerCase()
    //     .includes((value as string).toLowerCase()),
    // onFilterDropdownOpenChange: (visible) => {
    //   if (visible) {
    //     setTimeout(() => searchInput.current?.select(), 100);
    //   }
    // },
    // render: (text) =>
    //   searchedColumn === dataIndex ? (
    //     <div>console.log("Hello")</div>
    //   ) : (
    //     // <Highlighter
    //     //   highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
    //     //   searchWords={[searchColumns]}
    //     //   autoEscape
    //     //   textToHighlight={text ? text.toString() : ""}
    //     // />
    //     text
    //   ),
  });

  // --------- Table list data ---------
  React.useEffect(() => {
    setLoading(true);
    // debugger;
    const getListData = async (
      search: string,
      groupSearch: string,
      start: number,
      length: number,
      ledgerStatus?: LedgerStatus,
      GstTxt?: string,
      selectIndState?: IndianStates,
      contPerson?: string,
      contPhone?: string
      // StateTxt?: IndianStates,
    ) => {
      await LedgerService.getLedgerList(
        currentClient?.id!,
        start,
        length,
        search,
        groupSearch,
        filter.sortCol,
        filter.sortDir,
        "",
        ledgerStatus,
        GstTxt,
        selectIndState,
        contPerson,
        contPhone
        // serchAllField
      )
        .then((res: any) => {
          if (res.items) {
            setLedgerData(
              res?.items?.map((r: any, i: any) => {
                let defaultConatct = r?.contactInformation?.find(
                  (x: any) => x?.isDefault === true
                );
                // console.log("defaultConatct", defaultConatct);
                return {
                  key: i,
                  sno: i + 1,
                  system: r?.system,
                  id: r?.id,
                  ledgerName: r?.ledgerName,
                  parentGroup: r?.groupName,
                  balance: Utils.getFormattedNumber(r?.balance),
                  gstin: r?.gstin,
                  state: r?.state,
                  status: r?.status,
                  name: defaultConatct?.name,
                  phone: defaultConatct?.phone,
                  isActive: r?.isActive,
                };
              })
            );
            // setLedgerData(res.items);
            setLoading(false);
            setTotalRecords(res?.totalRecords);
          } else {
            setLedgerData([]);
            setLoading(false);
            setTotalRecords(res?.totalRecords);
            // console.log("Error", res);
          }
        })
        .catch((err: any) => {
          setLoading(false);
          // console.log("Error", err);
        });
    };
    getListData(
      serchAllField,
      searchColumns.parentGroup,
      (page - 1) * pageSize,
      pageSize,
      ledgerStatus,
      searchColumns.GSTNo,
      selectIndState,
      searchColumns?.contPerson,
      searchColumns?.contPhone

      // filterGroupTypes,
      // searchColumns,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    trigger,
    filter,
    searchColumns,
    serchAllField,
    ledgerStatus,
    selectIndState,
  ]);

  //  [trigger, serchAllField, filter, filterGroupTypes];
  // --------- Table Columns  ---------
  const columns: ColumnsType<IItemListData> = [
    {
      title: "Ledger Name",
      dataIndex: "ledgerName",
      key: "ledgerName",
      width: "19%",
      sorter: true,
      defaultSortOrder: "ascend",
      // width: width <= 1440 ? "8%" : "15%",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          <NavLink
            to={`/accounts/clients/${currentClient?.id}/master/ledger/${record?.id}`}
          >
            {record?.ledgerName}
          </NavLink>
        </Skeleton>
      ),
      // ...getColumnSearchProps("ledgerName"),
    },
    {
      title: "Parent Group",
      dataIndex: "parentGroup",
      key: "parentGroup",
      width: "19%",
      sorter: true,
      // width: width <= 1440 ? "8%" : "15%",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.parentGroup}
        </Skeleton>
      ),
      ...getColumnSearchProps("parentGroup"),
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
      width: "9%",
      sorter: true,
      align: "right",
      // onFilter: (value: string, record) => {
      //   return set
      // },
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
            style: { display: "flex", justifyContent: "end" },
          }}
          title={false}
        >
          {record.balance}
        </Skeleton>
      ),
      // ...getColumnSearchProps("balance"),
    },
    {
      title: "GSTIN",
      dataIndex: "gstin",
      key: "gstin",
      width: "14%",
      sorter: true,
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.gstin}
        </Skeleton>
      ),
      ...getColumnSearchProps("gstin"),
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      width: "13%",
      // width: width <= 1440 ? "12%" : "10%",
      sorter: true,
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {getIndianStates(record?.state)}
        </Skeleton>
      ),
      ...getColumnSearchProps("state"),
    },
    {
      title: "Contact Person",
      dataIndex: "name",
      key: "name",
      width: "10%",
      // sorter: true,
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.name}
        </Skeleton>
      ),
      ...getColumnSearchProps("name"),
    },
    {
      title: "Contact No.",
      dataIndex: "phone",
      // key: "group_Name",
      width: "9%",
      // width: width <= 1440 ? "12%" : "10%",
      // sorter: true,
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.phone}
        </Skeleton>
      ),
      ...getColumnSearchProps("phone"),
    },
    // {
    //   title: "Status",
    //   dataIndex: "isActive",
    //   width: "5%",
    //   align: "center",
    //   render: (_, record) => (
    //     <Skeleton
    //       active
    //       paragraph={{ rows: 1 }}
    //       loading={loading}
    //       title={false}
    //       className="cursor"
    //     >
    //       <Checkbox
    //         checked={record?.isActive}
    //         onChange={(e: any) => {
    //           console.log(e.target.checked);
    //           setOpenModel({ ...openModel, id: record?.id });
    //           setConfirmation(true);
    //           setSelectChange(false);
    //         }}
    //       ></Checkbox>
    //     </Skeleton>
    //   ),
    // },
    {
      title: " ",
      dataIndex: "Edit",
      // width: "5%",
      key: "action",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          <div>
            <Tooltip
              title={
                record?.system ? "Default ledger are not editable" : "Edit"
              }
              placement="top"
              color={record?.system ? "rgb(216 207 207)" : "#444653"}

              // color="#444653"
            >
              <EditOutlined
                // className="ca-edit-btn"
                style={{
                  color: record?.system ? "rgb(216 207 207)" : "#444653",
                }}
                onClick={() => {
                  !record?.system &&
                    setOpenModel({
                      open: true,
                      id: record?.id,
                    });
                }}
              />
            </Tooltip>
          </div>
        </Skeleton>
      ),
    },
    {
      title: " ",
      dataIndex: "Edit",
      key: "action",
      render: (_, record) => (
        <div>
          {ledgerStatus !== LedgerStatus.deactive &&
          record.status === LedgerStatus.active ? (
            <Skeleton
              active
              loading={loading}
              paragraph={{ rows: 1 }}
              title={false}
            >
              <Tooltip
                title={
                  record?.system ? "Default groups are not delete" : "Delete"
                }
                placement="top"
                color={record?.system ? "rgb(216 207 207)" : "red"}
              >
                <DeleteTwoTone
                  className="ca-delete-btn cursor"
                  twoToneColor={record?.system ? "rgb(216 207 207)" : "#ff879d"}
                  onClick={() => {
                    if (!record?.system) {
                      setOpenModel({ ...openModel, id: record?.id });
                      setConfirmation(true);
                      setSelectChange(false);
                    }
                  }}
                />
              </Tooltip>
            </Skeleton>
          ) : (
            <Skeleton
              active
              loading={loading}
              paragraph={{ rows: 1 }}
              title={false}
            >
              {/* <Tooltip
                title="Revive Delivery Challan"
                placement="top"
                color="green"
              >
                <RetweetOutlined
                  className="ca-invoice-btn"
                  style={{ color: "#64CA31" }}
                  onClick={() => {
                    setOpenModel({ ...openModel, id: record?.id });
                    setConfirmation(true);
                    setSelectChange(true);
                  }}
                />
              </Tooltip> */}
            </Skeleton>
          )}
        </div>
      ),
    },
  ];

  //----- Table Ascending & Desending Order -----
  const tableSort = (pagination?: any, sort?: any, filter?: any) => {
    setfilter({
      sortCol: filter.column !== undefined ? filter.columnKey : "number",
      sortDir:
        filter.column !== undefined
          ? filter.order === "descend"
            ? "desc"
            : "asc"
          : "desc",
    });
    // console.log("sort", sort);

    // if (sort?.group_Type) {
    // console.log("sort", sort?.group_Type);
    // setFilterGroupTypes(sort?.group_Type);
    // }
  };

  //----- Short-Cut for Open Model -----
  useKeyboardShortcuts("N", ["shiftKey"], () => {
    setOpenModel({ open: true, id: "" });
  });

  const exportCsv = () => {
    LedgerService.exportCsv(
      currentClient?.id!,
      serchAllField,
      searchColumns.parentGroup,
      filter.sortCol,
      filter.sortDir,
      "",
      ledgerStatus,
      searchColumns.GSTNo,
      selectIndState,
      searchColumns?.contPerson,
      searchColumns?.contPhone
      // invoiceStatus
    )
      .then((res: any) => {
        if (res) {
          console.log("download sucess");
        }
      })
      .catch((ex: any) => {
        openNotification("error", ex.message);
      });
  };
  return (
    <Card
      size="small"
      className="TableCard"
      style={{
        marginTop: "6px",
        padding: "48px 0px 0px 0px",
        boxShadow: "0px 0px 10px 0px #96A1C84D",
      }}
    >
      <React.Fragment>
        <Row
          justify="space-between"
          style={
            {
              // paddingTop: props?.partyId ? "10px" : "0px"
              // marginTop: 10,
              // padding: "0px 0px 10px 0px",
            }
          }
          // style={{ position: "sticky",zIndex:1, top: 0, padding: "5px" }}
        >
          <Col>
            <Space size={width <= 768 ? 465 : 5}>
              <Col>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => setOpenModel({ open: true, id: "" })}
                >
                  Ledger
                </Button>
              </Col>
              <Button
                type="default"
                icon={<DownloadOutlined />}
                onClick={() => exportCsv()}
              >
                Export
              </Button>

              {/* <Button
              style={{
                marginLeft: "10px",
              }}
              icon={<ArrowDownOutlined />}
            >
              Export
            </Button> */}
            </Space>
          </Col>

          <Col
            style={{
              marginTop: width <= 768 ? 15 : "",
            }}
          >
            <Row gutter={width <= 768 ? 98 : 8}>
              <Col>
                <Select
                  defaultValue={LedgerStatus.all}
                  style={{ width: "160px" }}
                  onChange={(e: LedgerStatus) => {
                    // setPage(1);
                    // setLedgerStatus(e);
                  }}
                  options={[
                    { value: LedgerStatus.all, label: "All" },
                    { value: LedgerStatus.active, label: "Only Transactions " },
                  ]}
                />
              </Col>
              <Col>
                <Input
                  allowClear
                  style={{ width: "200px", border: "1px solid #f1f3ff" }}
                  placeholder="Search.."
                  onChange={(e: any) => {
                    setLoading(true);
                    if (!e.cancelable) {
                      //console.log("typeing user............");
                      const serchAllField = setTimeout(() => {
                        setPage(1);
                        setSerchAllField(e.target.value);
                      }, 1000);
                      return () => clearTimeout(serchAllField);
                    } else {
                      // console.log("Click cancle............");
                      setSerchAllField(e.target.value);
                    }
                  }}
                  suffix={
                    loading || serchAllField ? (
                      ""
                    ) : (
                      <img alt="Search.." src={search} />
                    )
                  }
                />
              </Col>

              <Col>
                <Select
                  defaultValue={LedgerStatus.active}
                  style={{ width: "100px" }}
                  onChange={(e: LedgerStatus) => {
                    setPage(1);
                    setLedgerStatus(e);
                  }}
                  options={[
                    { value: LedgerStatus.all, label: "All" },
                    { value: LedgerStatus.active, label: "Active" },
                    { value: LedgerStatus.deactive, label: "Deactive" },
                  ]}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        {/* --------- Table --------- */}
        <div>
          <Table
            columns={columns}
            dataSource={
              loading
                ? (Array.from({ length: pageSize }, (_, index) => ({
                    key: `loading-${index}`,
                  })) as IItemListData[])
                : ledgerData
            }
            locale={{
              triggerDesc: "",
              triggerAsc: "",
              cancelSort: "",
            }}
            onChange={tableSort}
            className={`Tabel-style table-${
              themeData?.componentSize ?? "middle"
            }`}
            // rowSelection={rowSelection}
            pagination={{
              total: totalRecords,
              current: page,
              pageSize: pageSize,
              showSizeChanger: true,
              onChange: (page: number, pageSize: number) => {
                setPage(page);
                setPageSize(pageSize);
              },
              pageSizeOptions: ["10", "15", "25", "50", "100"],
              showTotal: (totalRecords, page) =>
                `${page[0]}-${page[1]} of ${totalRecords} items`,
            }}
            scroll={{ x: 1150, y: window.innerHeight - 315 }}
          />
        </div>
        {openModel.open && (
          <AddLedger
            openDrawer={openModel.open}
            id={openModel?.id}
            setOpenDrawer={() => setOpenModel({ open: false, id: "" })}
            setTrigger={() => setTrigger(!trigger)}
            type={splitPathInVchType}
          />
        )}
        {confirmation && (
          <ConfirmationModal
            open={confirmation}
            onNo={() => setConfirmation(false)}
            onYes={async () =>
              await LedgerService.makeLedgerActiveInactive(
                currentClient?.id!,
                openModel?.id
              )
                .then((res: any) => {
                  if (res.result) {
                    console.log("res", res);
                    setTrigger((x: boolean) => !x);
                    openNotification(
                      "success",
                      `${selectChange ? "Active" : "Delete"} Successfully`
                    );
                    setConfirmation(false);
                  } else if (res?.message) {
                    openNotification("error", res?.message);
                    setConfirmation(false);
                  }
                })
                .catch((err: any) => {
                  openNotification("error", err);
                  setConfirmation(false);
                  console.log("error", err);
                })
            }
            text={`Are you sure you want to ${
              selectChange ? "Active" : "Delete"
            } Ledger?`}
          />
        )}
      </React.Fragment>
      {contextHolder}
    </Card>
  );
};

export default Customers;
