import React, { useEffect, useState, useContext } from "react";
import {
  Alert,
  Button,
  Checkbox,
  CheckboxProps,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Tooltip,
} from "antd";
import "./Assets.css";
import FixedAssetsService from "../../Services/FixedAssetsServiceNew";
import { useClient } from "../../Context/ClientContext";
import {
  LoadingOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { dayjs } from "../../../Utilities/dayjs";
import SalesService, { TradingPartnerType } from "../../Services/SalesService";
import { CalcMethod, PmtMethod } from "./AssetModel";
import { Utils } from "../../../Utilities/Utils";
import LedgerService, {
  SearchLedgerByGroupsTypes,
} from "../../Services/LedgerService";
import { notificationContext } from "../../Common/PageRoute";
import { AssetStatus } from "../../Services/FixedAssetsServiceNew";
import AddLedger from "../Master/Ledger/AddLedger";
interface ModelProps {
  isDisposeOpen: boolean;
  setIsDisposeOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setReRender: any;
  reRender: boolean;
}
const DisposeAssetmodel = ({
  isDisposeOpen,
  setIsDisposeOpen,
  setReRender,
  reRender,
}: ModelProps) => {
  // const [methodValue, setMethodValue] = React.useState<String>("Rate");
  // const [isConfirmation, setIsConfirmation] = React.useState<boolean>(false);
  const { currentClient } = useClient();
  const { openNotification } = useContext(notificationContext);
  const [form] = Form.useForm();
  const [loss, setLoss] = React.useState<number>(0);
  const [assetOption, setAssetOption] = React.useState<any[]>([]);
  const [fixedAssetList, setFixedAssetList] = React.useState<any[]>([]);
  const [totalRecords, setTotalRecords] = React.useState<number>(0);
  const [payLedgers, setPayLedgers] = useState<any>([]);
  const [paymentMode, setPaymentMode] = React.useState<any>();
  const [modelLoading, setModelLoading] = React.useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = React.useState<boolean>(false);
  const [customerOptions, setCustomerOptions] = React.useState<any[]>([]);
  const [checked, setChecked] = useState(false);

  const [journal, setJournal] = React.useState<{ open: boolean; data: any[] }>({
    open: false,
    data: [],
  });
  const [searchCustomer, setSearchCustomer] = React.useState<{
    search: string;
    length: number;
  }>({
    search: " ",
    length: 10,
  });
  const [searchAsset, setSearchAsset] = React.useState<{
    search: string;
    length: number;
  }>({
    search: " ",
    length: 10,
  });

  const [openModel, setOpenModel] = React.useState<{
    type: string;
    param: any;
  }>({
    type: "",
    param: "",
  });

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const onPaymentModeChange = (selValue: PmtMethod) => {
    if (selValue === PmtMethod.bank) {
      LedgerService.getLedgersByGroupName(
        currentClient?.id!,
        SearchLedgerByGroupsTypes.BankOnly
      ).then((res: any) => {
        setPayLedgers(res);
      });
    }
    if (selValue === PmtMethod.fullCredit) {
      LedgerService.getLedgersByGroupName(
        currentClient?.id!,
        SearchLedgerByGroupsTypes.PartiesOnly
      ).then((res: any) => {
        setPayLedgers(res);
      });
    }
    if (selValue === PmtMethod.cash) {
      LedgerService.getLedgersByGroupName(
        currentClient?.id!,
        SearchLedgerByGroupsTypes.CashOnly
      ).then((res: any) => {
        setPayLedgers(res);
      });
    }
    setPaymentMode(selValue);
  };

  const calculateDispose = (
    disDate: any,
    rate: any,
    price: any,
    method: CalcMethod,
    residualValue: any,
    startDate: any,
    lastDepDate: any,
    accumulatedDep: any,
    year: any,
    isDepreciableAsset: boolean
  ) => {
    let depreciation = 0;
    let leapyear = false;
    if (isDepreciableAsset) {
      const date1 = lastDepDate
        ? dayjs(lastDepDate, "YYYY/MM/DD")
        : dayjs(startDate, "YYYY/MM/DD");
      const date2 = dayjs(disDate, "DD/MM/YYYY");
      leapyear = date2.year() % 4 === 1 ? true : false;
      let yeardays = leapyear ? 366 : 365;
      let days = date2.diff(date1, "day") < 0 ? 0 : date2.diff(date1, "day");
      if (!lastDepDate || accumulatedDep === 0) days++;
      let calcPrice = price - accumulatedDep;
      if (method === CalcMethod.WrittenDownValue) {
        depreciation = (calcPrice * (rate / 100) * days) / yeardays;
      } else {
        depreciation = (((price - residualValue) / year) * days) / yeardays;
        if (depreciation > calcPrice) {
          depreciation = calcPrice > 0 ? calcPrice : 0;
        }
      }
    }
    return depreciation;
  };

  const onFieldsChange: any = async (val: any, disDate: any) => {
    //console.log("Value of Val:", val, disDate);
    let disPrice = form.getFieldValue("disPrice");
    let disposalPrice =
      disPrice && typeof disPrice === "string" && disPrice?.includes("₹")
        ? Utils.getNumber(disPrice?.slice(1))
        : Utils.getNumber(disPrice);
    let depreciation = calculateDispose(
      disDate,
      val?.title?.rate,
      val?.title?.price,
      val?.title?.method,
      val?.title?.residualValue,
      val?.title?.startDate,
      val?.title?.lastDepDate,
      val?.title?.accumulatedDepreciation,
      val?.title?.year,
      val?.title?.isDepreciable
    );

    form.setFieldValue(
      "deprOnDis",
      Utils.getFormattedCurrency(
        depreciation + val?.title?.accumulatedDepreciation
      )
    );
    form.setFieldValue(
      "wdv",
      Utils.getFormattedCurrency(
        val?.title?.price - (depreciation + val?.title?.accumulatedDepreciation)
      )
    );
    setLoss(
      disposalPrice -
        (val?.title?.price - depreciation - val?.title?.accumulatedDepreciation)
    );
    form.setFieldValue("payDate", disDate);
  };

  const onSubmit = () => {
    setButtonLoading(true);
    form
      .validateFields()
      .then(async (val) => {
        const DisPrice =
          val?.disPrice && val?.disPrice?.includes("₹")
            ? (val.disPrice = Utils.getNumber(
                val?.disPrice?.slice(1) + val?.rate
              ))
            : Utils.getNumber(val?.disPrice);

        const DeprOnDis =
          val?.deprOnDis && val?.deprOnDis?.includes("₹")
            ? (val.deprOnDis = Utils.getNumber(val?.deprOnDis?.slice(1)))
            : Utils.getNumber(val?.deprOnDis);

        const data = {
          disposalDate: dayjs(val?.disDate), //.format("DD/MM/YYYY"),
          disposalValue: DisPrice,
          depOnDisposal: DeprOnDis,
          profitNLoss: loss,
          paymentMethod: val?.paymentMethod,
          disposalLedger: val?.paymentLedger?.value,
          payRef: val?.payRef,
          payDate: dayjs(val?.payDate),
          // GSTinclude: checked,
          // bank: {
          //   id: val?.bank?.value,
          //   name: val?.bank?.label,
          // },
          // debtor: {
          //   paymentMethod: val?.paymentMethod,
          //   refrence: { id: val?.customer?.value, name: val?.customer?.label },
          // },
          // currentDep:
          //   DeprOnDis - (val?.assetName?.title?.accumulatedDepreciation ?? 0),
        };
        console.log("submit", data);
        FixedAssetsService.postDispose(
          currentClient?.id!,
          val?.assetName?.value,
          data
        )
          .then((res) => {
            if (res?.status && res?.result) {
              setJournal({ open: true, data: res?.result });
              openNotification("success", `Asset disposed successfully`);
              setReRender(() => !reRender);
            }
            setButtonLoading(false);
            setIsDisposeOpen(false);
          })
          .catch((ex) => {
            openNotification("error", ex);
          });
      })
      .catch(() => {
        setButtonLoading(false);
      });
  };

  React.useEffect(() => {
    setModelLoading(true);
    FixedAssetsService.getFixedAssetsList(
      currentClient?.id!,
      0,
      searchAsset?.length,
      null,
      "",
      searchAsset?.search,
      AssetStatus.Active
    ).then((res) => {
      if (res) {
        let data: any[] = res?.items?.map((itm: any) => ({
          key: itm?.id,
          value: itm?.id,
          label: itm?.name,
          title: {
            price: itm?.invoiceDetail?.price,
            rate: itm?.depRate,
            year: itm?.lifeCycleDuration,
            method: itm?.depMethod,
            residualValue: itm?.residualValue,
            accumulatedDepreciation: itm?.accumulatedDepreciation,
            lastDepDate: itm?.depreciationLastDate,
            startDate: itm?.depreciationStartDate,
            isDepreciable: itm?.isDepreciable,
            gst: itm?.gst?.rate,
          },
        }));
        setAssetOption([...data]);
      } else setAssetOption([]);
      setModelLoading(false);
    });
    // FixedAssetsService.searchAsset(
    //   currentClient?.id!,
    //   searchAsset.search,
    //   searchAsset.length
    // ).then((res) => {
    //   console.log("ressss", res);
    //   if (res?.result) {
    //     let data: any[] = res?.result?.map((itm: any) => ({
    //       key: itm?.fixedAsset?.id,
    //       value: itm?.fixedAsset?.id,
    //       label: itm?.fixedAsset?.name,
    //       title: {
    //         price: itm?.price,
    //         rate: itm?.rate,
    //         year: itm?.year,
    //         method: itm?.method,
    //         accumulatedDepreciation: itm?.accumulatedDepreciation,
    //         lastDepDate: itm?.lastDepDate,
    //         startDate: itm?.startDate,
    //         isDepreciable: itm?.isDepreciable,
    //       },
    //     }));
    //     setAssetOption([...data]);
    //   } else setAssetOption([]);
    //   setModelLoading(false);
    // });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchAsset?.search]);

  // // ----- Serch customer Api ------
  // React.useEffect(() => {
  //   SalesService.searchParties(
  //     currentClient?.id!,
  //     searchCustomer.search,
  //     TradingPartnerType.Customer,
  //     searchCustomer.length
  //   ).then((res: any) => {
  //     setCustomerOptions(res?.result);
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [searchCustomer.search]);

  const [addLedgerResponse, setAddLedgerResponse] = useState<any>(null);

  useEffect(() => {
    if (addLedgerResponse) {
      const exists = payLedgers.some(
        (option: any) => option?.id === addLedgerResponse.id
      );
      if (exists) {
        form.setFieldValue("paymentLedger", addLedgerResponse?.id);
        setAddLedgerResponse(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payLedgers]);

  return (
    <>
      <Modal
        title="Add Disposed Asset"
        maskClosable={false}
        open={isDisposeOpen}
        onCancel={() => setIsDisposeOpen(false)}
        width={520}
        footer={
          <>
            <Button onClick={() => setIsDisposeOpen(false)}>Cancel</Button>
            <Button type="primary" onClick={onSubmit} loading={buttonLoading}>
              Save
            </Button>
          </>
        }
      >
        <Spin indicator={antIcon} spinning={modelLoading}>
          <Form
            name="basic"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            style={{ maxWidth: 600 }}
            autoComplete="off"
            form={form}
            requiredMark={false}
            layout="vertical"
            size="middle"
          >
            <Row gutter={9}>
              <Col lg={16} md={12}>
                <Form.Item
                  name="assetName"
                  label="Asset Name"
                  rules={[{ required: true, message: "Name is required" }]}
                  className="inputboxsize inputboxmb inputLabel33"
                >
                  <Select
                    showSearch
                    allowClear
                    labelInValue
                    filterOption={false}
                    placeholder={"Search active asset"}
                    style={{ width: "312px" }}
                    options={assetOption}
                    onSearch={(value: string) => {
                      setSearchAsset({ search: value, length: 10 });
                    }}
                    // onClick={() =>
                    //   setSearchAsset({ ...searchCustomer, search: "" })
                    // }
                    onChange={(val: any) =>
                      onFieldsChange(val, form.getFieldValue("disDate"))
                    }
                  />
                </Form.Item>
              </Col>
              <Col lg={8} md={12}>
                <Form.Item
                  name="disDate"
                  className="inputBoxMb8  inputLabel33"
                  label="Date of Disposal"
                >
                  <DatePicker
                    allowClear={false}
                    format={"DD/MM/YYYY"}
                    // style={{ width: "160px" }}
                    // disabledDate={(current) =>
                    //   current && current > dayjs().endOf("day")
                    // }
                    onChange={(val: any) =>
                      onFieldsChange(form.getFieldValue("assetName"), val)
                    }
                  />
                </Form.Item>
              </Col>
              <Col lg={8} md={8}>
                <Form.Item
                  name="disPrice"
                  className="inputBoxMb8  inputLabel33"
                  label={
                    <Space>
                      Disposal Amount
                      <Tooltip
                        title="Please input the disposal amount excluding the GST means base disposal amount only"
                        placement="top"
                        color="#4b5b95"
                      >
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </Space>
                  }
                  rules={[{ required: true, message: "Enter price" }]}
                >
                  <Input
                    onBlur={(e) => {
                      let value = e?.target?.value
                        ? e?.target?.value?.includes("₹")
                          ? Utils.getNumber(e?.target?.value?.slice(1))
                          : Utils.getNumber(e?.target?.value)
                        : 0;
                      let wdv = form.getFieldValue("wdv");
                      let selAsset = form.getFieldValue("assetName");
                      let newWDV =
                        wdv && wdv?.includes("₹")
                          ? Utils?.getNumber(wdv?.slice(1))
                          : Utils?.getNumber(wdv);
                      let gstAmount =
                        value - (value * 100) / (selAsset?.title?.gst + 100);
                      let profitLoss = value - newWDV;
                      setLoss(profitLoss);
                      form.setFieldValue(
                        "disPrice",
                        Utils.getFormattedCurrency(value)
                      );
                    }}
                  />
                </Form.Item>
              </Col>
              {/* <Col lg={3} md={3}>
                <Form.Item
                  name="GSTinclude"
                  label="GST"
                  className="inputBoxMb8  inputLabel33 "
                >
                  <Checkbox checked={checked} onChange={onChange} />
                </Form.Item>
              </Col> */}
              <Col lg={8} md={8}>
                <Form.Item
                  name="deprOnDis"
                  className="inputBoxMb8  inputLabel33"
                  label="Depreciation"
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col lg={8} md={8}>
                <Form.Item
                  name="wdv"
                  className="inputBoxMb8  inputLabel33"
                  label="Written Down Value"
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col lg={10} md={7}>
                <Form.Item
                  name="paymentMethod"
                  className="inputBoxMb8  inputLabel33"
                  label="Payment Method"
                  rules={[{ required: true, message: "Select payment method" }]}
                >
                  <Select
                    placeholder="Select payment method"
                    onChange={onPaymentModeChange}
                    options={[
                      { value: PmtMethod.bank, label: "Bank" },
                      { value: PmtMethod.cash, label: "Cash" },
                      {
                        value: PmtMethod.fullCredit,
                        label: "Full Credit",
                      },
                      {
                        value: PmtMethod.partialCredit,
                        label: "Partial Payment",
                      },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col lg={14} md={7}>
                <Form.Item
                  name="paymentLedger" //"customer"
                  className="inputBoxMb8  inputLabel33"
                  label="Payment Ledger" //"Customer"
                  rules={[{ required: true, message: "Select Ledger" }]}
                >
                  <Select
                    showSearch
                    allowClear
                    labelInValue
                    optionFilterProp="children"
                    placeholder="Select Ledger"
                    popupMatchSelectWidth={230}
                    placement="bottomLeft"
                    className="selectedItem"
                    filterOption={(input, option) =>
                      (option?.label?.toString() ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    onSearch={(value: string) =>
                      setSearchCustomer({ search: value, length: 10 })
                    }
                    onClick={() =>
                      setSearchCustomer({ ...searchCustomer, search: "" })
                    }
                    options={payLedgers?.map((value: any) => ({
                      value: value?.id,
                      label: value?.ledgerName.concat(
                        value?.aliasName
                          ? `${" (" + value?.aliasName + ")"}`
                          : ""
                      ),
                    }))}
                    dropdownRender={(supplierOptions) => (
                      <>
                        {supplierOptions}
                        <Divider style={{ margin: "5px 0px" }} />
                        <Button
                          style={{ width: "100%" }}
                          icon={<PlusOutlined />}
                          type="link"
                          onClick={() => {
                            setOpenModel({
                              type: "addLedger",
                              param: "",
                            });
                            // setEditParty({ id: "" });
                          }}
                        >
                          New Ledger
                        </Button>
                      </>
                    )}
                  />
                </Form.Item>
              </Col>
              <Col lg={10} md={7}>
                <Form.Item
                  name="payDate"
                  className="inputBoxMb8  inputLabel33"
                  label="Payment Date"
                  rules={[{ required: true, message: "Select payment method" }]}
                >
                  <DatePicker
                    allowClear={false}
                    format={"DD/MM/YYYY"}
                    placeholder="Select Payment Date"
                  />
                </Form.Item>
              </Col>
              <Col lg={14} md={7}>
                <Form.Item
                  name="payRef" //"customer"
                  className="inputBoxMb8  inputLabel33"
                  label="Payment Reference" //"Customer"
                  // rules={[{ required: true, message: "Select Ledger" }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            {!isNaN(loss) && loss !== 0 && (
              <>
                {loss > 0 ? (
                  <Alert
                    message={`Profit on sale of fixed asset - ${Utils.getFormattedCurrency(
                      loss
                    )}`}
                    type="success"
                    showIcon
                  />
                ) : (
                  <Alert
                    message={`Loss on sale of fixed asset - ${Utils.getFormattedCurrency(
                      Math.abs(loss)
                    )}`}
                    type="error"
                    showIcon
                  />
                )}
              </>
            )}
          </Form>
        </Spin>
      </Modal>
      {/* {isConfirmation && (
        <>
          <ConfirmationModal
            open={isConfirmation}
            text={
              "All details filled will be lost, are you sure you want to continue"
            }
            onNo={() => setIsConfirmation(false)}
            onYes={() => {
              setIsConfirmation(false);
              setIsDisposeOpen(false);
              form.resetFields();
            }}
          />
        </>
      )} */}
      {/* {journal.open && (
        <PostJournal
          journal={journal}
          onDismiss={() => {
            setJournal({ open: false, data: [] });
            setIsDisposeOpen(false);
          }}
        />
      )} */}
      {openModel.type === "addLedger" && (
        <AddLedger
          openDrawer={true}
          id={""}
          setOpenDrawer={() => setOpenModel({ type: "", param: "" })}
          setTrigger={() =>
            onPaymentModeChange(form.getFieldValue("paymentMethod"))
          }
          setAddLedgerResponse={setAddLedgerResponse}
        />
      )}
    </>
  );
};
export default DisposeAssetmodel;

// interface IPostJournalProps {
//   journal: any;
//   onDismiss: (value: any) => void;
// }

// const PostJournal = ({ journal, onDismiss }: IPostJournalProps) => {
//   console.log("journal", journal?.data);

//   return (
//     <Modal
//       title="Disposal Journals"
//       open={journal?.open}
//       onCancel={onDismiss}
//       maskClosable={false}
//       footer={false}
//     >
//       <>
//         {journal?.data?.map((jrnl: any) => {
//           return (
//             <>
//               <div className="fw-600 mb-10">{jrnl?.description}</div>
//               {jrnl?.journalAccounts?.map((itm: any) => {
//                 return (
//                   <>
//                     <Row>
//                       <Col lg={16}>{itm?.accountHead?.name}</Col>
//                       <Col lg={3}>{itm?.amount > 0 ? "Dr" : "Cr"}</Col>
//                       <Col lg={5}>
//                         {Utils.getFormattedCurrency(itm?.amount)}
//                       </Col>
//                     </Row>
//                     <Divider className="my-7" />
//                   </>
//                 );
//               })}
//             </>
//           );
//         })}
//       </>
//     </Modal>
//   );
// };
