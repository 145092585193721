import {
  CloseOutlined,
  DeleteTwoTone,
  PaperClipOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import "../Assets/Assets.css";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
  Tooltip,
  Upload,
  Spin,
} from "antd";
import "../Sales/SalesStyle.css";
import React, { useContext, useEffect, useState } from "react";
import { ConfirmationModal } from "../../Common/ConfirmationModal";
import phoneImg from "../../../images/phoneIcon.svg";
import emailImg from "../../../images/emailIcon.svg";
import { NameComponent } from "../../Common/CAPComponents";
import { useClient } from "../../Context/ClientContext";
import "../../Common/Acc_Common.css";
import "./Party.css";
import TextArea from "antd/es/input/TextArea";
import FormItem from "antd/es/form/FormItem";
import PartyService from "../../Services/PartyService";
import { notificationContext } from "../../Common/PageRoute";
import { dayjs } from "../../../Utilities/dayjs";
import noteContext from "../../Context/StyleContext";
import { LoadingOutlined } from "@ant-design/icons";
import { useIndianState } from "../../Context/StateOptionsContext";
import { Utils } from "../../../Utilities/Utils";

interface AddPartyProps {
  openDrawer: boolean;
  setOpenDrawer?: any;
  setTrigger?: any;
  editParty?: any;
  setNewPartie: (value: string) => void;
}

const selectParty: any = [
  { label: "Both", value: 0 },
  { label: "Customer", value: 1 },
  { label: "Supplier", value: 2 },
];

const AddParty = ({
  openDrawer,
  setOpenDrawer,
  setTrigger,
  editParty,
  setNewPartie,
}: AddPartyProps) => {
  const { currentClient } = useClient();
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<any>([]);
  const [confirmation, setConfirmation] = useState<boolean>(false);
  const [touched, setTouched] = useState<boolean>(false);
  const [errorForBName, setErrorForBName] = useState<boolean>(false);
  const [checkbox, setCheckbox] = React.useState<number>(0);
  const { openNotification } = React.useContext(notificationContext);
  const [width, SetWidth] = useState<any>(window.innerWidth);
  const [modelLoading, setModelLoading] = React.useState<boolean>(false);
  const [saveButtonLoading, setSaveButtonLoading] = useState<boolean>(false);
  const [value, setValue] = React.useState<any>(" ");
  const { stateOptions } = useIndianState();
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  // console.log("state", stateOptions);
  useEffect(() => {
    if (editParty?.id != null) {
      setModelLoading(true);
      PartyService.getPartyById(currentClient!.id, editParty?.id)
        .then((res: any) => {
          if (res.result) {
            console.log("getdata", res.result);
            const contactInfo = res?.result?.contactInformation.map(
              (itm: any) => {
                return {
                  ...itm,
                  name: {
                    ...itm?.name,
                    title:
                      itm?.name?.title !== 0 ? itm?.name?.title : undefined,
                  },
                };
              }
            );
            form.setFieldsValue({
              ...res?.result,
              contactInformation: contactInfo,
              openingBalanceDate:
                res.result?.openingBalanceDate === ""
                  ? undefined
                  : dayjs(res.result?.openingBalanceDate),
              sameasAddress:
                res.result?.billingAddress === res.result?.shippingAddress,
            });
            setCheckbox(
              res?.result.contactInformation

                .map((e: any, i: number) => {
                  return e.isDefault;
                })

                .indexOf(true)
            );
            setModelLoading(false);
          }
        })
        .catch((err: any) => {
          setModelLoading(true);
          console.log("error", err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDrawer, editParty?.id]);

  // React.useEffect(() => {
  //   ClientService.getStateOption(false).then((res: any) => {
  //     setStatesOption(res?.result);
  //   });
  // }, []);

  const onDismissConfirm = () => {
    setConfirmation(false);
    setOpenDrawer(true);
  };

  const onDismissBothModels = () => {
    setTouched(false);
    setConfirmation(false);
    setOpenDrawer(false);
    setErrorForBName(false);
    form.resetFields();
  };

  const onClose = () => {
    if (touched) {
      setConfirmation(true);
    } else {
      form.resetFields();
      setErrorForBName(false);
      setConfirmation(false);
      setOpenDrawer(false);
    }
  };

  const addParty = () => {
    let data = form.validateFields().then((values) => {
      setSaveButtonLoading(true);
      const party = {
        ...values,
        openingBalanceDate: dayjs(values?.openingBalanceDate).format(
          "YYYY-MM-DD"
        ),
        // contactInformation: [...values.contactInformation],
        contactInformation: values.contactInformation.map(
          (index: any, i: number) => {
            console.log(checkbox);
            if (i === checkbox) {
              return Object.assign(index, { isDefault: true });
            } else if (i !== checkbox) {
              return Object.assign(index, { isDefault: false });
            }
          }
        ),
      };

      // console.log("partydata", party);
      PartyService.PostParty(currentClient?.id!, party, editParty.id)
        .then((res) => {
          if (res?.status) {
            console.log("message", errorForBName);
            setNewPartie(res?.result);
            openNotification(
              "success",
              `${editParty?.id ? "Updated" : "Saved"}Successfully`
            );
            setSaveButtonLoading(false);
            form.resetFields();
            setOpenDrawer(false);
            setTrigger((x: any) => !x);
          } else {
            openNotification("error", `Party details Not Save Successfully`);
            setSaveButtonLoading(false);
          }
        })
        .catch((err) => {
          openNotification("error", err.toString());
          setSaveButtonLoading(false);
          // console.log("hhh");
        });
    });

    console.log(data);
  };

  window.addEventListener("resize", () => {
    SetWidth(window.innerWidth);
  });

  const getwidth = () => {
    if (window.innerWidth) {
      return window.innerWidth === 1024 ? 680 : 920;
    }
  };

  //console.log(value, "test======================================");

  // console.log(windowWidth, "checkwindow width");

  return (
    <>
      <Drawer
        title={editParty.id === null ? "Add Party" : "Edit Party"}
        open={openDrawer}
        // onClose={onClose}
        width={getwidth()}
        maskClosable={false}
        closable={false}
        extra={
          <Space>
            <CloseOutlined onClick={onClose} />
          </Space>
        }
        footer={
          <Space
            style={{
              display: "flex",
              justifyContent: "end",
              marginRight: 20,
            }}
          >
            <Button onClick={onClose}>Cancel</Button>
            <Button
              onClick={() => addParty()}
              type="primary"
              loading={saveButtonLoading}
            >
              {editParty.id === null ? "Save" : "Update"}
            </Button>
          </Space>
        }
        className="drawerTitle partydrawer"
      >
        <Spin indicator={antIcon} spinning={modelLoading}>
          <Form
            form={form}
            name="customers"
            requiredMark={false}
            colon={false}
            initialValues={{
              openingstock: Utils.getFormattedNumber(0),
              atprice: 0,
              totalprice: 0,
              contactInformation: [{}],
              type: 0,
              state: 29,
              gstType: 0,
            }}
            scrollToFirstError
            autoComplete="off"
            layout="vertical"
          >
            <Row
              gutter={14}
              style={{ justifyContent: width <= 375 ? "center" : "" }}
            >
              <Col lg={7} md={7} sm={24}>
                <Form.Item
                  name={["gst", "gstin"]}
                  className="inputBoxMb8 inputLabel33"
                  label="GSTIN"
                  rules={[
                    {
                      pattern:
                        /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
                      message: "Enter Correct Gst Number",
                    },
                  ]}
                >
                  <Input
                    placeholder="GSTIN Search.."
                    allowClear={true}
                    onChange={(e: any) => {
                      if (e.target.value) setTouched(true);
                      else setTouched(false);
                    }}
                    suffix={<SearchOutlined />}
                  />
                </Form.Item>
              </Col>
              <Col lg={7} md={7} sm={24}>
                <Tooltip
                  placement="bottom"
                  color="#fff"
                  title={
                    <p className="grayColor fs-12">
                      Enter the name of the business to be reflected on the
                      invoice. (Eg. company's name, partnership firm's name,
                      sole trader business' name, individual's name, etc).
                    </p>
                  }
                >
                  <Form.Item
                    name="businessName"
                    className="inputBoxMb8 inputLabel33"
                    label="Business Name"
                    rules={[
                      {
                        required: true,
                        message: "Business name can't be empty",
                      },
                    ]}
                  >
                    <Input
                      value={value}
                      onChange={(e: any) => {
                        setValue(e.target.value.trimStart());
                        if (e.target.value) setTouched(true);
                        else setTouched(false);
                        const newvalue = e.target.value;
                        //setValue(newvalue.trimLeft());
                        const capitalvalue =
                          newvalue.charAt(0).toUpperCase() + newvalue.slice(1);
                        form.setFieldValue("businessName", capitalvalue);

                        //console.log(inputValue, "checkar");
                      }}
                      // style={{ width: "225px" }}
                      onKeyPress={(e) =>
                        !/[A-Za-z\s]/.test(e.key) && e.preventDefault()
                      }
                      style={{
                        width: window.innerWidth <= 425 ? "226px" : " ",
                      }}
                    />
                  </Form.Item>
                </Tooltip>
              </Col>

              <Col lg={7} md={7} sm={24}>
                <Form.Item
                  name="type"
                  className="inputBoxMb8 inputLabel33"
                  label="Party Type"
                >
                  <Select
                    placeholder="Select Party "
                    options={selectParty}
                    showSearch={true}
                    style={{
                      width: window.innerWidth <= 425 ? "226px" : " ",
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <div>
              <Divider
                orientation="left"
                orientationMargin="0"
                className="dividertext"
                style={{ marginTop: "11px", marginBottom: "11px" }}
              >
                <p style={{ fontWeight: 600 }}>Default Contact</p>
              </Divider>
            </div>
            <div
              style={{
                maxWidth: window.innerWidth <= 425 ? "800px" : "",
                overflowX: "auto",
              }}
            >
              <table style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th style={{ width: "28%" }} className="textStart fw-500 ">
                      Name
                    </th>
                    <th
                      style={{ width: "31%" }}
                      className="textStart px-12 fw-500"
                    >
                      Phone No.
                    </th>
                    <th
                      style={{ width: "29%", paddingLeft: "6px" }}
                      className="textStart fw-500"
                    >
                      Email
                    </th>
                    <th style={{ width: "3%" }}></th>
                  </tr>
                </thead>
                <Form.List name="contactInformation">
                  {(fields, { add, remove }) => (
                    <>
                      <tbody>
                        {fields.map(({ key, name, ...restField }, index) => (
                          <tr key={key}>
                            <td>
                              <Form.Item
                                name={[name, "name"]}
                                {...restField}
                                className="inputBoxMb8"
                              >
                                <NameComponent
                                  value={form.getFieldValue([
                                    "contactInformation",
                                    "name",
                                  ])}
                                  FormItemName={[name, "name"]}
                                  innerLabel={false}
                                />
                              </Form.Item>
                            </td>
                            <td className="px-12">
                              <Form.Item
                                name={[name, "phone"]}
                                {...restField}
                                className="inputBoxMb8"
                                rules={[
                                  {
                                    min: 10,
                                    message: "Phone no. must be 10 digits",
                                  },
                                ]}
                              >
                                <Input
                                  placeholder="0123456789"
                                  suffix={
                                    <img src={phoneImg} alt="phone"></img>
                                  }
                                  // addonBefore={
                                  //   <Form.Item
                                  //     name={[name, "prefix"]}
                                  //     {...restField}
                                  //     noStyle
                                  //   >
                                  //     <Select
                                  //       style={{ width: 67 }}
                                  //       options={[
                                  //         { value: "0", label: "+81" },
                                  //         { value: "1", label: "+91" },
                                  //       ]}
                                  //     />
                                  //   </Form.Item>
                                  // }
                                  maxLength={10}
                                  onKeyPress={(e) =>
                                    !/[0-9]/.test(e.key) && e.preventDefault()
                                  }
                                  onChange={(e) => {
                                    if (e.target.value) setTouched(true);
                                    else setTouched(false);
                                  }}
                                  style={{
                                    width:
                                      window.innerWidth <= 425 ? "160px" : "",
                                  }}
                                />
                              </Form.Item>
                            </td>
                            <td>
                              <Form.Item
                                name={[name, "email"]}
                                {...restField}
                                className="inputBoxMb8"
                                rules={[
                                  {
                                    type: "email",
                                    message: "Enter valid email address",
                                  },
                                ]}
                              >
                                <Input
                                  placeholder="user@exam.com"
                                  suffix={
                                    <img src={emailImg} alt="email"></img>
                                  }
                                  onChange={(e) => {
                                    if (e.target.value) setTouched(true);
                                    else setTouched(false);
                                  }}
                                  style={{
                                    width:
                                      window.innerWidth <= 425 ? "180px" : "",
                                  }}
                                />
                              </Form.Item>
                            </td>
                            <td>
                              <Form.Item className="inputBoxMb8 mleft">
                                <Checkbox
                                  checked={checkbox === index}
                                  onChange={() => {
                                    if (checkbox !== index) {
                                      setCheckbox(index);
                                      console.log("checkbox", checkbox);
                                      console.log("index", index);
                                    }
                                  }}
                                />
                              </Form.Item>
                            </td>
                            <td className="pb">
                              <DeleteTwoTone
                                className="ca-delete-btn"
                                onClick={() => {
                                  if (index === 0 && checkbox !== 0) {
                                    setCheckbox(checkbox - 1);
                                  } else if (
                                    checkbox === 0 &&
                                    fields.length > 1
                                  ) {
                                    // console.log("first delete");
                                    setCheckbox(0);
                                  } else if (checkbox !== 0 && checkbox !== 4) {
                                    // console.log("center delete");
                                    checkbox !== index - 1 &&
                                      setCheckbox(checkbox - 1);
                                  } else if (
                                    fields.length === index + 1 &&
                                    fields.length !== 1
                                  ) {
                                    // console.log("// last Delete");
                                    setCheckbox(index - 1);
                                  } else if (
                                    fields.length === index + 1 &&
                                    fields.length !== 1
                                  ) {
                                    setCheckbox(index - 1);
                                  }
                                  return fields.length === 1
                                    ? ""
                                    : remove(name);
                                }}
                                twoToneColor="#ff879d"
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td>
                            <Form.Item className="inputBoxMb">
                              <Row>
                                <Col>
                                  <Button
                                    type="link"
                                    onClick={() => add()}
                                    block
                                    icon={<PlusOutlined />}
                                    disabled={
                                      fields[4]?.name === 4 ? true : false
                                    }
                                  >
                                    Add Contact
                                  </Button>
                                </Col>
                              </Row>
                            </Form.Item>
                          </td>
                        </tr>
                      </tfoot>
                    </>
                  )}
                </Form.List>
              </table>
            </div>
            <Divider
              orientation="left"
              orientationMargin="0"
              className="dividertext"
              style={{ marginTop: "11px", marginBottom: "11px" }}
            >
              <p style={{ fontWeight: 600 }}>GST & Address Details</p>
            </Divider>
            <Row
              gutter={12}
              style={{ justifyContent: width <= 375 ? "center" : "" }}
            >
              <Col lg={7} md={7} sm={12}>
                <Form.Item
                  name="gstType"
                  label="GST Type"
                  className="inputBoxMb inputLabel33 inputBoxMb8"
                >
                  <Select
                    placeholder="GST Type"
                    options={[
                      { value: 0, label: "Unregistered" },
                      { value: 1, label: "Regular" },
                      {
                        value: 2,
                        label: "Composition",
                      },
                    ]}
                    style={{
                      width: window.innerWidth <= 425 ? "226px" : " ",
                    }}
                  />
                </Form.Item>
                <FormItem
                  name="state"
                  label="State"
                  className="inputLabel33 inputBoxMb8"
                >
                  <Select
                    showSearch
                    placeholder="State"
                    optionFilterProp="children"
                    options={stateOptions?.map((x: any) => ({
                      value: x.id,
                      label: x.name,
                    }))}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    style={{
                      width: window.innerWidth <= 425 ? "226px" : " ",
                    }}
                  />
                </FormItem>
              </Col>
              <Col lg={7} md={7} sm={12}>
                <FormItem
                  name="billingAddress"
                  label="Billing Address"
                  className="inputLabel33 inputBoxMb8"
                >
                  <TextArea
                    rows={4}
                    //placeholder="Billing Address"
                    className="textarea-size "
                    onChange={(e: any) => {
                      const billaddress = form.getFieldValue("billingAddress");
                      const shipaddress = form.getFieldValue("shippingAddress");
                      if (billaddress === shipaddress) {
                        //  console.log("hello", billaddress === shipaddress);
                        form.setFieldValue("sameasAddress", true);
                      } else {
                        form.setFieldValue("sameasAddress", false);
                      }
                      // console.log(billaddress, "bill", shipaddress);
                    }}
                    style={{
                      width: window.innerWidth <= 425 ? "226px" : " ",
                    }}
                  />
                </FormItem>
              </Col>
              <Col lg={7} md={7}>
                <FormItem
                  name="shippingAddress"
                  label="Shipping Address"
                  className="inputLabel33 inputBoxMb8"
                >
                  <TextArea
                    rows={4}
                    //placeholder="Shipping Address"
                    className="textarea-size"
                    onChange={(e: any) => {
                      const billaddress = form.getFieldValue("billingAddress");
                      const shipaddress = form.getFieldValue("shippingAddress");
                      if (billaddress === shipaddress) {
                        form.setFieldValue("sameasAddress", true);
                      } else {
                        form.setFieldValue("sameasAddress", false);
                      }
                      // console.log(shipaddress, "ship", billaddress);
                    }}
                    style={{
                      width: window.innerWidth <= 425 ? "226px" : " ",
                    }}
                  />
                </FormItem>
              </Col>
            </Row>
            <Row
              justify={"start"}
              style={{ justifyContent: width <= 375 ? "center" : "" }}
            >
              <Col lg={7}></Col>
              <Col lg={7}></Col>
              <Col
                lg={7}
                // style={{
                //   paddingLeft: window.innerWidth === 1240 ? "365px" : "8px",
                // }}
              >
                <Form.Item name="sameasAddress" valuePropName="checked">
                  <Checkbox
                    onChange={(e: any) => {
                      if (e.target.checked) {
                        form.setFieldValue(
                          "shippingAddress",
                          form.getFieldValue("billingAddress")
                        );
                      } else {
                        form.setFieldValue("shippingAddress", " ");
                      }
                    }}
                  >
                    Same as Billing Address
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Divider
              orientation="left"
              orientationMargin="0"
              className="dividertext"
              style={{ marginTop: "0px", marginBottom: "11px" }}
            >
              <p style={{ fontWeight: 600 }}>Additional Information</p>
            </Divider>
            <Row
              gutter={12}
              style={{ justifyContent: width <= 375 ? "center" : "" }}
            >
              <Col lg={7} md={6} sm={24}>
                <Form.Item
                  name="openingBalance"
                  className="inputBoxMb8  inputLabel33"
                  label="Opening Balance"
                  rules={[
                    { pattern: /^[0-9]*$/, message: "Enter only Number!" },
                  ]}
                >
                  <Input
                    style={{
                      width: window.innerWidth <= 425 ? "226px" : " ",
                    }}
                  />
                </Form.Item>
              </Col>
              <Col lg={7} md={6} sm={12}>
                <Form.Item
                  name="openingBalanceDate"
                  className="inputBoxMb8  inputLabel33"
                  label="Date"
                >
                  <DatePicker
                    allowClear={false}
                    format={"DD-MM-YYYY"}
                    style={{
                      width: window.innerWidth <= 425 ? "226px" : " ",
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row
              gutter={6}
              style={{ justifyContent: width <= 375 ? "center" : "" }}
            >
              <Col lg={18} md={18}>
                <Form.Item
                  name="notes"
                  label="Notes"
                  className="inputBoxMb8  inputLabel33 pt-5 "
                >
                  <TextArea
                    onChange={(e) => {
                      if (e.target.value) setTouched(true);
                      else setTouched(false);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col lg={1} md={1} style={{ paddingTop: "34px" }}>
                <Upload
                  accept=".image/jpeg"
                  beforeUpload={() => {
                    return false;
                  }}
                  className="imgpreview"
                  name="attachment"
                  listType="text"
                  maxCount={1}
                >
                  <Button className="imgupload ">
                    <PaperClipOutlined />
                  </Button>
                </Upload>
              </Col>
            </Row>
            {fileList.map((x: any) => {
              return <Row>{x?.name}</Row>;
            })}

            {confirmation && (
              <ConfirmationModal
                open={confirmation}
                text="The changes you made will not going to save, are you sure you want to continue?"
                onNo={onDismissConfirm}
                onYes={onDismissBothModels}
              />
            )}
          </Form>
        </Spin>
      </Drawer>
    </>
  );
};

export default AddParty;
