import { useHotkeys } from "react-hotkeys-hook";

interface EntryLevelKeys {
  createNew?: () => void;
  save: () => void;
  saveAndNew?: () => void;
  saveAndPrint?: () => void;
  cancel?: () => void;
}
const EntryLevelKeys = ({
  createNew,
  save,
  saveAndNew,
  saveAndPrint,
  cancel,
}: any) => {
  useHotkeys("alt+n", (event) => {
    event.preventDefault();
    createNew();
    // notify("Save");
  });
  useHotkeys("alt+s", (event) => {
    event.preventDefault();
    save();
    // notify("Save");
  });
  useHotkeys("alt+s+n, alt+d", (event) => {
    event.preventDefault();
    saveAndNew();
    // notify("Save & New");
  });
  useHotkeys("alt+p", (event) => {
    event.preventDefault();
    saveAndPrint();
    // notify("Save & Print");
  });
  useHotkeys("alt+c", (event) => {
    event.preventDefault();
    cancel();
    // notify("Cancel / Discard");
  });
};
export default EntryLevelKeys;
