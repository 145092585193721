import { PurchaseInvoiceTypes } from "../../Types/CommonConstants";
import { IAddress } from "../../Types/CommonTypes";
import { ApiUtility } from "../../Utilities/ApiUtility.axios";
import {
  CAPItemTypes,
  DBRecordStatus,
  InvoiceStatus,
  InvoiceTypes,
  NameTitles,
} from "./SalesService";

interface ContactModeModel {
  primary: string | undefined;
  secondary: string | undefined;
}
interface ContactAccountDetails {
  name: string;
  sortCode: string;
  number: string;
}
interface ContactPersonDetails {
  contactName: INameModel;
  phone: string;
  email: string;
}
export enum CAPContactTypes {
  Customer,
  Supplier,
}


export interface INameModel {
  title: NameTitles;
  first: string | undefined;
  last: string | undefined;
}

export enum PurchaseOrderTypes {
  PurchaseOrder = 1,
  Recurring = 2,
}

export interface IContact {
  id: string;
  number: string;
  type: CAPContactTypes;
  name: INameModel;
  bName: string | undefined;
  phone: ContactModeModel;
  email: ContactModeModel;
  bAddress: IAddress;
  sAddress: IAddress;
  //   language: Languages;
  //   bankName: IIdName<number>;
  accDetails: ContactAccountDetails;
  //   currency: IIdName;
  //   discount: string | undefined;
  //   payOption: ContactPaymentOptions;
  //   vat: ContactVatDetail;
  //   tags: IIdName<number>[];
  notes: string | undefined;
  contactPerson: ContactPersonDetails;
  fax: string;
  website: string;
  //   attachments: Partial<IFileNameModel> & Pick<IFileNameModel, "name">[];
  //   files?: Partial<IFileNameModel> & Pick<IFileNameModel, "name">[];
  //   payTerms: IDueOn;
  //   unAllocatedAmount: number;
}

class PurchaseService {
  route = "v1/API";

  postSupplier = (clientId: string, params: any, id?: string) => {
    return ApiUtility.postForm(
      `${this.route}/Acc_TradingPartners/${clientId}${id ? "/" + id : ""}`,
      params
    );
  };

  getSupplierList = (
    clientId: any,
    type: CAPContactTypes.Supplier,
    start: any,
    length: any,
    sortCol?: any,
    sortDir?: any,
    search?: any,
    status?: any
  ) => {
    return ApiUtility.getResult(
      `${this.route}/Acc_TradingPartners/${clientId}`,
      {
        type,
        start,
        length,
        sortCol,
        sortDir,
        search,
        status,
      }
    );
  };

  getCustomerById = (clientId: string, id?: string) => {
    return ApiUtility.get(
      `${this.route}/Acc_TradingPartners/${clientId}/${id}`
    );
  };

  //---------- Purchase Invoice API ----------

  // used as initial value, get data by id and for view only data
  getPurchaseInvoices = (
    clientId: string,
    type?: PurchaseInvoiceTypes,
    viewOnly?: boolean,
    id?: string,
    isCopy?: boolean,
    invDate?: string
  ) =>
    ApiUtility.get(
      `${this.route}/PurchaseInvoice/${clientId}/Get?${id ? "id=" + id : ""}`,
      {
        isCopy,
        type,
        viewOnly,
        invDate,
      }
    );

  // https: //localhost:7142/V1/API/PurchaseInvoice/64647592e72dfee880fd3f5d/Get?isCopy=false&type=1&viewOnly=fals

  // initialPurchaseInvoice = (clientId: string, type: PurchaseInvoiceTypes) => {
  //   return ApiUtility.get(
  //     `${this.route}/PurchaseInvoice/${clientId}/Initial?type=${type}`
  //   );
  // };
  // https: //localhost:7142/V1/API/PurchaseInvoice/64647592e72dfee880fd3f5d/Initial?type=1

  searchPurchaseItem = (
    clientId: string | undefined,
    term: string,
    isService: boolean,
    type: CAPItemTypes,
    length: number = 15
  ) =>
    ApiUtility.getResult(
      `${this.route}/Acc_Item/${clientId}/Search?term=${
        term ?? ""
      }&length=${length}&isService=${isService}&type=${type}`
    );

  postPurchaseInvoice = (clientId: string, params: any, id?: string) => {
    return ApiUtility.postForm(
      `${this.route}/PurchaseInvoice/${clientId}${id ? "/" + id : ""}`,
      params
    );
  };

  // getPurchaseInvoice = (clientId: string, id?: string) =>
  //   ApiUtility.get(`${this.route}/PurchaseInvoice/${clientId}/${id}`);

  getPurchaseInvoiceList = (
    clientId: any,
    status: InvoiceStatus,
    type: PurchaseInvoiceTypes,
    start: number,
    length: number,
    sortCol?: any,
    sortDir?: any,
    search?: any,
    from?: any,
    to?: any,
    partyId?: string
  ) => {
    return ApiUtility.getResult(`${this.route}/PurchaseInvoice/${clientId}`, {
      status,
      type,
      start,
      length,
      sortCol,
      sortDir,
      search,
      from,
      to,
      partyId,
    });
  };

  deletePurchaseInvoice = (
    clientId: string,
    id: string,
    reactivate: boolean,
    isDebitNote: boolean
  ) =>
    ApiUtility.get(
      `${this.route}/PurchaseInvoice/${clientId}/${id}/Delete?reactivate=${reactivate}&isDebitNote=${isDebitNote}`
    );

  getPurchaseInvoiceNumberDetails = (
    clientId: string,
    invoiceId: string,
    start: number,
    length: number,
    sortCol?: string,
    sortDir?: string
  ) =>
    ApiUtility.getResult(
      `${this.route}/PurchaseInvoice/${clientId}/purchaseInvoice/${invoiceId}`,
      {
        start,
        length,
        sortCol,
        sortDir,
      }
    );

  //---------- Purchase order API ----------
  initialValuePurchaseOrder = (clientId: string, type: number) =>
    ApiUtility.get(
      `${this.route}/PurchaseOrder/${clientId}/Initial?type=${type}`
    );
  postPurchaseOrder = (clientId: string, params: any, id?: string) =>
    ApiUtility.postForm(
      `${this.route}/PurchaseOrder/${clientId}${id ? "/" + id : ""}`,
      params
    );
  getPurchaseOrderList = (
    clientId: any,
    status: InvoiceStatus,
    type: InvoiceTypes,
    start: number,
    length: number,
    sortCol?: any,
    sortDir?: any,
    search?: any,
    from?: any,
    to?: any,
    partyId?: string
  ) =>
    ApiUtility.getResult(`${this.route}/PurchaseOrder/${clientId}`, {
      status,
      type,
      start,
      length,
      sortCol,
      sortDir,
      search,
      from,
      to,
      partyId,
    });

  getPurchaseOrder = (
    clientId: string,
    type: PurchaseOrderTypes,
    id?: string
  ) =>
    ApiUtility.get(
      `${this.route}/PurchaseOrder/${clientId}/Get?${id ? "id=" + id : ""}`,
      type
    );

  deletePurchaseOrder = (
    clientId: string,
    id: string,
    recordStatus: DBRecordStatus
  ) =>
    ApiUtility.post(
      `${this.route}/PurchaseOrder/${clientId}/Delete/${id}/${recordStatus}`,
      ""
    );

  //---------- Purchase PaymentOut API ----------

  getPaymentOutData = (
    clientId: string,
    id?: string,
    invoiceId?: string,
    accounts?: boolean,
    partyId?: string
  ) => {
    return ApiUtility.get(`${this.route}/PaymentOut/Get?clientId=${clientId}`, {
      id,
      invoiceId,
      accounts,
      partyId,
    });
  };

  postPaymentOut = (clientId: string, params: any, id?: string) => {
    return ApiUtility.postForm(
      `${this.route}/PaymentOut/${id ? "/" + id : ""}?clientId=${clientId}`,
      params
    );
  };

  getPaymentOutList = (
    clientId: string,
    start: number,
    length: number,
    search?: string,
    fromDate?: string,
    toDate?: string,
    status?: string,
    sortCol?: string,
    sortDir?: string,
    partyId?: string
  ) => {
    return ApiUtility.getResult(
      `${this.route}/PaymentOut?clientId=${clientId}`,
      {
        fromDate,
        toDate,
        start,
        length,
        sortCol,
        sortDir,
        search,
        status,
        partyId,
      }
    );
  };

  deletePaymentOut = (clientId: string, reactivate: boolean, id: string) =>
    ApiUtility.get(
      `${this.route}/PaymentOut/${id}/Delete?clientId=${clientId}&reactivate=${reactivate}`
    );

  // https://localhost:7142/v1/API/PaymentOut/64dca26cd777154bde3839e8/Delete?clientId=64647592e72dfee880fd3f5d&reactivate=false
}
export default new PurchaseService();
