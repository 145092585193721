import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Col, Modal, Row, Table, TableColumnsType, Upload } from "antd";
import React from "react";
import { useTheme } from "../../../Context/ThemeContext";
import Icon from "@ant-design/icons/lib/components/Icon";
import Papa from "papaparse";
import { useNavigate } from "react-router-dom";
import { useClient } from "../../../Context/ClientContext";
import * as XLSX from "xlsx";
import { Party_Tax } from "../../../Services/VoucherServices";
import BankTransactions from "../../../Services/BankTransactions";

type ParsedDataType = {
  [key: string]: any;
};

interface ColumnType {
  title: string;
  dataIndex: string;
  key: string;
}

interface IProps {
  open: boolean;
  onCancel: any;
  ledgerId: string;
  partyOrTax?: any;
}

const ImportBankTransactionModal = ({
  open,
  onCancel,
  ledgerId,
  partyOrTax,
}: IProps) => {
  console.log("props", ledgerId);

  const navigate = useNavigate();
  const { currentClient } = useClient();
  const [tableRows, setTableRows] = React.useState<any>();
  const [values, setValues] = React.useState<any>([]);
  const [parsedData, setParsedData] = React.useState<any>([]);

  const { themeData } = useTheme();
  // const handleBeforeUpload = (file: any) => {
  //   const reader = new FileReader();
  //   reader.onload = (e: any) => {
  //     Papa.parse(e.target.result, {
  //       quoteChar: '"',
  //       escapeChar: '"',
  //       delimiter: ",",
  //       header: true,
  //       skipEmptyLines: true,
  //       complete: function (results: Papa.ParseResult<any>) {
  //         const rowsArray: any[] = [];
  //         const valuesArray: any[] = [];
  //         results.data.map((d: any) => {
  //           rowsArray.push(Object.keys(d));
  //           valuesArray.push(Object.values(d));
  //         });
  //         setParsedData(results.data);
  //         setTableRows(rowsArray[0]);
  //         setValues(valuesArray);
  //         navigate(
  //           `/accounts/clients/${currentClient?.id}/banking/bankAccount/bankTransactionsDetails`,
  //           {
  //             state: { CSVData: rowsArray[0], parsedData: results.data },
  //           }
  //         );
  //       },
  //     });
  //   };
  // reader.readAsText(file);
  // return false;
  // };

  console.log("pope", ledgerId);

  const handleBeforeUpload = (file: File) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const binaryStr = e.target?.result as string;
      const workbook = XLSX.read(binaryStr, { type: "binary" });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];

      // Convert sheet to JSON where headers are used as keys
      const jsonData = XLSX.utils.sheet_to_json(worksheet, {
        header: 1,
      }) as any[][];

      if (jsonData.length > 0) {
        // Extract header row
        const header = jsonData[0];
        const rowsArray = jsonData.slice(1); // Data rows

        // Convert header to an array of objects for table columns
        const columns: ColumnType[] = header.map((col) => ({
          title: col,
          dataIndex: col,
          key: col,
        }));

        // Convert rowsArray to array of objects with column headers as keys
        const formattedData: ParsedDataType[] = rowsArray.map((row) =>
          row.reduce((acc, value, index) => {
            acc[header[index]] = value;
            return acc;
          }, {} as ParsedDataType)
        );

        setParsedData(formattedData);
        setTableRows(columns); // Columns for the table
        setValues(formattedData); // Data for the table

        onCancel(false);
        // Navigate with additional state including the file name
        navigate(
          `/accounts/clients/${currentClient?.id}/banking/bankTransactionsDetails`,
          {
            state: {
              ledgerId: ledgerId,
              partyOrTax: partyOrTax,
              CSVData: header,
              parsedData: formattedData,
              fileName: file.name, // Add the file name to the state,
              // activeTab: "1",
            },
          }
        );
      }
    };

    reader.readAsBinaryString(file);
    return false;
  };

  const handleDownload = () => {
    // Public folder ke andar Docs folder mein rakhi file ka URL
    const fileUrl = `${process.env.PUBLIC_URL}/Docs/BankTransactionsTemplate.xlsx`;
    // Hidden anchor element banaye jo file ko download karega
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = "BankTransactionsTemplate.xlsx"; // File ka naam jo download hoga
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const dataSource = [
    // {
    //   key: "1",
    //   name: "Ignore",
    //   required: "",
    //   notes: "Any data in this field is ignored",
    // },
    {
      key: "2",
      name: "Reference",
      required: "YES",
      notes:
        "Any transaction references like Cheque No., RTGS / NEFT / IMPS reference No., narration of transaction, etc. can be put here.",
    },
    // {
    //   key: "3",
    //   name: "Amount",
    //   required: "",
    //   notes:
    //     "Use this field for statements which provide single column for money-in and money-out.",
    // },
    {
      key: "4",
      name: "Money In",
      required: "YES",
      notes: "Please provide only positive values.",
      // notes:
      //   "Accepts only positive values. Use this field for statements which provide two columns for money-in and money-out.",
    },
    {
      key: "5",
      name: "Money Out",
      required: "YES",
      notes: "Please provide only positive values.",
      // notes:
      // "Accepts only positive values. Use this field for statements which provide two columns for money-in and money-out.",
    },
    {
      key: "6",
      name: "Date",
      required: "YES",
      notes: "Format: DD/MM/YYYY or DD-MM-YYYY",
    },
    // {
    //   key: "7",
    //   name: "Detail",
    //   required: "YES",
    //   notes: "Detail",
    // },
  ];

  const columns: TableColumnsType<any> = [
    {
      title: "Field Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Required",
      dataIndex: "required",
      key: "require",
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
    },
  ];
  return (
    <>
      <div>
        <Modal
          width={1200}
          open={open}
          title="Bank Transaction"
          onCancel={() => onCancel()}
          footer={
            <div style={{ marginTop: "0px" }}>
              <Button
                onClick={() => {
                  onCancel();
                }}
              >
                Close
              </Button>
            </div>
          }
        >
          <Row
            gutter={20}
            style={{ justifyContent: "space-between", padding: "15px" }}
          >
            <Col span={7} className="card">
              <p
                style={{
                  fontSize: "15px",
                  paddingTop: "1px",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                Step 1. Download our bank template file.
              </p>
              <p
                style={{
                  paddingTop: "10px",
                  lineHeight: "1.4rem",
                  color: "black",
                }}
              >
                You can download our XLSX bank template file. The file has got
                accurate prescribed headings which are to be imported.{" "}
              </p>
              <p
                style={{ color: "blue", cursor: "pointer", paddingTop: "5px" }}
                onClick={handleDownload}
              >
                <span>
                  <DownloadOutlined /> Download Template
                </span>
              </p>
            </Col>
            <Col span={7} className="card" style={{ margin: "0px 10px" }}>
              <p
                style={{
                  fontSize: "15px",
                  paddingTop: "1px",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                Step 2. Copy Data into template file.
              </p>
              <p
                style={{
                  paddingTop: "10px",
                  height: "100%",
                  lineHeight: "1.4rem",
                  color: "black",
                }}
              >
                You can copy your data into the template file using excel or any
                other spreadsheet editor. Make sure the bank data you copied,
                matches the column headings provided in the template. Headings
                are not changeable.
              </p>
            </Col>
            <Col span={7} className="card">
              <p
                style={{
                  fontSize: "15px",
                  paddingTop: "1px",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                Step 3. Import the filled template file.
              </p>
              <p
                style={{
                  paddingTop: "10px",
                  height: "100%",
                  lineHeight: "1.4rem",
                  color: "black",
                }}
              >
                <Upload
                  //   {...Props}
                  accept=".xlsx"
                  showUploadList={false}
                  beforeUpload={handleBeforeUpload}
                >
                  <Button icon={<UploadOutlined />} type="primary">
                    Import
                  </Button>
                </Upload>
              </p>
            </Col>
          </Row>
          <div style={{ marginTop: "20px" }}>
            <p
              style={{
                padding: "5px 10px 5px 5px",
                boxShadow: " 0 2px 6px rgba(0, 0, 0, 0.1)",
                width: "10%",
                fontSize: "16px",
                borderRadius: "5px",
              }}
            >
              Available Fields
            </p>
            <Table
              bordered
              pagination={false}
              className={`Tabel-style table-${
                themeData?.componentSize ?? "middle"
              }`}
              size="small"
              columns={columns}
              dataSource={dataSource}
            />
          </div>
        </Modal>
      </div>
    </>
  );
};

export default ImportBankTransactionModal;
